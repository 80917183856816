import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { gridPageCountSelector, gridPageSelector, gridPageSizeSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';

const DataGridPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      size="small"
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
          {...item}
        />
      )}
      onChange={(event, value) => {
        apiRef.current.setPaginationModel({
          page: value - 1,
          pageSize
        });
      }}
    />
  );
}

export default DataGridPagination;
