import { useRef } from 'react';

import { useQueryClient } from 'react-query';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';
import translitRuEn from '../../utils/translitRuEn';
import DragAndDropIcon from '../icons/DragAndDropIcon';
import QuestionIcon from '../icons/QuestionIcon';

// тело конструктора отчета
const CustomBodyReport = ({
  isBodyReportOpen,
  setIsBodyReportOpen,
  isClientlReportOpen,
  setIsClientReportOpen,
  bodyAtrCheckboxes,
  handleColumnAllNames,
  setBodyAtrCheckboxes,
  handleColumnNames,
  dataCustomDevices,
  handleColumnFilters,
  bodyClientAtrCheckboxes,
  setBodyClientAtrCheckboxes,
  selectedDevice,
  bodyChanneUspdlAtrCheckboxes,
  setBodyChannelUspdAtrCheckboxes,
  bodyChanneSenslAtrCheckboxes,
  setBodyChannelSensAtrCheckboxes,
}: any) => {
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  // сортировка при перетаскивании чекбоксов
  const handleSort = (setStateCheckboxes: React.Dispatch<React.SetStateAction<any>>) => {
    setStateCheckboxes((prevCheckboxes: any) => {
      const newCheckboxes = {
        ...prevCheckboxes,
      };

      const draggedItem = newCheckboxes.attr.columnAtrNames[dragItem.current];
      newCheckboxes.attr.columnAtrNames.splice(dragItem.current, 1);
      newCheckboxes.attr.columnAtrNames.splice(dragOverItem.current, 0, draggedItem);

      const draggedFilterItem = newCheckboxes.attr.columnAtrFilters[dragItem.current];
      newCheckboxes.attr.columnAtrFilters.splice(dragItem.current, 1);
      newCheckboxes.attr.columnAtrFilters.splice(dragOverItem.current, 0, draggedFilterItem);

      return newCheckboxes;
    });
  };

  return (
    <Grid container spacing={3} flexWrap="nowrap">
      <Grid item xs={6}>
        <Box
          sx={{
            padding: 3,
            background: (theme) => theme.palette.background.secondary,
            borderRadius: '16px',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3">Тело отчета</Typography>
              <Tooltip
                arrow
                title={
                  <Typography variant="inherit" component="span">
                    В форме создания тела отчета выбираются атрибуты, которые будут отображаться в
                    табличной части отчета. В колонках отчета будут отображаться значения атрибутов.
                    Есть возможность добавить пользовательское поле отчета.
                  </Typography>
                }
                style={{ marginLeft: '6px' }}
              >
                <div data-testid="filter-tooltip" style={{ lineHeight: 0, marginLeft: 4 }}>
                  <QuestionIcon fontSize="small" color="secondary" />
                </div>
              </Tooltip>
            </div>
            <div style={{ cursor: 'pointer', lineHeight: 0 }}>
              {isBodyReportOpen ? (
                <KeyboardArrowUp
                  style={{ fontSize: '2.5rem' }}
                  onClick={() => setIsBodyReportOpen((prevState: any) => !prevState)}
                />
              ) : (
                <KeyboardArrowDown
                  style={{ fontSize: '2.5rem' }}
                  onClick={() => setIsBodyReportOpen((prevState: any) => !prevState)}
                />
              )}
            </div>
          </Stack>
          {isBodyReportOpen && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bodyAtrCheckboxes?.attr?.columnAllChecked}
                        onChange={(event) => {
                          handleColumnAllNames(event, bodyAtrCheckboxes, setBodyAtrCheckboxes);
                          sendMetrik(
                            'vntVdknl',
                            'otchety_konstruktor',
                            'checkbox_click',
                            'naimenovanie_atributa',
                            event.target.checked,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/reports/customReportCreate',
                            null,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                        color="primary"
                      />
                    }
                    label="Наименование атрибута"
                    sx={{
                      '& .MuiTypography-root': { fontSize: 12, pl: 4 },
                      ml: 1.5,
                    }}
                  />
                  <Typography sx={{ fontSize: 12 }}>Отображать в фильтрах</Typography>
                  <div></div>
                </Box>
                <Divider sx={{ my: 2 }}></Divider>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {bodyAtrCheckboxes?.attr?.columnAtrNames?.map((option: any, index: any) => (
                      <FormControlLabel
                        key={option?.name}
                        draggable={true}
                        onDragStart={(e) => (dragItem.current = index)}
                        onDragEnter={(e) => (dragOverItem.current = index)}
                        onDragEnd={() => handleSort(setBodyAtrCheckboxes)}
                        onDragOver={(e) => e.preventDefault()}
                        control={
                          <>
                            <DragAndDropIcon
                              sx={{ width: '6px', height: '10px', marginRight: '6px' }}
                            />
                            <Checkbox
                              checked={option?.checked}
                              onChange={(event) => {
                                handleColumnNames(
                                  event,
                                  index,
                                  dataCustomDevices?.attrib_name_mapping_for_rg_common,
                                  bodyAtrCheckboxes,
                                  setBodyAtrCheckboxes,
                                );

                                sendMetrik(
                                  'vntVdknl',
                                  'otchety_konstruktor',
                                  'checkbox_click',
                                  translitRuEn(option?.label).toLowerCase(),
                                  event.target.checked,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/reports/customReportCreate',
                                  null,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }}
                              color="primary"
                            />
                          </>
                        }
                        label={option?.label}
                        sx={{
                          '& .MuiTypography-root': { fontSize: 14, pl: 4 },
                          pb: 3,
                        }}
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', pr: 5 }}>
                    {bodyAtrCheckboxes?.attr?.columnAtrFilters?.map((option: any, index: any) => (
                      <FormControlLabel
                        key={option?.name}
                        control={
                          <Checkbox
                            checked={option?.checked}
                            onChange={(event) => {
                              handleColumnFilters(
                                event,
                                index,
                                dataCustomDevices?.attrib_name_mapping_for_rg_common,
                                bodyAtrCheckboxes,
                                setBodyAtrCheckboxes,
                              );

                              sendMetrik(
                                'vntVdknl',
                                'otchety_konstruktor',
                                'checkbox_click',
                                translitRuEn(option?.label).toLowerCase(),
                                event.target.checked,
                                userData?.permissions[0].uuid,
                                userData ? '1' : '0',
                                '/reports/customReportCreate',
                                null,
                                null,
                                null,
                                'interactions',
                                userData?.profile_type,
                                'web',
                              );
                            }}
                            disabled={option?.disabled}
                            color="primary"
                          />
                        }
                        label={option?.label}
                        sx={{ pb: 3 }}
                      />
                    ))}
                  </Box>
                  <Box></Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            padding: 3,
            background: (theme) => theme.palette.background.secondary,
            borderRadius: '16px',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3">Пользовательские атрибуты</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                lineHeight: 0,
              }}
            >
              {isClientlReportOpen ? (
                <KeyboardArrowUp
                  style={{ fontSize: '2.5rem' }}
                  onClick={() => setIsClientReportOpen((prevState: any) => !prevState)}
                />
              ) : (
                <KeyboardArrowDown
                  style={{ fontSize: '2.5rem' }}
                  onClick={() => setIsClientReportOpen((prevState: any) => !prevState)}
                />
              )}
            </div>
          </Stack>
          {isClientlReportOpen && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bodyClientAtrCheckboxes?.attr?.columnAllChecked}
                        onChange={(event) => {
                          handleColumnAllNames(
                            event,
                            bodyClientAtrCheckboxes,
                            setBodyClientAtrCheckboxes,
                          );
                          sendMetrik(
                            'vntVdknl',
                            'otchety_konstruktor',
                            'checkbox_click',
                            'naimenovanie_atributa',
                            event.target.checked,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/reports/customReportCreate',
                            null,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                        color="primary"
                      />
                    }
                    label="Наименование атрибута"
                    sx={{
                      '& .MuiTypography-root': { fontSize: 12, pl: 4 },
                      ml: 1.5,
                    }}
                  />
                  <Typography sx={{ fontSize: 12 }}>Отображать в фильтрах</Typography>
                  <div></div>
                </Box>
                <Divider sx={{ my: 2 }}></Divider>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {bodyClientAtrCheckboxes?.attr?.columnAtrNames?.map(
                      (option: any, index: any) => (
                        <FormControlLabel
                          key={option?.name}
                          draggable={true}
                          onDragStart={(e) => (dragItem.current = index)}
                          onDragEnter={(e) => (dragOverItem.current = index)}
                          onDragEnd={() => handleSort(setBodyClientAtrCheckboxes)}
                          onDragOver={(e) => e.preventDefault()}
                          control={
                            <>
                              <DragAndDropIcon
                                sx={{ width: '6px', height: '10px', marginRight: '6px' }}
                              />
                              <Checkbox
                                checked={option?.checked}
                                onChange={(event) => {
                                  handleColumnNames(
                                    event,
                                    index,
                                    dataCustomDevices?.attrib_name_mapping_client,
                                    bodyClientAtrCheckboxes,
                                    setBodyClientAtrCheckboxes,
                                  );
                                  sendMetrik(
                                    'vntVdknl',
                                    'otchety_konstruktor',
                                    'checkbox_click',
                                    translitRuEn(option?.label).toLowerCase(),
                                    event.target.checked,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/reports/customReportCreate',
                                    null,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                color="primary"
                              />
                            </>
                          }
                          label={option?.label}
                          sx={{
                            '& .MuiTypography-root': { fontSize: 14, pl: 4 },
                            pb: 3,
                          }}
                        />
                      ),
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', pl: 4 }}>
                    {bodyClientAtrCheckboxes?.attr?.columnAtrFilters?.map(
                      (option: any, index: any) => (
                        <FormControlLabel
                          key={option.name}
                          control={
                            <Checkbox
                              checked={option.checked}
                              onChange={(event) => {
                                handleColumnFilters(
                                  event,
                                  index,
                                  dataCustomDevices?.attrib_name_mapping_client,
                                  bodyClientAtrCheckboxes,
                                  setBodyClientAtrCheckboxes,
                                );
                                sendMetrik(
                                  'vntVdknl',
                                  'otchety_konstruktor',
                                  'checkbox_click',
                                  translitRuEn(option?.label).toLowerCase(),
                                  event.target.checked,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/reports/customReportCreate',
                                  null,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }}
                              disabled={option.disabled}
                              color="primary"
                            />
                          }
                          label={option.label}
                          sx={{ pb: 3 }}
                        />
                      ),
                    )}
                  </Box>
                  <Box></Box>
                </Box>
              </Box>

              {selectedDevice === 'uspd' ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Divider sx={{ my: 2 }}></Divider>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bodyChanneUspdlAtrCheckboxes?.attr?.columnAllChecked}
                          onChange={(event) => {
                            handleColumnAllNames(
                              event,
                              bodyChanneUspdlAtrCheckboxes,
                              setBodyChannelUspdAtrCheckboxes,
                            );
                            sendMetrik(
                              'vntVdknl',
                              'otchety_konstruktor',
                              'checkbox_click',
                              'naimenovanie_atributa',
                              event.target.checked,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports/customReportCreate',
                              null,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          color="primary"
                        />
                      }
                      label="Наименование атрибута"
                      sx={{
                        '& .MuiTypography-root': { fontSize: 12, pl: 4 },
                        ml: 1.5,
                      }}
                    />
                    <Typography sx={{ fontSize: 12 }}>Отображать в фильтрах</Typography>
                    <div></div>
                  </Box>
                  <Divider sx={{ my: 2 }}></Divider>
                  <Typography sx={{ mb: 2, fontWeight: 500 }}>Поканальные настройки</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {bodyChanneUspdlAtrCheckboxes?.attr?.columnAtrNames?.map(
                        (option: any, index: any) => (
                          <FormControlLabel
                            key={option?.name}
                            draggable={true}
                            onDragStart={(e) => (dragItem.current = index)}
                            onDragEnter={(e) => (dragOverItem.current = index)}
                            onDragEnd={() => handleSort(setBodyChannelUspdAtrCheckboxes)}
                            onDragOver={(e) => e.preventDefault()}
                            control={
                              <>
                                <DragAndDropIcon
                                  sx={{ width: '6px', height: '10px', marginRight: '6px' }}
                                />
                                <Checkbox
                                  checked={option?.checked}
                                  onChange={(event) => {
                                    handleColumnNames(
                                      event,
                                      index,
                                      dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_uspd,
                                      bodyChanneUspdlAtrCheckboxes,
                                      setBodyChannelUspdAtrCheckboxes,
                                    );
                                    sendMetrik(
                                      'vntVdknl',
                                      'otchety_konstruktor',
                                      'checkbox_click',
                                      translitRuEn(option?.label).toLowerCase(),
                                      event.target.checked,
                                      userData?.permissions[0].uuid,
                                      userData ? '1' : '0',
                                      '/reports/customReportCreate',
                                      null,
                                      null,
                                      null,
                                      'interactions',
                                      userData?.profile_type,
                                      'web',
                                    );
                                  }}
                                  color="primary"
                                />
                              </>
                            }
                            label={option?.label}
                            sx={{
                              '& .MuiTypography-root': { fontSize: 14, pl: 4 },
                              pb: 3,
                            }}
                          />
                        ),
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pr: 14 }}>
                      {bodyChanneUspdlAtrCheckboxes?.attr?.columnAtrFilters?.map(
                        (option: any, index: any) => (
                          <FormControlLabel
                            key={option?.name}
                            control={
                              <Checkbox
                                checked={option?.checked}
                                onChange={(event) => {
                                  handleColumnFilters(
                                    event,
                                    index,
                                    dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_uspd,
                                    bodyChanneUspdlAtrCheckboxes,
                                    setBodyChannelUspdAtrCheckboxes,
                                  );
                                  sendMetrik(
                                    'vntVdknl',
                                    'otchety_konstruktor',
                                    'checkbox_click',
                                    translitRuEn(option?.label).toLowerCase(),
                                    event.target.checked,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/reports/customReportCreate',
                                    null,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                disabled={option.disabled}
                                color="primary"
                              />
                            }
                            label={option?.label}
                            sx={{ pb: 3 }}
                          />
                        ),
                      )}
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              ) : selectedDevice === 'pressure_sensor' ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Divider sx={{ my: 2 }}></Divider>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bodyChanneSenslAtrCheckboxes?.attr?.columnAllChecked}
                          onChange={(event) => {
                            handleColumnAllNames(
                              event,
                              bodyChanneSenslAtrCheckboxes,
                              setBodyChannelSensAtrCheckboxes,
                            );
                            sendMetrik(
                              'vntVdknl',
                              'otchety_konstruktor',
                              'checkbox_click',
                              'naimenovanie_atributa',
                              event.target.checked,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports/customReportCreate',
                              null,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          color="primary"
                        />
                      }
                      label="Наименование атрибута"
                      sx={{
                        '& .MuiTypography-root': { fontSize: 12, pl: 4 },
                        ml: 1.5,
                      }}
                    />
                    <Typography sx={{ fontSize: 12 }}>Отображать в фильтрах</Typography>
                    <div></div>
                  </Box>
                  <Divider sx={{ my: 2 }}></Divider>
                  <Typography sx={{ mb: 2, fontWeight: 500 }}>Поканальные настройки</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {bodyChanneSenslAtrCheckboxes?.attr?.columnAtrNames?.map(
                        (option: any, index: any) => (
                          <FormControlLabel
                            key={option?.name}
                            draggable={true}
                            onDragStart={(e) => (dragItem.current = index)}
                            onDragEnter={(e) => (dragOverItem.current = index)}
                            onDragEnd={() => handleSort(setBodyChannelSensAtrCheckboxes)}
                            onDragOver={(e) => e.preventDefault()}
                            control={
                              <>
                                <DragAndDropIcon
                                  sx={{ width: '6px', height: '10px', marginRight: '6px' }}
                                />
                                <Checkbox
                                  checked={option?.checked}
                                  onChange={(event) => {
                                    handleColumnNames(
                                      event,
                                      index,
                                      dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_dd,
                                      bodyChanneSenslAtrCheckboxes,
                                      setBodyChannelSensAtrCheckboxes,
                                    );
                                    sendMetrik(
                                      'vntVdknl',
                                      'otchety_konstruktor',
                                      'checkbox_click',
                                      translitRuEn(option?.label).toLowerCase(),
                                      event.target.checked,
                                      userData?.permissions[0].uuid,
                                      userData ? '1' : '0',
                                      '/reports/customReportCreate',
                                      null,
                                      null,
                                      null,
                                      'interactions',
                                      userData?.profile_type,
                                      'web',
                                    );
                                  }}
                                  color="primary"
                                />
                              </>
                            }
                            label={option?.label}
                            sx={{
                              '& .MuiTypography-root': { fontSize: 14, pl: 4 },
                              pb: 3,
                            }}
                          />
                        ),
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pr: 14 }}>
                      {bodyChanneSenslAtrCheckboxes?.attr?.columnAtrFilters.map(
                        (option: any, index: any) => (
                          <FormControlLabel
                            key={option?.name}
                            control={
                              <Checkbox
                                checked={option?.checked}
                                onChange={(event) => {
                                  handleColumnFilters(
                                    event,
                                    index,
                                    dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_dd,
                                    bodyChanneSenslAtrCheckboxes,
                                    setBodyChannelSensAtrCheckboxes,
                                  );
                                  sendMetrik(
                                    'vntVdknl',
                                    'otchety_konstruktor',
                                    'checkbox_click',
                                    translitRuEn(option?.label).toLowerCase(),
                                    event.target.checked,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/reports/customReportCreate',
                                    null,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                disabled={option?.disabled}
                                color="primary"
                              />
                            }
                            label={option?.label}
                            sx={{ pb: 3 }}
                          />
                        ),
                      )}
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomBodyReport;
