import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  LegendProps
} from 'recharts';

import theme from '../../theme';

type IChartLegendProps = LegendProps & {
  showThreshold?: boolean;
}

const StyledChartLegend = styled("div", {
  name: 'ChartLegend',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})(() => ({}));

const ChartLegend = (props: IChartLegendProps) => {
  const {payload, showThreshold} = props;

  return (
    <StyledChartLegend>
      <ul className='chartLegend-list'>
        {Array.isArray(payload) && payload.map((entry: any, index: number) => (
          <li className='chartLegend-item' key={`item-${index}`}>
            {entry.value} -
            <Box sx={{backgroundColor: entry.color}}  className='chartLegend-icon' />
          </li>
        ))}
        {showThreshold &&
          <li className='chartLegend-item'>
            уставки -
            <Box sx={{backgroundColor: theme.palette.primary.lightOrange}} className='chartLegend-icon' />
          </li>
        }
      </ul>
    </StyledChartLegend>
  );
};

export default ChartLegend;
