import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { ADMIN_CUSTOMER_ID } from '../../../constants';
import { IUser } from '../../../types';

import Params from './Params';
import Results from './Results';

const ReportsSpecial = () => {
  const queryClient = useQueryClient();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const navigate = useNavigate();

  useEffect(() => {
    if (
      userData &&
      userData.customerId !== ADMIN_CUSTOMER_ID &&
      userData.customerId !== '12499850-ab80-11ed-816b-ef4525099864'
    ) {
      navigate('/reports', { replace: true });
    }
  }, [userData, navigate]);

  return (
    <>
      <Params />
      <Results />
    </>
  );
};

export default ReportsSpecial;
