import { DataGrid, GridColDef, GridFooterContainer } from '@mui/x-data-grid';

import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';

type IDashboardsConsumptionTable = {
  cols: GridColDef[] | [];
  rows: any;
};

const CustomFooter = () => {
  return <GridFooterContainer sx={{ display: 'none' }}></GridFooterContainer>;
};

const DashboardsConsumptionTable = (props: IDashboardsConsumptionTable) => {
  const { cols, rows } = props;

  return (
    <DataGrid
      sx={{ padding: '20px 24px 30px' }}
      columns={cols}
      rows={rows}
      components={{
        ColumnSortedAscendingIcon: AscendingIcon,
        ColumnSortedDescendingIcon: DescendingIcon,
        Footer: CustomFooter,
      }}
    />
  );
};

export default DashboardsConsumptionTable;
