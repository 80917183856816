import { IObject } from "../types";

const filterObjectsByIds = (data: IObject[], matchedIDS: string[]): IObject[] => {
  return data
    .filter((item) => matchedIDS.indexOf(item.id) > -1)
    .map((item) => ({
      ...item,
      objects: item.objects ? filterObjectsByIds(item.objects, matchedIDS) : [],
    }));
};

export default filterObjectsByIds;
