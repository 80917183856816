import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M10 6.74994C10 7.16415 9.66424 7.49994 9.25003 7.49994H6.75003C6.33582 7.49994 6.00003 7.16415 6.00003 6.74994C6.00003 6.33573 6.33582 5.99994 6.75003 5.99994H9.25003C9.66424 5.99994 10 6.33573 10 6.74994Z" />
      <path d="M9.50003 9.75391C9.50003 10.1681 9.16424 10.5039 8.75003 10.5039H7.25003C6.83582 10.5039 6.50003 10.1681 6.50003 9.75391C6.50003 9.33969 6.83582 9.00391 7.25003 9.00391H8.75003C9.16424 9.00391 9.50003 9.33969 9.50003 9.75391Z" />
      <path fillRule="evenodd" d="M9.96497 14.9027C9.36396 14.9616 8.70254 15 8 15C7.29747 15 6.63608 14.9616 6.03507 14.9028C4.69881 14.7719 4.03067 14.7065 3.18701 13.8645C2.34335 13.0225 2.27653 12.3536 2.1429 11.0158C2.05853 10.1712 2 9.16535 2 8C2 6.83499 2.0585 5.82932 2.14282 4.98487C2.27646 3.64646 2.34328 2.97725 3.18709 2.13518C4.0309 1.29312 4.69937 1.22776 6.03631 1.09706C6.63699 1.03833 7.29796 1 8 1C8.70207 1 9.36307 1.03834 9.96377 1.09707C11.3006 1.22777 11.9691 1.29313 12.8129 2.13518C13.6567 2.97723 13.7235 3.64639 13.8572 4.98472C13.9415 5.82921 14 6.83492 14 7.99999C14 9.1654 13.9415 10.1713 13.8571 11.0159C13.7234 12.3537 13.6566 13.0226 12.813 13.8645C11.9693 14.7065 11.3012 14.7719 9.96497 14.9027ZM3.63547 10.8667C3.556 10.0712 3.5 9.11503 3.5 8C3.5 6.88529 3.55597 5.92937 3.6354 5.1339C3.70706 4.4162 3.74673 4.08621 3.82903 3.82555C3.88619 3.64454 3.97309 3.46994 4.24666 3.19694C4.52046 2.92371 4.69514 2.83725 4.87575 2.78059C5.13599 2.69896 5.46528 2.66004 6.18226 2.58994C6.7402 2.53539 7.35217 2.5 8 2.5C8.64787 2.5 9.25986 2.5354 9.81781 2.58995C10.5348 2.66004 10.864 2.69896 11.1242 2.7806C11.3048 2.83725 11.4795 2.9237 11.7533 3.19694C12.0269 3.46994 12.1138 3.64453 12.1709 3.82552C12.2532 4.08615 12.2929 4.41612 12.3646 5.13378C12.444 5.92926 12.5 6.88523 12.5 7.99999C12.5 9.11508 12.444 10.0712 12.3645 10.8668C12.2928 11.5842 12.2532 11.9139 12.1709 12.1744C12.1138 12.3553 12.0269 12.5298 11.7534 12.8028C11.4795 13.0761 11.3049 13.1625 11.1245 13.2192C10.8644 13.3008 10.5354 13.3397 9.81882 13.4099C9.2606 13.4645 8.64826 13.5 8 13.5C7.35175 13.5 6.73942 13.4645 6.18121 13.4099C5.46457 13.3397 5.13555 13.3008 4.87551 13.2192C4.69507 13.1625 4.52044 13.0761 4.24662 12.8028C3.97305 12.5298 3.88618 12.3552 3.82906 12.1744C3.74679 11.9139 3.70713 11.5841 3.63547 10.8667Z" />
    </SvgIcon>
  );
};

export default DocumentIcon;
