import { fromUnixTime } from 'date-fns';

const getUTCTimestamp = (date: number) => {
  const offset = fromUnixTime(date).getTimezoneOffset();
  const timestamp = date + ((offset * 60) * Math.sign(offset));

  return timestamp;
};

export default getUTCTimestamp;
