import { useQuery } from 'react-query';

import { GET_REPORTS_HISTORY } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchReportsHistory = () => {
  const { isLoading, isError, data } = useQuery(
    'reportsHistory',
    () => fetchData(GET_REPORTS_HISTORY),
    {
      refetchOnMount: true,
    },
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useFetchReportsHistory;
