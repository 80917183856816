import { accordionClasses } from '@mui/material/Accordion';
import { alertClasses } from '@mui/material/Alert';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { buttonClasses } from '@mui/material/Button';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { containerClasses } from '@mui/material/Container';
import { dialogActionsClasses } from '@mui/material/DialogActions';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { formLabelClasses } from '@mui/material/FormLabel';
import { iconButtonClasses } from '@mui/material/IconButton';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { menuItemClasses } from '@mui/material/MenuItem';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { paginationItemClasses } from '@mui/material/PaginationItem';
import { selectClasses } from '@mui/material/Select';
import { createTheme, alpha } from '@mui/material/styles';
import { switchClasses } from '@mui/material/Switch';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { typographyClasses } from '@mui/material/Typography';
import { gridClasses, ruRU } from '@mui/x-data-grid';
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay';

import ArrowDownIcon from '../components/icons/ArrowDownIcon';
import ArrowLeftIcon from '../components/icons/ArrowLeftIcon';
import ArrowRightIcon from '../components/icons/ArrowRightIcon';
import CalendarIcon from '../components/icons/CalendarIcon';
import CheckBoxCheckedIcon from '../components/icons/CheckBoxCheckedIcon';
import CheckBoxIcon from '../components/icons/CheckBoxIcon';
import CheckBoxIndeterminateIcon from '../components/icons/CheckBoxIndeterminateIcon';
import CloseAltIcon from '../components/icons/CloseAltIcon';
import ErrorIcon from '../components/icons/ErrorIcon';
import ExpandIcon from '../components/icons/ExpandIcon';
import InfoIcon from '../components/icons/InfoIcon';
import MoreHorIcon from '../components/icons/MoreHorIcon';
import SuccessIcon from '../components/icons/SuccessIcon';
import Spinner from '../components/Spinner';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface TypographyVariants {
    h2Alt: React.CSSProperties;
    h3Alt: React.CSSProperties;
    bold: React.CSSProperties;
    paragraph1: React.CSSProperties;
    paragraph2: React.CSSProperties;
    paragraphMedium2: React.CSSProperties;
    paragraphBold2: React.CSSProperties;
    paragraph3: React.CSSProperties;
    paragraphMedium3: React.CSSProperties;
    paragraphBold3: React.CSSProperties;
    paragraph4: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h2Alt?: React.CSSProperties;
    h3Alt?: React.CSSProperties;
    bold?: React.CSSProperties;
    paragraph1?: React.CSSProperties;
    paragraph2?: React.CSSProperties;
    paragraphMedium2?: React.CSSProperties;
    paragraphBold2?: React.CSSProperties;
    paragraph3?: React.CSSProperties;
    paragraphMedium3?: React.CSSProperties;
    paragraphBold3?: React.CSSProperties;
    paragraph4?: React.CSSProperties;
  }

  interface Palette {
    icons: Palette['primary'];
    constant: Palette['primary'];
    link: Palette['primary'];
    negative: Palette['primary'];
    perfect: Palette['primary'];
  }
  interface PaletteOptions {
    icons: PaletteOptions['primary'];
    constant: PaletteOptions['primary'];
    link?: PaletteOptions['primary'];
    negative?: PaletteOptions['primary'];
    perfect?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    secondary: string;
    tertiary: string;
    greyscale200: string;
    greyRgbaMiddle: string;
    normalBlackberry: string;
    lightBlackberry: string;
    lightestBlackberry: string;
    normalBlueberry: string;
    normalMint: string;
    normalApple: string;
    normalLime: string;
    normalBanana: string;
    normalRaspberry: string;
    normalCranberry: string;
    normalPlum: string;
    lightPlum: string;
    darkOrange: string;
    normalOrange: string;
    lightOrange: string;
    lightMint: string;
  }
  interface SimplePaletteColorOptions {
    secondary?: string;
    tertiary?: string;
    greyscale200?: string;
    greyRgbaMiddle?: string;
    normalBlackberry?: string;
    lightBlackberry?: string;
    lightestBlackberry?: string;
    normalBlueberry?: string;
    normalMint?: string;
    normalApple?: string;
    normalLime?: string;
    normalBanana?: string;
    normalRaspberry?: string;
    normalCranberry?: string;
    normalPlum?: string;
    lightPlum?: string;
    darkOrange?: string;
    normalOrange?: string;
    lightOrange?: string;
    lightMint?: string;
  }

  interface TypeAction {
    primary?: string;
    secondary?: string;
    tertiary?: string;
    inactive?: string;
    alternative?: string;
  }

  interface TypeBackground {
    primary?: string;
    stroke?: string;
    secondary?: string;
    inverted?: string;
    hover?: string;
    modalDark?: string;
  }
  interface TypeText {
    headline?: string;
    tertiary?: string;
    inverted?: string;
    positive?: string;
    negative?: string;
    link?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h2Alt: true;
    h3Alt: true;
    bold: true;
    paragraph1: true;
    paragraph2: true;
    paragraphMedium2: true;
    paragraphBold2: true;
    paragraph3: true;
    paragraphMedium3: true;
    paragraphBold3: true;
    paragraph4: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    link: true;
    negative: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    perfect: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    extralarge: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    large: true;
  }
}

const baseTheme = createTheme(
  {
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        xs: 960,
        sm: 1280,
        md: 1440,
        lg: 1680,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        main: '#FF0032',
        greyscale200: '#E2E5EB',
        greyRgbaMiddle: 'rgba(29, 32, 35, 0.20)',
        normalBlackberry: '#014FCE',
        lightBlackberry: '#6384E0',
        lightestBlackberry: '#E7EAFA',
        normalBlueberry: '#0097FD',
        normalMint: '#00C19B',
        normalApple: '#26CD58',
        normalLime: '#C1EB1D',
        normalBanana: '#FBE739',
        normalRaspberry: '#EA1F49',
        normalCranberry: '#E54887',
        normalPlum: '#883888',
        lightPlum: '#A86EA7',
        darkOrange: '#E04A17',
        normalOrange: '#F95721',
        lightOrange: '#FFA080',
        lightMint: '#00D9BC',
      },
      secondary: {
        main: '#626C77',
      },
      error: {
        main: '#F95721',
      },
      success: {
        main: '#26CD58',
      },
      info: {
        main: '#007CFF',
      },
      warning: {
        main: '#FAC031',
      },
      text: {
        headline: '#000000',
        primary: '#1D2023',
        secondary: '#626C77',
        tertiary: '#969FA8',
        inverted: '#fff',
        positive: '#12B23F',
        negative: '#EB4A13',
        link: '#007CFF',
      },
      background: {
        primary: '#fff',
        stroke: '#BCC3D080',
        secondary: '#F2F3F7',
        inverted: '#1D2023',
        hover: '#BCC3D040',
        modalDark: '#2C3135',
      },
      action: {
        primary: '#FF0032',
        secondary: '#1D2023',
        tertiary: '#F2F3F7',
        inactive: '#BCC3D080',
        alternative: '#fff',
      },
      icons: {
        main: '#000000',
        secondary: '#969FA8',
        tertiary: '#BBC1C7',
      },
      constant: {
        main: 'red',
        lightestBlackberry: '#E7EAFA',
      },
      negative: {
        main: '#EB4A13',
      },
      link: {
        main: '#007CFF',
      },
      perfect: {
        main: '#00D9BC',
        dark: '#00D9BC',
        light: '#00D9BC',
        contrastText: '#fff',
      },
    },
    shadows: [
      'none',
      '0 0 1px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 2px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 3px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 4px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 5px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 6px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)', // Lowest shadows[6]
      '0 0 11px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 12px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 13px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 14px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 15px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)',
      '0 0 16px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08)', // Low shadows[12]
      '0 4px 19px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)',
      '0 4px 20px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)',
      '0 4px 21px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)',
      '0 4px 22px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)',
      '0 4px 23px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08)', // Middle shadows[18]
      '0 -2px 24px rgba(0, 0, 0, 0.08), 0 -8px 24px rgba(0, 0, 0, 0.1)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 11px 17px rgba(0, 0, 0, 0.14)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 17px rgba(0, 0, 0, 0.14)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 18px rgba(0, 0, 0, 0.14)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 19px rgba(0, 0, 0, 0.14)',
      '0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 20px rgba(0, 0, 0, 0.14)', // High shadows[24]
    ],
    typography: {
      fontFamily: ['MTSCompact', 'sans-serif'].join(','),
      h1: {
        fontFamily: 'MTSWide, sans-serif',
        fontSize: 32,
        lineHeight: '36px',
        fontWeight: 500,
      },
      h2: {
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: 500,
      },
      h2Alt: {
        fontFamily: 'MTSWide, sans-serif',
        fontSize: 32,
        lineHeight: '36px',
        fontWeight: 500,
      },
      h3: {
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 500,
      },
      h3Alt: {
        fontFamily: 'MTSWide, sans-serif',
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: 500,
      },
      h4: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 700,
      },
      body1: {
        fontSize: 17,
        lineHeight: '24px',
      },
      body2: {
        fontSize: 14,
        lineHeight: '20px',
      },
      bold: {
        fontWeight: 700,
        fontSize: 'inherit',
      },
      paragraph1: {
        fontSize: 20,
        lineHeight: '28px',
      },
      paragraph2: {
        fontSize: 17,
        lineHeight: '24px',
      },
      paragraphMedium2: {
        fontWeight: 500,
        fontSize: 17,
        lineHeight: '24px',
      },
      paragraphBold2: {
        fontWeight: 700,
        fontSize: 17,
        lineHeight: '24px',
      },
      paragraph3: {
        fontSize: 14,
        lineHeight: '20px',
      },
      paragraphMedium3: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
      },
      paragraphBold3: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '20px',
      },
      paragraph4: {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h2Alt: 'h2',
            h3Alt: 'h3',
            paragraph1: 'p',
            paragraph2: 'p',
            paragraphMedium2: 'p',
            paragraphBold2: 'p',
            paragraph3: 'p',
            paragraphMedium3: 'p',
            paragraphBold3: 'p',
            paragraph4: 'p',
          },
        },
      },
    },
  },
  ruRU,
  coreRuRU,
);

const theme = createTheme(baseTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'MTSCompact';
          src: url('/fonts/MTSCompact-Regular.eot');
          src:
              url('/fonts/MTSCompact-Regular.eot?#iefix') format('embedded-opentype'),
              url('/fonts/MTSCompact-Regular.woff2') format('woff2'),
              url('/fonts/MTSCompact-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'MTSCompact';
          src: url('/fonts/MTSCompact-Medium.eot');
          src:
               url('/fonts/MTSCompact-Medium.eot?#iefix') format('embedded-opentype'),
               url('/fonts/MTSCompact-Medium.woff2') format('woff2'),
               url('/fonts/MTSCompact-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: 'MTSCompact';
          src: url('/fonts/MTSCompact-Bold.eot');
          src:
               url('/fonts/MTSCompact-Bold.eot?#iefix') format('embedded-opentype'),
               url('/fonts/MTSCompact-Bold.woff2') format('woff2'),
               url('/fonts/MTSCompact-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }
        @font-face {
          font-family: 'MTSWide';
          src: url('/fonts/MTSWide-Medium.eot');
          src:
               url('/fonts/MTSWide-Medium.eot?#iefix') format('embedded-opentype'),
               url('/fonts/MTSWide-Medium.woff2') format('woff2'),
               url('/fonts/MTSWide-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
        }
        a {
          text-decoration: none;
          color: ${baseTheme.palette.text.link};
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 100px ${baseTheme.palette.background.secondary} inset !important;
          border-radius: 8px;
        }
        .MuiTextField-alt input:-webkit-autofill,
        .MuiTextField-alt input:-webkit-autofill:hover,
        .MuiTextField-alt input:-webkit-autofill:focus,
        .MuiTextField-alt input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 100px ${baseTheme.palette.background.primary} inset !important;
        }
      `,
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'none',
          boxShadow: 'none',
          transition: 'none',
          '&:before': {
            display: 'none',
          },
          [`&.${accordionClasses.expanded}`]: {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 12,
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandIcon fontSize="small" />,
      },
      styleOverrides: {
        root: {
          minHeight: 64,
          padding: 0,
          borderBottom: `1px solid ${baseTheme.palette.background.stroke}`,
          transition: 'none',
        },
        expandIconWrapper: {
          color: baseTheme.palette.text.primary,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <InfoIcon />,
          success: <SuccessIcon />,
          error: <ErrorIcon />,
        },
      },
      styleOverrides: {
        root: {
          padding: '5px 12px',
          background: baseTheme.palette.background.inverted,
          borderRadius: 12,
          color: baseTheme.palette.text.inverted,
          fontSize: 17,
        },
        icon: {
          flexShrink: 0,
          width: 24,
          height: 24,
          marginTop: 3,
          marginRight: 10,
          padding: 0,
          opacity: 1,
        },
        message: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        action: {
          paddingTop: 0,
        },
        standardSuccess: {
          [`& .${alertClasses.icon}`]: {
            color: baseTheme.palette.success,
          },
        },
        standardError: {
          [`& .${alertClasses.icon}`]: {
            color: baseTheme.palette.error,
          },
        },
        standardInfo: {
          [`& .${alertClasses.icon}`]: {
            color: baseTheme.palette.info,
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: baseTheme.shadows[12],
          zIndex: baseTheme.zIndex.drawer + 1,
        },
        colorDefault: {
          backgroundColor: baseTheme.palette.background.primary,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.icons.tertiary,
          fontWeight: '700',
          fontSize: 14,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ArrowDownIcon />,
        loadingText: <Spinner />,
        noOptionsText: 'Ничего не найдено',
        disableClearable: true,
      },
      styleOverrides: {
        root: {
          [`.${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
            padding: 0,
          },
          [`&.${autocompleteClasses.hasClearIcon}`]: {
            [`.${outlinedInputClasses.root}`]: {
              paddingRight: 9,
            },
            [`.${autocompleteClasses.clearIndicator}`]: {
            },
          },
          [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}`]: {
            [`.${outlinedInputClasses.root}`]: {
              paddingRight: 78,
            },
          },
          [`&.chipsField .${autocompleteClasses.inputRoot}`]: {
            paddingTop: 5,
            paddingBottom: 5,
          },
          [`&.chipsField .${autocompleteClasses.input}`]: {
            height: 32,
            marginLeft: 4,
          },
        },
        endAdornment: {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto',
          marginRight: 0,
          color: baseTheme.palette.icons.secondary,
          transition: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        popper: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        paper: {
          borderRadius: 6,
          boxShadow: baseTheme.shadows[24],
        },
        loading: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 88,
          color: baseTheme.palette.primary.main,
        },
        listbox: {
          [`& .${autocompleteClasses.option}`]: {
            minHeight: 44,
            "&[aria-selected='true']": {
              backgroundColor: baseTheme.palette.background.hover,
              [`&.${autocompleteClasses.focused}`]: {
                backgroundColor: baseTheme.palette.background.hover,
              },
            },
            '&:hover': {
              background: 'none',
            },
            [`&.${autocompleteClasses.focused}`]: {
              background: 'none',
            },
          },
        },
        tag: {
          margin: 2,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          background: baseTheme.palette.background.secondary,
          color: baseTheme.palette.text.primary,
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ArrowRightIcon fontSize="small" />,
      },
      styleOverrides: {
        root: {
          marginBottom: 16,
          color: baseTheme.palette.text.tertiary,
          fontSize: 14,
        },
        li: {
          'a, button': {
            color: 'inherit',
            verticalAlign: 'baseline',
          },
          span: {
            color: baseTheme.palette.text.primary,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          background: 'none',
          boxShadow: 'none',
          borderRadius: 8,
          textTransform: 'none',
          fontSize: 17,
          transition: `transform ${baseTheme.transitions.duration.short}ms ${baseTheme.transitions.easing.easeInOut}`,
          whiteSpace: 'nowrap',
          '&:hover': {
            background: 'none',
            boxShadow: 'none',
            transform: 'scale(1.02)',
          },
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: baseTheme.palette.action.inactive,
          },
        },
        text: {
          color: baseTheme.palette.text.headline,
        },
        textLink: {
          color: baseTheme.palette.text.link,
        },
        contained: {
          backgroundColor: baseTheme.palette.action.primary,
          color: baseTheme.palette.text.inverted,
          '&:hover': {
            backgroundColor: baseTheme.palette.action.primary,
          },
        },
        containedSecondary: {
          backgroundColor: baseTheme.palette.action.tertiary,
          color: baseTheme.palette.text.headline,
          '&:hover': {
            backgroundColor: baseTheme.palette.action.tertiary,
          },
        },
        containedNegative: {
          backgroundColor: baseTheme.palette.background.secondary,
          color: baseTheme.palette.text.negative,
          '&:hover': {
            backgroundColor: baseTheme.palette.background.secondary,
          },
        },
        sizeSmall: {
          height: 32,
          padding: '0 16px',
          borderRadius: 6,
          fontSize: 14,
          lineHeight: '32px',
        },
        sizeMedium: {
          height: 44,
          padding: '0 18px',
          lineHeight: '44px',
        },
        sizeLarge: {
          height: 52,
          padding: '0 22px',
          lineHeight: '52px',
        },
        startIcon: {
          position: 'relative',
          zIndex: '2',
        },
        iconSizeSmall: {
          '.MuiSvgIcon-fontSizeMedium': {
            fontSize: 24,
          },
        },
        iconSizeMedium: {
          '*:nth-of-type(1)': {
            fontSize: 24,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& > div:first-of-type': {
            position: 'relative',
            justifyContent: 'center',
            "[role='presentation']": {
              marginRight: '0',
              fontSize: 17,
              '& > div:first-letter': {
                textTransform: 'uppercase',
              },
              [`.${iconButtonClasses.root}`]: {
                display: 'none',
              },
            },
            '.MuiPickersArrowSwitcher-root': {
              position: 'absolute',
              left: 0,
              top: 0,
              display: 'block',
              width: '100%',
              '.MuiPickersArrowSwitcher-button': {
                position: 'absolute',
                color: baseTheme.palette.icons.main,
              },
              [`.${iconButtonClasses.edgeEnd}`]: {
                left: 8,
              },
              [`.${iconButtonClasses.edgeStart}`]: {
                right: 8,
              },
            },
          },
          '.MuiTypography-caption': {
            display: 'block',
            width: 32,
            height: 32,
            textTransform: 'lowercase',
            fontSize: 14,
            lineHeight: '40px',
            '&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)':
              {
                color: baseTheme.palette.text.primary,
              },
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <CheckBoxIcon />,
        checkedIcon: <CheckBoxCheckedIcon />,
        indeterminateIcon: <CheckBoxIndeterminateIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          color: baseTheme.palette.action.inactive,
          transition: `transform ${baseTheme.transitions.duration.standard}ms ${baseTheme.transitions.easing.easeInOut}`,
          '&:hover': {
            transform: 'scale(1.02)',
          },
          [`&.${checkboxClasses.checked}`]: {
            color: baseTheme.palette.action.primary,
          },
          [`&.${checkboxClasses.disabled}`]: {
            color: baseTheme.palette.action.inactive,
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseAltIcon />,
      },
      styleOverrides: {
        root: {
          background: baseTheme.palette.background.secondary,
          '&.MuiAutocomplete-tag': {
            background: baseTheme.palette.background.primary,
          },
          '.MuiTextField-alt &.MuiAutocomplete-tag': {
            background: baseTheme.palette.background.secondary,
          },
        },
        sizeMedium: {
          borderRadius: 6,
          fontSize: 17,
        },
        sizeSmall: {
          height: 28,
          borderRadius: 4,
          fontSize: 14,
          lineHeight: '28px',
        },
        CloseAltIcon: {
          fontSize: 16,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,
          [baseTheme.breakpoints.up('tablet')]: {
            paddingLeft: 40,
            paddingRight: 40,
          },
          [baseTheme.breakpoints.up('sm')]: {
            paddingLeft: 32,
            paddingRight: 32,
          },
          [baseTheme.breakpoints.up('md')]: {
            paddingLeft: 40,
            paddingRight: 40,
          },
          [`&.${containerClasses.disableGutters}`]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true,
        slots: {
          baseCheckbox: Checkbox,
          moreActionsIcon: MoreHorIcon,
          loadingOverlay: Spinner,
        },
        slotProps: {
          pagination: {
            labelRowsPerPage: 'Отображать по',
          },
        },
        disableColumnFilter: true,
        disableColumnMenu: true,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableRowSelectionOnClick: true,
        rowHeight: 43,
        pagination: true,
      },
      styleOverrides: {
        root: {
          border: 'none',
          [`.${gridClasses.actionsCell}`]: {
            [`.${iconButtonClasses.root}`]: {
              width: 24,
              height: 24,
              backgroundColor: baseTheme.palette.action.tertiary,
              color: baseTheme.palette.icons.main,
            },
          },
        },
        toolbarContainer: {
          marginBottom: 22,
          padding: 0,
          '& .MuiDataGrid-toolbarQuickFilter': {
            width: 184,
            padding: 0,
            '.MuiIconButton-root': {
              display: 'none',
            },
            svg: {
              order: 1,
              marginLeft: '-2px',
              marginRight: 8,
              color: baseTheme.palette.icons.tertiary,
              fontSize: 24,
            },
            input: {
              order: 0,
            },
          },
        },
        iconButtonContainer: {
          width: 'auto',
          visibility: 'visible',
        },
        columnHeaders: {
          borderBottomColor: baseTheme.palette.background.stroke,
        },
        columnHeader: {
          '&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
            opacity: '1',
          },
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnSeparator: {
          display: 'none',
        },
        row: {
          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            background: 'none',
          },
        },
        cell: {
          borderBottomColor: baseTheme.palette.background.stroke,
          '&:focus-within': {
            outline: 'none',
          },
        },
        footerContainer: {
          justifyContent: 'center',
          paddingTop: 32,
          border: 'none',
        },
        overlay: {
          paddingTop: baseTheme.spacing(1),
          background: 'none',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        disableFuture: true,
        components: {
          OpenPickerIcon: CalendarIcon,
          LeftArrowIcon: ArrowLeftIcon,
          RightArrowIcon: ArrowRightIcon,
        },
        InputAdornmentProps: {
          className: 'MuiInputAdornment-rootDatePicker',
        },
        PaperProps: {
          className: 'MuiPaper-calendar',
        },
        PopperProps: {
          placement: 'bottom-end',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [40, 5],
              },
            },
          ],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-close': {
            position: 'absolute',
            left: 'calc(100% + 8px)',
            top: 0,
            color: baseTheme.palette.text.inverted,
          },
        },
        paper: {
          overflowY: 'inherit',
          width: 656,
          borderRadius: 16,
        },
        paperWidthMd: {
          width: 464,
          [`.${dialogActionsClasses.root}`]: {
            [`.${buttonClasses.root}`]: {
              width: '100%',
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        margin: 'normal',
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-formControl': {
            marginBottom: 4,
          },
          '& .MuiInputBase-formControl, &.MuiTextField-root': {
            margin: 0,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          marginLeft: 8,
        },
        labelPlacementStart: {
          justifyContent: 'space-between',
          [`& .${formControlLabelClasses.label}`]: {
            marginLeft: 0,
          }
        }
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          alignItems: 'center',
          gap: 8,
          [`.${formLabelClasses.root}`]: {
            margin: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
        [`&.${formHelperTextClasses.error}`]: {
          color: baseTheme.palette.text.negative,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: 5,
          fontSize: 14,
          [`&.${formLabelClasses.focused}`]: {
            color: baseTheme.palette.text.secondary,
          },
          [`&.${formLabelClasses.disabled}`]: {
            color: baseTheme.palette.text.secondary,
          },
          [`&.${formLabelClasses.error}`]: {
            color: baseTheme.palette.text.negative,
          },
          '.MuiFormLabel-inner': {
            display: 'flex',
            alignItems: 'center',
          },
          '.MuiSvgIcon-root': {
            color: baseTheme.palette.icons.secondary,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: 'none',
          borderRadius: 8,
          transition: `transform ${baseTheme.transitions.duration.standard}ms ${baseTheme.transitions.easing.easeInOut}`,
          svg: {
            position: 'relative',
            zIndex: '2',
          },
          '&:hover': {
            background: 'none',
            transform: 'scale(1.02)',
          },
          [`.${inputAdornmentClasses.root}DatePicker &`]: {
            color: baseTheme.palette.icons.secondary,
          },
        },
        colorPrimary: {
          backgroundColor: baseTheme.palette.action.primary,
          color: baseTheme.palette.primary.contrastText,
          [`&.${iconButtonClasses.disabled}`]: {
            backgroundColor: baseTheme.palette.background.stroke,
            color: baseTheme.palette.icons.secondary,
          },
          '&:hover': {
            backgroundColor: baseTheme.palette.action.primary,
          },
        },
        colorSecondary: {
          backgroundColor: baseTheme.palette.action.tertiary,
          color: baseTheme.palette.icons.main,
          '&:hover': {
            backgroundColor: baseTheme.palette.action.tertiary,
          },
        },
        sizeExtraSmall: {
          width: 24,
          height: 24,
          borderRadius: 4,
        },
        sizeSmall: {
          width: 32,
          height: 32,
          borderRadius: 6,
        },
        sizeMedium: {
          width: 44,
          height: 44,
        },
        sizeLarge: {
          width: 52,
          height: 52,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          backgroundColor: baseTheme.palette.background.secondary,
          borderRadius: 8,
          border: '1px solid',
          borderColor: baseTheme.palette.background.stroke,
          fontSize: 17,
          transition: 'border-color 0.3s cubic-bezier(0.64, 0.09, 0.08, 1)',
          '&:before, &:after': {
            display: 'none',
          },
          '&:hover': {
            borderColor: baseTheme.palette.action.inactive,
          },
          [`&.${inputBaseClasses.focused}`]: {
            borderColor: baseTheme.palette.text.link,
          },
          [`&.${inputBaseClasses.disabled}`]: {
            color: baseTheme.palette.text.primary,
            input: {
              WebkitTextFillColor: baseTheme.palette.text.primary,
            },
            '&:hover': {
              borderColor: baseTheme.palette.background.stroke,
            },
          },
          [`&.${inputBaseClasses.error}`]: {
            borderColor: baseTheme.palette.error.main,
          },
          [`.MuiTextField-alt &`]: {
            background: baseTheme.palette.background.primary,
          },
          [`&.${inputBaseClasses.sizeSmall}`]: {
            borderRadius: 6,
            fontSize: 14,
            input: {
              height: 30,
            },
          },
        },
        input: {
          height: 42,
          padding: '0 10px',
          '&::placeholder': {
            color: baseTheme.palette.text.secondary,
            WebkitTextFillColor: baseTheme.palette.text.secondary,
            opacity: '1',
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.icons.tertiary,
          [`&.${inputAdornmentClasses.root}DatePicker`]: {
            flexShrink: 0,
            marginRight: 12,
          },
        },
        positionStart: {
          marginLeft: 8,
          marginRight: '-2px',
        },
        positionEnd: {
          marginRight: 8,
          marginLeft: '-2px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          transition: 'none',
          transform: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.link,
        },
        underlineHover: {
          '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            bottom: '-2px',
            width: '100%',
            borderBottom: '1px solid',
            opacity: 0,
          },
          '&:hover': {
            textDecoration: 'none',
            '&:after': {
              opacity: '1',
            },
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <Spinner />,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          transition: `transform ${baseTheme.transitions.duration.short}ms ${baseTheme.transitions.easing.easeInOut}`,
        },
        loadingIndicator: {
          color: baseTheme.palette.icons.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 32,
          fontSize: 14,
          '&:focus': {
            background: 'none',
          },
          '&:hover': {
            background: baseTheme.palette.background.hover,
          },
          [`&.${menuItemClasses.selected}`]: {
            background: baseTheme.palette.background.hover,
            '&:hover, &:focus': {
              background: baseTheme.palette.background.hover,
            },
          },
          '.MuiMenuList-select &': {
            minHeight: 44,
            fontSize: 17,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          width: 'auto',
          '.PrivatePickersMonth-root': {
            display: 'block',
            flexBasis: 'calc(33.3% - 16px)',
            height: 32,
            marginLeft: 8,
            marginRight: 8,
            borderRadius: 6,
            fontSize: 17,
            '&:first-letter': {
              textTransform: 'uppercase',
            },
            [`&.${pickersDayClasses.selected}`]: {
              background: baseTheme.palette.background.inverted,
              color: baseTheme.palette.text.inverted,
              '&:hover, &:focus': {
                background: baseTheme.palette.background.inverted,
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 8,
        },
        input: {
          height: 42,
          padding: '0 10px',
        },
        notchedOutline: {
          display: 'none',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: '0 8px',
          borderRadius: 6,
          fontWeight: '500',
          lineHeight: 1,
          [`&.${paginationItemClasses.selected}`]: {
            backgroundColor: baseTheme.palette.background.primary,
            boxShadow: baseTheme.shadows[12],
          },
        },
        sizeSmall: {
          minWidth: 32,
          height: 32,
          fontSize: 17,
        },
        sizeLarge: {
          width: 44,
          height: 44,
          fontSize: 20,
        },
        previousNext: {
          svg: {
            fontSize: 24,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-calendar': {
            borderRadius: 16,
            boxShadow: baseTheme.shadows[24],
            '& > div > div, & > div > div > div': {
              width: 272,
            },
            '.PrivatePickersSlideTransition-root': {
              minHeight: 36 * 6,
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          borderRadius: 6,
          fontSize: 17,
          [`&.${pickersDayClasses.selected}, &.${pickersDayClasses.selected}.${pickersDayClasses.today}`]:
            {
              background: baseTheme.palette.background.inverted,
              color: baseTheme.palette.text.inverted,
              '&:hover, &:focus': {
                background: baseTheme.palette.background.inverted,
              },
            },
          [`&.${pickersDayClasses.today}`]: {
            background: 'none',
            border: 'none',
            fontWeight: '500',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 6,
          boxShadow: baseTheme.shadows[24],
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDownIcon,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: -5,
            horizontal: 'center',
          },
          classes: {
            list: 'MuiMenuList-select',
          },
        },
      },
      styleOverrides: {
        select: {
          height: 42,
          lineHeight: '42px',
          [`&.${inputBaseClasses.input}`]: {
            paddingRight: 40,
          },
          span: {
            color: baseTheme.palette.text.secondary,
            WebkitTextFillColor: baseTheme.palette.text.secondary,
          },
          '&:focus': {
            background: 'none',
          },
          '&.MuiSelect-alt': {
            background: baseTheme.palette.background.primary,
          },
          '&.MuiSelect-endAdornment': {
            paddingRight: 70,
            [`& ~ .${inputAdornmentClasses.positionEnd}`]: {
              position: 'absolute',
              right: 30,
            },
          },
        },
        icon: {
          right: 9,
          color: baseTheme.palette.icons.secondary,
          [`&.${selectClasses.disabled}`]: {
            color: baseTheme.palette.icons.secondary,
          },
        },
        nativeInput: {
          height: '100%',
          padding: '0 10px',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
        autoHideDuration: 3000,
      },
      styleOverrides: {
        root: {
          left: 32,
          right: 32,
          bottom: 32,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: baseTheme.palette.icons.main,
        },
        colorSecondary: {
          color: baseTheme.palette.icons.secondary,
        },
        colorAction: {
          color: baseTheme.palette.primary.main,
        },
        fontSizeSmall: {
          fontSize: 16,
        },
        fontSizeLarge: {
          fontSize: 32,
        },
        fontSizeExtraLarge: {
          fontSize: 44,
        },
      },
      variants: [
        {
          props: { fontSize: 'extralarge' },
          style: {
            fontSize: '2.75rem',
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          width: 44,
          height: 32,
          padding: '4px 0',
          [`.${switchClasses.switchBase}.${switchClasses.checked}`]: {
            '&:hover': {
              background: 'none',
            },
            [`& + .${switchClasses.track}`]: {
              background: baseTheme.palette.success.main,
              opacity: 1,
            },
          },
        },
        switchBase: {
          overflow: 'visible',
          padding: '7px 3px',
          '&:hover': {
            background: 'none',
          },
        },
        thumb: {
          width: 18,
          height: 18,
          background: baseTheme.palette.background.primary,
          boxShadow: [12],
        },
        track: {
          borderRadius: 100,
          background: baseTheme.palette.background.stroke,
          opacity: 1,
        },
        sizeSmall: {
          width: 28,
          paddingTop: 8,
          paddingBottom: 8,
          [`& .${switchClasses.switchBase}`]: {
            padding: '10px 2px',
            [`&.${switchClasses.checked}`]: {
              transform: 'translateX(12px)',
            },
          },
          [`& .${switchClasses.thumb}`]: {
            width: 12,
            height: 12,
          },
        },
        sizeLarge: {
          width: 56,
          paddingTop: 0,
          paddingBottom: 0,
          [`& .${switchClasses.switchBase}`]: {
            padding: 4,
            [`&.${switchClasses.checked}`]: {
              transform: 'translateX(24px)',
            },
          },
          [`& .${switchClasses.thumb}`]: {
            width: 24,
            height: 24,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          margin: '0 -12px 24px',
          '& .MuiTabs-indicator': {
            transition: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0,
          margin: '0 12px',
          paddingLeft: 0,
          paddingRight: 0,
          opacity: 1,
          textTransform: 'none',
          fontSize: 14,
          color: 'inherit',
          '& .MuiBadge-root': {
            alignItems: 'center',
          },
          '& .MuiBadge-badge': {
            position: 'relative',
            marginLeft: 10,
            transform: 'none',
          },
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelDisplayedRows: () => {},
        labelRowsPerPage: 'Отображать по',
      },
      styleOverrides: {
        root: {
          fontSize: 17,
          [`.${tablePaginationClasses.toolbar} .${tablePaginationClasses.selectLabel}`]: {
            display: 'block',
          },
        },
        selectRoot: {
          background: 'none',
          border: 'none',
          marginRight: 0,
          fontWeight: '500',
          fontSize: 17,
        },
        selectLabel: {
          margin: 0,
          fontWeight: '500',
          fontSize: 17,
        },
        select: {
          paddingLeft: 0,
        },
        selectIcon: {
          color: baseTheme.palette.icons.main,
        },
        toolbar: {
          minHeight: 0,
        },
        displayedRows: {
          display: 'none',
        },
        actions: {
          display: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 52,
          [baseTheme.breakpoints.up('xs')]: {
            minHeight: 52,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '10px 12px',
          backgroundColor: baseTheme.palette.background.inverted,
          borderRadius: 8,
          fontWeight: '400',
          fontSize: 14,
        },
        arrow: {
          color: baseTheme.palette.background.inverted,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          p: 'p',
        },
      },
      styleOverrides: {
        root: {
          [`&.${typographyClasses.gutterBottom}.${typographyClasses.h1}`]: {
            marginBottom: 32,
          },
          [`&.${typographyClasses.gutterBottom}.MuiTypography-h2Alt`]: {
            marginBottom: 24,
          },
        },
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '.PrivatePickersYear-yearButton': {
            borderRadius: 6,
            fontSize: 17,
            [`&.${pickersDayClasses.selected}`]: {
              background: baseTheme.palette.background.inverted,
              color: baseTheme.palette.text.inverted,
              '&:hover, &:focus': {
                background: baseTheme.palette.background.inverted,
              },
            },
          },
          '.PrivatePickersYear-root': {
            flexBasis: '33.3%',
            button: {
              width: '100%',
              height: 32,
              margin: 8,
              borderRadius: 6,
              fontSize: 17,
              [`&.${pickersDayClasses.selected}`]: {
                background: baseTheme.palette.background.inverted,
                color: baseTheme.palette.text.inverted,
                '&:hover, &:focus': {
                  background: baseTheme.palette.background.inverted,
                },
              },
              [`&.${pickersDayClasses.disabled}`]: {
                cursor: 'default',
                '&:hover, &:focus': {
                  background: 'none',
                },
              },
            },
          },
        },
      },
    },
    Badge: {
      styleOverrides: {
        root: {
          display: 'inline-block',
          height: 24,
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 6,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      variants: [
        {
          props: { size: 'extrasmall' },
          style: {
            height: 16,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 4,
            fontSize: 12,
            lineHeight: '16px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: 20,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 4,
            fontSize: 12,
            lineHeight: '20px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: 32,
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 8,
            fontSize: 14,
            lineHeight: '32px',
          },
        },
      ],
    },
    ChartLegend: {
      styleOverrides: {
        root: {
          fontSize: 12,
          '.chartLegend-list': {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 16,
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            listStyle: 'none',
            color: baseTheme.palette.text.secondary,
          },
          '.chartLegend-item': {
            display: 'flex',
            alignItems: 'center',
          },
          '.chartLegend-icon': {
            width: 6,
            height: 6,
            borderRadius: '50%',
            marginLeft: 2,
          },
        },
      },
    },
    ChartTooltip: {
      styleOverrides: {
        root: {
          padding: '10px 12px',
          backgroundColor: baseTheme.palette.background.inverted,
          borderRadius: 8,
          fontSize: 14,
          color: baseTheme.palette.text.inverted,
          '.chartTooltip-icon': {
            display: 'inline-block',
            width: 6,
            height: 6,
            borderRadius: '50%',
            marginLeft: 2,
          },
        },
      },
    },
    DataGridPanels: {
      styleOverrides: {
        root: {
          [`.${gridClasses.main}`]: {
            margin: '0 -10px',
          },
          [`.${gridClasses.columnHeaders}`]: {
            border: 'none',
          },
          [`.${gridClasses.columnHeader}--statusColumn .statusFilter--active, .${gridClasses.columnHeader}--deviceTypeColumn .deviceTypeFilter--active`]: {
            color: baseTheme.palette.action.primary,
          },
          [`.${gridClasses.columnHeaderTitleContainer}`]: {
            order: 2,
          },
          [`.${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 'normal',
            fontSize: 12,
          },
          [`.${gridClasses.menuIcon}`]: {
            order: 1,
            visibility: 'visible',
            width: 'auto',
            marginRight: 0,
          },
          [`.${gridClasses.row}`]: {
            position: 'relative',
            [`.${gridClasses.cell}:first-of-type`]: {
              paddingLeft: '22px',
            },
            [`.${gridClasses.cell}:last-of-type`]: {
              paddingRight: '22px',
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              inset: '0 10px',
              display: 'block',
              borderRadius: 8,
              boxShadow: baseTheme.shadows[12],
              zIndex: '-1',
            },
          },
          [`.${gridClasses.cell}`]: {
            border: 'none',
            paddingLeft: '12px',
            paddingRight: '12px',
          },
        },
      },
    },
    DeviceParamStatus: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          verticalAlign: 'middle',
        },
      },
    },
    Message: {
      styleOverrides: {
        root: {
          paddingTop: 24,
          paddingBottom: 24,
          textAlign: 'center',
          color: baseTheme.palette.text.tertiary,
          '& .Message-icon': {
            display: 'inline-flex',
            width: '64px',
            height: '64px',
            marginBottom: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            boxShadow: `0px 8px 24px ${baseTheme.palette.constant.lightestBlackberry}`,
          },
        },
      },
    },
    StatusBadge: {
      styleOverrides: {
        root: {
          width: 12,
          height: 12,
          background: baseTheme.palette.secondary.main,
          borderRadius: '50%',
        },
      },
      variants: [
        {
          props: { color: 'perfect' },
          style: {
            background: baseTheme.palette.perfect.main,
          },
        },
        {
          props: { color: 'success' },
          style: {
            background: baseTheme.palette.success.main,
          },
        },
        {
          props: { color: 'warning' },
          style: {
            background: baseTheme.palette.warning.main,
          },
        },
        {
          props: { color: 'error' },
          style: {
            background: baseTheme.palette.primary.main,
          },
        },
      ],
    },
    Tag: {
      styleOverrides: {
        root: {
          height: 28,
          borderRadius: '28px',
          fontWeight: 400,
          fontSize: 17,
          lineHeight: '28px',
        },
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            color: baseTheme.palette.success.main,
            background: alpha(baseTheme.palette.success.main, 0.2),
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: baseTheme.palette.warning.main,
            background: alpha(baseTheme.palette.warning.main, 0.2),
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: baseTheme.palette.primary.main,
            background: alpha(baseTheme.palette.primary.main, 0.2),
          },
        },
        {
          props: { color: 'perfect' },
          style: {
            color: baseTheme.palette.perfect.main,
            background: alpha(baseTheme.palette.perfect.main, 0.2),
          },
        },
      ],
    },
    WidgetBox: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: 120,
          padding: '16px 24px',
          borderRadius: 24,
          boxShadow: baseTheme.shadows[12],
          '.widgetBox-header': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: 8,
          },
          '.widgetBox-title': {
            fontWeight: '500',
            fontSize: 12,
            lineHeight: '16px',
            color: baseTheme.palette.text.headline,
          },
        },
      },
    },
  },
});

export default theme;
