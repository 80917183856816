import { createElementObject, createLayerComponent, extendContext } from '@react-leaflet/core';

import { DonutClusterMarker } from './Leaflet.DonutCluster';

const Marker = createLayerComponent(
  function createMarker({ children: _c, position, group, ...options }, ctx) {
    const marker = new DonutClusterMarker(position, group, options);

    return createElementObject(marker, extendContext(ctx, { overlayContainer: marker }));
  },
  function updateMarker(marker, props, prevProps) {
    if (props.group !== prevProps.group) {
      marker.setKind(props.group);
    }
    if (props.position !== prevProps.position) {
      marker.setLatLng(props.position);
    }
    if (props.icon != null && props.icon !== prevProps.icon) {
      marker.setIcon(props.icon);
    }
    if (props.zIndexOffset != null && props.zIndexOffset !== prevProps.zIndexOffset) {
      marker.setZIndexOffset(props.zIndexOffset);
    }
    if (props.opacity != null && props.opacity !== prevProps.opacity) {
      marker.setOpacity(props.opacity);
    }
    if (marker.dragging != null && props.draggable !== prevProps.draggable) {
      if (props.draggable === true) {
        marker.dragging.enable();
      } else {
        marker.dragging.disable();
      }
    }
  },
);

export default Marker;
