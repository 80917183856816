import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { CREATE_BALANCE_GROUP_URL } from '../../constants';
import { IBalanceGroup } from '../../types';
import fetchData from '../../utils/fetchData';
import useSnackbars from '../../utils/hooks/useSnackbars';

import BalanceGroupForm from './BalanceGroupForm';

const CreateBalanceGroup = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const balanceGroupDataMutation = useMutation(
    (templateData: IBalanceGroup) => {
      return fetchData(CREATE_BALANCE_GROUP_URL, {
        method: 'POST',
        body: templateData,
      });
    },
    {
      onSuccess: () => {
        showSnackbar(<Alert severity='success'>Балансовая группа добавлена</Alert>);
        queryClient.invalidateQueries('balanceGroupsData', {
          refetchInactive: true
        });
        navigate(`/balance-groups`);
      },
      onError: () => {
        showSnackbar(<Alert severity='error'>Балансовая группа не добавлена</Alert>);
      },
    },
  );

  const handleSubmit = (data: IBalanceGroup) => {
    balanceGroupDataMutation.mutate(data);
  };

  return (
    <>
      <Breadcrumbs>
        <Link component={RouterLink} to="/balance-groups">
          Балансовые группы
        </Link>
        <span>Добавить балансовую группу</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Добавить балансовую группу
      </Typography>
      <BalanceGroupForm
        isLoading={balanceGroupDataMutation.isLoading}
        onFormSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateBalanceGroup;
