import { useMemo } from 'react';

import { GET_OBJECT_DEVICES_WS_URL } from '../../../constants';
import useWebsocket from '../../../utils/hooks/useWebsocket';

const useWSObjectDevices = (
  type: string,
  id: string,
) => {

  const queryKey = useMemo(() => ['objectDevicesData', type, id], [id, type]);

  return useWebsocket({
    url: GET_OBJECT_DEVICES_WS_URL(
      type,
      id,
    ),
    queryKey,
    enabled: true
  });
};

export default useWSObjectDevices;
