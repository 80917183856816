import React, { useState, useEffect } from 'react';

import { useQueryClient } from 'react-query';

import { FormProvider, useForm } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

import CustomBodyReport from '../../../components/CustomReports/CustomBodyReport';
import CustomHeaderReport from '../../../components/CustomReports/CustomHeaderReport';
import CustomSettingsReport from '../../../components/CustomReports/CustomSettingsReport';
import ErrorIcon from '../../../components/icons/ErrorIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { IContextReportValues, IUser } from '../../../types';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchCustomReports from '../hooks/useFetchCustomReports';

const reportTemplates: any = {
  templates: {
    report_device: 'Отчет по устройству',
    report_group: 'Отчет по группе',
  },
};

const ReportsCustomParams = ({
  onFormSubmit,
  selectedDevice,
  selectedTemplate,
  reportTitleText,
  reportDescriptionText,
  headerAtrCheckboxes,
  numFieldsCreated,
  isHeaderTextEnabled,
  selectDeviceError,
  selectTamplateError,
  setSelectDeviceError,
  setSelectTamplateError,
  setSelectedDevice,
  setSelectedTemplate,
  setReportTitleText,
  setReportDescriptionText,
  setNumFieldsCreated,
  setSettingFields,
  setIsHeaderTextEnabled,
  setHeaderAtrCheckboxes,
  bodyAtrCheckboxes,
  setBodyAtrCheckboxes,
  bodyClientAtrCheckboxes,
  setBodyClientAtrCheckboxes,
  bodyChanneUspdlAtrCheckboxes,
  setBodyChannelUspdAtrCheckboxes,
  bodyChanneSenslAtrCheckboxes,
  setBodyChannelSensAtrCheckboxes,
  settingFields,
  reportTitleTextError,
  setReportTitleTextError,
  headerDateError,
  setHeaderDateError,
}: IContextReportValues) => {
  const [isSettingsReportOpen, setIsSettingsReportOpen] = useState(true); // состояние блока с настройкой отчета
  const [isHeaderReportOpen, setIsHeaderReportOpen] = useState(true); // состояние блока с шапкой отчета
  const [isBodyReportOpen, setIsBodyReportOpen] = useState(true); // состояние блока с телом отчета
  const [isClientlReportOpen, setIsClientReportOpen] = useState(true); // состояние блока с пользоват. атр. отчета

  // получаем атрибуты для формы
  const { dataCustomDevices, isLoading } = useFetchCustomReports();

  const methods = useForm({ mode: 'onChange' });

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const handleTemplateCheckbox = (e: any) => {
    const value = e.target.value;
    const prevValue = selectedTemplate;

    if (setSelectedTemplate && setHeaderAtrCheckboxes && setSelectTamplateError) {
      setSelectedTemplate(value);

      if (value === 'report_group') {
        setHeaderAtrCheckboxes((prevState: any) => {
          const updatedCheckboxes = [...prevState];
          const assetCheckbox = updatedCheckboxes.find((item) => item.name === 'asset');
          const dateCheckbox = updatedCheckboxes.find((item) => item.name === 'date');

          if (assetCheckbox) {
            assetCheckbox.checked = true;
          }
          if (dateCheckbox) {
            dateCheckbox.checked = true;
          }

          const idCheckbox = updatedCheckboxes.find((item) => item.name === 'id');
          if (idCheckbox && prevValue === 'report_device') {
            idCheckbox.checked = false;
          }

          return updatedCheckboxes;
        });
      } else if (value === 'report_device') {
        setHeaderAtrCheckboxes((prevState: any) => {
          const updatedCheckboxes = [...prevState];
          const idCheckbox = updatedCheckboxes.find((item) => item.name === 'id');
          const dateCheckbox = updatedCheckboxes.find((item) => item.name === 'date');

          if (idCheckbox) {
            idCheckbox.checked = true;
          }
          if (dateCheckbox) {
            dateCheckbox.checked = true;
          }

          const assetCheckbox = updatedCheckboxes.find((item) => item.name === 'asset');
          if (assetCheckbox && prevValue === 'report_group') {
            assetCheckbox.checked = false;
          }

          return updatedCheckboxes;
        });
      }

      setSelectTamplateError(false);
    }
  };

  // включение и выключение чекбокса текстовое поле в шапке отчета
  const handleIsHeaderTextEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsHeaderTextEnabled && setIsHeaderTextEnabled(e.target.checked);

    sendMetrik(
      'vntVdknl',
      'otchety_konstruktor',
      'checkbox_click',
      'tekstovoe_pole',
      e.target.checked,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports/customReportCreate',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  // изменение состояния ввода в полях в шапке отчета
  const handleFieldChange =
    (index: number, fieldToUpdate: any) => (event: { target: { value: any } }) => {
      const newFields = settingFields.map((field: any, i: number) => {
        if (index === i) {
          return {
            ...field,
            [fieldToUpdate]: event.target.value,
          };
        }

        return field;
      });
      setSettingFields && setSettingFields(newFields);
    };

  // добавление новых полей в шапке отчета
  const handleAddField = () => {
    if (setSettingFields && setNumFieldsCreated && numFieldsCreated) {
      setSettingFields([...settingFields, { name: '', value: '' }]);
      setNumFieldsCreated(numFieldsCreated + 1);
    }
  };

  // удаление новых полей в шапке отчета
  const handleDeleteFields = () => {
    if (setSettingFields && setNumFieldsCreated && numFieldsCreated) {
      const newFields = settingFields.slice(0, numFieldsCreated - 1);
      setSettingFields(newFields);
      setNumFieldsCreated(numFieldsCreated - 1);
    }
  };

  // выбор атрибутов (чекбоксы) в шапке отчета
  const handleHeaderAtr = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCheckboxes = headerAtrCheckboxes?.map((checkbox: { name: string }) =>
      checkbox.name === event.target.name
        ? { ...checkbox, checked: event.target.checked }
        : checkbox,
    );
    setHeaderAtrCheckboxes && setHeaderAtrCheckboxes(updatedCheckboxes);
  };

  useEffect(() => {
    if (dataCustomDevices?.attrib_name_mapping_for_rg_common) {
      const columnAtrNames = Object.keys(dataCustomDevices.attrib_name_mapping_for_rg_common).map(
        (key) => ({
          name: key,
          label: dataCustomDevices.attrib_name_mapping_for_rg_common[key],
          checked: false,
        }),
      );
      const columnAtrFilters = Object.keys(dataCustomDevices.attrib_name_mapping_for_rg_common).map(
        (key) => ({
          name: key,
          label: '',
          checked: false,
          disabled: true,
        }),
      );
      setBodyAtrCheckboxes &&
        setBodyAtrCheckboxes((prevState: any) => ({
          ...prevState,
          attr: {
            columnAtrNames,
            columnAtrFilters,
            columnAllChecked: false,
          },
        }));
    }

    if (dataCustomDevices?.attrib_name_mapping_client) {
      const columnAtrNames = Object.keys(dataCustomDevices.attrib_name_mapping_client).map(
        (key) => ({
          name: key,
          label: dataCustomDevices.attrib_name_mapping_client[key],
          checked: false,
        }),
      );
      const columnAtrFilters = Object.keys(dataCustomDevices.attrib_name_mapping_client).map(
        (key) => ({
          name: key,
          label: '',
          checked: false,
          disabled: true,
        }),
      );
      setBodyClientAtrCheckboxes &&
        setBodyClientAtrCheckboxes((prevState: any) => ({
          ...prevState,
          attr: {
            columnAtrNames,
            columnAtrFilters,
            columnAllChecked: false,
          },
        }));
    }

    if (dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_uspd) {
      const columnAtrNames = Object.keys(
        dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_uspd,
      ).map((key) => ({
        name: key,
        label: dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_uspd[key],
        checked: false,
      }));
      const columnAtrFilters = Object.keys(
        dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_uspd,
      ).map((key) => ({
        name: key,
        label: '',
        checked: false,
        disabled: true,
      }));
      setBodyChannelUspdAtrCheckboxes &&
        setBodyChannelUspdAtrCheckboxes((prevState: any) => ({
          ...prevState,
          attr: {
            columnAtrNames,
            columnAtrFilters,
            columnAllChecked: false,
          },
        }));
    }

    if (dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_dd) {
      const columnAtrNames = Object.keys(
        dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_dd,
      ).map((key) => ({
        name: key,
        label: dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_dd[key],
        checked: false,
      }));
      const columnAtrFilters = Object.keys(
        dataCustomDevices.attrib_name_mapping_for_rg_channel_settings_dd,
      ).map((key) => ({
        name: key,
        label: '',
        checked: false,
        disabled: true,
      }));
      setBodyChannelSensAtrCheckboxes &&
        setBodyChannelSensAtrCheckboxes((prevState: any) => ({
          ...prevState,
          attr: {
            columnAtrNames,
            columnAtrFilters,
            columnAllChecked: false,
          },
        }));
    }
  }, [
    dataCustomDevices,
    setBodyAtrCheckboxes,
    setBodyChannelSensAtrCheckboxes,
    setBodyChannelUspdAtrCheckboxes,
    setBodyClientAtrCheckboxes,
  ]);

  // функции управления чекбоксами атрибутов в теле отчета
  const handleColumnNames = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    data: any,
    dataCheckboxes: any,
    setStateCheckboxes: any,
  ) => {
    if (data && event.target.checked) {
      const updatedColumnAtrNames = [...dataCheckboxes.attr.columnAtrNames];
      updatedColumnAtrNames[index].checked = true;
      setStateCheckboxes((prevState: any) => {
        const updatedColumnAtrFilters = [...prevState.attr.columnAtrFilters];
        updatedColumnAtrFilters[index].disabled = false;

        return {
          ...prevState,
          attr: {
            ...prevState.attr,
            columnAtrNames: updatedColumnAtrNames,
            columnAtrFilters: updatedColumnAtrFilters,
            columnAllChecked: updatedColumnAtrNames.every((option) => option.checked),
          },
        };
      });
    } else {
      const updatedColumnAtrNames = [...dataCheckboxes.attr.columnAtrNames];
      updatedColumnAtrNames[index].checked = false;
      setStateCheckboxes((prevState: any) => {
        const updatedColumnAtrFilters = [...prevState.attr.columnAtrFilters];
        updatedColumnAtrFilters[index].disabled = true;
        updatedColumnAtrFilters[index].checked = false;

        return {
          ...prevState,
          attr: {
            ...prevState.attr,
            columnAtrNames: updatedColumnAtrNames,
            columnAtrFilters: updatedColumnAtrFilters,
            columnAllChecked: false,
          },
        };
      });
    }
  };

  const handleColumnFilters = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    data: any,
    dataCheckboxes: any,
    setStateCheckboxes: any,
  ) => {
    if (data && dataCheckboxes.attr.columnAtrNames[index].checked) {
      const updatedColumnAtrFilters = [...dataCheckboxes.attr.columnAtrFilters];
      updatedColumnAtrFilters[index].checked = event.target.checked;
      setStateCheckboxes((prevState: any) => ({
        ...prevState,
        attr: {
          ...prevState.attr,
          columnAtrFilters: updatedColumnAtrFilters,
        },
      }));
    }
  };

  const handleColumnAllNames = (
    event: React.ChangeEvent<HTMLInputElement>,
    dataCheckboxes: any,
    setStateCheckboxes: any,
  ) => {
    const updatedColumnAtrNames = dataCheckboxes.attr.columnAtrNames.map((option: any) => ({
      ...option,
      checked: event.target.checked,
    }));
    setStateCheckboxes((prevState: any) => {
      const updatedColumnAtrFilters = prevState.attr.columnAtrFilters.map((option: any) => ({
        ...option,
        disabled: !event.target.checked,
      }));

      if (!event.target.checked) {
        updatedColumnAtrFilters.forEach((filter: any) => {
          filter.checked = false;
          filter.disabled = true;
        });
      }

      return {
        ...prevState,
        attr: {
          ...prevState.attr,
          columnAtrNames: updatedColumnAtrNames,
          columnAtrFilters: updatedColumnAtrFilters,
          columnAllChecked: event.target.checked,
        },
      };
    });
  };

  if (isLoading) {
    return <Spinner data-testid="loader"></Spinner>;
  }

  return (
    <>
      {dataCustomDevices ? (
        <FormProvider {...methods}>
          <form onSubmit={(event) => onFormSubmit && onFormSubmit(event)}>
            <CustomSettingsReport
              dataCustomDevices={dataCustomDevices}
              isSettingsReportOpen={isSettingsReportOpen}
              setIsSettingsReportOpen={setIsSettingsReportOpen}
              selectedDevice={selectedDevice}
              selectDeviceError={selectDeviceError}
              setSelectedDevice={setSelectedDevice}
              setSelectDeviceError={setSelectDeviceError}
              selectTamplateError={selectTamplateError}
              selectedTemplate={selectedTemplate}
              reportTemplates={reportTemplates}
              handleTemplateCheckbox={handleTemplateCheckbox}
              reportTitleText={reportTitleText}
              setReportTitleText={setReportTitleText}
              reportDescriptionText={reportDescriptionText}
              setReportDescriptionText={setReportDescriptionText}
              reportTitleTextError={reportTitleTextError}
              setReportTitleTextError={setReportTitleTextError}
            />
            <CustomHeaderReport
              isHeaderReportOpen={isHeaderReportOpen}
              setIsHeaderReportOpen={setIsHeaderReportOpen}
              isHeaderTextEnabled={isHeaderTextEnabled}
              handleIsHeaderTextEnabled={handleIsHeaderTextEnabled}
              settingFields={settingFields}
              handleFieldChange={handleFieldChange}
              handleAddField={handleAddField}
              numFieldsCreated={numFieldsCreated}
              handleDeleteFields={handleDeleteFields}
              headerAtrCheckboxes={headerAtrCheckboxes}
              handleHeaderAtr={handleHeaderAtr}
              selectedTemplate={selectedTemplate}
              setHeaderAtrCheckboxes={setHeaderAtrCheckboxes}
              headerDateError={headerDateError}
              setHeaderDateError={setHeaderDateError}
            />
            <CustomBodyReport
              isBodyReportOpen={isBodyReportOpen}
              setIsBodyReportOpen={setIsBodyReportOpen}
              isClientlReportOpen={isClientlReportOpen}
              setIsClientReportOpen={setIsClientReportOpen}
              bodyAtrCheckboxes={bodyAtrCheckboxes}
              handleColumnAllNames={handleColumnAllNames}
              setBodyAtrCheckboxes={setBodyAtrCheckboxes}
              handleColumnNames={handleColumnNames}
              dataCustomDevices={dataCustomDevices}
              handleColumnFilters={handleColumnFilters}
              bodyClientAtrCheckboxes={bodyClientAtrCheckboxes}
              setBodyClientAtrCheckboxes={setBodyClientAtrCheckboxes}
              selectedDevice={selectedDevice}
              bodyChanneUspdlAtrCheckboxes={bodyChanneUspdlAtrCheckboxes}
              setBodyChannelUspdAtrCheckboxes={setBodyChannelUspdAtrCheckboxes}
              bodyChanneSenslAtrCheckboxes={bodyChanneSenslAtrCheckboxes}
              setBodyChannelSensAtrCheckboxes={setBodyChannelSensAtrCheckboxes}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-testid="submit"
              sx={{ px: 5, backgroundColor: '#FF0032', mt: 4 }}
              onClick={() => {
                sendMetrik(
                  'vntVdknl',
                  'otchety_konstruktor',
                  'button_click',
                  'sohranit',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/reports/customReportCreate',
                  null,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              }}
            >
              <Typography sx={{ weigth: 500, fontSize: '17px', color: '#FFFFFF' }}>
                Сохранить
              </Typography>
            </Button>
          </form>
        </FormProvider>
      ) : (
        <Message icon={<ErrorIcon />}>
          Не получилось загрузить данные для конструктора. Попробуйте еще раз.
        </Message>
      )}
    </>
  );
};

export default ReportsCustomParams;
