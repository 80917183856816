import { useQuery } from 'react-query';

import { DEVICE_PROFILES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchDeviceProfiles = () => {
  return useQuery('deviceProfilesData',
  () => fetchData(DEVICE_PROFILES_URL));
}

export default useFetchDeviceProfiles;
