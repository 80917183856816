import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';

import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import ErrorIcon from '../../../components/icons/ErrorIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { GET_REPORT_ITEMS_PAGE_URL, GET_BALANCE_GROUP_REPORT_URL } from '../../../constants';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
// import getLocalTimestamp from '../../utils/getLocalTimestamp';
// import getUTCTimestamp from '../../utils/getUTCTimestamp';
import useFetchBalanceGroupReport from '../hooks/useFetchBalanceGroupReport';

const Panel = styled(Box)(({ theme }) => ({
  overflow: "auto",
  marginBottom: 38,
  padding: '8px 16px',
  background: theme.palette.background.secondary,
  borderRadius: 16,
}));

const BalanceGroupReportDetails = () => {
  let { balanceGroupId = '' } = useParams();
  let [searchParams] = useSearchParams();
  let dateFromParam = searchParams.get('date_from') || '';
  let dateToParam = searchParams.get('date_to') || '';
  let nameParam = searchParams.get('name') || '';

  const { isLoading, isError, data } = useFetchBalanceGroupReport(
    balanceGroupId,
    dateFromParam,
    dateToParam,
    false,
  );


  let deviationDataColumns: any[] = [];
  let deviationDataRows: any[] = [];
  let parentsConsumptionDataColumns: any[] = [];
  let parentsConsumptionDataRows: any[] = [];
  let childrenConsumptionDataColumns: any[] = [];
  let childrenConsumptionDataRows: any[] = [];
  let parentsDevicesDataColumns: any[] = [];
  let childrenDevicesDataColumns: any[] = [];
  let childrenDevicesDataRows: any[] = [];
  if (data) {
    deviationDataColumns = [
      {
        field: "days",
        headerName: data?.columns?.balance_group_difference?.days,
        flex: 1,
      },
      {
        field: 'diff',
        headerName: data?.columns?.balance_group_difference?.diff,
        flex: 1,
      },
      {
        field: 'percent_diff',
        headerName: data?.columns?.balance_group_difference?.percent_diff,
        flex: 1,
      },
    ];
    deviationDataRows = [{
      id: 'deviation',
      ...data.balance_group_difference[0]
    }];

    parentsConsumptionDataColumns = [
      {
        field: 'start_date',
        headerName: data?.columns?.balance_group_parents?.start_date,
        flex: 1,
      },
      {
        field: 'end_date',
        headerName: data?.columns?.balance_group_parents?.end_date,
        flex: 1,
      },
      {
        field: 'consumption',
        headerName: data?.columns?.balance_group_parents?.consumption,
        flex: 1,
      },
    ];
    parentsConsumptionDataRows = [{
      id: 'parentDevicesConsumption',
      ...data.balance_group_parents[0]
    }];

    childrenConsumptionDataColumns = [
      {
        field: 'start_date',
        headerName: data?.columns?.balance_group_children?.start_date,
        flex: 1,
      },
      {
        field: 'end_date',
        headerName: data?.columns?.balance_group_children?.end_date,
        flex: 1,
      },
      {
        field: 'consumption',
        headerName: data?.columns?.balance_group_children?.consumption,
        flex: 1,
      },
    ];
    childrenConsumptionDataRows = [{
      id: 'childDevicesConsumption',
      ...data.balance_group_children[0]
    }];

    parentsDevicesDataColumns = [
      {
        field: 'device_id',
        headerName: data?.columns?.balance_group_parents_details?.device_id,
        flex: 1,
      },
      {
        field: 'channel_numer',
        headerName: data?.columns?.balance_group_parents_details?.channel_numer,
        flex: 1,
      },
      {
        field: 'sensor_id',
        headerName: data?.columns?.balance_group_parents_details?.sensor_id,
        flex: 1,
      },
      {
        field: 'min_date',
        headerName: data?.columns?.balance_group_parents_details?.min_date,
        flex: 1,
      },
      {
        field: 'max_date',
        headerName: data?.columns?.balance_group_parents_details?.max_date,
        flex: 1,
      },
      {
        field: 'consumption',
        headerName: data?.columns?.balance_group_parents_details?.consumption,
        flex: 1,
      },
    ];

    childrenDevicesDataColumns = [
      {
        field: 'device_id',
        headerName: data?.columns?.balance_group_children_details?.device_id,
        flex: 1,
      },
      {
        field: 'channel_numer',
        headerName: data?.columns?.balance_group_children_details?.channel_numer,
        flex: 1,
      },
      {
        field: 'sensor_id',
        headerName: data?.columns?.balance_group_children_details?.sensor_id,
        flex: 1,
      },
      {
        field: 'min_date',
        headerName: data?.columns?.balance_group_children_details?.min_date,
        flex: 1,
      },
      {
        field: 'max_date',
        headerName: data?.columns?.balance_group_children_details?.max_date,
        flex: 1,
      },
      {
        field: 'consumption',
        headerName: data?.columns?.balance_group_children_details?.consumption,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const { row, value } = params;
          if (row.id === "total") {
            let totalValue = 0;
            data?.balance_group_children_details.forEach((item: any) => {
              totalValue += item.consumption;
            });

            return totalValue;
          }

          return value;
        }
      }
    ];

    childrenDevicesDataRows = [
      ...data?.balance_group_children_details,
      {
        id: "total",
        "device_id": "",
        "channel_numer": "",
        "sensor_id": "",
        "start_date": "",
        "end_date": "Итого",
        "cunsumption": ""
      },
    ];
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !data) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные отчета. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to={GET_REPORT_ITEMS_PAGE_URL(
            'uspd',
            'balanceGroup',
            '',
            dateFromParam,
            dateToParam,
            null,
            null,
          )}
        >
          Отчеты
        </Link>
        <span>Отчет по балансовой группе</span>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          mb: 3,
        }}
      >
        <Typography variant="h1">Отчет по балансовой группе “{nameParam}”</Typography>
        <IconButton
          component="a"
          color="primary"
          size="small"
          href={GET_BALANCE_GROUP_REPORT_URL(balanceGroupId, dateFromParam, dateToParam, true)}
          download
          sx={{
            ml: 2,
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Box>
      <Panel
        sx={{
          padding: 3,
        }}
      >
        <Typography variant="paragraph3">
          <b>Период:</b> {convertTimestampToDate(dateFromParam)} -{' '}
          {convertTimestampToDate(dateToParam)}
        </Typography>
      </Panel>
      <Typography variant="h4" gutterBottom>{data.titles.balance_group_difference}</Typography>
      <Panel>
        <DataGrid
          columns={deviationDataColumns}
          rows={deviationDataRows}
          components={{
            ColumnSortedAscendingIcon: AscendingIcon,
            ColumnSortedDescendingIcon: DescendingIcon,
          }}
          hideFooter
        />
      </Panel>
      <Typography variant="h4" gutterBottom>{data.titles.balance_group_parents}</Typography>
      <Panel>
        <DataGrid
          columns={parentsConsumptionDataColumns}
          rows={parentsConsumptionDataRows}
          components={{
            ColumnSortedAscendingIcon: AscendingIcon,
            ColumnSortedDescendingIcon: DescendingIcon,
          }}
          hideFooter
        />
      </Panel>
      <Typography variant="h4" gutterBottom>{data.titles.balance_group_children}</Typography>
      <Panel>
        <DataGrid
          columns={childrenConsumptionDataColumns}
          rows={childrenConsumptionDataRows}
          components={{
            ColumnSortedAscendingIcon: AscendingIcon,
            ColumnSortedDescendingIcon: DescendingIcon,
          }}
          hideFooter
        />
      </Panel>
      <Typography variant="h4" gutterBottom>{data.titles.balance_group_parents_details}</Typography>
      <Panel sx={{
        maxHeight: "195px",
      }}>
        <DataGrid
          columns={parentsDevicesDataColumns}
          rows={data.balance_group_parents_details}
          components={{
            ColumnSortedAscendingIcon: AscendingIcon,
            ColumnSortedDescendingIcon: DescendingIcon,
          }}
          getRowId={(row) => `${row.device_id}-${row.sensor_id}-${row.channel_numer}`}
          hideFooter
        />
      </Panel>
      <Typography variant="h4" gutterBottom>{data.titles.balance_group_children_details}</Typography>
      <Panel sx={{
        maxHeight: "195px",
      }}>
        <DataGrid
          columns={childrenDevicesDataColumns}
          rows={childrenDevicesDataRows}
          components={{
            ColumnSortedAscendingIcon: AscendingIcon,
            ColumnSortedDescendingIcon: DescendingIcon,
          }}
          getRowId={(row) => `${row.device_id}-${row.sensor_id}-${row.channel_numer}`}
          hideFooter
        />
      </Panel>
    </>
  );
};

export default BalanceGroupReportDetails;
