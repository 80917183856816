import { useState } from 'react';

import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Sidebar from '../../components/Sidebar';
import { LOGOUT_URL, LOGIN_URL } from '../../constants';
import useAppContext from '../../store/hooks/useAppContext';
import { IUser } from '../../types';
import getInitials from '../../utils/getInitials';
import sendMetrik from '../../utils/sendMetrik';

const Logo = styled(NavLink)<NavLinkProps>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '52px',
  marginRight: 120,
  fontWeight: 500,
  fontSize: 11,
  lineHeight: '16px',
  fontFamily: 'MTSWide, sans-serif',
  textDecoration: 'none',
  color: theme.palette.text.headline,
  '.logo-text': {
    marginLeft: 8,
  },
}));

const MainNavLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
  position: 'relative',
  height: '52px',
  margin: '0 12px',
  textDecoration: 'none',
  fontWeight: '500',
  fontSize: '17px',
  color: 'inherit',
  lineHeight: '52px',
  whiteSpace: 'nowrap',
  '&:after': {
    content: "''",
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    opacity: 0,
  },
  '&.active': {
    '&:after': {
      opacity: 1,
    },
  },
}));

const Header = () => {
  let location = useLocation();
  const isHeadlessPage = location.pathname === '/teplo';

  const queryClient = useQueryClient();

  const { userRights } = useAppContext();

  const userData: IUser | undefined = queryClient.getQueryData('userData');
  const envData: any = queryClient.getQueryData('envData');

  const [isProfileSidebarActive, setIsProfileSidebarActive] = useState(false);

  const handleLogoutClick = () => {
    window.location.assign(LOGOUT_URL);
  };

  const handleLoginClick = () => {
    window.location.assign(LOGIN_URL);

    sendMetrik(
      'vntLogin',
      'avtorizaciya',
      'button_click',
      'voiti',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/landing',
      'voiti',
      null,
      'screen',
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  return (
    <AppBar color="default" position="static">
      <Container>
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Logo
            to="/"
            onClick={() =>
              sendMetrik(
                'vntVdknl',
                userData ? 'main' : 'lending',
                'element_click',
                'logo',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                userData ? '/' : '/landing',
                null,
                null,
                'header',
                'interactions',
                userData?.profile_type,
                'web',
              )
            }
          >
            <img className="logo-img" src="/mts_logo.svg" alt="МТС" />
            <Box className="logo-text">
              ЦИФРОВОЙ
              <br /> ВОДОКАНАЛ
            </Box>
          </Logo>
          {!isHeadlessPage &&
            (userData?.customerId ? (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    margin: '0 -12px',
                  }}
                >
                  <MainNavLink
                    to="map"
                    onClick={() =>
                      sendMetrik(
                        'vntVdknl',
                        'main',
                        'element_click',
                        'karta',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/',
                        null,
                        null,
                        'header',
                        'interactions',
                        userData?.profile_type,
                        'web',
                      )
                    }
                  >
                    Карта
                  </MainNavLink>
                  <MainNavLink
                    to="objects"
                    onClick={() =>
                      sendMetrik(
                        'vntVdknl',
                        'main',
                        'element_click',
                        'ustroistva',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/',
                        null,
                        null,
                        'header',
                        'interactions',
                        userData?.profile_type,
                        'web',
                      )
                    }
                  >
                    Устройства
                  </MainNavLink>
                  <MainNavLink
                    to="reports"
                    onClick={() =>
                      sendMetrik(
                        'vntVdknl',
                        'main',
                        'element_click',
                        'otchety',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/',
                        null,
                        null,
                        'header',
                        'interactions',
                        userData?.profile_type,
                        'web',
                      )
                    }
                  >
                    Отчеты
                  </MainNavLink>
                  <MainNavLink
                    to="dashboards"
                    onClick={() =>
                      sendMetrik(
                        'vntVdknl',
                        'main',
                        'element_click',
                        'dashbordy',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/',
                        null,
                        null,
                        'header',
                        'interactions',
                        userData?.profile_type,
                        'web',
                      )
                    }
                  >
                    Дашборды
                  </MainNavLink>
                  {(envData?.APP_ENV_NOTIFICATIONS !== '0' ||
                    userData?.customerId === '792d52e3-3630-4fbb-bfe9-03f93e7d19f4' ||
                    userData?.customerId === 'c32142b0-5dfd-11ee-935a-2da7766d1a9b') && (
                    <MainNavLink to="notifications">Уведомления</MainNavLink>
                  )}
                  {envData && envData.APP_ENV_BALANCEGROUPS !== '0' && (
                    <MainNavLink
                      to="balance-groups"
                      onClick={() =>
                        sendMetrik(
                          'vntVdknl',
                          'main',
                          'element_click',
                          'balansovye_gruppy',
                          null,
                          userData?.permissions[0].uuid,
                          userData ? '1' : '0',
                          '/',
                          null,
                          null,
                          'header',
                          'interactions',
                          userData?.profile_type,
                          'web',
                        )
                      }
                    >
                      Балансовые группы
                    </MainNavLink>
                  )}
                  {userRights?.isAdmin && envData && envData.APP_ENV_USERS !== '0' && (
                    <MainNavLink to="users">Пользователи</MainNavLink>
                  )}
                  {userRights?.isAdmin && (
                    <MainNavLink
                      to="admin"
                      onClick={() =>
                        sendMetrik(
                          'vntVdknl',
                          'main',
                          'element_click',
                          'admin',
                          null,
                          userData?.permissions[0].uuid,
                          userData ? '1' : '0',
                          '/',
                          null,
                          null,
                          'header',
                          'interactions',
                          userData?.profile_type,
                          'web',
                        )
                      }
                    >
                      Админ
                    </MainNavLink>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsProfileSidebarActive(true);
                      sendMetrik(
                        'vntVdknl',
                        'main',
                        'element_click',
                        'userpik',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/',
                        null,
                        null,
                        'header',
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    <Avatar>{getInitials(userData.fullname)}</Avatar>
                  </Box>
                  <Sidebar
                    open={isProfileSidebarActive}
                    title="Профиль"
                    onClose={() => setIsProfileSidebarActive(false)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: '20px',
                      }}
                    >
                      <Avatar>{getInitials(userData.fullname)}</Avatar>
                      <Typography ml="10px" paragraph>
                        {userData.fullname}
                      </Typography>
                    </Box>
                    <Button onClick={handleLogoutClick}>Выйти</Button>
                  </Sidebar>
                </Box>
              </>
            ) : (
              <div style={{ display: 'flex' }}>
                <Button onClick={handleLoginClick}>Войти</Button>
              </div>
            ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
