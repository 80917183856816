import Box from '@mui/material/Box';
import FormLabel, {FormLabelProps} from '@mui/material/FormLabel';

import LockIcon from '../icons/LockIcon';

const CustomFormLabel = ({disabled, children, ...props}: FormLabelProps) => {

  return (
    <FormLabel {...props}>
      <Box component="span" className='MuiFormLabel-inner'>
        {children}
        {disabled && <LockIcon fontSize="small" />}
      </Box>
    </FormLabel>
  );
}; 

export default CustomFormLabel;