import { useState } from 'react';

import { useMutation } from 'react-query';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Divider, Grid, FormControl, Input, InputLabel, Typography } from '@mui/material';

import Main from '../../components/Layout/Main';
import {
  CREATE_USER_URL,
  GET_USER_URL,
  UPDATE_USER_URL,
  GET_DELETE_USER_URL,
} from '../../constants';
import fetchData from '../../utils/fetchData';
import useSnackbars from '../../utils/hooks/useSnackbars';

import CurrentARKIObjects from './components/CurrentARKIObjects';
import FindARKIObjects from './components/FindARKIObjects';

type FormValues = {
  msisdn: string;
  customerId?: string;
  permissions_short?: any;
};

const defaultValues = {
  msisdn: '',
  customerId: '',
  permissions_short: '',
};

const Admin = () => {
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const { handleSubmit: createUserHandleSubmit, control: createUserControl } = useForm<FormValues>({
    defaultValues,
  });
  const { handleSubmit: getUserHandleSubmit, control: getUserControl } = useForm<FormValues>({
    defaultValues,
  });
  const { handleSubmit: updateUserHandleSubmit, control: updateUserControl } = useForm<FormValues>({
    defaultValues,
  });
  const { handleSubmit: deleteUserHandleSubmit, control: deleteUserControl } = useForm<FormValues>({
    defaultValues,
  });

  const { showSnackbar } = useSnackbars();

  const createUserMutation = useMutation(
    (formData: FormValues) =>
      fetchData(CREATE_USER_URL, {
        method: 'POST',
        body: formData,
      }),
    {
      onSuccess: () => {
        setCreateUserLoading(false);
        showSnackbar(<Alert severity="success">Запрос отправлен</Alert>);
      },
      onError: () => {
        setCreateUserLoading(false);
        showSnackbar(<Alert severity="error">Ошибка | Запрос не отправлен</Alert>);
      },
    },
  );
  const getUserMutation = useMutation((msisdn: string) => fetchData(GET_USER_URL(msisdn)), {
    onSuccess: () => {
      setGetUserLoading(false);
      showSnackbar(<Alert severity="success">Запрос отправлен</Alert>);
    },
    onError: () => {
      setGetUserLoading(false);
      showSnackbar(<Alert severity="error">Ошибка | Запрос не отправлен</Alert>);
    },
  });

  const updateUserMutation = useMutation(
    (formData: FormValues) =>
      fetchData(UPDATE_USER_URL, {
        method: 'PUT',
        body: formData,
      }),
    {
      onSuccess: () => {
        setUpdateUserLoading(false);
        showSnackbar(<Alert severity="success">Запрос отправлен</Alert>);
      },
      onError: () => {
        setUpdateUserLoading(false);
        showSnackbar(<Alert severity="error">Ошибка | Запрос не отправлен</Alert>);
      },
    },
  );

  const deleteUserMutation = useMutation(
    (msisdn: string) =>
      fetchData(GET_DELETE_USER_URL(msisdn), {
        method: 'PUT',
      }),
    {
      onSuccess: () => {
        setDeleteUserLoading(false);
        showSnackbar(<Alert severity="success">Запрос отправлен</Alert>);
      },
      onError: () => {
        setDeleteUserLoading(false);
        showSnackbar(<Alert severity="error">Ошибка | Запрос не отправлен</Alert>);
      },
    },
  );
  const onCreateUserSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (formData.msisdn && formData.customerId && formData.permissions_short) {
      setCreateUserLoading(true);
      const newFormData = {
        ...formData,
        permissions_short: formData.permissions_short.split(' '),
      };
      await createUserMutation.mutateAsync(newFormData);
    }
  };

  const onGetUserSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (formData.msisdn) {
      setGetUserLoading(true);
      await getUserMutation.mutateAsync(formData.msisdn);
    }
  };

  const onUpdateUserSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (formData.msisdn && formData.customerId && formData.permissions_short) {
      setUpdateUserLoading(true);
      const newFormData = {
        ...formData,
        permissions_short: formData['permissions_short']?.split(' '),
      };
      await updateUserMutation.mutateAsync(newFormData);
    }
  };
  const onDeleteUserSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (formData.msisdn) {
      setDeleteUserLoading(true);
      await deleteUserMutation.mutateAsync(formData.msisdn);
    }
  };

  return (
    <Main>
      <Typography variant="h2" gutterBottom>
        Управление пользователями
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <form onSubmit={createUserHandleSubmit(onCreateUserSubmit)}>
            <Typography variant="h3">Создание пользователя</Typography>
            <FormControl>
              <InputLabel>Телефон</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="msisdn"
                control={createUserControl}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Customer ID</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="customerId"
                control={createUserControl}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Права</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="permissions_short"
                control={createUserControl}
              />
            </FormControl>
            <FormControl>
              <LoadingButton type="submit" loading={createUserLoading}>
                Создать
              </LoadingButton>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={getUserHandleSubmit(onGetUserSubmit)}>
            <Typography variant="h3">Получение данных пользователя</Typography>
            <FormControl>
              <InputLabel>Телефон</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="msisdn"
                control={getUserControl}
              />
            </FormControl>
            <FormControl>
              <LoadingButton type="submit" loading={getUserLoading}>
                Отправить запрос
              </LoadingButton>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={updateUserHandleSubmit(onUpdateUserSubmit)}>
            <Typography variant="h3">Обновление пользователя</Typography>
            <FormControl>
              <InputLabel>Телефон</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="msisdn"
                control={updateUserControl}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Customer ID</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="customerId"
                control={updateUserControl}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Права</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="permissions_short"
                control={updateUserControl}
              />
            </FormControl>
            <FormControl>
              <LoadingButton type="submit" loading={updateUserLoading}>
                Обновить
              </LoadingButton>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={deleteUserHandleSubmit(onDeleteUserSubmit)}>
            <Typography variant="h3">Удаление пользователя</Typography>
            <FormControl>
              <InputLabel>Телефон</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="msisdn"
                control={deleteUserControl}
              />
            </FormControl>
            <FormControl>
              <LoadingButton type="submit" loading={deleteUserLoading}>
                Удалить
              </LoadingButton>
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Divider sx={{ my: 6 }} />
      <Typography variant="h2" gutterBottom>
        АРКИ
      </Typography>
      <FindARKIObjects />
      <CurrentARKIObjects />
    </Main>
  );
};

export default Admin;
