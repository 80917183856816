import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
  createContext,
  SyntheticEvent,
  ReactElement,
} from 'react';

import Snackbar from '@mui/material/Snackbar';

type ISnackbarsProviderProps = {
  children: ReactNode | ReactNode[];
};

export type ISnackbar = {
  node: ReactElement;
  key: number;
};

export type ISnackbarsContext = {
  snackbars: ISnackbar[];
  showSnackbar: (node: ReactElement) => void;
};

export const SnackbarsContext = createContext<ISnackbarsContext | null>(null);

const SnackbarsProvider = (props: ISnackbarsProviderProps) => {
  const { children } = props;
  const [snackbars, setSnackbars] = useState<ISnackbar[]>([]);
  const [message, setMessage] = useState<ISnackbar | undefined>(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (snackbars.length && !message) {
      setMessage({ ...snackbars[0] });
      setSnackbars((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbars.length && message && open) {
      setOpen(false);
    }
  }, [snackbars, message, open]);

  const showSnackbar = useCallback((node: ReactElement) => {
    setSnackbars((prev) => [
      ...prev,
      {
        node: node,
        key: new Date().getTime(),
      },
    ]);
  }, []);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessage(undefined);
  };

  const contextValue = useMemo(
    () => ({
      snackbars,
      showSnackbar,
    }),
    [snackbars, showSnackbar],
  );

  return (
    <SnackbarsContext.Provider value={contextValue}>
      {children}
      <Snackbar
        key={message ? message.key : undefined}
        open={open}
        children={message ? message.node : undefined}
        TransitionProps={{ onExited: handleExited }}
        onClose={handleClose}
      />
    </SnackbarsContext.Provider>
  );
};

export default SnackbarsProvider;
