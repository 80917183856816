import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ShapeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 4" {...props}>
      <path
        d="M10.9162 0.915789C10.7217 1.11029 10.7073 1.30407 10.6785 1.69162C10.671 1.79208 10.6665 1.89572 10.6665 1.99935C10.6665 2.10298 10.671 2.20662 10.6785 2.30708C10.7073 2.69463 10.7217 2.88841 10.9162 3.08291C11.1107 3.27741 11.3045 3.29183 11.6921 3.32067C11.7925 3.32815 11.8962 3.33268 11.9998 3.33268C12.1034 3.33268 12.2071 3.32815 12.3075 3.32067C12.6951 3.29183 12.8889 3.27741 13.0834 3.08291C13.2779 2.88841 13.2923 2.69463 13.3211 2.30708C13.3286 2.20662 13.3331 2.10298 13.3331 1.99935C13.3331 1.89572 13.3286 1.79208 13.3211 1.69162C13.2923 1.30407 13.2779 1.11029 13.0834 0.915789C12.8889 0.721289 12.6951 0.706867 12.3075 0.678023C12.2071 0.670546 12.1034 0.666016 11.9998 0.666016C11.8962 0.666016 11.7925 0.670546 11.6921 0.678023C11.3045 0.706867 11.1107 0.721289 10.9162 0.915789Z"
        fill="#969FA8"
      />
      <path
        d="M0.678512 1.69186C0.707355 1.30431 0.721777 1.11053 0.916277 0.916033C1.11078 0.721533 1.30455 0.707111 1.69211 0.678268C1.79257 0.670791 1.8962 0.66626 1.99984 0.66626C2.10347 0.66626 2.2071 0.670791 2.30757 0.678268C2.69512 0.707111 2.8889 0.721533 3.0834 0.916033C3.2779 1.11053 3.29232 1.30431 3.32116 1.69186C3.32864 1.79233 3.33317 1.89596 3.33317 1.99959C3.33317 2.10323 3.32864 2.20686 3.32116 2.30732C3.29232 2.69488 3.2779 2.88865 3.0834 3.08315C2.8889 3.27765 2.69512 3.29207 2.30757 3.32092C2.2071 3.3284 2.10347 3.33293 1.99984 3.33293C1.8962 3.33293 1.79257 3.3284 1.69211 3.32092C1.30455 3.29207 1.11078 3.27765 0.916277 3.08315C0.721777 2.88865 0.707355 2.69488 0.678512 2.30732C0.671035 2.20686 0.666504 2.10323 0.666504 1.99959C0.666504 1.89596 0.671035 1.79233 0.678512 1.69186Z"
        fill="#969FA8"
      />
      <path
        d="M5.67856 1.69186C5.70741 1.30431 5.72183 1.11053 5.91633 0.916033C6.11083 0.721533 6.3046 0.707111 6.69216 0.678268C6.79262 0.670791 6.89626 0.66626 6.99989 0.66626C7.10352 0.66626 7.20715 0.670791 7.30762 0.678268C7.69517 0.707111 7.88895 0.721533 8.08345 0.916033C8.27795 1.11053 8.29237 1.30431 8.32121 1.69186C8.32869 1.79233 8.33322 1.89596 8.33322 1.99959C8.33322 2.10323 8.32869 2.20686 8.32121 2.30732C8.29237 2.69488 8.27795 2.88865 8.08345 3.08315C7.88895 3.27765 7.69517 3.29207 7.30762 3.32092C7.20715 3.3284 7.10352 3.33293 6.99989 3.33293C6.89626 3.33293 6.79262 3.3284 6.69216 3.32092C6.3046 3.29207 6.11083 3.27765 5.91633 3.08315C5.72183 2.88865 5.70741 2.69488 5.67856 2.30732C5.67109 2.20686 5.66655 2.10323 5.66655 1.99959C5.66655 1.89596 5.67109 1.79233 5.67856 1.69186Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default ShapeIcon;
