import { Link as RouterLink } from 'react-router-dom';

import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FormLabel from '../../../../components/FormLabel';
import { USER_ROLES, PHONE_REGEX } from '../../../../constants';
import { IUser } from '../../../../types';

type IUserFormProps = {
  defaultValues?: Omit<IUser, 'permissions' | 'customerId' | 'customerName'>;
  isLoading: boolean;
  onFormSubmit: (data: IUser) => void;
};

const UserForm = (props: IUserFormProps) => {
  const {
    defaultValues = {
      fullname: '',
      email: '',
      msisdn: '',
      permissions_short: '',
      telegram_username: '',
    },
    isLoading,
    onFormSubmit,
  } = props;

  const methods = useForm<IUser>({
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<IUser> = (formData) => {
    onFormSubmit(formData);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={4}
            sx={{
              marginBottom: 8,
            }}
          >
            <Grid item mobile={12} xs={4}>
              <FormControl>
                <FormLabel>ФИО</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputProps={{
                        'aria-label': 'ФИО',
                      }}
                      fullWidth
                      placeholder="Введите ФИО"
                    />
                  )}
                  name="fullname"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <FormControl>
                <FormLabel>Почта</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputProps={{
                        'aria-label': 'Почта',
                      }}
                      fullWidth
                      placeholder="Укажите почту"
                    />
                  )}
                  name="email"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <FormControl>
                <FormLabel>Номер телефона</FormLabel>
                <Controller
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      mask="+7 (999) 999 99 99"
                      value={field.value}
                    >
                      <TextField
                        inputProps={{
                          'aria-label': 'Номер телефона',
                        }}
                        fullWidth
                        placeholder="Введите номер телефона"
                        error={!!errors.msisdn}
                        helperText={errors.msisdn?.message}
                      />
                    </InputMask>
                  )}
                  name="msisdn"
                  control={control}
                  rules={{
                    required: 'Введите номер телефона',
                    pattern: {
                      value: PHONE_REGEX,
                      message: 'Введите корректный номер телефона',
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <FormControl>
                <FormLabel>Роль</FormLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Укажите роль</span>;
                        }
                        const selectedItem = USER_ROLES.find((item: any) => item.id === selected);

                        return selectedItem?.name;
                      }}
                      inputProps={{
                        'aria-label': 'Роль',
                      }}
                      error={!!errors.permissions_short}
                    >
                      {USER_ROLES.map((item: Record<string, string>) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="permissions_short"
                  control={control}
                  rules={{
                    required: 'Укажите роль',
                  }}
                />
                {errors.permissions_short?.message && (
                  <FormHelperText error>{errors.permissions_short?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <FormControl>
                <FormLabel>Telegram</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputProps={{
                        'aria-label': 'Telegram',
                      }}
                      fullWidth
                      placeholder="Введите ник Telegram для рассылки "
                    />
                  )}
                  name="telegram_username"
                  control={control}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 3,
            }}
          >
            <Button color="secondary" type="button" component={RouterLink} to="/users">
              Отмена
            </Button>
            <LoadingButton loading={isLoading} type="submit">
              Сохранить
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default UserForm;
