import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M10.2865 4C13.7584 4 16.573 6.81368 16.573 10.2845C16.573 11.7109 16.0976 13.0263 15.2966 14.0811L19.7493 18.5375C20.0838 18.8723 20.0835 19.4149 19.7486 19.7494C19.4136 20.0838 18.8708 20.0835 18.5363 19.7487L14.0842 15.2932C13.0291 16.0939 11.7133 16.5691 10.2865 16.5691C6.81455 16.5691 4 13.7554 4 10.2845C4 6.81368 6.81455 4 10.2865 4ZM10.2865 5.71387C7.76145 5.71387 5.71448 7.76022 5.71448 10.2845C5.71448 12.8089 7.76145 14.8552 10.2865 14.8552C12.8115 14.8552 14.8585 12.8089 14.8585 10.2845C14.8585 7.76022 12.8115 5.71387 10.2865 5.71387Z" />
    </SvgIcon>
  );
};

export default SearchIcon;
