import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from '../../components/icons/EditIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

import useFetchObjectInfo from './hooks/useFetchObjectInfo';

const ObjectInfo = () => {
  let { objectId = '' } = useParams();
  let [searchParams] = useSearchParams();
  let objectType = searchParams.get('type') || '';

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { isLoading, isError, data: objectData } = useFetchObjectInfo(objectType, objectId, true);

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  if (isError) {
    return <Message>Не получилось загрузить данные объекта. Попробуйте еще раз.</Message>;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            flex: '1 1 auto',
          }}
        >
          <Typography variant="h1">{objectData.name}</Typography>
        </Box>
        <Box
          sx={{
            mt: 0.5,
            ml: 2,
          }}
        >
          <IconButton
            color="secondary"
            size="small"
            onClick={() => {
              navigate(`/objects/${objectId}/edit?type=${objectType}`);
              sendMetrik(
                'vntVdknl',
                'spisok_ustroistv_i_obektov',
                'button_click',
                'redaktirovanie_gruppy',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/objects',
                objectId,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              );
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ObjectInfo;
