import { useState } from 'react';

import { useParams, useNavigate, Navigate, Link as RouterLink } from 'react-router-dom';

import { useQueryClient, useMutation } from 'react-query';

import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getUnixTime, fromUnixTime } from 'date-fns';

import Dialog from '../../components/Dialog';
import WarningIcon from '../../components/icons/WarningIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { GET_UPDATE_DEVICE_URL } from '../../constants';
import convertArrayToHEX from '../../utils/convertArrayToHEX';
import convertHEXToArray from '../../utils/convertHEXToArray';
import convertTimestampToDate from '../../utils/convertTimestampToDate';
import fetchData from '../../utils/fetchData';
import useFetchObjectGroups from '../../utils/hooks/useFetchObjectGroups';
import sendMetrik from '../../utils/sendMetrik';

import DATDDevices from './DATDDevices';
import DeviceClientAttributes from './DeviceClientAttributes';
import DeviceServerAttributes from './DeviceServerAttributes';
import DeviceSettings from './DeviceSettings';
import useFetchDeviceInfo from './hooks/useFetchDeviceInfo';
import useFetchDeviceProfiles from './hooks/useFetchDeviceProfiles';
import Perimeter5Devices from './Perimeter5Devices';

import type { IDATDDevice, IPerimeter5Device, IUser } from '../../types';

type IUpdatedDeviceData = {
  parentId: string;
  serverAttributes: Record<string, string>;
};

type FormValues = {
  name: string;
  parent: Record<'id' | 'name', string> | null;
  clientAttributes: Record<string, string>[];
  live_mode: 'schedule' | 'interval';
  dm: string[];
  t: string[];
  perm: string;
  pm: string;
  am: string;
  amChannels: string[];
  address_full: string | null;
  address_coords: string;
  serverAttributes: Record<string, string>[];
  DATDDevices: (Omit<IDATDDevice, 'settings'> & { settings: Record<string, string>[] })[];
  Perimeter5Devices: IPerimeter5Device[];
  settings: Record<string, string>[];
};

const EditDeviceForm = () => {
  let { objectId, deviceId = '' } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const setFormValues = (data: any, objectGroupsData: any) => {
    if (data && !data.detail && objectGroupsData) {
      const { serverAttributes, clientAttributes, uspd_devices, settings } = data;
      const values = {
        name: serverAttributes?.name || '',
        parent: objectGroupsData.filter((group: any) => group.id === data.parentId)[0],
        clientAttributes: clientAttributes
          ? Object.entries(clientAttributes)
              .filter(
                (attr: any[]) =>
                  attr[0] !== 'possible_names' &&
                  attr[0] !== 'dm' &&
                  attr[0] !== 't' &&
                  attr[0] !== 'perm' &&
                  attr[0] !== 'am' &&
                  attr[0] !== 'pm',
              )
              .map((attr: any) => ({
                attributeName: attr[0],
                attributeValue: attr[1]
                  ? attr[0] === 'tsf'
                    ? convertTimestampToDate(parseInt(clientAttributes.tsf))
                    : attr[1]
                  : '',
              }))
          : [],
        live_mode: clientAttributes.perm > 0 ? ('interval' as const) : ('schedule' as const),
        dm: convertHEXToArray(clientAttributes.dm, 'dm'),
        t: convertHEXToArray(clientAttributes.t, 't'),
        perm: clientAttributes.perm
          ? clientAttributes.perm === '0'
            ? ''
            : clientAttributes.perm
          : '',
        pm: clientAttributes.pm ? clientAttributes.pm.toString() : '',
        am: clientAttributes.am || '',
        amChannels: convertHEXToArray(clientAttributes.am, 'am'),
        serverAttributes: Object.entries(serverAttributes)
          .map((attr: any) => ({
            attributeName: attr[0],
            attributeValue: attr[1],
          }))
          .filter(
            (attr: Record<string, string>) =>
              attr.attributeName !== 'address_full' &&
              attr.attributeName !== 'address_coords' &&
              attr.attributeName !== 'name' &&
              attr.attributeName !== 'devices',
          ),
        address_full: serverAttributes.address_full || null,
        address_coords: serverAttributes.address_coords || '',
        DATDDevices:
          Array.isArray(uspd_devices) && data.deviceProfileId === 'uspd'
            ? uspd_devices.map((item) => ({
                model: item.model || '',
                id: item.id || '',
                currentChannelNumber: item.channel_number || '',
                channel_number: item.channel_number || '',
                verification_date: item.verification_date
                  ? fromUnixTime(item.verification_date)
                  : null,
                verification_interval: item.verification_interval || '',
                connection_type: item.connection_type || '',
                anti_shatter: item.anti_shatter || '',
                initial_value: item.initial_value || '',
                impulse_weight: item.impulse_weight || '',
                settings: item.settings
                  ? Object.entries(item.settings).map((attr: any) => ({
                      attributeName: attr[0],
                      attributeValue: attr[1] ? attr[1] : '',
                    }))
                  : [],
                status_of_updated_fields: item.status_of_updated_fields,
              }))
            : [],
        Perimeter5Devices:
          Array.isArray(uspd_devices) && data.deviceProfileId === 'perimeter-5'
            ? uspd_devices
                .sort((a, b) => a.channel_number - b.channel_number)
                .slice(0, 5)
                .map((item) => ({
                  channel_number: item.channel_number || 0,
                  sensor_type: item.sensor_type || '',
                  sensor_name: item.sensor_name || '',
                  connection_type: item.connection_type || '',
                  status_of_updated_fields: item.status_of_updated_fields,
                }))
            : [],
        settings:
          Object.entries(settings).length > 0
            ? Object.entries(settings).map((attr: any) => ({
                attributeName: attr[0],
                attributeValue: attr[1]
                  ? (attr[0] === 'a_d1' || attr[0] === 'a_t1') && attr[1]
                    ? (attr[1] / 10000).toString()
                    : attr[1]
                  : '',
              }))
            : [],
      };
      reset(values);
    }
  };

  const prepareNewDeviceData = () => {
    const formData = getValues();

    let sharedAttributesData: Record<string, string | number> = {};
    let serverAttributesData: Record<string, string> = {};
    let DATDDevicesData: IDATDDevice[] | null = null;
    let Perimeter5DevicesData: IPerimeter5Device[] | null = null;
    let settingsData: Record<string, string> = {};

    if (formData.dm.length) {
      sharedAttributesData.dm = convertArrayToHEX(formData.dm, 'dm');
    }
    if (formData.t.length) {
      sharedAttributesData.t = convertArrayToHEX(formData.t, 't');
    }
    if (formData.live_mode === 'schedule') {
      sharedAttributesData.perm = 0;
    } else if (formData.live_mode === 'interval' && formData.perm !== '') {
      sharedAttributesData.perm = parseInt(formData.perm);
    }

    if (formData.pm !== '') {
      sharedAttributesData.pm = parseInt(formData.pm);
    }
    if (data.deviceProfileId === 'pressure_sensor' && formData.am !== '') {
      sharedAttributesData.am = formData.am;
    } else if ((data.deviceProfileId === 'uspd' || data.deviceProfileId === 'perimeter-5') && formData.amChannels.length) {
      sharedAttributesData.am = convertArrayToHEX(formData.amChannels, 'am');
    }

    formData.serverAttributes.forEach(
      (attr) => (serverAttributesData[attr.attributeName] = attr.attributeValue),
    );

    let [name, address_full, address_coords] = getValues([
      'name',
      'address_full',
      'address_coords',
    ]);
    if (name) serverAttributesData.name = name;
    if (address_full) serverAttributesData.address_full = address_full;
    if (address_coords) serverAttributesData.address_coords = address_coords;

    let devices: any = [];
    if (data.deviceProfileId === 'uspd') {
      devices = getValues('DATDDevices');
      if (devices.length)
        DATDDevicesData = devices.map(
          (item: Omit<IDATDDevice, 'settings'> & { settings: Record<string, string>[] }) => {
            const {
              model,
              id: DATDDeviceId,
              verification_date,
              channel_number,
              verification_interval,
              connection_type,
              anti_shatter,
              initial_value,
              impulse_weight,
              settings: DATDDeviseSettings,
            } = item;

            let deviceData: IDATDDevice = {
              model: model ? model : null,
              id: DATDDeviceId ? DATDDeviceId : null,
              channel_number: channel_number ? channel_number : null,
              verification_date: verification_date
                ? getUnixTime(new Date(verification_date))
                : null,
              verification_interval: verification_interval
                ? typeof verification_interval === 'string'
                  ? parseInt(verification_interval)
                  : verification_interval
                : null,
              connection_type: connection_type ? connection_type : null,
              anti_shatter: anti_shatter
                ? typeof anti_shatter === 'string'
                  ? parseInt(anti_shatter)
                  : anti_shatter
                : null,
              initial_value: initial_value
                ? typeof initial_value === 'string'
                  ? parseFloat(initial_value.replace(/,/, '.'))
                  : initial_value
                : null,
              impulse_weight: impulse_weight
                ? typeof impulse_weight === 'string'
                  ? parseFloat(impulse_weight.replace(/,/, '.'))
                  : impulse_weight
                : null,
              settings: {},
            };

            if (Array.isArray(DATDDeviseSettings)) {
              DATDDeviseSettings.forEach((attr) => {
                deviceData.settings[attr.attributeName] = attr.attributeValue.replace(/,/, '.');
              });
            }

            return deviceData;
          },
        );
    } else if (data.deviceProfileId === 'perimeter-5') {
      devices = getValues('Perimeter5Devices');
      if (devices.length)
        Perimeter5DevicesData = devices.map((item: IPerimeter5Device) => {
          const { channel_number, sensor_type, sensor_name, connection_type } = item;

          let deviceData: IPerimeter5Device = {
            channel_number,
            sensor_type: sensor_type ? sensor_type : null,
            sensor_name: sensor_name ? sensor_name : null,
            connection_type: connection_type ? connection_type : null,
          };

          return deviceData;
        });
    }

    if (Array.isArray(formData.settings)) {
      formData.settings.forEach((attr) => {
        if (
          (attr.attributeName === 'a_d1' || attr.attributeName === 'a_t1') &&
          attr.attributeValue
        ) {
          settingsData[attr.attributeName] = `${
            parseFloat(attr.attributeValue.replace(/,/, '.')) * 10000
          }`;
        } else {
          settingsData[attr.attributeName] = attr.attributeValue.replace(/,/, '.');
        }
      });
    }

    const newDeviceData = {
      parentId: formData?.parent?.id || data.parentId,
      sharedAttributes: sharedAttributesData,
      serverAttributes: serverAttributesData,
      uspd_devices: DATDDevicesData || Perimeter5DevicesData,
      settings: settingsData,
    };

    return newDeviceData;
  };

  const onSuccessDeviceInfo = (responseData: any) => {
    setFormValues(responseData, objectGroupsData);
  };
  const onSuccessObjectGroups = (responseData: any) => {
    setFormValues(data, responseData);
  };

  const { isLoading, isError, data } = useFetchDeviceInfo(deviceId, onSuccessDeviceInfo);

  const { isLoading: isLoadingDeviceProfilesData, isError: isErrorDeviceProfilesData } =
    useFetchDeviceProfiles();

  const {
    isLoading: isLoadingObjectGroups,
    isError: isErrorObjectGroups,
    data: objectGroupsData,
  } = useFetchObjectGroups(!!data?.customerId, data?.customerId, onSuccessObjectGroups);

  const deviceDataMutation = useMutation(
    (updatedDeviceData: IUpdatedDeviceData) => {
      return fetchData(GET_UPDATE_DEVICE_URL(deviceId), {
        method: 'PUT',
        body: updatedDeviceData,
      });
    },
    {
      onSuccess: (responseData: any) => {
        queryClient.invalidateQueries(['deviceData', deviceId]);
        if (responseData.parentId !== objectId) {
          navigate(`/objects/${responseData.parentId}/devices/${deviceId}/edit`, {
            replace: true,
          });
        }
        setSuccessSnackbarOpen(true);
        setIsSaveDialogOpen(false);
      },
      onError: () => {
        setErrorSnackbarOpen(true);
      },
    },
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      parent: null,
      clientAttributes: [],
      pm: '',
      am: '',
      amChannels: [],
      live_mode: 'schedule',
      dm: ['all'],
      t: ['all'],
      perm: '',
      serverAttributes: [],
      address_full: null,
      address_coords: '',
      DATDDevices: [],
      settings: [],
    },
  });
  const { getValues, handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<FormValues> = () => {
    const newDeviceData = prepareNewDeviceData();
    if (
      data.clientAttributes.dm !== newDeviceData.sharedAttributes.dm ||
      data.clientAttributes.t !== newDeviceData.sharedAttributes.t ||
      parseInt(data.clientAttributes.perm) !== newDeviceData.sharedAttributes.perm
    ) {
      setIsSaveDialogOpen(true);
    } else {
      deviceDataMutation.mutate(newDeviceData);
    }
  };

  const handleSaveDialogClose = () => {
    setIsSaveDialogOpen(false);
  };

  const handleSuccessSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbarOpen(false);
  };

  const handleErrorSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  if (data && data.parentId !== objectId) {
    return <Navigate to={`/objects/${data.parentId}/devices/${deviceId}/edit`} replace />;
  }

  if (isLoading || isLoadingDeviceProfilesData || isLoadingObjectGroups) {
    return <Spinner data-testid="loader" />;
  }

  if (isError || isErrorDeviceProfilesData || isErrorObjectGroups) {
    return <Message>Не получилось загрузить данные устройства. Попробуйте еще раз.</Message>;
  }

  if (data?.detail === 'Not Found') {
    return <Message>Устройство с таким номером не найдено</Message>;
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to={`/objects/${data.parentId}?type=${
            data.customerId === data.parentId ? 'customer' : 'asset'
          }&devicesType=${data.deviceProfileId}`}
        >
          Устройства
        </Link>
        <span>Редактирование</span>
      </Breadcrumbs>

      {data && objectGroupsData && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h1">Редактирование</Typography>

            <DeviceClientAttributes />

            {Object.keys(data.settings).length > 0 && <DeviceSettings />}

            <DeviceServerAttributes address={data.serverAttributes.address_full} />

            {data.deviceProfileId === 'uspd' && (
              <DATDDevices
                devices={data.uspd_devices}
                channelsAmount={parseInt(data.clientAttributes.channels)}
              />
            )}
            {data.deviceProfileId === 'perimeter-5' && <Perimeter5Devices />}

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Button
                color="secondary"
                type="button"
                onClick={() => {
                  navigate(-1);
                  sendMetrik(
                    'vntVdknl',
                    'redaktirovanie_ustroistva',
                    'button_click',
                    'otmenit',
                    null,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/objects',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
              >
                Отменить
              </Button>
              <LoadingButton
                loading={deviceDataMutation.isLoading}
                type="submit"
                onClick={() => {
                  sendMetrik(
                    'vntVdknl',
                    'redaktirovanie_ustroistva',
                    'button_click',
                    'sohranit',
                    null,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/objects',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
              >
                Сохранить
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      )}

      <Dialog
        busy={deviceDataMutation.isLoading}
        heading={
          <Box sx={{ textAlign: 'center' }}>
            <WarningIcon fontSize="extralarge" />
          </Box>
        }
        actionButtonText="Сохранить"
        open={isSaveDialogOpen}
        onAction={() => deviceDataMutation.mutate(prepareNewDeviceData())}
        onClose={handleSaveDialogClose}
      >
        <Typography paragraph>
          Корректировка параметров произойдет не ранее следующего выхода устройства на связь.
        </Typography>
        <Typography paragraph>
          <b>Внимание!</b> Увеличение частоты выхода устройства на связь ведет к сокращению срока
          работы от батареи.
        </Typography>
      </Dialog>

      <Snackbar open={successSnackbarOpen} onClose={handleSuccessSnackbarClose}>
        <Alert severity="success" action={false}>
          Устройство обновлено
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnackbarOpen} onClose={handleErrorSnackbarClose}>
        <Alert severity="error" action={false}>
          Устройство не обновлено
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditDeviceForm;
