import { useQuery } from 'react-query';

import { BALANCE_GROUP_ITEMS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchBalanceGroupItems = () => {
  return useQuery(['balanceGroupItemsData'], () => fetchData(BALANCE_GROUP_ITEMS_URL));
};

export default useFetchBalanceGroupItems;
