const getInitials = (fullname: string): string => {
  if (fullname && fullname.length) {
    let initials = "";
    fullname.split(" ").forEach((word: string, index: number) => {
      if (index < 2) {
        initials += word[0];
      }
    });
  
    return initials;
  }

  return "";
};

export default getInitials;
