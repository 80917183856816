import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { Controller, FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  endOfDay,
  fromUnixTime,
  getUnixTime,
  isBefore,
  isPast,
  isToday,
  startOfDay,
} from 'date-fns';

import FormLabel from '../../../components/FormLabel';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import ErrorIcon from '../../../components/icons/ErrorIcon';
import PlusIcon from '../../../components/icons/PlusIcon';
import QuestionIcon from '../../../components/icons/QuestionIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { DEVICE_TEMP_MEASURE_TYPES, REPORT_TYPES } from '../../../constants';
import { IUser } from '../../../types';
import useFetchAddresses from '../../../utils/hooks/useFetchAddresses';
import useFetchGroupsNames from '../../../utils/hooks/useFetchGroupsNames';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchBalanceGroups from '../../balanceGroups/hooks/useFetchBalanceGroups';
import useFetchReportDeviceTypes from '../hooks/useFetchReportDeviceTypes';
import useFetchStandartReportsResults from '../hooks/useFetchStandartReportsResults';

type FormValues = {
  device_type: string;
  report_type: string;
  temperature_measure: string;
  date_from: Date | number | null;
  date_to: Date | number | null;
  asset_name: string | null;
  address: string | null;
};

const ReportsStandartParams = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let reportTypeParam = searchParams.get('report_type') || '';
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let groupNameParam = searchParams.get('asset_name') || null;
  let addressParam = searchParams.get('address') || null;

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const envData: any = queryClient.getQueryData('envData');
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const [isGroupsListOpen, setIsGroupsListOpen] = useState(false);
  const [isAddressesListOpen, setIsAddressesListOpen] = useState(false);
  const [isOtherParamsOpen, setIsOtherParamsOpen] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      device_type: deviceTypeParam,
      report_type: reportTypeParam,
      temperature_measure: temperatureMeasureParam,
      date_from: dateFromParam ? fromUnixTime(parseInt(dateFromParam)) : null,
      date_to: dateToParam ? fromUnixTime(parseInt(dateToParam)) : null,
      asset_name: groupNameParam,
      address: addressParam,
    },
  });
  const { control, handleSubmit, reset, setValue, formState } = methods;

  const deviceTypeWatch = useWatch({
    control,
    name: 'device_type',
  });
  const reportTypeWatch = useWatch({
    control,
    name: 'report_type',
  });
  const dateFromWatch = useWatch({
    control,
    name: 'date_from',
  });
  const dateToWatch = useWatch({
    control,
    name: 'date_to',
  });
  const groupNameWatch = useWatch({
    control,
    name: 'asset_name',
  });
  const addressWatch = useWatch({
    control,
    name: 'address',
  });

  const { isFetching, refetch } = useFetchStandartReportsResults(
    deviceTypeParam,
    reportTypeParam,
    temperatureMeasureParam,
    dateFromParam,
    dateToParam,
    groupNameParam,
    addressParam,
  );

  const {
    isLoading: isLoadingReportDeviceTypes,
    isError: isErrorReportDeviceTypes,
    data: reportDeviceTypesData,
  } = useFetchReportDeviceTypes();

  const { isFetched: isFetchedGroups, data: groupsData } = useFetchGroupsNames(isGroupsListOpen);

  const { isFetched: isFetchedAddresses, data: addressesData } =
    useFetchAddresses(isAddressesListOpen);

  const { isFetching: isFetchingBalanceGroups, refetch: refetchBalanceGroups } =
    useFetchBalanceGroups();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      device_type,
      report_type,
      temperature_measure,
      date_from,
      date_to,
      asset_name,
      address,
    } = formData;
    const params: any = {
      device_type: device_type,
      report_type: report_type,
    };
    if (date_from) {
      params.date_from = getUnixTime(date_from);
    }
    if (
      date_to &&
      (report_type === 'period' ||
        report_type === 'periodHour' ||
        report_type === 'segment' ||
        reportTypeWatch === 'balanceGroup')
    ) {
      let dateWithTime;
      if (isToday(date_to)) {
        dateWithTime = new Date();
      } else {
        dateWithTime = endOfDay(date_to);
      }
      params.date_to = getUnixTime(dateWithTime);
    }
    if (device_type === 'pressure_sensor' && temperature_measure && temperature_measure !== 'all') {
      params.temperature_measure = temperature_measure;
    }
    if (report_type !== 'balanceGroup') {
      if (asset_name) {
        params.asset_name = asset_name;
      }
      if (address) {
        params.address = address;
      }
    }
    const newSearchParams = new URLSearchParams(params);
    if (searchParams.toString() === newSearchParams.toString()) {
      if (reportTypeParam === 'balanceGroup') {
        refetchBalanceGroups();
      } else {
        refetch();
      }
    } else {
      setSearchParams(newSearchParams.toString());
    }
  };

  const handleGroupsListOpen = () => {
    setIsGroupsListOpen(true);
  };

  const handleAddressesListOpen = () => {
    setIsAddressesListOpen(true);
  };

  const handleOtherParamsToggleClick = () => {
    setIsOtherParamsOpen(!isOtherParamsOpen);

    !isOtherParamsOpen &&
      sendMetrik(
        'vntVdknl',
        'otchety',
        'element_click',
        'dopolnitelnye_filtry',
        null,
        userData?.permissions[0].uuid,
        userData ? '1' : '0',
        '/reports',
        null,
        null,
        null,
        'interactions',
        userData?.profile_type,
        'web',
      );
  };

  const handleClearFilterClick = () => {
    if (searchParams.toString()) {
      navigate('/reports');
    } else {
      reset();
    }
  };

  // Определяет нужно ли поле "Конец периода"
  const hasDateTo =
    reportTypeWatch === 'period' ||
    reportTypeWatch === 'periodHour' ||
    reportTypeWatch === 'segment' ||
    reportTypeWatch === 'balanceGroup';

  // Определяет доступно ли формирование отчетов
  let isFilterReady = !!(deviceTypeWatch && reportTypeWatch && dateFromWatch);
  if (hasDateTo && !dateToWatch) {
    isFilterReady = false;
  }

  // Определяет отображение дополнительных фильтров
  const isOtherParamsVisible = reportTypeWatch !== 'balanceGroup';

  // Определяет кол-во выбранных дополнительных фильтров
  let otherParamsActiveAmount = 0;
  if (groupNameWatch) {
    otherParamsActiveAmount++;
  }
  if (addressWatch) {
    otherParamsActiveAmount++;
  }

  // Эффект для подгрузки данных и обновления состояния полей при изменении адреса страницы (переход по ссылке с параметрами или использование браузерной навигации)
  useEffect(() => {
    if (Array.from(searchParams.entries()).length) {
      if (reportTypeParam === 'balanceGroup') {
        refetchBalanceGroups();
      } else {
        refetch();
      }
    }
    reset({
      device_type: deviceTypeParam,
      report_type: reportTypeParam,
      temperature_measure: temperatureMeasureParam,
      date_from: dateFromParam ? fromUnixTime(parseInt(dateFromParam)) : null,
      date_to: dateToParam ? fromUnixTime(parseInt(dateToParam)) : null,
      asset_name: groupNameParam,
      address: addressParam,
    });
  }, [
    searchParams,
    deviceTypeParam,
    reportTypeParam,
    temperatureMeasureParam,
    dateFromParam,
    dateToParam,
    groupNameParam,
    addressParam,
    reset,
    refetch,
    refetchBalanceGroups,
  ]);

  if (isLoadingReportDeviceTypes) {
    return <Spinner data-testid="loader" />;
  }

  if (isErrorReportDeviceTypes) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные фильтров. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <Box
      sx={{
        marginBottom: 3,
        padding: 3,
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '16px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container columnSpacing={2}>
            <Grid item mobile={12} xs={8}>
              <Grid container spacing={2}>
                <Grid item mobile={12} xs={3}>
                  <FormControl margin="none">
                    <FormLabel>Тип устройства</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Выберите тип устройства</span>;
                            }
                            const selectedItem = reportDeviceTypesData.device_types.find(
                              (item: any) => item.id === selected,
                            );

                            return selectedItem.name;
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              value === 'pressure_sensor' &&
                              (reportTypeWatch === 'segment' || reportTypeWatch === 'balanceGroup')
                            ) {
                              setValue('report_type', '');
                            }

                            field.onChange(value);
                            sendMetrik(
                              'vntVdknl',
                              'otchety',
                              'filter_',
                              'tip_ustroistva',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports',
                              value,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          SelectDisplayProps={{
                            className: 'MuiSelect-alt',
                          }}
                        >
                          {/* там где фильтр временно скрыл от прода Периметр-5 */}
                          {reportDeviceTypesData.device_types
                            .filter((item: { name: string }) => {
                              if (envData?.APP_ENV === 'prod') return item.name !== 'Периметр-5';

                              return item;
                            })
                            .map((item: Record<string, string>) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      name="device_type"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item mobile={12} xs={3}>
                  <FormControl disabled={!deviceTypeWatch} margin="none">
                    <FormLabel disabled={!deviceTypeWatch}>Тип отчета</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          onChange={(e) => {
                            field.onChange(e);
                            sendMetrik(
                              'vntVdknl',
                              'otchety',
                              'filter_',
                              'tip_otcheta',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports',
                              e.target.value,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Выберите тип отчета</span>;
                            }
                            if (selected === 'period' && deviceTypeWatch === 'uspd') {
                              return REPORT_TYPES.periodDATD;
                            }

                            return REPORT_TYPES[selected];
                          }}
                          SelectDisplayProps={{
                            className: 'MuiSelect-alt',
                          }}
                        >
                          {Object.entries(REPORT_TYPES)
                            .filter((item) => {
                              if (
                                (item[0] === 'period' && deviceTypeWatch === 'uspd') ||
                                ((item[0] === 'periodDATD' ||
                                  item[0] === 'periodHour' ||
                                  item[0] === 'segment' ||
                                  item[0] === 'balanceGroup') &&
                                  deviceTypeWatch === 'pressure_sensor')
                              )
                                return false;

                              return item;
                            })
                            .map((item) => (
                              <MenuItem
                                key={item[0]}
                                value={item[0] === 'periodDATD' ? 'period' : item[0]}
                              >
                                {item[1]}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      name="report_type"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item mobile={12} xs={3}>
                  <FormControl disabled={!reportTypeWatch} margin="none">
                    <FormLabel disabled={!reportTypeWatch}>
                      {hasDateTo ? 'Начало периода' : 'Период'}
                    </FormLabel>
                    <Controller
                      rules={{
                        validate: (date) => {
                          if (date) {
                            return isPast(date) || 'Дата должна быть не позже текущего дня';
                          }
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <DatePicker
                          disabled={!reportTypeWatch}
                          value={value}
                          onChange={(newValue) => {
                            onChange(newValue);
                            sendMetrik(
                              'vntVdknl',
                              'otchety',
                              'filter_',
                              hasDateTo ? 'nachalo_perioda' : 'period',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports',
                              newValue,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="MuiTextField-alt"
                              error={!!formState.errors.date_from?.message}
                              helperText={formState.errors.date_from?.message}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: hasDateTo
                                  ? 'Укажите начало периода'
                                  : 'Укажите период',
                              }}
                            />
                          )}
                        />
                      )}
                      name="date_from"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                {hasDateTo && (
                  <Grid item mobile={12} xs={3}>
                    <FormControl disabled={!reportTypeWatch} margin="none">
                      <FormLabel disabled={!reportTypeWatch}>Конец периода</FormLabel>
                      <Controller
                        rules={{
                          validate: {
                            notFuture: (date) => {
                              if (date) {
                                return isPast(date) || 'Дата должна быть не позже текущего дня';
                              }
                            },
                            minDate: (date) => {
                              if (dateFromWatch && date) {
                                return (
                                  !isBefore(date, dateFromWatch) ||
                                  'Дата должна быть позже начала периода'
                                );
                              }
                            },
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <DatePicker
                            disabled={!reportTypeWatch}
                            value={value}
                            onChange={(newValue) => {
                              if (newValue) {
                                onChange(startOfDay(newValue));
                                sendMetrik(
                                  'vntVdknl',
                                  'otchety',
                                  'filter_',
                                  'konets_perioda',
                                  null,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/reports',
                                  newValue,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="MuiTextField-alt"
                                error={!!formState.errors.date_to?.message}
                                helperText={formState.errors.date_to?.message}
                                InputProps={{
                                  ...params.InputProps,
                                  className: 'MuiOutlinedAltInput-root',
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'Укажите конец периода',
                                }}
                              />
                            )}
                            minDate={dateFromWatch}
                          />
                        )}
                        name="date_to"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )}
                {isOtherParamsOpen && isOtherParamsVisible && (
                  <>
                    {deviceTypeWatch === 'pressure_sensor' && (
                      <Grid item mobile={12} xs={3}>
                        <FormControl margin="none">
                          <FormLabel>Подтип устройства</FormLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                displayEmpty
                                onChange={(e) => {
                                  field.onChange(e);
                                  sendMetrik(
                                    'vntVdknl',
                                    'otchety',
                                    'filter_',
                                    'podtip_ustroistva',
                                    null,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/reports',
                                    e.target.value,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <span>Выберите подтип отчета</span>;
                                  }

                                  return DEVICE_TEMP_MEASURE_TYPES[selected];
                                }}
                                SelectDisplayProps={{
                                  className: 'MuiSelect-alt',
                                }}
                              >
                                {Object.entries(DEVICE_TEMP_MEASURE_TYPES).map((item) => (
                                  <MenuItem key={item[0]} value={item[0]}>
                                    {item[1]}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            name="temperature_measure"
                            control={control}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item mobile={12} xs={3}>
                      <FormControl margin="none">
                        <FormLabel>Группа</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              open={isGroupsListOpen}
                              options={groupsData}
                              loading={!isFetchedGroups}
                              disableClearable={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Выберите группу"
                                  className="MuiTextField-alt"
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      event.preventDefault();
                                      const enteredValue: any = params?.inputProps?.value;
                                      const matchingOption = groupsData.find((option: string) =>
                                        option.toLowerCase().includes(enteredValue?.toLowerCase()),
                                      );
                                      if (matchingOption) {
                                        field.onChange(matchingOption);
                                        setIsGroupsListOpen(false);
                                      }
                                    }
                                  }}
                                />
                              )}
                              onOpen={handleGroupsListOpen}
                              onChange={(_, value) => {
                                field.onChange(value);
                                setIsGroupsListOpen(false);
                                sendMetrik(
                                  'vntVdknl',
                                  'otchety',
                                  'filter_',
                                  'gruppa',
                                  null,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/reports',
                                  value,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }}
                              onClose={() => setIsGroupsListOpen(false)}
                            />
                          )}
                          name="asset_name"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item mobile={12} xs={3}>
                      <FormControl margin="none">
                        <FormLabel>Адрес</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={addressesData}
                              loading={!isFetchedAddresses}
                              disableClearable={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Укажите адрес"
                                  className="MuiTextField-alt"
                                />
                              )}
                              onOpen={handleAddressesListOpen}
                              onChange={(_, value) => {
                                field.onChange(value);
                                sendMetrik(
                                  'vntVdknl',
                                  'otchety',
                                  'filter_',
                                  'adres',
                                  null,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/reports',
                                  value,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }}
                            />
                          )}
                          name="address"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingTop: 2,
                }}
              >
                {isOtherParamsVisible && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      marginRight: 3,
                    }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      color="link"
                      onClick={handleOtherParamsToggleClick}
                    >
                      {isOtherParamsOpen ? 'Скрыть фильтры' : 'Дополнительные фильтры'}
                      {otherParamsActiveAmount > 0 && ` (${otherParamsActiveAmount})`}
                    </Button>
                    <Tooltip
                      arrow
                      title={
                        <>
                          {isOtherParamsOpen ? 'Скрыть ' : 'Показать '}
                          дополнительные фильтры для отчетов :
                          <Box>
                            - Группа
                            {groupNameWatch && (
                              <Typography
                                variant="inherit"
                                component="span"
                                sx={{ marginLeft: 1, fontWeight: '700' }}
                              >
                                {groupNameWatch}
                              </Typography>
                            )}
                          </Box>
                          <Box>
                            - Адрес
                            {addressWatch && (
                              <Typography
                                variant="inherit"
                                component="span"
                                sx={{ marginLeft: 1, fontWeight: '700' }}
                              >
                                {addressWatch}
                              </Typography>
                            )}
                          </Box>
                        </>
                      }
                    >
                      <div data-testid="filter-tooltip">
                        <QuestionIcon fontSize="small" color="secondary" />
                      </div>
                    </Tooltip>
                  </Box>
                )}
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    color="primary"
                    disabled={!isFilterReady}
                    type="submit"
                    title="Сформировать"
                    data-testid="submit"
                    onClick={() => {
                      sendMetrik(
                        'vntVdknl',
                        'otchety',
                        'button_click',
                        'sformirovat_otchet',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/reports',
                        null,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    {isFetching || (isFetchingBalanceGroups && searchParams.toString()) ? (
                      <Spinner />
                    ) : (
                      <PlusIcon />
                    )}
                  </IconButton>
                  <IconButton size="small" onClick={handleClearFilterClick}>
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </Box>
  );
};

export default ReportsStandartParams;
