import { useState } from 'react';

import { useQueryClient } from 'react-query';

import { useFormContext, useFieldArray, Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormLabel from '../../components/FormLabel';
import MoreHorIcon from '../../components/icons/MoreHorIcon';
import { NUMBER_REGEX, FLOAT_NUMBER_REGEX } from '../../constants';
import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

import DeviceParamStatus from './DeviceParamStatus';

type IDATDDeviceEditProps = {
  deviceIndex: number;
  currentChannel: number;
  availableChannels: number[];
  onChannelChange: (c: number, n: number) => void;
  onRemove: (i: number, c: number, n: number) => void;
  statuses: Record<string, boolean>;
};

const DATDDevice = (props: IDATDDeviceEditProps) => {
  const { deviceIndex, currentChannel, availableChannels, statuses, onChannelChange, onRemove } =
    props;

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const deviceProfilesData: any = queryClient.getQueryData('deviceProfilesData');
  const DATDConnectionTypesData: any = queryClient.getQueryData('DATDConnectionTypesData');

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { fields } = useFieldArray({
    name: `DATDDevices.${deviceIndex}.settings`,
    control,
  });

  const newChannel = getValues(`DATDDevices.${deviceIndex}.channel_number`);
  let channels = [...availableChannels];
  if (newChannel) {
    if (channels.indexOf(newChannel) === -1) {
      channels.push(newChannel);
    }
    channels.sort();
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    sendMetrik(
      'vntVdknl',
      'redaktirovanie_ustroistva',
      'button_click',
      'izmenit',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/objects',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleRemoveDeviceClick = () => {
    onRemove(deviceIndex, currentChannel, newChannel);
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography variant="paragraphMedium2">Счетчик {currentChannel || ''}</Typography>
          <IconButton
            size="extraSmall"
            color="secondary"
            aria-label="Меню устройства"
            onClick={handleMenuClick}
          >
            <MoreHorIcon fontSize="small" />
          </IconButton>
          <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleRemoveDeviceClick}>Удалить счетчик</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Grid container columnSpacing={4}>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.model}</FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  fullWidth
                  placeholder="Введите модель"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.model,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                />
              )}
              name={`DATDDevices.${deviceIndex}.model`}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.id}</FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  fullWidth
                  placeholder="Введите серийный номер"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.id,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                />
              )}
              name={`DATDDevices.${deviceIndex}.id`}
              control={control}
            />
          </FormControl>
        </Grid>
        {channels.length > 0 && (
          <Grid item mobile={12} xs={6}>
            <FormControl>
              <FormLabel>{deviceProfilesData?.attributes_mapping.channel_number}</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Выберите номер канала</span>;
                      }

                      return selected;
                    }}
                    error={!!errors.DATDDevices?.[deviceIndex]?.channel_number}
                    onChange={(newValue) => {
                      onChannelChange(newChannel, newValue.target.value);
                      field.onChange(newValue);
                      sendMetrik(
                        'vntVdknl',
                        'redaktirovanie_ustroistva',
                        'form_change',
                        deviceProfilesData?.attributes_mapping.channel_number,
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/objects',
                        newValue.target.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    <MenuItem value={''}>
                      <span>Выберите номер канала</span>
                    </MenuItem>
                    {channels.map((item: number, index: number) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={`DATDDevices.${deviceIndex}.channel_number`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Выберите номер канала',
                  },
                }}
              />
              {errors.DATDDevices?.[deviceIndex]?.channel_number?.message && (
                <FormHelperText error>
                  {errors.DATDDevices?.[deviceIndex]?.channel_number?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.verification_date}</FormLabel>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.verification_date,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      newValue,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Укажите дату поверки',
                      }}
                    />
                  )}
                />
              )}
              name={`DATDDevices.${deviceIndex}.verification_date`}
              control={control}
              defaultValue={null}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.verification_interval}</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Укажите межповерочный интервал"
                  error={!!errors.DATDDevices?.[deviceIndex]?.verification_interval}
                  helperText={errors.DATDDevices?.[deviceIndex]?.verification_interval?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.verification_interval,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                />
              )}
              name={`DATDDevices.${deviceIndex}.verification_interval`}
              control={control}
              rules={{
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
        </Grid>
        {Array.isArray(DATDConnectionTypesData) && (
          <Grid item mobile={12} xs={6}>
            <FormControl>
              <FormLabel>{deviceProfilesData?.attributes_mapping.connection_type}</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'redaktirovanie_ustroistva',
                        'form_change',
                        deviceProfilesData?.attributes_mapping.connection_type,
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/objects',
                        e.target.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Выберите тип передачи данных</span>;
                      }

                      return selected;
                    }}
                    SelectDisplayProps={{
                      className: statuses.hasOwnProperty('connection_type')
                        ? 'MuiSelect-endAdornment'
                        : '',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <DeviceParamStatus statuses={statuses} field="connection_type" />
                      </InputAdornment>
                    }
                    error={!!errors.DATDDevices?.[deviceIndex]?.connection_type}
                  >
                    {DATDConnectionTypesData.map((item: string, index: number) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={`DATDDevices.${deviceIndex}.connection_type`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Выберите тип передачи данных',
                  },
                }}
              />
              {errors.DATDDevices?.[deviceIndex]?.connection_type?.message && (
                <FormHelperText error>
                  {errors.DATDDevices?.[deviceIndex]?.connection_type?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.anti_shatter}</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Укажите значение"
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.anti_shatter,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <DeviceParamStatus statuses={statuses} field="anti_shatter" />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.DATDDevices?.[deviceIndex]?.anti_shatter}
                  helperText={errors.DATDDevices?.[deviceIndex]?.anti_shatter?.message}
                />
              )}
              name={`DATDDevices.${deviceIndex}.anti_shatter`}
              control={control}
              rules={{
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.initial_value}</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Укажите показания"
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.initial_value,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <DeviceParamStatus statuses={statuses} field="initial_value" />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.DATDDevices?.[deviceIndex]?.initial_value}
                  helperText={errors.DATDDevices?.[deviceIndex]?.initial_value?.message}
                />
              )}
              name={`DATDDevices.${deviceIndex}.initial_value`}
              control={control}
              rules={{
                pattern: {
                  value: FLOAT_NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.impulse_weight}</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Укажите вес импульса"
                  onChange={(e) => {
                    field.onChange(e);
                    sendMetrik(
                      'vntVdknl',
                      'redaktirovanie_ustroistva',
                      'form_change',
                      deviceProfilesData?.attributes_mapping.impulse_weight,
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/objects',
                      e.target.value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <DeviceParamStatus statuses={statuses} field="impulse_weight" />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.DATDDevices?.[deviceIndex]?.impulse_weight}
                  helperText={errors.DATDDevices?.[deviceIndex]?.impulse_weight?.message}
                />
              )}
              name={`DATDDevices.${deviceIndex}.impulse_weight`}
              control={control}
              rules={{
                pattern: {
                  value: FLOAT_NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {fields.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{
              mt: 2,
            }}
          >
            Уставки
          </Typography>
          <Grid container columnSpacing={4}>
            {fields.map((settingsField: any, index: number) => (
              <Grid item mobile={12} xs={6} key={settingsField.id}>
                <FormControl>
                  <FormLabel>
                    {deviceProfilesData?.attributes_mapping[settingsField.attributeName] ||
                      settingsField.attributeName}
                  </FormLabel>
                  <Controller
                    name={`DATDDevices.${deviceIndex}.settings.${index}.attributeValue`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e);
                          sendMetrik(
                            'vntVdknl',
                            'redaktirovanie_ustroistva',
                            'form_change',
                            deviceProfilesData?.attributes_mapping[settingsField.attributeName] ||
                              settingsField.attributeName,
                            null,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/objects',
                            e.target.value,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                        error={
                          !!errors.DATDDevices?.[deviceIndex]?.settings?.[index]?.attributeValue
                        }
                        helperText={
                          errors.DATDDevices?.[deviceIndex]?.settings?.[index]?.attributeValue
                            ?.message
                        }
                      />
                    )}
                    rules={{
                      pattern: {
                        value: FLOAT_NUMBER_REGEX,
                        message: 'Введите число',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default DATDDevice;
