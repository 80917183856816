import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PlusIcon from '../../../../components/icons/PlusIcon';
import Spinner from '../../../../components/Spinner';
import useFetchUsers from '../../hooks/useFetchUsers';

const UsersList = () => {
  const { isLoading } = useFetchUsers();

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Пользователи
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button component={Link} to="/users/create" startIcon={<PlusIcon />}>
          Добавить пользователя
        </Button>
      </Box>
    </>
  );
};

export default UsersList;
