import React, { useEffect, useState } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { useForm, useWatch, Controller, SubmitHandler, FormProvider } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  getUnixTime,
  fromUnixTime,
  isPast,
  isBefore,
  isToday,
  startOfDay,
  endOfDay,
} from 'date-fns';

import ChipsField from '../../components/ChipsField';
import FormLabel from '../../components/FormLabel';
import DeleteIcon from '../../components/icons/DeleteIcon';
import ErrorIcon from '../../components/icons/ErrorIcon';
import PlusIcon from '../../components/icons/PlusIcon';
import QuestionIcon from '../../components/icons/QuestionIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { DASHBOARD_TYPES, DEVICE_TEMP_MEASURE_TYPES } from '../../constants';
import { IUser } from '../../types';
import useFetchGroupsNames from '../../utils/hooks/useFetchGroupsNames';
import sendMetrik from '../../utils/sendMetrik';

import useFetchDashboardDeviceTypes from './hooks/useFetchDashboardDeviceTypes';

type FormValues = {
  device_type: string;
  dashboard_type: string;
  date_from: Date | number | null;
  date_to: Date | number | null;
  device_id: string;
  group_name: string | null;
  exc_device_ids: string[];
  temperature_measure: string;
};

type IDashboardsParams = {
  isLoading: boolean;
};

const DashboardsParams = (props: IDashboardsParams) => {
  const { isLoading } = props;

  let [searchParams, setSearchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let deviceIdParam = searchParams.get('device_id') || '';
  let groupNameParam = searchParams.get('group_name') || null;
  let excludeDeviceIdsParam = searchParams.get('exc_device_ids');
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const envData: any = queryClient.getQueryData('envData');

  const [isGroupsListOpen, setIsGroupsListOpen] = useState(false);
  const [isOtherParamsOpen, setIsOtherParamsOpen] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      device_type: deviceTypeParam,
      dashboard_type: dashboardTypeParam,
      date_from: dateFromParam ? fromUnixTime(parseInt(dateFromParam)) : null,
      date_to: dateToParam ? fromUnixTime(parseInt(dateToParam)) : null,
      device_id: deviceIdParam,
      group_name: groupNameParam,
      exc_device_ids: excludeDeviceIdsParam ? excludeDeviceIdsParam.split(',') : [],
      temperature_measure: temperatureMeasureParam,
    },
  });
  const { control, handleSubmit, reset, formState } = methods;

  const deviceTypeWatch = useWatch({
    control,
    name: 'device_type',
  });
  const dashboardTypeWatch = useWatch({
    control,
    name: 'dashboard_type',
  });
  const dateFromWatch = useWatch({
    control,
    name: 'date_from',
  });
  const dateToWatch = useWatch({
    control,
    name: 'date_to',
  });
  const deviceIdWatch = useWatch({
    control,
    name: 'device_id',
  });
  const groupNameWatch = useWatch({
    control,
    name: 'group_name',
  });
  const excDeviceIdsWatch = useWatch({
    control,
    name: 'exc_device_ids',
  });
  const tempMeasureWatch = useWatch({
    control,
    name: 'temperature_measure',
  });

  const {
    isLoading: isLoadingDashboardDeviceTypes,
    isError: isErrorDashboardDeviceTypes,
    data: dashboardDeviceTypesData,
  } = useFetchDashboardDeviceTypes();

  const { isFetched: isFetchedGroups, data: groupsData } = useFetchGroupsNames(isGroupsListOpen);

  let isParamsReady = true;
  if (
    !deviceTypeWatch ||
    !dashboardTypeWatch ||
    (dashboardTypeWatch !== 'real_time' && !dateFromWatch) ||
    (dashboardTypeWatch !== 'real_time' && !dateToWatch) ||
    ((dashboardTypeWatch === 'device' || dashboardTypeWatch === 'real_time') && !deviceIdWatch) ||
    (dashboardTypeWatch === 'group' && !groupNameWatch) ||
    (deviceTypeWatch === 'pressure_sensor' &&
      dashboardTypeWatch === 'real_time' &&
      !tempMeasureWatch)
  ) {
    isParamsReady = false;
  }

  let otherParamsActiveAmount = 0;
  if (excDeviceIdsWatch.length > 0) {
    otherParamsActiveAmount++;
  }
  if (tempMeasureWatch.length > 0) {
    otherParamsActiveAmount++;
  }

  const handleGroupsListOpen = () => {
    setIsGroupsListOpen(true);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      device_type,
      dashboard_type,
      date_from,
      date_to,
      device_id,
      group_name,
      exc_device_ids,
      temperature_measure,
    } = formData;

    const params: any = {
      device_type: device_type,
      dashboard_type: dashboard_type,
    };
    if (dashboard_type !== 'real_time') {
      if (date_from) {
        params.date_from = getUnixTime(date_from);
      }
      if (date_to) {
        let dateWithTime;
        if (isToday(date_to)) {
          dateWithTime = new Date();
        } else {
          dateWithTime = endOfDay(date_to);
        }
        params.date_to = getUnixTime(dateWithTime);
      }
    }
    if (device_id && (dashboard_type === 'device' || dashboard_type === 'real_time')) {
      params.device_id = device_id;
    }
    if (group_name && dashboard_type === 'group') {
      params.group_name = group_name;
    }
    if (exc_device_ids.length && dashboard_type === 'group') {
      params.exc_device_ids = exc_device_ids;
    }
    if (
      device_type === 'pressure_sensor' &&
      (dashboard_type === 'group' || dashboard_type === 'real_time') &&
      temperature_measure &&
      temperature_measure !== 'all'
    ) {
      params.temperature_measure = temperature_measure;
    }
    const newSearchParams = new URLSearchParams(params);
    if (searchParams.toString() === newSearchParams.toString()) {
      if (dashboardTypeParam !== 'real_time') {
        queryClient.invalidateQueries('dashboardData');
      }
    } else {
      if (dashboardTypeParam === 'real_time') {
        queryClient.setQueryData('dashboardData', null);
      }
      setSearchParams(newSearchParams.toString());
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleOtherParamsToggleClick = () => {
    setIsOtherParamsOpen(!isOtherParamsOpen);
  };

  const handleClearFilterClick = () => {
    if (searchParams.toString()) {
      navigate('/dashboards');
    } else {
      reset();
    }
  };

  //Устанавливает новые значения параметров дашборда при использовании браузерной навигации
  useEffect(() => {
    reset({
      device_type: deviceTypeParam,
      dashboard_type: dashboardTypeParam,
      date_from: dateFromParam ? fromUnixTime(parseInt(dateFromParam)) : null,
      date_to: dateToParam ? fromUnixTime(parseInt(dateToParam)) : null,
      device_id: deviceIdParam,
      group_name: groupNameParam,
      exc_device_ids: excludeDeviceIdsParam ? excludeDeviceIdsParam.split(',') : [],
      temperature_measure: temperatureMeasureParam,
    });
  }, [
    dashboardTypeParam,
    dateFromParam,
    dateToParam,
    deviceIdParam,
    deviceTypeParam,
    excludeDeviceIdsParam,
    groupNameParam,
    reset,
    temperatureMeasureParam,
  ]);

  if (isLoadingDashboardDeviceTypes) {
    return <Spinner data-testid="loader" />;
  }

  if (isErrorDashboardDeviceTypes) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные фильтров. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <Box
      sx={{
        marginBottom: 3,
        padding: 3,
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '16px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => handleKeyDown(e)}>
        <FormProvider {...methods}>
          <Grid container columnSpacing={2}>
            <Grid item mobile={12} xs={8}>
              <Grid container spacing={2}>
                <Grid item mobile={12} xs={3}>
                  <FormControl margin="none">
                    <FormLabel>Тип устройства</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          onChange={(e) => {
                            field.onChange(e);
                            sendMetrik(
                              'vntVdknl',
                              'dashbordy',
                              'form_change',
                              'tip_ustroistva',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/dashboards',
                              e.target.value,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Выберите тип устройства</span>;
                            }

                            const selectedItem = dashboardDeviceTypesData.device_types.find(
                              (item: any) => item.id === selected,
                            );

                            return selectedItem.name;
                          }}
                          SelectDisplayProps={{
                            className: 'MuiSelect-alt',
                          }}
                        >
                          {dashboardDeviceTypesData.device_types
                            .filter((item: { name: string }) => {
                              // временно скрыл периметр 5 для прода и для device и group
                              if (
                                envData?.APP_ENV === 'prod' ||
                                dashboardTypeWatch === 'device' ||
                                dashboardTypeWatch === 'group'
                              )
                                return item.name !== 'Периметр-5';

                              return item;
                            })
                            .map((item: Record<string, string>) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      name="device_type"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item mobile={12} xs={3}>
                  <FormControl disabled={!deviceTypeWatch} margin="none">
                    <FormLabel disabled={!deviceTypeWatch}>Тип дашборда</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          onChange={(e) => {
                            field.onChange(e);
                            sendMetrik(
                              'vntVdknl',
                              'dashbordy',
                              'form_change',
                              'tip_dashborda',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/dashboards',
                              e.target.value,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Выберите тип дашборда</span>;
                            }

                            return DASHBOARD_TYPES[selected];
                          }}
                          SelectDisplayProps={{
                            className: 'MuiSelect-alt',
                          }}
                        >
                          {Object.entries(DASHBOARD_TYPES).map((item: [string, string]) => {
                            // временно скрыл для периметр 5 типы дашбородв кроме реал тайма
                            if (deviceTypeWatch === 'perimeter-5' && item[0] !== 'real_time')
                              return null;

                            return (
                              <MenuItem key={item[0]} value={item[0]}>
                                {item[1]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      name="dashboard_type"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                {(dashboardTypeWatch === 'device' || dashboardTypeWatch === 'real_time') && (
                  <Grid item mobile={12} xs={3}>
                    <FormControl margin="none">
                      <FormLabel id="device_id">ID датчика</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              sendMetrik(
                                'vntVdknl',
                                'dashbordy',
                                'form_change',
                                'aidi_datchika',
                                null,
                                userData?.permissions[0].uuid,
                                userData ? '1' : '0',
                                '/dashboards',
                                e.target.value,
                                null,
                                null,
                                'interactions',
                                userData?.profile_type,
                                'web',
                              );
                            }}
                            inputProps={{ 'aria-labelledby': 'device_id' }}
                            className="MuiTextField-alt"
                          />
                        )}
                        name="device_id"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )}
                {dashboardTypeWatch === 'group' && (
                  <Grid item mobile={12} xs={3}>
                    <FormControl margin="none">
                      <FormLabel>Группа устройств</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={groupsData}
                            loading={!isFetchedGroups}
                            onChange={(_, value) => {
                              field.onChange(value);
                              setIsGroupsListOpen(false);
                            }}
                            onClose={() => setIsGroupsListOpen(false)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Выберите группу"
                                className="MuiTextField-alt"
                                onChange={(e) => {
                                  field.onChange(e);
                                  sendMetrik(
                                    'vntVdknl',
                                    'dashbordy',
                                    'form_change',
                                    'gruppa_ustroistv',
                                    null,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/dashboards',
                                    e.target.value,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    event.preventDefault();
                                    const enteredValue: any = params?.inputProps?.value;
                                    const matchingOption = groupsData.find((option: string) =>
                                      option.toLowerCase().includes(enteredValue?.toLowerCase()),
                                    );
                                    if (matchingOption) {
                                      field.onChange(matchingOption);
                                      setIsGroupsListOpen(false);
                                    }
                                  }
                                }}
                              />
                            )}
                            open={isGroupsListOpen}
                            onOpen={handleGroupsListOpen}
                          />
                        )}
                        name="group_name"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )}
                {dashboardTypeWatch !== 'real_time' && (
                  <>
                    <Grid item mobile={12} xs={3}>
                      <FormControl disabled={!dashboardTypeWatch} margin="none">
                        <FormLabel disabled={!dashboardTypeWatch}>Начало периода</FormLabel>
                        <Controller
                          rules={{
                            validate: (date) => {
                              if (date) {
                                return isPast(date) || 'Дата должна быть не позже текущего дня';
                              }
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DatePicker
                              disabled={!dashboardTypeWatch}
                              value={value}
                              onChange={(newValue) => {
                                onChange(newValue);
                                sendMetrik(
                                  'vntVdknl',
                                  'dashbordy',
                                  'form_change',
                                  'nachalo_perioda',
                                  null,
                                  userData?.permissions[0].uuid,
                                  userData ? '1' : '0',
                                  '/dashboards',
                                  newValue,
                                  null,
                                  null,
                                  'interactions',
                                  userData?.profile_type,
                                  'web',
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="MuiTextField-alt"
                                  error={!!formState.errors.date_from?.message}
                                  helperText={formState.errors.date_from?.message}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'Укажите начало периода',
                                  }}
                                />
                              )}
                            />
                          )}
                          name="date_from"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item mobile={12} xs={3}>
                      <FormControl disabled={!dashboardTypeWatch} margin="none">
                        <FormLabel disabled={!dashboardTypeWatch}>Конец периода</FormLabel>
                        <Controller
                          rules={{
                            validate: {
                              notFuture: (date) => {
                                if (date) {
                                  return isPast(date) || 'Дата должна быть не позже текущего дня';
                                }
                              },
                              minDate: (date) => {
                                if (dateFromWatch && date) {
                                  return (
                                    !isBefore(date, dateFromWatch) ||
                                    'Дата должна быть позже начала периода'
                                  );
                                }
                              },
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DatePicker
                              disabled={!dashboardTypeWatch}
                              value={value}
                              onChange={(newValue) => {
                                if (newValue) {
                                  onChange(startOfDay(newValue));
                                  sendMetrik(
                                    'vntVdknl',
                                    'dashbordy',
                                    'form_change',
                                    'konets_perioda',
                                    null,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/dashboards',
                                    newValue,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="MuiTextField-alt"
                                  error={!!formState.errors.date_to?.message}
                                  helperText={formState.errors.date_to?.message}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'Укажите конец периода',
                                  }}
                                />
                              )}
                              minDate={dateFromWatch}
                            />
                          )}
                          name="date_to"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {(dashboardTypeWatch === 'real_time' || isOtherParamsOpen) && (
                  <>
                    {(dashboardTypeWatch === 'group' || dashboardTypeWatch === 'real_time') &&
                      deviceTypeWatch === 'pressure_sensor' && (
                        <>
                          <Grid item mobile={12} xs={3}>
                            <FormControl margin="none">
                              <FormLabel>Подтип устройства</FormLabel>
                              <Controller
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    displayEmpty
                                    onChange={(e) => {
                                      field.onChange(e);
                                      sendMetrik(
                                        'vntVdknl',
                                        'dashbordy',
                                        'form_change',
                                        'podtip_ustroistva',
                                        null,
                                        userData?.permissions[0].uuid,
                                        userData ? '1' : '0',
                                        '/dashboards',
                                        e.target.value,
                                        null,
                                        null,
                                        'interactions',
                                        userData?.profile_type,
                                        'web',
                                      );
                                    }}
                                    renderValue={(selected) => {
                                      if (selected.length === 0) {
                                        return <span>Выберите подтип устройства</span>;
                                      }

                                      return DEVICE_TEMP_MEASURE_TYPES[selected];
                                    }}
                                    SelectDisplayProps={{
                                      className: 'MuiSelect-alt',
                                    }}
                                  >
                                    {Object.entries(DEVICE_TEMP_MEASURE_TYPES).map((item, id) => (
                                      <MenuItem key={id} value={item[0]}>
                                        {item[1]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                name="temperature_measure"
                                control={control}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    {dashboardTypeWatch === 'group' && (
                      <Grid item mobile={12} xs={3}>
                        <FormControl margin="none">
                          <FormLabel>Номер устройства</FormLabel>
                          <Controller
                            render={({ field }) => (
                              <ChipsField
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  sendMetrik(
                                    'vntVdknl',
                                    'dashbordy',
                                    'form_change',
                                    'nomer_ustroistva',
                                    null,
                                    userData?.permissions[0].uuid,
                                    userData ? '1' : '0',
                                    '/dashboards',
                                    e?.target?.value,
                                    null,
                                    null,
                                    'interactions',
                                    userData?.profile_type,
                                    'web',
                                  );
                                }}
                                options={[]}
                                freeSolo
                                className="MuiTextField-alt"
                              />
                            )}
                            name="exc_device_ids"
                            control={control}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item mobile={12} xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingTop: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    marginRight: 3,
                  }}
                >
                  {dashboardTypeWatch === 'group' && (
                    <>
                      <Button
                        variant="text"
                        size="small"
                        color="link"
                        onClick={handleOtherParamsToggleClick}
                      >
                        {isOtherParamsOpen ? 'Скрыть фильтры' : 'Дополнительные фильтры'}
                        {otherParamsActiveAmount > 0 && ` (${otherParamsActiveAmount})`}
                      </Button>
                      <Tooltip
                        arrow
                        title={
                          <>
                            {isOtherParamsOpen ? 'Скрыть ' : 'Показать '}
                            дополнительные фильтры для дашбордов :
                            <Box>
                              - Подтип устройства
                              {tempMeasureWatch.length > 0 && (
                                <Typography
                                  variant="inherit"
                                  component="span"
                                  sx={{ marginLeft: 1, fontWeight: '700' }}
                                ></Typography>
                              )}
                            </Box>
                            <Box>
                              - Номер устройства
                              {excDeviceIdsWatch.length > 0 && (
                                <Typography
                                  variant="inherit"
                                  component="span"
                                  sx={{ marginLeft: 1, fontWeight: '700' }}
                                >
                                  {excDeviceIdsWatch.join(', ')}
                                </Typography>
                              )}
                            </Box>
                          </>
                        }
                      >
                        <div>
                          <QuestionIcon fontSize="small" color="secondary" />
                        </div>
                      </Tooltip>
                    </>
                  )}
                </Box>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    color="primary"
                    disabled={!isParamsReady}
                    type="submit"
                    data-testid="submit"
                    onClick={() => {
                      sendMetrik(
                        'vntVdknl',
                        'dashbordy',
                        'button_click',
                        'sohranit',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/dashboards',
                        null,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    {isLoading ? <Spinner /> : <PlusIcon />}
                  </IconButton>
                  <IconButton size="small" onClick={handleClearFilterClick}>
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </Box>
  );
};

export default DashboardsParams;
