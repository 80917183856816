import { useQuery } from 'react-query';

import { GISGKH_GROUPS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchGISGKHGroups = () => {
  return useQuery('GISGKHGroupsData', () => fetchData(GISGKH_GROUPS_URL));
};

export default useFetchGISGKHGroups;
