import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExpandIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M3.75196 6.1636C3.08338 6.1636 2.74855 6.97146 3.22131 7.44393L4.98106 9.20261C6.40416 10.6248 7.11571 11.3359 7.9999 11.3359C8.8841 11.3359 9.59565 10.6248 11.0187 9.2026L12.7785 7.44393C13.2513 6.97146 12.9164 6.1636 12.2478 6.1636C12.0488 6.1636 11.8579 6.24262 11.7172 6.38327L9.95743 8.14194C9.21588 8.88304 8.76423 9.32991 8.39681 9.61007C8.22723 9.73938 8.12363 9.79448 8.0668 9.81816C8.02386 9.83606 8.0087 9.83598 8.00091 9.83594L7.9999 9.83594L7.9989 9.83594C7.99111 9.83598 7.97594 9.83606 7.93301 9.81816C7.87617 9.79448 7.77258 9.73938 7.603 9.61007C7.23558 9.32991 6.78393 8.88304 6.04237 8.14195L4.28262 6.38327C4.14188 6.24262 3.951 6.1636 3.75196 6.1636Z" />
    </SvgIcon>
  );
};

export default ExpandIcon;
