import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

type IMainProps = {
  children?: React.ReactNode;
};

const MainBase = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  '.main': {
    padding: '32px 0',
  },
}));

const Main = (props: IMainProps) => {
  const { children } = props;

  return (
    <MainBase>
      <Box className="main">
        <Container>
          {children}
        </Container>
      </Box>
    </MainBase>
  );
};

export default Main;
