import { useState } from 'react';

import { useForm, useWatch, Controller, SubmitHandler } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import Badge from '../../../components/Badge';
import DataGridPagination from '../../../components/DataGrid/DataGridPagination';
import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import WidgetBox from '../../../components/WidgetBox';
import { NUMBER_REGEX } from '../../../constants';
import { IWidgetBoxProps } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';

type IDashboardsGroupConsumptionTableProps = {
  data: any;
  dates: Set<number>;
} & Omit<IWidgetBoxProps, 'children'>;

type FormValues = {
  min_deviation_percentage: string;
  max_deviation_percentage: string;
};

const minDeviationColor = '#B6E9BF66';
const midDeviationColor = '#FAC03166';
const maxDeviationColor = '#FF003266';

const TableFooter = () => {
  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '32px',
        }}
      >
        <GridPagination />
      </Box>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const Marker = styled(Box)({
  width: 8,
  height: 8,
  marginTop: 13,
  borderRadius: '50%',
});

const ValueBadge = styled(Badge)(({ theme, color }) => ({
  minWidth: 68,
  height: 26,
  borderRadius: 4,
  fontWeight: 'normal',
  color:
    color === minDeviationColor
      ? theme.palette.text.positive
      : color === maxDeviationColor
      ? theme.palette.text.negative
      : color === midDeviationColor
      ? theme.palette.warning.main
      : theme.palette.text.primary,
}));

const DashboardsGroupConsumptionTable = (props: IDashboardsGroupConsumptionTableProps) => {
  const { title, data = [], dates } = props;

  const theme = useTheme();

  const [minDeviationPercentage, setMinDeviationPercentage] = useState<number>(0);
  const [maxDeviationPercentage, setMaxDeviationPercentage] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      min_deviation_percentage: '',
      max_deviation_percentage: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const minDeviationWatch = useWatch({
    control,
    name: 'min_deviation_percentage',
  });

  const maxDeviationWatch = useWatch({
    control,
    name: 'max_deviation_percentage',
  });

  let columns: GridColDef[] = [
    {
      headerName: 'Группа',
      field: 'group_name',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'id датчик',
      field: 'id',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'id счетчик',
      field: 'id_channel',
      flex: 1,
      minWidth: 150,
    },
  ];

  const datesArray = Array.from(dates);
  datesArray
    .sort()
    .forEach((date: number) => {
      columns.push({
        headerName: convertTimestampToDate(date),
        field: date.toString(),
        flex: 1,
        minWidth: 130,
        valueGetter: (params) => {
          const { field, row } = params;
          const currentDate = row.dates.find(
            (rowDate: Record<'date' | 'value', number>) =>
              rowDate.date.toString() === field,
          );

          return currentDate?.value ?? 0;
        },
        renderCell: (params: GridRenderCellParams<any, string>) => {
          const { field, row } = params;
          const currentDate = row.dates.find(
            (rowDate: Record<'date' | 'value', number>) =>
              rowDate.date.toString() === field,
          );

          if (minDeviationPercentage && maxDeviationPercentage && currentDate) {
            const currentDateIndex = row.dates.indexOf(currentDate);
            if (currentDateIndex !== 0) {
              const previousDate = row.dates[currentDateIndex - 1];
              const color = getDeviationColor(currentDate.value, previousDate.value);

              return <ValueBadge color={color}>{currentDate.value}</ValueBadge>;
            }
          }

          return currentDate?.value ?? '-';
        },
      });
    });

  const getDeviationColor = (currentValue: number, previousValue: number) => {
    let color = midDeviationColor;
    if (
      currentValue >= previousValue - (previousValue * minDeviationPercentage) / 100 &&
      currentValue <= previousValue + (previousValue * minDeviationPercentage) / 100
    ) {
      color = minDeviationColor;
    } else if (
      currentValue < previousValue - (previousValue * maxDeviationPercentage) / 100 ||
      currentValue > previousValue + (previousValue * maxDeviationPercentage) / 100
    ) {
      color = maxDeviationColor;
    }

    return color;
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const { min_deviation_percentage, max_deviation_percentage } = formData;
    if (min_deviation_percentage && max_deviation_percentage) {
      setMinDeviationPercentage(parseInt(min_deviation_percentage));
      setMaxDeviationPercentage(parseInt(max_deviation_percentage));
    } else {
      setMinDeviationPercentage(0);
      setMaxDeviationPercentage(0);
    }
  };

  return (
    <WidgetBox title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" gap={1}>
          <Marker
            sx={{
              backgroundColor: theme.palette.success.main,
            }}
          />
          <FormControl
            fullWidth={false}
            margin="none"
            sx={{
              width: 240,
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="Процент нормального отклонения"
                  error={!!errors.min_deviation_percentage?.message}
                  helperText={errors.min_deviation_percentage?.message}
                />
              )}
              name="min_deviation_percentage"
              control={control}
              rules={{
                max: {
                  value: maxDeviationWatch,
                  message: 'Значение не должно превышать процент критичного отклонения',
                },
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
          <Marker
            sx={{
              backgroundColor: theme.palette.negative.main,
            }}
          />
          <FormControl
            fullWidth={false}
            margin="none"
            sx={{
              width: 240,
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="Процент критичного отклонения"
                  error={!!errors.max_deviation_percentage?.message}
                  helperText={errors.max_deviation_percentage?.message}
                />
              )}
              name="max_deviation_percentage"
              control={control}
              rules={{
                min: {
                  value: minDeviationWatch,
                  message: 'Значение должно превышать процент нормального отклонения',
                },
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Введите число',
                },
              }}
            />
          </FormControl>
          <Button type="submit" size='small' color="secondary">
            Применить
          </Button>
        </Stack>
      </form>
      <DataGrid
        columns={columns}
        rows={data}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 50]}
        getRowId={(row) => `${row.id}_${row.id_channel}`}
        slots={{
          columnSortedAscendingIcon: AscendingIcon,
          columnSortedDescendingIcon: DescendingIcon,
          footer: TableFooter,
        }}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        localeText={{
          noRowsLabel: 'Нет данных'
        }}
      />
    </WidgetBox>
  );
};

export default DashboardsGroupConsumptionTable;
