import { useQueryClient } from 'react-query';

import { GET_DASHBOARD_REALTIME_DATA_URL } from '../../../constants';
import { IUser } from '../../../types';
import useWebsocket from '../../../utils/hooks/useWebsocket';

const useWebSocketDashboard = (
  deviceType: string,
  dashboardType: string,
  deviceId: string,
  temperatureMeasure: string | null,
) => {
  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  return useWebsocket({
    url: `${GET_DASHBOARD_REALTIME_DATA_URL(
      deviceType,
      deviceId,
      temperatureMeasure,
    )}&customer_id=${userData?.customerId}`,
    queryKey: 'dashboardData',
    enabled: dashboardType === 'real_time'
  });
};

export default useWebSocketDashboard;
