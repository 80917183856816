import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { startOfDay, getUnixTime } from 'date-fns';

import IconBox from '../../components/IconBox';
import DeleteIcon from '../../components/icons/DeleteIcon';
import DocumentIcon from '../../components/icons/DocumentIcon';
import DocumentIcon2 from '../../components/icons/DocumentIcon2';
import EditIcon from '../../components/icons/EditIcon';
import { GET_BALANCE_GROUP_REPORT_PAGE_URL } from '../../constants';

export type IBalanceGroupListItemProps = {
  id: string;
  name: string;
  description: string;
  onDelete: (id: string, name: string) => void;
};

const StyledBalanceGroupsListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 3,
  minHeight: 60,
  marginBottom: 8,
  padding: '14px 24px',
  borderRadius: 16,
  boxShadow: theme.shadows[12],
  '& .balanceGroupsListItem-icon': {
    marginRight: 24,
  },
  '& .balanceGroupsListItem-info': {
    flexGrow: 1,
  },
  '& h3': {
    marginRight: 8,
    fontSize: 17,
  },
}));

const BalanceGroupsListItem = (props: IBalanceGroupListItemProps) => {
  const theme = useTheme();

  const {id, name, description, onDelete} = props;

  const startOfDayTimestamp = getUnixTime(startOfDay(new Date()));
  const endOfDayTimestamp = getUnixTime(new Date());

  return (
    <StyledBalanceGroupsListItem>
      <Box className="balanceGroupsListItem-icon">
        <IconBox color={theme.palette.primary.greyscale200}>
          <DocumentIcon />
        </IconBox>
      </Box>
      <Box className="balanceGroupsListItem-info">
        <Typography variant="h3">{name}</Typography>
        <Typography variant="body2">Описание: {description}</Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <IconButton
          component={Link}
          size="extraSmall"
          color="secondary"
          title="Отчет"
          to={GET_BALANCE_GROUP_REPORT_PAGE_URL(
            id,
            startOfDayTimestamp,
            endOfDayTimestamp,
            name,
          )}
        >
          <DocumentIcon2 fontSize="small" />
        </IconButton>
        <IconButton
          component={Link}
          size="extraSmall"
          color="secondary"
          title="Редактировать"
          to={`/balance-groups/${id}/edit`}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="extraSmall"
          color="secondary"
          title="Удалить"
          onClick={() => onDelete(`${id}`, name)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    </StyledBalanceGroupsListItem>
  )
}

export default BalanceGroupsListItem;
