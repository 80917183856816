import { useState } from 'react';

import { Link } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Dialog from '../../components/Dialog';
import PlusIcon from '../../components/icons/PlusIcon';
import Spinner from '../../components/Spinner';
import { GET_DELETE_BALANCE_GROUP_URL } from '../../constants';
import fetchData from '../../utils/fetchData';

import BalanceGroupsListItem from './BalanceGroupsListItem';
import useFetchBalanceGroups from './hooks/useFetchBalanceGroups';

const BalanceGroupsList = () => {
  const queryClient = useQueryClient();

  const [removingId, setRemovingId] = useState('');
  const [removingName, setRemovingName] = useState('');
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const { isLoading, data } = useFetchBalanceGroups();

  // Удаляет балансовую группу. При успешном выполнении закрываем диалоговое о окно и инвалидируем кэш балансовых групп.
  const balanceGroupDataDelete = useMutation(
    (id: string) => {
      return fetchData(GET_DELETE_BALANCE_GROUP_URL(id), {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        handleRemoveDialogClose();
        queryClient.invalidateQueries('balanceGroupsData');
      },
    },
  );

  // При нажатии на кнопку удаления, показываем диалогове окно для подтверждения
  const handleDeleteClick = (id: string, name: string) => {
    setIsRemoveDialogOpen(true);
    setRemovingId(id);
    setRemovingName(name);
  };

  // Инициируем удаление балансовой группы
  const remove = () => {
    balanceGroupDataDelete.mutate(removingId);
  };

  // Закрывает диалогове окно удаления
  const handleRemoveDialogClose = () => {
    setIsRemoveDialogOpen(false);
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" gutterBottom>
          Балансовые группы
        </Typography>
        <Button component={Link} to="/balance-groups/create" startIcon={<PlusIcon />}>
          Создать
        </Button>
      </Box>
      {data.map(({ uuid, name, description }: any) => (
        <BalanceGroupsListItem
          key={uuid}
          id={uuid}
          name={name}
          description={description}
          onDelete={handleDeleteClick}
        />
      ))}
      <Dialog
        busy={balanceGroupDataDelete.isLoading}
        maxWidth="md"
        heading="Удалить балансовую группу"
        actionButtonText="Удалить"
        open={isRemoveDialogOpen}
        hideCloseButton={true}
        onAction={remove}
        onClose={handleRemoveDialogClose}
      >
        Вы уверены, что хотите удалить балансовую группу “{removingName}”?
      </Dialog>
    </>
  );
};

export default BalanceGroupsList;
