import { useState, BaseSyntheticEvent } from 'react';

import { useForm, useFormContext, useFieldArray, Controller, SubmitHandler } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddressField from '../../components/AddressField';
import Dialog from '../../components/Dialog';
import FormLabel from '../../components/FormLabel';
import MoreHorIcon from '../../components/icons/MoreHorIcon';
import {
  GET_ADDRESS_URL,
  GET_COORDINATES_URL,
  COORDINATES_REGEX,
  RESERVED_NAMES,
} from '../../constants';
import fetchData from '../../utils/fetchData';

type IDeviceServerAttributesProps = {
  address: string;
};

type FormValues = {
  name?: string;
  value?: string;
};

const defaultValues = {
  name: '',
  value: '',
};

const DeviceServerAttributes = ({ address }: IDeviceServerAttributesProps) => {
  const [serverAttributesMenuAnchorEl, setServerAttributesMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const serverAttributesMenuOpen = Boolean(serverAttributesMenuAnchorEl);
  const [isNewServerAtrributeDialogOpen, setIsNewServerAtrributeDialogOpen] = useState(false);
  const [isDeleteServerAttributesModeActive, setIsDeleteServerAttributesModeActive] =
    useState(false);
  const [selectedServerAttributeIds, setSelectedServerAttributeIds] = useState<string[]>([]);

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    resetField: newServerAttributeResetField,
    getValues: newServerAttributeGetValues,
    handleSubmit: newServerAttributeHandleSubmit,
    control: newServerAttributeControl,
    formState: { errors: newServerAttributeErrors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const {
    fields: serverAttributeFields,
    append: appendServerAttribute,
    remove: removeServerAttribute,
  } = useFieldArray({
    name: 'serverAttributes',
    control,
  });

  const handleServerAttributesMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setServerAttributesMenuAnchorEl(event.currentTarget);
  };
  const handleServerAttributesMenuClose = () => {
    setServerAttributesMenuAnchorEl(null);
  };

  const handleServerAttributeCreateClick = () => {
    newServerAttributeResetField('name');
    newServerAttributeResetField('value');
    setIsNewServerAtrributeDialogOpen(true);
    handleServerAttributesMenuClose();
  };

  const handleServerAttributesDeleteModeClick = () => {
    setIsDeleteServerAttributesModeActive(true);
    handleServerAttributesMenuClose();
  };

  const handleNewServerAttributeDialogClose = () => {
    setIsNewServerAtrributeDialogOpen(false);
  };

  const onNewServerAttributeSubmit: SubmitHandler<FormValues> = (formData) => {
    appendServerAttribute({
      attributeName: newServerAttributeGetValues('name'),
      attributeValue: newServerAttributeGetValues('value'),
    });
    setIsNewServerAtrributeDialogOpen(false);
  };

  const handleServerAttributeSelect = (e: BaseSyntheticEvent, attributeId: string) => {
    const isSelected = e.target.checked;

    if (isSelected && selectedServerAttributeIds.indexOf(attributeId) === -1) {
      setSelectedServerAttributeIds([...selectedServerAttributeIds, attributeId]);
    } else {
      setSelectedServerAttributeIds(
        selectedServerAttributeIds.filter((item) => item !== attributeId),
      );
    }
  };

  const handleServerAttributesDeleteClick = () => {
    let selectedServerAttributeIndexes: number[] = [];
    serverAttributeFields.forEach((item, index) => {
      if (selectedServerAttributeIds.indexOf(item.id) > -1) {
        selectedServerAttributeIndexes.push(index);
      }
    });
    removeServerAttribute(selectedServerAttributeIndexes);
  };

  const handleAddressChange = () => {
    const curAddress = getValues('address_full');
    let curCoords = getValues('address_coords');
    if (curAddress && !curCoords) {
      fetchData(GET_COORDINATES_URL(curAddress)).then((responseData) => {
        if (responseData?.length) {
          setValue('address_coords', `${responseData[1]}, ${responseData[0]}`);
        }
      });
    }
  };

  const handleCoordsChange = () => {
    const curAddress = getValues('address_full');
    let curCoords = getValues('address_coords');
    if (!curAddress && curCoords) {
      curCoords = curCoords.split(',');
      fetchData(GET_ADDRESS_URL(curCoords[0].trim(), curCoords[1].trim())).then((responseData) => {
        setValue('address_full', responseData.formatted_address);
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography variant="h3">Пользовательские атрибуты</Typography>
          <IconButton size="extraSmall" color="secondary" onClick={handleServerAttributesMenuClick}>
            <MoreHorIcon fontSize="small" />
          </IconButton>
          <Menu
            anchorEl={serverAttributesMenuAnchorEl}
            open={serverAttributesMenuOpen}
            onClose={handleServerAttributesMenuClose}
          >
            <MenuItem onClick={handleServerAttributeCreateClick}>Создать атрибут</MenuItem>
            <MenuItem onClick={handleServerAttributesDeleteModeClick}>Удалить атрибут</MenuItem>
          </Menu>
        </Box>
        {isDeleteServerAttributesModeActive && (
          <Button size="small" onClick={handleServerAttributesDeleteClick}>
            Удалить
          </Button>
        )}
      </Box>
      <Grid container columnSpacing={4}>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>Адрес</FormLabel>
            <Controller
              control={control}
              render={({ field }) => (
                <AddressField
                  {...field}
                  onChange={(newValue: string) => {
                    field.onChange(newValue);
                    handleAddressChange();
                  }}
                />
              )}
              name="address_full"
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>Координаты</FormLabel>
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  onBlur={handleCoordsChange}
                  error={!!errors.address_coords}
                  helperText={errors.address_coords?.message}
                />
              )}
              name="address_coords"
              rules={{
                pattern: {
                  value: COORDINATES_REGEX,
                  message: 'Неправильный формат координат',
                },
              }}
            />
          </FormControl>
        </Grid>

        {serverAttributeFields.map((serverAttributeField: any, index: number) => (
          <Grid item mobile={12} xs={6} key={serverAttributeField.id}>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <FormControl>
                <FormLabel>{serverAttributeField.attributeName}</FormLabel>
                <Controller
                  render={({ field }) => <Input fullWidth {...field} />}
                  name={`serverAttributes.${index}.attributeValue`}
                  control={control}
                />
              </FormControl>
              {isDeleteServerAttributesModeActive && (
                <Box
                  sx={{
                    position: 'absolute',
                    left: '100%',
                    bottom: '20px',
                    transform: 'translate(8px, 0)',
                  }}
                >
                  <Checkbox
                    size="small"
                    onClick={(e: BaseSyntheticEvent) =>
                      handleServerAttributeSelect(e, serverAttributeField.id)
                    }
                  />
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Dialog
        heading="Новый атрибут"
        actionButtonText="Сохранить"
        open={isNewServerAtrributeDialogOpen}
        onAction={newServerAttributeHandleSubmit(onNewServerAttributeSubmit)}
        onClose={handleNewServerAttributeDialogClose}
      >
        <form>
          <FormControl margin="none">
            <FormLabel>Название атрибута</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!newServerAttributeErrors.name}
                  helperText={newServerAttributeErrors.name?.message}
                  placeholder="Введите название атрибута"
                  {...field}
                />
              )}
              name="name"
              control={newServerAttributeControl}
              rules={{
                required: true,
                validate: {
                  reservedName: (value) => {
                    if (value) {
                      return (
                        !RESERVED_NAMES.some((item) => item === value) ||
                        'Слово зарезервировано, используйте другое название.'
                      );
                    }
                  },
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Значение</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField fullWidth placeholder="Опишите атрибут" {...field} />
              )}
              name="value"
              control={newServerAttributeControl}
            />
          </FormControl>
        </form>
      </Dialog>
    </>
  );
};

export default DeviceServerAttributes;
