import React from 'react';

import { Box, Button, Dialog, DialogActions, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import counterImg from '../../images/counterImg.png';
import nbiotImg from '../../images/nbiotImg.png';
import sensorauthoImg from '../../images/sensorauthoImg.png';
import sensortempImg from '../../images/sensortempImg.png';
import signaldevice from '../../images/signaldevice.png';
import CloseIcon from '../icons/CloseIcon';

const LandingDeviceModal = styled('div')({
  '.land-modal-block': {
    display: 'flex',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    padding: '24px 305px 24px 24px',
  },
  '.modal-content': {
    width: '425px',
  },
  '.modal-header': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    color: '#1D2023',
  },
  '.modal-subtitle': {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#626C77',
  },
  '.modal-content-text': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#1D2023',
  },
});

const FeedbackModalStyled = styled(LandingDeviceModal)({
  padding: 24,
  textAlign: 'center',
});

export const CounterModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <LandingDeviceModal>
          <Box className="land-modal-block">
            <Box className="modal-content">
              <Typography variant="h2" className="modal-header">
                Счетчик воды
              </Typography>
              <Typography className="modal-subtitle">Параметры ПУ:</Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">
                    метрологические характеристики, превосходящие класс «С»
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    индукционный (бесконтактный) съём сигнала с турбины и электронное индикаторное
                    устройство
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    невосприимчивость к сильным магнитным полям
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    низкие значения гидравлического сопротивления
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    архив с часовыми, суточными и месячными значениями потребления
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    измерение потребления и текущего расхода, а также фиксация обратного потока воды
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    высокая перегрузочная способность – неограниченное время работы на максимальном
                    расходе
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    гарантийный срок эксплуатации счетчика – 6 лет с даты поверки
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    средний срок службы – не менее 12 лет
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    средняя наработка на отказ – не менее 100 000 ч.
                  </Typography>
                </li>
              </ul>
              <Typography className="modal-subtitle">
                Параметры радиочасти, встроенной в состав ПУ:
              </Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">
                    3 раза/день передача данных текущего потребления воды
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    1 раз/день почасовки за предыдущие 24 ч.
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    6 лет работы от батареи (до момента поверки ПУ)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    повышенная отказоустойчивость за счет использования промышленного SIM-чипа
                    вместо классической SIM-карты
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    водонепроницаемый корпус IP 68
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">сигнал о разряде батареек</Typography>
                </li>
              </ul>
            </Box>
            <div>
              <img
                src={counterImg}
                alt="device"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  width: '280px',
                  height: 'auto',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
            <DialogActions>
              <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-35px' }}
                size="small"
                onClick={props.handleClose}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            </DialogActions>
          </Box>
        </LandingDeviceModal>
      </Dialog>
    </div>
  );
};

export const NbIotModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <LandingDeviceModal>
          <Box className="land-modal-block">
            <Box className="modal-content">
              <Typography variant="h2" className="modal-header">
                Модуль передачи данных NB-IOT
              </Typography>
              <Typography className="modal-subtitle">Параметры:</Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">тип связи NB-IoT</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    частотные диапазоны - Band 3, 8,20
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">режим работы - PSM</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    протокол передачи данных - TCP/IP (REST JSON) или CoAP (JSON)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">питание 3,6 В </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    энергопотребление в режиме сна не более 20 мкА
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">тип антенны встроенная</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    количество входов 2-10 (в зависимости от модификации)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    глубина суточных архивов 150 суток
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    батарея - типоразмер С, 3,6 В, 8000 мАч. ER18505H-LD/-EHR-02
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    степень защиты корпуса IP68
                  </Typography>
                </li>
              </ul>
            </Box>
            <div>
              <img
                src={nbiotImg}
                alt="device"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  width: '280px',
                  height: 'auto',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
            <DialogActions>
              <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-35px' }}
                size="small"
                onClick={props.handleClose}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            </DialogActions>
          </Box>
        </LandingDeviceModal>
      </Dialog>
    </div>
  );
};

export const AuthoSensorModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <LandingDeviceModal>
          <Box className="land-modal-block">
            <Box className="modal-content">
              <Typography variant="h2" className="modal-header">
                Датчик давления с автономным питанием
              </Typography>
              <Typography className="modal-subtitle">Параметры:</Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">тип связи NB-IoT</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    частотные диапазоны - Band 3, 8,20
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">режим работы - PSM</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    протокол передачи данных - TCP/IP (REST JSON) или CoAP (JSON)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон измерения давления, МПа от 0 до 1,0 либо от 0 до 2,0
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">питание 3,6 В </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    энергопотребление в режиме сна не более 20 мкА
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">тип антенны встроенная</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон рабочих температур - 20…+60 °С
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон температур измеряемой среды - 20…+110 °С
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    батарея - типоразмер С, 3,6 В, 8000 мАч. ER18505H-LD/-EHR-02
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    степень защиты корпуса IP68
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    тип, материал штуцера - M20x1.5; Ключ S27
                  </Typography>
                </li>
              </ul>
            </Box>
            <div>
              <img
                src={sensorauthoImg}
                alt="device"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  width: '280px',
                  height: 'auto',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
            <DialogActions>
              <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-35px' }}
                size="small"
                onClick={props.handleClose}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            </DialogActions>
          </Box>
        </LandingDeviceModal>
      </Dialog>
    </div>
  );
};

export const TempSensorModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <LandingDeviceModal>
          <Box className="land-modal-block">
            <Box className="modal-content">
              <Typography variant="h2" className="modal-header">
                Датчик давления с внешним датчиком температуры
              </Typography>
              <Typography className="modal-subtitle">Параметры:</Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">тип связи NB-IoT</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    частотные диапазоны - Band 3, 8,20
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">режим работы - PSM</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    протокол передачи данных - TCP/IP (REST JSON) или CoAP (JSON)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон измерения давления, МПа от 0 до 1,0 либо от 0 до 2,0
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">питание 3,6 В </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    энергопотребление в режиме сна не более 10 мкА
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    тип антенны - внешняя, на магнитном основании, длина кабеля не менее 2 м
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон рабочих температур - 40…+85 °С
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон температур измеряемой среды - 20…+110 °С
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    батарея - типоразмер С, 3,6 В, 8000 мАч. ER18505H-LD/-EHR-02
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    степень защиты корпуса IP68
                  </Typography>
                </li>
              </ul>
            </Box>
            <div>
              <img
                src={sensortempImg}
                alt="device"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  width: '280px',
                  height: 'auto',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
            <DialogActions>
              <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-35px' }}
                size="small"
                onClick={props.handleClose}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            </DialogActions>
          </Box>
        </LandingDeviceModal>
      </Dialog>
    </div>
  );
};

export const SignalDeviceModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <LandingDeviceModal>
          <Box className="land-modal-block">
            <Box className="modal-content">
              <Typography variant="h2" className="modal-header">
                Устройства сигнализатор уровня воды
              </Typography>
              <Typography className="modal-subtitle">Параметры:</Typography>
              <ul style={{ paddingLeft: '25px', margin: '4px 0 4px' }}>
                <li>
                  <Typography className="modal-content-text">тип связи NB-IoT</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">режим работы - PSM</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    протокол передачи данных - TCP/IP (REST JSON) или CoAP (JSON)
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">питание 3,6 В </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    энергопотребление в режиме сна не более 10 мкА
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">тип антенны - встроенная</Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    диапазон рабочих температур - 20…+60 °С
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    батарея - типоразмер С, 3,6 В, 8000 мАч. ER18505H-LD/-EHR-02
                  </Typography>
                </li>
                <li>
                  <Typography className="modal-content-text">
                    степень защиты корпуса IP68
                  </Typography>
                </li>
              </ul>
            </Box>
            <div>
              <img
                src={signaldevice}
                alt="device"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  width: '280px',
                  height: 'auto',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
            <DialogActions>
              <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-35px' }}
                size="small"
                onClick={props.handleClose}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            </DialogActions>
          </Box>
        </LandingDeviceModal>
      </Dialog>
    </div>
  );
};

export const FeedbackModal = (props: any) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <FeedbackModalStyled>
        <Typography variant="h2" className="modal-header">
          Запрос отправлен
        </Typography>
        <Typography
          className="modal-subtitle"
          style={{ color: '#1D2023', fontWeight: 400, marginTop: '16px' }}
        >
          Мы получили ваш запрос и свяжемся с вами в ближайшее время!
        </Typography>
        <DialogActions style={{ padding: '0px' }}>
          <Button fullWidth type="submit" onClick={props.handleClose} sx={{
            marginTop: 4
          }}>
            Подтвердить
          </Button>
        </DialogActions>
        <IconButton
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          size="small"
          onClick={props.handleClose}
        >
          <CloseIcon color="secondary" fontSize="small" />
        </IconButton>
      </FeedbackModalStyled>
    </Dialog>
  );
};
