import axios from 'axios';

const fetchExcel = (path: string, title: string, data?: any) =>
  axios
    .post(
      path,
      data,
      {
        responseType: 'blob',
      },
    )
    .then((response) => {
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = title;
      link.click();
    });

export default fetchExcel;
