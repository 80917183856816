import { IObject } from "../types";

const dfs = (node: IObject, term: string, foundIds: string[]) => {
    const currentName = node.name.toLowerCase();
    const currentTerm = term.toLowerCase();
    let isMatching = currentName && currentName.indexOf(currentTerm) > -1;

    if (Array.isArray(node.objects)) {
      node.objects.forEach((child: IObject) => {
        const hasMatchingChild = dfs(child, currentTerm, foundIds);
        isMatching = isMatching || hasMatchingChild;
      });
    }

    if (isMatching && node.id) {
      foundIds.push(node.id);
    }

    return isMatching;
  };

export default dfs;
