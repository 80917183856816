import { useState } from 'react';

import MainWithAside from '../../components/Layout/MainWithAside';
import MapObjects from '../../components/MapObjects';
import MapSidebar from '../../components/MapSidebar';
import Spinner from '../../components/Spinner';
import { IColorVariants } from '../../types';

import useWSMapDevices from './hooks/useWSMapDevices';

export type SearchOption = {
  label: string;
  id: string[];
};

export type IMapFilter = {
  search: SearchOption | null;
  group: string | null;
  deviceTypes: any[];
  statuses: any[];
};

export const getDeviceType = (name: string) => {
  let deviceType = undefined;
  if (name === 'УСПД') {
    deviceType = 'uspd';
  } else if (name === 'Датчик давления') {
    deviceType = 'pressure_sensor';
  } else if (name === 'Периметр-5') {
    deviceType = 'perimeter5';
  }

  return deviceType;
};

export const getDeviceStatus = (name: string): IColorVariants => {
  let status: IColorVariants = undefined;
  if (name === 'Доступен') {
    status = 'success';
  } else if (name === 'Превышение уставки') {
    status = 'error';
  } else if (name === 'Низкий заряд батареи') {
    status = 'warning';
  }

  return status;
};

const Map = () => {
  const { isLoading, data: mapDevicesData } = useWSMapDevices();
  const [filter, setFilter] = useState<IMapFilter | undefined>();

  let filteredData: any[] = [];
  let position;
  if (Array.isArray(mapDevicesData.devices)) {
    filteredData = [...mapDevicesData.devices];
    if (filter) {
      const { search, group, deviceTypes, statuses } = filter;
      if (search) {
        filteredData = filteredData.filter(
          (item: any) =>
            item.address_full.toLowerCase().search(search.label.toLowerCase().trim()) !== -1 ||
            search.id.find((id) => id === item.device_id),
        );
      }
      if (group) {
        filteredData = filteredData.filter((item: any) => item.asset_name === group);
      }
      if (deviceTypes) {
        const filterActive = deviceTypes.some(type => type.value);
        if (filterActive) {
          filteredData = filteredData.filter((item: any) => {
            return deviceTypes.some((deviceType) => (deviceType.title === item.device_type) && deviceType.value);
          });
        }
      }
      if (statuses) {
        const filterActive = statuses.some(type => type.value);
        if (filterActive) {
          filteredData = filteredData.filter((item: any) =>
            statuses.find((status) => (status.title === item.status) && status.value),
          );
        }
      }
      if (filteredData.length === 1) {
        position = [filteredData[0].latitude, filteredData[0].longitude];
      }
    }
  }

  const handleFilterChange = (data: IMapFilter) => {
    setFilter(data);
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <MainWithAside
      main={<MapObjects data={filteredData} position={position} />}
      aside={<MapSidebar onFilterChange={handleFilterChange} />}
    />
  );
};

export default Map;
