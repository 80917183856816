import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type ISidebarProps = {
  title?: string;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const StyledSidebar = styled("div")(({ theme }) => ({
  position: "fixed",
  inset: "0 0 0 0",
  width: "100vw",
  height: "100vh",
  zIndex: theme.zIndex.drawer + 1,
  ".sidebar-overlay": {
    position: "absolute",
    inset: "0 0 0 0",
    background: "rgba(29, 32, 35, 0.4)",
  },
  ".sidebar-root": {
    position: "fixed",
    right: 0,
    top: 0,
    width: "380px",
    height: "100%",
    padding: "20px",
    backgroundColor: "#fff",
  },
  ".sidebar-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "20px"
  }
}));

const Sidebar = ({title, open, children, onClose}: ISidebarProps) => {
  return (
    <>
      {open &&
        <StyledSidebar>
          <Box className="sidebar-overlay" onClick={onClose} />
          <Box className="sidebar-root">
            <Box className="sidebar-header">
              <Typography variant='h2'>{title || " "}</Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {children}
          </Box>
        </StyledSidebar>
      }
    </>
  );
};

export default Sidebar;
