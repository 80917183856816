import { useSearchParams } from 'react-router-dom';

import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';

import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import WidgetBox from '../../../components/WidgetBox';
import { IWidgetBoxProps } from '../../../types';

type IDashboardsDeviationsNumberTableProps = {
  data: any;
} & Omit<IWidgetBoxProps, 'children'>;

const DashboardsDeviationsNumberTable = (props: IDashboardsDeviationsNumberTableProps) => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';

  const { title, data } = props;

  let columns: GridColDef[] = [];
  let rows: any[] = [];

  if (Array.isArray(data) && data.length > 0) {
    if (deviceTypeParam === 'uspd') {
      if (dashboardTypeParam !== 'group') {
        let channels: string[] = [];
        columns = [
          {
            field: 'settingType',
            headerName: '',
            width: 200,
            sortable: false,
          },
        ];
        rows = [
          {
            id: 'lowerLimit',
            settingType: 'Пересечение нижнего порога, кол-во раз',
          },
          {
            id: 'upperLimit',
            settingType: 'Пересечение верхнего порога, кол-во раз',
          },
        ];
        data.forEach((item: any) => {
          const { id_channel, channel_number, setting_type, count } = item;
          const channel = `${id_channel}_${channel_number}`;
          if (channels.indexOf(channel) === -1) {
            channels.push(channel);
            columns.push({
              field: channel,
              headerName: `Канал ${channel_number} | ${id_channel}`,
              flex: 1,
            });
          }
          if (setting_type === 'Нижняя') {
            rows[0][channel] = count;
          }
          if (setting_type === 'Верхняя') {
            rows[1][channel] = count;
          }
        });
      } else if (dashboardTypeParam === 'group') {
        columns = [
          {
            field: 'groupName',
            headerName: 'Группа',
            flex: 1,
          },
          {
            field: 'lowerThresholdCrossing',
            headerName: 'Пересечение нижнего порога, раз',
            flex: 1,
          },
          {
            field: 'upperThresholdCrossing',
            headerName: 'Пересечение верхнего порога, раз',
            flex: 1,
          },
        ];

        data.forEach((item: any) => {
          const { group_name, setting_type, count } = item;
          if (group_name) {
            const group = rows.find((groupItem: any) => groupItem.id === group_name);
            let lowerThresholdCrossing;
            let upperThresholdCrossing;
            if (setting_type === 'Нижняя') {
              lowerThresholdCrossing = count;
            }
            if (setting_type === 'Верхняя') {
              upperThresholdCrossing = count;
            }
            if (group) {
              if (lowerThresholdCrossing) {
                group.lowerThresholdCrossing = count;
              }
              if (upperThresholdCrossing) {
                group.upperThresholdCrossing = count;
              }
            } else {
              rows.push({
                id: group_name,
                groupName: group_name,
                lowerThresholdCrossing,
                upperThresholdCrossing,
              });
            }
          }
        });
      }
    } else if (deviceTypeParam === 'pressure_sensor') {
      if (dashboardTypeParam === 'device' || dashboardTypeParam === 'real_time') {
        columns = [
          {
            field: 'id',
            headerName: 'ID датчика',
            flex: 1,
          },
          {
            field: 'lowerThresholdCrossing',
            headerName: 'Пересечение нижнего порога, раз',
            flex: 1,
          },
          {
            field: 'upperThresholdCrossing',
            headerName: 'Пересечение верхнего порога, раз',
            flex: 1,
          },
        ];

        const {
          id,
          inter_lower_pressure_count,
          inter_upper_pressure_count,
          inter_lower_temp_count,
          inter_upper_temp_count,
        } = data[0];
        rows.push({
          id,
          lowerThresholdCrossing: inter_lower_pressure_count || inter_lower_temp_count || 0,
          upperThresholdCrossing: inter_upper_pressure_count || inter_upper_temp_count || 0,
        });
      } else if (dashboardTypeParam === 'group') {
        columns = [
          {
            field: 'groupName',
            headerName: 'Группа',
            flex: 1,
          },
          {
            field: 'lowerThresholdCrossing',
            headerName: 'Пересечение нижнего порога, раз',
            flex: 1,
          },
          {
            field: 'upperThresholdCrossing',
            headerName: 'Пересечение верхнего порога, раз',
            flex: 1,
          },
        ];

        data.forEach((item: any) => {
          const { group_name, setting_type, count } = item;
          if (group_name) {
            const group = rows.find((groupItem: any) => groupItem.id === group_name);
            let lowerThresholdCrossing;
            let upperThresholdCrossing;
            if (setting_type === 'Нижняя') {
              lowerThresholdCrossing = count;
            }
            if (setting_type === 'Верхняя') {
              upperThresholdCrossing = count;
            }
            if (group) {
              if (lowerThresholdCrossing) {
                group.lowerThresholdCrossing = count;
              }
              if (upperThresholdCrossing) {
                group.upperThresholdCrossing = count;
              }
            } else {
              rows.push({
                id: group_name,
                groupName: group_name,
                lowerThresholdCrossing,
                upperThresholdCrossing,
              });
            }
          }
        });
      }
    }
  }

  return (
    <WidgetBox title={title}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowHeight={() => 'auto'}
        components={{
          ColumnSortedAscendingIcon: AscendingIcon,
          ColumnSortedDescendingIcon: DescendingIcon,
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        hideFooter
      />
    </WidgetBox>
  );
};

export default DashboardsDeviationsNumberTable;
