import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import noAccessBg from '../../images/noaccess.svg';

const NoRightsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: `url(${noAccessBg}) no-repeat 50% 50%`,
  '.noaccess-inner': {
    width: '335px',
    marginLeft: '203px',
  },
}));

const NoRights = () => {
  return (
    <NoRightsWrapper>
      <Container>
        <Box className="noaccess-inner">
          <Typography variant="h2" gutterBottom>
            Что-то пошло не так…
          </Typography>
          <Typography paragraph>У вас нет доступа к данной странице</Typography>
          <Button fullWidth component={Link} to="/">
            На главную
          </Button>
        </Box>
      </Container>
    </NoRightsWrapper>
  );
};

export default NoRights;
