import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';

import QuestionIcon from '../../components/icons/QuestionIcon';
import Message from '../../components/Message';
import Tag from '../../components/Tag';
import convertTimestampToDate from '../../utils/convertTimestampToDate';
import getTimezoneTimestamp from '../../utils/getTimezoneTimestamp';

import CustomTooltip from './items/CustomTooltip';
import DashboardsBadgeBig from './items/DashboardsBadgeBig';
import DashboardsBatteryVoltage from './items/DashboardsBatteryVoltage';
import DashboardsDeviationsNumberTable from './items/DashboardsDeviationsNumberTable';
import DashboardsDeviationsTable from './items/DashboardsDeviationsTable';
import DashboardsGauge from './items/DashboardsGauge';
import DashboardsGroupConsumptionTable from './items/DashboardsGroupConsumptionTable';
import DashboardsLeadParam from './items/DashboardsLeadParam';
import DashboardsLineChart from './items/DashboardsLineChart';
import DashboardsRSSI from './items/DashboardsRSSI';
import DashboardsStatus from './items/DashboardsStatus';
import DevicesDashboardsWidgets from './items/DevicesDashboardsWidgets';
import GroupDashboardsWidgets from './items/GroupDashboardsWidgets';

const transformDates = (data: any[], fieldName: string, timezone: number) => {
  return data.map((item: any) => ({
    ...item,
    [fieldName]: getTimezoneTimestamp(item.date, timezone),
  }));
};

const DashboardsWidgets = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';

  const queryClient = useQueryClient();
  const data: any = queryClient.getQueryData('dashboardData');

  let modifiedData;
  let currentAddress = data?.address_full;
  let currentStatus;
  let currentBatteryVoltage;
  let currentRSSI;
  let currentPressure;
  let currentTemp;
  let currentTempTitle = 'Температура окружающей среды, C';
  let currentTempMin = 0;
  let currentTempMax = 80;
  const groupConsumingDates = new Set<number>();
  const pressureChartParams = [{ key: 'pressure_indication', color: '#4339F2' }];
  const tempChartParams = [{ key: 'temp_indication', color: '#EB4A13' }];
  let consumingChartParams: { key: string; color?: string }[] = [];

  if (data) {
    modifiedData = { ...data };
    if (data.inters_pressure_details) {
      modifiedData.inters_pressure_details = transformDates(
        data.inters_pressure_details,
        'date',
        data.timezone,
      );
    }
    if (data.inters_temp_details) {
      modifiedData.inters_temp_details = transformDates(
        data.inters_temp_details,
        'date',
        data.timezone,
      );
    }
    if (data.pressure_plot) {
      modifiedData.pressure_plot = transformDates(data.pressure_plot, 'date', data.timezone);
    }
    if (data.temp_plot) {
      modifiedData.temp_plot = transformDates(data.temp_plot, 'date', data.timezone);
    }
    if (data.last_date) {
      modifiedData.last_date = getTimezoneTimestamp(data.last_date, data.timezone);
    }
    if (data.deviation_table) {
      modifiedData.deviation_table = transformDates(data.deviation_table, 'date', data.timezone);
    }
    if (data.hourly_channel_consumption_detail) {
      let modifiedConsumingData: any[] = [];
      let consuming_devices: any[] = [];
      data.hourly_channel_consumption_detail.forEach((item: any) => {
        const name = `Канал ${item.channel_number}${
          item.id_channel ? ` (${item.id_channel})` : ''
        }`;
        const foundItem = modifiedConsumingData.find((dataItem) => item.date === dataItem.date);
        const foundDevice = consuming_devices.some((device: string) => device === name);
        if (!foundItem) {
          modifiedConsumingData.push({
            date: item.date,
            [name]: item.consumption,
          });
        } else if (!(name in foundItem)) {
          foundItem[name] = item.consumption;
        }
        if (!foundDevice) {
          consuming_devices.push(name);
        }
      });
      modifiedData.hourly_channel_consumption_detail = transformDates(
        modifiedConsumingData,
        'date',
        data.timezone,
      );
      consumingChartParams = consuming_devices.map((device: string) => ({
        key: device,
      }));
    }
    if (data.daily_details) {
      const modifiedGroupConsumingData: any[] = [];
      data.daily_details
        .sort((a: any, b: any) => {
          if (a.group_name === b.group_name) {
            if (a.id_channel === b.id_channel) {
              return a.date > b.date ? 1 : -1;
            } else {
              return a.id_channel > b.id_channel ? 1 : -1;
            }
          } else {
            return a.group_name > b.group_name ? 1 : -1;
          }
        })
        .forEach((item: any) => {
          groupConsumingDates.add(item.date);
          const foundItem = modifiedGroupConsumingData.find(
            (dataItem) => item.id === dataItem.id && item.id_channel === dataItem.id_channel,
          );
          if (!foundItem) {
            modifiedGroupConsumingData.push({
              group_name: item.group_name,
              id: item.id,
              id_channel: item.id_channel,
              dates: [
                {
                  date: item.date,
                  value: item.consumption,
                },
              ],
            });
          } else {
            foundItem.dates = [
              ...foundItem.dates,
              {
                date: item.date,
                value: item.consumption,
              },
            ];
          }
        });
      modifiedData.daily_details = transformDates(
        modifiedGroupConsumingData,
        'date',
        data.timezone,
      );
    }
    if (dashboardTypeParam === 'real_time') {
      if (
        (deviceTypeParam === 'pressure_sensor' || deviceTypeParam === 'perimeter-5') &&
        Array.isArray(data.last_values) &&
        data.last_values[0]
      ) {
        const {
          address_full,
          status,
          last_urf_value,
          last_rssi_value,
          last_pressure_value,
          last_temperature_value,
        } = data.last_values[0];
        currentAddress = address_full;
        currentStatus = status;
        currentBatteryVoltage = last_urf_value;
        currentRSSI = last_rssi_value;
        currentPressure = last_pressure_value
          ? parseFloat(last_pressure_value.toFixed(3))
          : last_pressure_value;
        currentTemp = last_temperature_value
          ? parseFloat(last_temperature_value.toFixed(2))
          : last_temperature_value;
        if (data.temp_sensor === 2) {
          currentTempTitle = 'Температура на штуцере, C';
        } else if (data.temp_sensor === 1) {
          currentTempTitle = 'Температура теплоносителя, C';
          currentTempMin = -40;
          currentTempMax = 150;
        }
      } else if (
        deviceTypeParam === 'uspd' &&
        Array.isArray(data.last_rssi_and_urf_values) &&
        data.last_rssi_and_urf_values[0]
      ) {
        const { status, last_urf_value, last_rssi_value } = data.last_rssi_and_urf_values[0];
        currentStatus = status;
        currentBatteryVoltage = last_urf_value;
        currentRSSI = last_rssi_value;
      }
    }
  }

  return (
    <>
      <Stack
        direction="row"
        gap={4}
        sx={{
          marginBottom: 4,
        }}
      >
        {currentAddress && (
          <Box>
            <Typography component="p" variant="paragraph3" marginBottom={1}>
              Адрес
            </Typography>
            <Tag label={currentAddress} />
          </Box>
        )}
        {/* временно скрыл для периметра-5 данные по устройству и и группе */}
        {deviceTypeParam === 'perimeter-5' &&
        (dashboardTypeParam === 'device' || dashboardTypeParam === 'group') ? null : (
          <Box>
            <Typography component="p" variant="paragraph3" marginBottom={1}>
              Таймзона
            </Typography>
            <Tag label={`UTC${modifiedData.timezone >= 0 ? '+' : '-'}${modifiedData.timezone}`} />
          </Box>
        )}
        {dashboardTypeParam === 'real_time' && (
          <>
            <Box>
              <Typography component="p" variant="paragraph3" marginBottom={1}>
                Статус
              </Typography>
              <DashboardsStatus status={currentStatus} />
            </Box>
            <Box>
              <Typography component="p" variant="paragraph3" marginBottom={1}>
                RSSI
              </Typography>
              <DashboardsRSSI value={currentRSSI} />
            </Box>
            <Box>
              <Typography component="p" variant="paragraph3" marginBottom={1}>
                Напряжение батареи
              </Typography>
              <DashboardsBatteryVoltage value={currentBatteryVoltage} />
            </Box>
          </>
        )}
      </Stack>
      {deviceTypeParam === 'uspd' && (
        <>
          <CustomTooltip>
            <DevicesDashboardsWidgets />
            <GroupDashboardsWidgets />
          </CustomTooltip>
          <Grid container spacing={4}>
            {dashboardTypeParam !== 'group' && (
              <Grid item mobile={12} xs={12}>
                <DashboardsLineChart
                  title="Почасовой график потребления"
                  type="monotone"
                  dataParams={consumingChartParams}
                  data={modifiedData.hourly_channel_consumption_detail}
                  showLegend
                />
              </Grid>
            )}
            {dashboardTypeParam === 'group' && (
              <Grid item mobile={12} xs={12}>
                <DashboardsGroupConsumptionTable data={modifiedData.daily_details} dates={groupConsumingDates} />
              </Grid>
            )}
            <Grid item mobile={12} xs={12}>
              <DashboardsDeviationsNumberTable
                title="Уставки"
                data={modifiedData.deviation_counts}
              />
            </Grid>
            <Grid item mobile={12} xs={12}>
              <DashboardsDeviationsTable data={modifiedData.deviation_table} />
            </Grid>
          </Grid>
        </>
      )}
      {deviceTypeParam === 'perimeter-5' && dashboardTypeParam === 'real_time' ? (
        <>
          <Box>
            <Typography sx={{ mb: 0.8 }} component="p" variant="paragraph4" color="#626C77">
              Статус датчика открытия
            </Typography>
            <Grid container spacing={4}>
              {data?.board_statuses
                ?.sort(
                  (a: { channel_number: number }, b: { channel_number: number }) =>
                    a.channel_number - b.channel_number,
                )
                .map(
                  (el: {
                    sensor_type: string;
                    channel_number: number;
                    sensor_name: string;
                    value: string;
                  }) =>
                    el.sensor_type === 'opening_sensor' && (
                      <Grid item key={el.channel_number + el.sensor_name + el.sensor_type}>
                        <DashboardsBadgeBig
                          title={`Канал ${el.channel_number}`}
                          subtitle={el.sensor_name}
                          status={el.value}
                        />
                      </Grid>
                    ),
                )}
            </Grid>
          </Box>
          <Box>
            <Typography sx={{ mb: 0.8 }} component="p" variant="paragraph4" color="#626C77">
              Статус датчика подтопления
            </Typography>
            <Grid container spacing={4}>
              {data?.board_statuses?.map(
                (el: {
                  sensor_type: string;
                  channel_number: any;
                  sensor_name: string;
                  value: string;
                }) =>
                  el.sensor_type === 'flooding_sensor' && (
                    <Grid item key={el.channel_number + el.sensor_name + el.sensor_type}>
                      <DashboardsBadgeBig
                        title={`Канал ${el.channel_number}`}
                        subtitle={el.sensor_name}
                        status={el.value}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
          </Box>
        </>
      ) : (
        // временно скрыл для периметра-5 данные по устройству и и группе
        deviceTypeParam === 'perimeter-5' &&
        (dashboardTypeParam === 'device' || dashboardTypeParam === 'group') && (
          <Message>В настоящий момент нет данных по устройству или группе для Периметр-5</Message>
        )
      )}
      {deviceTypeParam === 'pressure_sensor' &&
        (dashboardTypeParam === 'device' || dashboardTypeParam === 'real_time') && (
          <Grid container spacing={4}>
            {dashboardTypeParam === 'real_time' ? (
              <>
                <Grid item mobile={12} xs={3}>
                  <DashboardsGauge
                    title={<Typography variant="paragraphBold2">Давление, МПа</Typography>}
                    value={currentPressure}
                    min={0}
                    max={2.5}
                    unit="МПа"
                    sx={{
                      height: '100%',
                    }}
                  />
                </Grid>
                <Grid item mobile={12} xs={3}>
                  <DashboardsGauge
                    title={
                      <Typography variant="paragraphBold2">{currentTempTitle}</Typography>
                    }
                    value={currentTemp}
                    min={currentTempMin}
                    max={currentTempMax}
                    unit="C"
                    sx={{
                      height: '100%',
                    }}
                  />
                </Grid>
                <Grid item mobile={12} xs={6}>
                  <Box
                    sx={{
                      marginBottom: 4,
                    }}
                  >
                    <DashboardsDeviationsNumberTable
                      title={
                        <>
                          Уставки | <Typography variant="bold">Давление</Typography>
                        </>
                      }
                      data={modifiedData.inters_pressure}
                    />
                  </Box>
                  <DashboardsDeviationsNumberTable
                    title={
                      <>
                        Уставки | <Typography variant="bold">Температура</Typography>
                      </>
                    }
                    data={modifiedData.inters_temp}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item mobile={12} xs={12}>
                  <Grid container columnSpacing={4}>
                    <Grid item mobile={12} xs={4}>
                      <DashboardsLeadParam
                        title="Давление"
                        mainHeader={
                          <Tooltip
                            arrow
                            title={`Показание на ${
                              modifiedData.last_date
                                ? convertTimestampToDate(modifiedData.last_date, 'dd.MM.Y HH:mm:ss')
                                : ''
                            }`}
                          >
                            <div data-testid="filter-tooltip">
                              <QuestionIcon fontSize="small" color="secondary" />
                            </div>
                          </Tooltip>
                        }
                        value={`${modifiedData.last_pressure} МПа`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mobile={12} xs={6}>
                  <DashboardsDeviationsNumberTable
                    title={
                      <>
                        Уставки | <Typography variant="bold">Давление</Typography>
                      </>
                    }
                    data={modifiedData.inters_pressure}
                  />
                </Grid>
                <Grid item mobile={12} xs={6}>
                  <DashboardsDeviationsNumberTable
                    title={
                      <>
                        Уставки | <Typography variant="bold">Температура</Typography>
                      </>
                    }
                    data={modifiedData.inters_temp}
                  />
                </Grid>
              </>
            )}
            <Grid item mobile={12} xs={12}>
              <DashboardsLineChart
                title="График давления с пиковыми значениями"
                dataParams={pressureChartParams}
                data={modifiedData.pressure_plot}
              />
            </Grid>
            <Grid item mobile={12} xs={12}>
              <DashboardsDeviationsTable
                title="Таблица отклонений давления"
                data={modifiedData.inters_pressure_details}
              />
            </Grid>
            {Array.isArray(modifiedData.temp_plot) && modifiedData.temp_plot.length > 0 && (
              <Grid item mobile={12} xs={12}>
                <DashboardsLineChart
                  title="График температуры с пиковыми значениями"
                  dataParams={tempChartParams}
                  data={modifiedData.temp_plot}
                />
              </Grid>
            )}
            {Array.isArray(modifiedData.temp_details) && modifiedData.temp_details.length > 0 && (
              <Grid item mobile={12} xs={12}>
                <DashboardsDeviationsTable
                  title="Таблица отклонения температуры"
                  data={modifiedData.inters_temp_details}
                />
              </Grid>
            )}
          </Grid>
        )}
      {deviceTypeParam === 'pressure_sensor' && dashboardTypeParam === 'group' && (
        <Grid container spacing={4}>
          {(temperatureMeasureParam === '' || temperatureMeasureParam === 'false') && (
            <>
              <Grid item mobile={12} xs={12}>
                <DashboardsDeviationsNumberTable
                  title={
                    <>
                      Уставки | <Typography variant="bold">Давление</Typography>
                    </>
                  }
                  data={modifiedData.inters_pressure}
                />
              </Grid>
              <Grid item mobile={12} xs={12}>
                <DashboardsDeviationsTable
                  title="Таблица отклонений давления"
                  data={modifiedData.inters_pressure_details}
                />
              </Grid>
            </>
          )}
          {(temperatureMeasureParam === '' || temperatureMeasureParam === 'true') && (
            <>
              <Grid item mobile={12} xs={12}>
                <DashboardsDeviationsNumberTable
                  title={
                    <>
                      Уставки | <Typography variant="bold">Температура</Typography>
                    </>
                  }
                  data={modifiedData.inters_temp}
                />
              </Grid>
              <Grid item mobile={12} xs={12}>
                <DashboardsDeviationsTable
                  title="Таблица отклонения температуры"
                  data={modifiedData.inters_temp_details}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default DashboardsWidgets;
