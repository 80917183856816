import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowLeftSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#F2F3F7" />
        <g clipPath="url(#clip0_5935_312796)">
          <path
            d="M13.8364 7.75196C13.8364 7.08338 13.0285 6.74855 12.5561 7.22131L10.7974 8.98106C9.37517 10.4042 8.66406 11.1157 8.66406 11.9999C8.66406 12.8841 9.37517 13.5956 10.7974 15.0187L12.5561 16.7785C13.0285 17.2513 13.8364 16.9164 13.8364 16.2478C13.8364 16.0488 13.7574 15.8579 13.6167 15.7172L11.8581 13.9574C11.117 13.2159 10.6701 12.7642 10.3899 12.3968C10.2606 12.2272 10.2055 12.1236 10.1818 12.0668C10.1639 12.0239 10.164 12.0087 10.1641 12.0009L10.1641 11.9999L10.1641 11.9989C10.164 11.9911 10.1639 11.9759 10.1818 11.933C10.2055 11.8762 10.2606 11.7726 10.3899 11.603C10.6701 11.2356 11.117 10.7839 11.8581 10.0424L13.6167 8.28262C13.7574 8.14188 13.8364 7.951 13.8364 7.75196Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_5935_312796">
            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default ArrowLeftSidebarIcon;
