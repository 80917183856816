import { useState, useEffect, ReactNode } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '../../components/icons/CloseIcon';

type IDialogProps = {
  heading?: ReactNode;
  actionButtonText?: string;
  cancelButtonText?: string;
  wide?: boolean;
  open: boolean;
  busy?: boolean;
  hideCloseButton?: boolean;
  onAction?: () => void;
  onClose?: () => void;
} & DialogProps;

const CustomDialog = (props: IDialogProps) => {
  const {
    heading,
    actionButtonText,
    cancelButtonText,
    busy,
    hideCloseButton = false,
    onAction,
    ...rest
  } = props;

  const [isBusy, setIsBusy] = useState(busy || false);

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
  };

  useEffect(() => {
    if (busy !== undefined) {
      setIsBusy(busy);
    }
  }, [busy]);

  return (
    <Dialog {...rest}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>{rest.children}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={rest.onClose}>
          {cancelButtonText ? cancelButtonText : 'Отменить'}
        </Button>
        <LoadingButton loading={isBusy} onClick={handleAction}>
          {actionButtonText ? actionButtonText : 'Отправить'}
        </LoadingButton>
      </DialogActions>
      {!hideCloseButton && (
        <IconButton className="MuiDialog-close" size="small" onClick={rest.onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Dialog>
  );
};

export default CustomDialog;
