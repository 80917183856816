import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M15 10C15 10.5523 14.5523 11 14 11H10C9.44772 11 9 10.5523 9 10C9 9.44771 9.44772 9 10 9L14 9C14.5523 9 15 9.44772 15 10Z" />
      <path d="M14 14C14 14.5523 13.5523 15 13 15H11C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H13C13.5523 13 14 13.4477 14 14Z" />
      <path fillRule="evenodd" d="M4.16725 16.0563C4.06715 14.9034 3.99951 13.551 3.99951 12.0011C3.99951 10.4517 4.0671 9.09964 4.16714 7.94697C4.34291 5.92171 4.43079 4.90908 5.72481 3.61961C7.01882 2.33014 7.98962 2.24932 9.93121 2.0877C10.5797 2.03371 11.2731 2.00073 11.9996 2.00073C12.726 2.00073 13.4195 2.03372 14.068 2.08771C16.0095 2.24934 16.9803 2.33015 18.2743 3.6196C19.5683 4.90905 19.6561 5.92162 19.8319 7.94675C19.932 9.09947 19.9996 10.4516 19.9996 12.0011C19.9996 13.5511 19.9319 14.9036 19.8318 16.0565C19.6561 18.0807 19.5682 19.0929 18.2744 20.3822C16.9806 21.6715 16.0104 21.7524 14.0699 21.9143C13.4208 21.9684 12.7267 22.0014 11.9996 22.0014C11.2724 22.0014 10.5783 21.9684 9.9293 21.9143C7.98874 21.7525 7.01846 21.6716 5.72468 20.3822C4.43089 19.0928 4.34301 18.0807 4.16725 16.0563ZM6.15975 15.8833C6.06461 14.7875 5.99951 13.4927 5.99951 12.0011C5.99951 10.51 6.06456 9.21558 6.15965 8.1199C6.2533 7.04084 6.30818 6.51742 6.43794 6.09635C6.5342 5.78398 6.68753 5.48374 7.13653 5.03631C7.59585 4.5786 7.89164 4.43003 8.17999 4.34021C8.57377 4.21754 9.05968 4.16716 10.0971 4.0808C10.6955 4.03099 11.3334 4.00073 11.9996 4.00073C12.6657 4.00073 13.3037 4.03099 13.9021 4.08081C14.9395 4.16718 15.4254 4.21756 15.8191 4.34022C16.1074 4.43003 16.4032 4.5786 16.8625 5.0363C17.3116 5.48374 17.4649 5.78397 17.5611 6.09631C17.6909 6.51734 17.7458 7.04071 17.8394 8.1197C17.9345 9.21543 17.9996 10.5099 17.9996 12.0011C17.9996 13.4928 17.9345 14.7876 17.8393 15.8835C17.7457 16.962 17.6908 17.485 17.5611 17.9058C17.4649 18.2179 17.3116 18.5181 16.8626 18.9656C16.4033 19.4233 16.1076 19.5719 15.8195 19.6617C15.426 19.7843 14.9406 19.8347 13.9037 19.9212C13.3048 19.9711 12.6663 20.0014 11.9996 20.0014C11.3328 20.0014 10.6943 19.9711 10.0955 19.9212C9.05855 19.8347 8.57306 19.7843 8.17959 19.6617C7.8915 19.5719 7.59579 19.4233 7.13647 18.9656C6.68747 18.5181 6.53419 18.2179 6.43798 17.9058C6.30827 17.485 6.2534 16.9619 6.15975 15.8833Z" />
    </SvgIcon>
  );
};

export default ListIcon;
