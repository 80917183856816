import { createPathComponent, createElementObject, extendContext } from '@react-leaflet/core';
import { Icon } from 'leaflet';
import './Leaflet.DonutCluster';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import { DonutCluster } from './Leaflet.DonutCluster';

// webpack failing when loading leaflet marker icon
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

function createMarkerClusterGroup({ children: _c, ...options }, ctx) {
  const clusterProps = {};
  const clusterEvents = {};
  // Splitting props and events to different objects
  Object.entries(options).forEach(([propName, prop]) => {
    if (propName.startsWith('on')) {
      clusterEvents[propName] = prop;
    } else {
      clusterProps[propName] = prop;
    }
  });

  const markerClusterGroup = new DonutCluster(
    { chunkedLoading: true, showCoverageOnHover: false },
    {
      groupColors: clusterProps.groupColors,
    },
  );

  // Initializing event listeners
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    markerClusterGroup.on(clusterEvent, callback);
  });

  return createElementObject(
    markerClusterGroup,
    extendContext(ctx, { layerContainer: markerClusterGroup }),
  );
}

function updateMarkerClusterGroup(instance, props, prevProps) {
  if (props.groupColors !== prevProps.groupColors) {
    instance.setOptions({ groupColors: props.groupColors });
  }
}

const MarkerClusterGroup = createPathComponent(createMarkerClusterGroup, updateMarkerClusterGroup);

export default MarkerClusterGroup;
