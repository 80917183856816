const getAttributes = (dataCustomAttr: any, savedData: any) => {
  if (dataCustomAttr) {
    const columnAtrNames = Object.keys(dataCustomAttr).map((key) => ({
      name: key,
      label: dataCustomAttr[key],
      checked: false,
    }));
    const columnAtrFilters = Object.keys(dataCustomAttr).map((key) => ({
      name: key,
      label: '',
      checked: false,
      disabled: false,
    }));

    return (savedData = {
      attr: {
        columnAtrNames,
        columnAtrFilters,
        columnAllChecked: false,
      },
    });
  }
};

export default getAttributes;
