import Tag from '../../../components/Tag';
import { DEVICE_STATUSES } from '../../../constants';
import { IColorVariants } from '../../../types';

export type IDashboardsStatus = {
  status: string;
};

const DashboardsStatus = (props: IDashboardsStatus) => {
  const { status } = props;

  let color: IColorVariants;
  if (DEVICE_STATUSES.indexOf(status) === 0) {
    color = 'success';
  } else if (DEVICE_STATUSES.indexOf(status) === 1) {
    color = 'warning';
  } else if (DEVICE_STATUSES.indexOf(status) === 2 || DEVICE_STATUSES.indexOf(status) === 3) {
    color = 'error';
  }

  return <Tag color={color} label={status} />;
};

export default DashboardsStatus;
