import { useFormContext, Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import FormLabel from '../../components/FormLabel';
import DeleteIcon from '../../components/icons/DeleteIcon';
import { IBalanceGroupItem } from '../../types';

type IBalanceGroupDeviceFieldProps<T> = {
  fieldArrayName: T;
  fieldIndex: number;
  devices: IBalanceGroupItem[];
  channels: any;
  addresses: string[];
  onRemove: (index: number) => void;
  onAddressChange: (value: string | null, fieldArrayName: T, fieldIndex: number) => void;
  onIdChange: (fieldArrayName: T, fieldIndex: number) => void;
};

const BalanceGroupDeviceField = (props: IBalanceGroupDeviceFieldProps<'parent_items' | 'child_items'>) => {
  const {
    fieldArrayName,
    fieldIndex,
    devices,
    channels,
    addresses,
    onRemove,
    onAddressChange,
    onIdChange,
  } = props;

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <Box
      sx={{
        position: 'relative',
        paddingRight: 5,
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Адрес</FormLabel>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={addresses}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onAddressChange(value, fieldArrayName, fieldIndex);
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Укажите адрес" />}
                />
              )}
              name={`${fieldArrayName}.${fieldIndex}.address`}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>ID</FormLabel>
            <Controller
              rules={{
                required: 'Выберите устройство',
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={devices}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onIdChange(fieldArrayName, fieldIndex);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="ID устройства"
                      error={!!errors?.[fieldArrayName]?.[fieldIndex]?.item}
                      helperText={errors?.[fieldArrayName]?.[fieldIndex]?.item?.message}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.device_id === value.device_id}
                  getOptionLabel={(option) => option.device_id.toString()}
                  noOptionsText="Нет доступных устройств"
                />
              )}
              name={`${fieldArrayName}.${fieldIndex}.item`}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={4}>
          <FormControl disabled={!getValues(`${fieldArrayName}.${fieldIndex}.item`)}>
            <FormLabel disabled={!getValues(`${fieldArrayName}.${fieldIndex}.item`)}>
              Канал
            </FormLabel>
            <Controller
              rules={{
                required: 'Выберите канал',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span>Номер канала</span>;
                    }

                    return selected;
                  }}
                  error={!!errors?.[fieldArrayName]?.[fieldIndex]?.channel}
                >
                  {channels.map(({ number_of_channel, sensor_id }: any) => (
                    <MenuItem key={number_of_channel} value={number_of_channel}>
                      <b>{`${number_of_channel} канал`}</b>-{sensor_id ? sensor_id : 'не задан'}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={`${fieldArrayName}.${fieldIndex}.channel`}
              control={control}
            />
            {!!errors?.[fieldArrayName]?.[fieldIndex]?.channel && (
              <FormHelperText error>
                {errors?.[fieldArrayName]?.[fieldIndex]?.channel?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <IconButton
        size="extraSmall"
        color="secondary"
        title="Удалить устройство"
        sx={{
          position: 'absolute',
          right: 0,
          top: 50,
        }}
        onClick={() => onRemove(fieldIndex)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default BalanceGroupDeviceField;
