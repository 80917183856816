import { useCallback, useEffect, useState, useRef, memo } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { useQueryClient, useMutation } from 'react-query';

import TreeView from '@mui/lab/TreeView';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { GET_DELETE_GROUP_URL, ADMIN_CUSTOMER_ID } from '../../constants';
import useFetchObjectInfo from '../../routes/objects/hooks/useFetchObjectInfo';
import { IUser } from '../../types';
import dfs from '../../utils/depthFirstSearch';
import fetchData from '../../utils/fetchData';
import filterObjectsByIds from '../../utils/filterObjectsByIds';
import useDebounce from '../../utils/hooks/useDebounce';
import useSnackbars from '../../utils/hooks/useSnackbars';
import sendMetrik from '../../utils/sendMetrik';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';
import MoreHorIcon from '../icons/MoreHorIcon';
import SearchIcon from '../icons/SearchIcon';
import Spinner from '../Spinner';

import useFetchObjectsTree from './hooks/useFetchObjectsTree';
import TreeItem from './TreeItem';

import type { IObject } from '../../types';

export type IObjectsTreeProps = {
  expanded: string[];
  selected: string[];
  onNodeToggle: (e: React.SyntheticEvent, nodeIds: string[]) => void;
  onNodeSelect: (e: React.SyntheticEvent, accountId: string[]) => void;
  data: IObject[];
  onCreateGroup: (data: any) => void;
};

const MemoizedTreeView = memo(({ data, onCreateGroup, ...props }: IObjectsTreeProps) => {
  return (
    <TreeView
      {...props}
      defaultCollapseIcon={<ChevronUpIcon />}
      defaultExpandIcon={<ChevronDownIcon />}
      children={
        Array.isArray(data)
          ? data.map((itemData: IObject) =>
              renderTreeItem(itemData, null, itemData.id, onCreateGroup),
            )
          : []
      }
    />
  );
});

const renderTreeItem = (
  { id, name, type, personalDevices, objects }: IObject,
  parentId: string | null,
  customerId: string | null,
  onCreateGroup: (data: any) => void,
) => {
  let currentCustomerId = type === 'ASSET' || type === 'INPUT' ? customerId : id;

  return (
    <TreeItem
      onFocusCapture={(e) => e.stopPropagation()}
      key={id}
      nodeId={id}
      label={name}
      ContentProps={{
        type,
        amount: personalDevices,
        parentId,
        customerId: currentCustomerId,
        onCreateGroup,
      }}
      children={
        Array.isArray(objects) && objects.length > 0 ? (
          objects.map((object) => renderTreeItem(object, id, currentCustomerId, onCreateGroup))
        ) : (
          <div />
        )
      }
      TransitionProps={{
        timeout: 0,
      }}
    />
  );
};

const ObjectsTree = () => {
  let { objectId = '', deviceId = '' } = useParams();
  let [searchParams] = useSearchParams();
  let objectTypeParam = searchParams.get('type') || '';

  let navigate = useNavigate();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const prevObjectId = useRef(objectId);

  const [nodeToFetch, setNodeToFetch] = useState<Record<'id' | 'type', string> | null>(null);
  const [expanded, setExpanded] = useState<string[]>(
    userData?.customerId && userData.customerId !== ADMIN_CUSTOMER_ID ? [userData.customerId] : [],
  );
  const [selectedNodeType, setSelectedNodeType] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [isFetchObjectsTreeDataEnabled, setIsFetchObjectsTreeDataEnabled] =
    useState<boolean>(false);
  const [isAddNodeModeActive, setIsAddNodeModeActive] = useState<boolean>(false);
  const [currentObjectsTreeData, setCurrentObjectsTreeData] = useState<IObject[] | null>(null);
  const [currentObjectsSearchTreeData, setCurrentObjectsSearchTreeData] = useState<
    IObject[] | any | null
  >(null);
  const [objectsTreeSearch, setObjectsTreeSearch] = useState('');
  const debouncedObjectsTreeSearch = useDebounce<string>(objectsTreeSearch);
  const [objectsTreeMenuAnchorEl, setObjectsTreeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const objectsTreeMenuOpen = Boolean(objectsTreeMenuAnchorEl);

  const updateTreeChildObjects = (childrenData: any) => {
    const updateObjects = (objects: IObject[]): IObject[] => {
      return objects.map((node: IObject) => {
        if (node.id === nodeToFetch?.id) {
          let inputNode;
          if (Array.isArray(node.objects)) {
            inputNode = node.objects.find((item: IObject) => item.type === 'INPUT');
          }

          return {
            ...node,
            objects: inputNode && isAddNodeModeActive ? [inputNode, ...childrenData] : childrenData,
          };
        }

        if (node.objects && node.objects.length > 0) {
          return {
            ...node,
            objects: updateObjects(node.objects),
          };
        }

        return node;
      });
    };

    setCurrentObjectsTreeData((prevState: IObject[] | null) => {
      if (prevState === null) {
        return null;
      }

      return updateObjects(prevState);
    });
  };

  const {
    isLoading,
    isError,
    data: objectsTreeData,
  } = useFetchObjectsTree({ id: 'root', type: '' }, true);
  useFetchObjectsTree(nodeToFetch, isFetchObjectsTreeDataEnabled, (data: any) => {
    updateTreeChildObjects(data);
  });
  const {
    isLoading: isLoadingObjectInfo,
    isError: isErrorObjectInfo,
    data: objectData,
  } = useFetchObjectInfo(
    'customer',
    userData?.customerId as string,
    userData?.customerId !== ADMIN_CUSTOMER_ID,
  );

  const searchTreeNode = useCallback((tree: IObject[] | null, id: string): IObject | undefined => {
    if (tree) {
      for (const node of tree) {
        if (node.id === id) {
          return node;
        } else if (Array.isArray(node.objects)) {
          const found = searchTreeNode(node.objects, id);

          if (found) return found;
        }
      }
    }
  }, []);

  const objectsTreeDataDeleteMutation = useMutation(
    () => {
      return fetchData(GET_DELETE_GROUP_URL(selected[0]), {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        const deletedNode = searchTreeNode(currentObjectsTreeData, selected[0]);
        if (deletedNode) {
          let parent;
          parent = searchTreeNode(currentObjectsTreeData, deletedNode.parentId as string);
          if (parent) {
            const { id, type } = parent;
            if (id !== nodeToFetch?.id) {
              setNodeToFetch({ id, type });
            } else {
              queryClient.invalidateQueries(['objectsTreeData', parent.id]);
            }
          }
        }
        showSnackbar(<Alert severity="success">Группа удалена</Alert>);
        navigate('/objects', {
          replace: true,
        });
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Ошибка | Группа не удалена</Alert>);
      },
    },
  );

  const search = useCallback(
    (term: string) => {
      const matchedIDS: string[] = [];
      currentObjectsTreeData?.forEach((item: IObject) => dfs(item, term, matchedIDS));
      setExpanded(matchedIDS);

      if (currentObjectsTreeData) return filterObjectsByIds(currentObjectsTreeData, matchedIDS);
    },
    [currentObjectsTreeData],
  );

  const handleToggle = useCallback(
    (e: React.BaseSyntheticEvent, nodeIds: string[]) => {
      const nodeToFetchId = nodeIds.find((nodeId) => !expanded.includes(nodeId));
      if (nodeToFetchId) {
        setNodeToFetch({ id: nodeToFetchId, type: e.currentTarget.parentNode.dataset.type });
        setIsFetchObjectsTreeDataEnabled(true);
      }
      setExpanded(nodeIds);
    },
    [expanded],
  );

  const handleSelect = useCallback(
    (e: React.BaseSyntheticEvent, selectedId: string[]) => {
      if (Array.isArray(selectedId)) {
        setSelected(selectedId);
      } else {
        setSelectedNodeType(e.currentTarget.dataset.type);
        setSelected([selectedId]);
      }
      sendMetrik(
        'vntVdknl',
        'spisok_ustroistv_i_obektov',
        'element_click',
        e.currentTarget.dataset.type === 'CUSTOMER' ? 'klient' : 'gruppa',
        null,
        userData?.permissions[0].uuid,
        userData ? '1' : '0',
        '/objects',
        selectedId,
        null,
        null,
        'interactions',
        userData?.profile_type,
        'web',
      );
    },
    [userData],
  );

  const handleObjectsTreeSearchChange = (e: React.BaseSyntheticEvent) => {
    const searchValue = e.target.value;
    setObjectsTreeSearch(searchValue);

    //отправка метрики
    sendMetrik(
      'vntVdknl',
      'spisok_ustroistv_i_obektov',
      'form_change',
      'poisk_po_gruppam',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/objects',
      searchValue,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  const handleObjectsTreeMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    sendMetrik(
      'vntVdknl',
      'spisok_ustroistv_i_obektov',
      'button_click',
      'dobavit_udalit_gruppu',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/objects',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
    setObjectsTreeMenuAnchorEl(event.currentTarget);
  };
  const handleObjectsTreeMenuClose = () => {
    setObjectsTreeMenuAnchorEl(null);
  };

  const insertInputNode = useCallback(
    (node: IObject): IObject | boolean => {
      node.hasInputNode = false;
      node.objects = Array.isArray(node.objects)
        ? node.objects.filter((item) => insertInputNode(item))
        : [];

      if (selected[0] === node.id && !node.hasInputNode) {
        node.hasInputNode = true;
        if (Array.isArray(node.objects)) {
          node.objects.unshift({
            id: `${Date.now()}`,
            name: 'Новая группа',
            type: 'INPUT',
          });
        }
      }

      if (node.type === 'INPUT') {
        return false;
      }

      return true;
    },
    [selected],
  );

  const handleAddNodeClick = useCallback(() => {
    setObjectsTreeMenuAnchorEl(null);
    setCurrentObjectsTreeData((prev) => {
      const newData = Array.isArray(prev) ? prev.filter((node) => insertInputNode(node)) : [];

      return newData;
    });
    setIsAddNodeModeActive(true);
    setExpanded((prev) => [...prev, selected[0]]);
  }, [insertInputNode, selected]);

  const handleCreate = (data: any) => {
    setNodeToFetch({ id: selected[0], type: selectedNodeType.toLocaleUpperCase() });
    setIsAddNodeModeActive(false);
    setIsFetchObjectsTreeDataEnabled(true);
  };

  const deleteAsset = () => {
    setObjectsTreeMenuAnchorEl(null);
    if (selectedNodeType === 'ASSET') {
      objectsTreeDataDeleteMutation.mutate();
    }
  };

  // Обновляем кол-во устройств в дереве после изменения родительской группы устройства
  if (prevObjectId.current && objectId && prevObjectId.current !== objectId && deviceId) {
    const prevObjectNode = searchTreeNode(currentObjectsSearchTreeData, prevObjectId.current);
    const newObjectNode = searchTreeNode(currentObjectsSearchTreeData, objectId);
    if (prevObjectNode && prevObjectNode.personalDevices !== undefined) {
      prevObjectNode.personalDevices = --prevObjectNode.personalDevices;
    }
    if (newObjectNode && newObjectNode.personalDevices !== undefined) {
      newObjectNode.personalDevices = ++newObjectNode.personalDevices;
    }
    prevObjectId.current = objectId;
    setCurrentObjectsSearchTreeData((prev: IObject[]) => [...prev]);
  }

  useEffect(() => {
    if (objectsTreeData) {
      // Создаем первый уровень дерева
      if (!currentObjectsTreeData) {
        if (userData && userData?.customerId !== ADMIN_CUSTOMER_ID && objectData) {
          setCurrentObjectsTreeData([
            {
              id: userData.customerId,
              name: objectData.name,
              objects: objectsTreeData,
              type: 'CUSTOMER',
            },
          ]);
        } else if (userData?.customerId === ADMIN_CUSTOMER_ID) {
          setCurrentObjectsTreeData(objectsTreeData);
        }
        // Обновляем ноды дерева
      } else {
        const nodeToUpdate = searchTreeNode(currentObjectsTreeData, objectId);
        const updatedNode = objectsTreeData.find((node: IObject) => node.id === objectId);
        // Обновляем ноду верхнего уровня
        if (nodeToUpdate && updatedNode && nodeToUpdate?.name !== updatedNode.name) {
          nodeToUpdate.name = updatedNode.name;
          setCurrentObjectsSearchTreeData((prev: IObject[]) => [...prev]);
          // Обновляем дочерние ноды
        } else if (nodeToUpdate && !updatedNode) {
          const objectInfoData: any = queryClient.getQueryData(['objectData', objectId]);
          if (objectInfoData && nodeToUpdate.name !== objectInfoData.name) {
            nodeToUpdate.name = objectInfoData.name;
            setCurrentObjectsSearchTreeData((prev: IObject[]) => [...prev]);
          }
        }
      }
    }
  }, [
    userData,
    objectsTreeData,
    objectData,
    currentObjectsTreeData,
    objectId,
    searchTreeNode,
    queryClient,
  ]);

  useEffect(() => {
    if (debouncedObjectsTreeSearch.length) {
      setCurrentObjectsSearchTreeData(search(debouncedObjectsTreeSearch));
    } else {
      setCurrentObjectsSearchTreeData(currentObjectsTreeData);
    }
  }, [currentObjectsTreeData, debouncedObjectsTreeSearch, search]);

  useEffect(() => {
    if (objectId && objectTypeParam && !selected.length) {
      setSelectedNodeType(objectTypeParam);
      setSelected([objectId]);
    } else if (!objectId && !objectTypeParam && selected.length) {
      setSelected([]);
    }
  }, [objectId, objectTypeParam, selected]);

  if (isLoading || isLoadingObjectInfo) {
    return <Spinner data-testid="loader" />;
  }

  if (isError || isErrorObjectInfo) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography paragraph>
          Не получилось загрузить дерево объектов. Попробуйте перезагрузить страницу.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          p: 3,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <FormControl margin="none">
            <Input
              value={objectsTreeSearch}
              placeholder="Поиск по группам"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={handleObjectsTreeSearchChange}
              onClick={() =>
                //отправка метрики
                sendMetrik(
                  'vntVdknl',
                  'spisok_ustroistv_i_obektov',
                  'form_click',
                  'poisk_po_gruppam',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/objects',
                  null,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                )
              }
            />
          </FormControl>
          <Box
            sx={{
              ml: 1,
            }}
          >
            <IconButton color="secondary" onClick={handleObjectsTreeMenuClick}>
              <MoreHorIcon />
            </IconButton>
            <Menu
              anchorEl={objectsTreeMenuAnchorEl}
              open={objectsTreeMenuOpen}
              onClose={handleObjectsTreeMenuClose}
            >
              <MenuItem key="create_asset" onClick={handleAddNodeClick}>
                Добавить группу
              </MenuItem>
              <MenuItem
                key="delete_asset"
                disabled={objectsTreeDataDeleteMutation.isLoading}
                onClick={deleteAsset}
              >
                Удалить группу
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Typography variant="h3">Список групп</Typography>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          overflowX: 'hidden',
          height: '100%',
        }}
      >
        {Array.isArray(currentObjectsSearchTreeData) ? (
          <MemoizedTreeView
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            data={currentObjectsSearchTreeData}
            onCreateGroup={handleCreate}
          />
        ) : (
          <Box
            sx={{
              px: 3,
            }}
          >
            <Typography paragraph>Нет объектов</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ObjectsTree;
