import { useFormContext, Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import FormLabel from '../../components/FormLabel';
import DeleteIcon from '../../components/icons/DeleteIcon';
import { IBalanceGroupBalanceGroup } from '../../types';

type IBalanceGroupBalanceGroupFieldProps<T> = {
  fieldArrayName: T;
  fieldIndex: number;
  groups: IBalanceGroupBalanceGroup[];
  onRemove: (index: number) => void;
};

const BalanceGroupBalanceGroupField = (
  props: IBalanceGroupBalanceGroupFieldProps<'child_balance_groups'>,
) => {
  const {
    fieldArrayName,
    fieldIndex,
    groups,
    onRemove,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        position: 'relative',
        paddingRight: 5,
      }}
    >
      <FormControl>
        <FormLabel>Наименование</FormLabel>
        <Controller
          rules={{
            required: 'Выберите балансовую группу',
          }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={groups}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Наименование группы"
                  error={!!errors?.[fieldArrayName]?.[fieldIndex].group}
                  helperText={errors?.[fieldArrayName]?.[fieldIndex]?.group?.message}
                />
              )}
              isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
              getOptionLabel={(option) => option.name}
              noOptionsText="Нет доступных групп"
            />
          )}
          name={`${fieldArrayName}.${fieldIndex}.group`}
          control={control}
        />
      </FormControl>
      <IconButton
        size="extraSmall"
        color="secondary"
        title="Удалить балансовую группу"
        sx={{
          position: 'absolute',
          right: 0,
          top: 50,
        }}
        onClick={() => onRemove(fieldIndex)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default BalanceGroupBalanceGroupField;
