import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DescendingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M8.78087 10.0239C8.38054 10.5243 7.61946 10.5243 7.21913 10.0239L5.29976 7.6247C4.77595 6.96993 5.24212 6 6.08062 6L9.91938 6C10.7579 6 11.2241 6.96993 10.7002 7.62469L8.78087 10.0239Z" />
    </SvgIcon>
  );
};

export default DescendingIcon;
