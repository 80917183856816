import { fromUnixTime } from 'date-fns';

const getTimezoneTimestamp = (date: number, zone: number) => {
  const offset = fromUnixTime(date).getTimezoneOffset();
  const timestamp = date + zone * 60 * 60 + offset * 60;

  return timestamp;
};

export default getTimezoneTimestamp;
