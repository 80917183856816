import { useState } from 'react';

import { Link } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import PlusIcon from '../../../components/icons/PlusIcon';
import SquareSearchIcon from '../../../components/icons/SquareSearchIcon';
import Spinner from '../../../components/Spinner';
import { IUser } from '../../../types';
import useDebounce from '../../../utils/hooks/useDebounce';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchCustomReportShablons from '../hooks/useFetchCustomReportShablons';

import CustomReportsList from './CustomReportsList';

const ReportsCustom = () => {
  // получаем список шаблонов
  const { dataCustomReportShablons, isLoading } = useFetchCustomReportShablons();

  const [searchTemplate, setSearchTemplate] = useState('');

  const queryClient = useQueryClient();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  // поиск шаблонов в строке
  const handleSearchChange = (value: string) => {
    setSearchTemplate(value);
    //отправка метрики
    sendMetrik(
      'vntVdknl',
      'otchet_polzovatelskii',
      'form_change',
      'poisk_po_nazvaniu',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports/custom',
      value,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  const debounceSearchTemplate = useDebounce(searchTemplate);

  // отфильтрованные данные после поиска в строке
  const filteredTemplates = dataCustomReportShablons?.filter((template: { name: string }) =>
    template.name.toLowerCase().includes(debounceSearchTemplate.toLowerCase()),
  );

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: 'flex-end', justifyContent: 'space-between', mb: 4 }}
      >
        <TextField
          sx={{ width: '324px' }}
          fullWidth
          placeholder="Поиск по названию"
          value={searchTemplate}
          onChange={(e) => handleSearchChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ pt: '12px' }}>
                  <SquareSearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Link to="/reports/customReportCreate">
          <Button
            sx={{ backgroundColor: '#FF0032' }}
            variant="contained"
            color="primary"
            type="submit"
            data-testid="submit"
            startIcon={<PlusIcon />}
            onClick={() => {
              sendMetrik(
                'vntVdknl',
                'otchet_polzovatelskii',
                'button_click',
                'sozdat_otchet',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/reports/custom',
                null,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              );
            }}
          >
            <Typography sx={{ weigth: 500, fontSize: '17px', color: '#FFFFFF' }}>
              Создать отчет
            </Typography>
          </Button>
        </Link>
      </Stack>
      <CustomReportsList filteredTemplates={filteredTemplates} />
    </>
  );
};

export default ReportsCustom;
