import { useEffect, useMemo, useState, forwardRef } from 'react';

import { withYMaps } from 'react-yandex-maps';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type IAddressFieldProps = {
  ymaps?: any;
  value: string | null;
  className?: string;
  placeholder?: string;
  onChange: (v: string | null) => void;
  onLoad?: () => void;
};

const AddressFieldBase = (props: IAddressFieldProps) => {
  const { ymaps, className, placeholder, value, onChange, ...rest } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    let active = true;

    ymaps.suggest(inputValue).then(function (items: any) {
      if (active) {
        let newOptions = items.map((item: any) => item.displayName);
        newOptions = newOptions.filter((item: string, index: number) => newOptions.indexOf(item) === index);
        if (value && newOptions.indexOf(value) === -1) {
          newOptions = [value, ...newOptions];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, ymaps]);

  return (
    <Autocomplete
      {...rest}
      freeSolo
      value={value}
      options={options}
      forcePopupIcon={false}
      popupIcon={false}
      filterOptions={(x) => x}
      renderInput={(params) => {
        return <TextField {...params} placeholder={placeholder} className={className} />;
      }}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      onBlur={() => {
        if (inputValue) {
          onChange(inputValue);
        }
      }}
      onInputChange={(_, newValue) => {
        setInputValue(newValue);
      }}
    />
  );
};

const AddressField = forwardRef((props: any, ref) => {
  const { value, ...rest } = props;
  const [isYMapsReady, setisYMapsReady] = useState(false);
  const Field = useMemo(() => {
    return withYMaps(AddressFieldBase, true, ['suggest']);
  }, []);

  return (
    <>
      <Field {...props} onLoad={() => setisYMapsReady(true)} />
      {!isYMapsReady && <TextField value={value ? value : ''} {...rest} />}
    </>
  );
});

export default AddressField;
