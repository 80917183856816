import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { CREATE_USER_URL } from '../../../../constants';
import { IUser } from '../../../../types';
import fetchData from '../../../../utils/fetchData';
import useSnackbars from '../../../../utils/hooks/useSnackbars';
import UserForm from '../../components/UserForm/UserForm';

type INewUserData = {
  fullname: string | null;
  email: string | null;
  msisdn: string;
  permissions_short: string[];
  telegram_username: string | null;
  customerId: string;
};

const UserCreatePage = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const userDataMutation = useMutation(
    (newUserData: INewUserData) => {
      return fetchData(CREATE_USER_URL, {
        method: 'POST',
        body: newUserData,
      });
    },
    {
      onSuccess: () => {
        showSnackbar(<Alert severity="success">Пользователь добавлен</Alert>);
        queryClient.invalidateQueries('usersData', {
          refetchInactive: true,
        });
        navigate(`/users`);
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Пользователь не добавлен</Alert>);
      },
    },
  );

  const handleSubmit = (data: IUser) => {
    let newData: INewUserData = {
      fullname: data.fullname || null,
      email: data.email || null,
      msisdn: data.msisdn.replace(/\+7|[()\s]/g, ''),
      permissions_short: [data.permissions_short],
      telegram_username: data.telegram_username || null,
      customerId: userData?.customerId as string,
    };
    userDataMutation.mutate(newData);
  };

  return (
    <>
      <Breadcrumbs>
        <Link component={RouterLink} to="/users">
          Пользователи
        </Link>
        <span>Добавление нового пользователя</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Добавить пользователя
      </Typography>
      <UserForm isLoading={userDataMutation.isLoading} onFormSubmit={handleSubmit} />
    </>
  );
};

export default UserCreatePage;
