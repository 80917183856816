import { useRef } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import device from '../../images/teplo_device.jpg';
import doc1 from '../../images/teplo_doc1.png';
import doc2 from '../../images/teplo_doc2.png';
import leadBg from '../../images/teplo_lead_bg.jpg';
import mockup from '../../images/teplo_mockup.png';

const Lead = styled(Box)({
  background: `url(${leadBg}) no-repeat center center / cover`,
  borderRadius: 32,
  height: '567px',
  marginTop: 64,
  marginBottom: 64,
  padding: '60px 56px 0',
});

const Panel = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.secondary,
  borderRadius: 32,
  padding: 32,
}));

const PanelAlt = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.primary,
  borderRadius: 32,
  boxShadow: theme.shadows[6],
  padding: 32,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: 0,
  fontSize: 20,
  '&:last-child': {
    fontWeight: '500',
  },
}));

const HeatingNetworksPage = () => {
  const theme = useTheme();

  const tariffSectionRef = useRef<HTMLDivElement>(null);

  return (
    <Container fixed>
      <Lead>
        <Typography variant="h1" gutterBottom sx={{ fontSize: 44 }}>
          ТЕПЛОВЫЕ СЕТИ
        </Typography>
        <Typography variant="paragraph1" mb={3}>
          Готовое решение для теплосетей
        </Typography>
        <Button onClick={() => tariffSectionRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          Выбрать тариф
        </Button>
      </Lead>
      <Grid container spacing={2} mb={8}>
        <Grid item xs={4}>
          <PanelAlt sx={{ height: '100%' }}>
            <Stack direction="row" alignItems="center" gap={2} mb={2}>
              <img
                width={20}
                height={19}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBrZSBDYIwEEV/nIAROgIjMIIbyAayAY7gBsQJ0AnQCcQJcAPYAFv8pY32ICI/+anS67sr9ArISrQL7Ua7p+/apXaKH5R4kFa7Irjg75ZzDWMnlXugTDsS4lIvaS7B9nDbkkC+FGPNml1osmXWEMy8t3PgeURoS8aogpkUwqrokGKuPfpZekKxAGjnTZXRhhmMLliuEwuLfeATy3XlOADtR+jwv4YtrwLi2BlgzT/JxIKb9mNiXnGsLd222FLZYzee4SMfxMKCLS1V93XsFKuU2q6kP2ViGwQ6xSiD62WFeSm4Xs6koAPc1ZQKMaaqHO4aO2BGCVa8D32leL83uy0LKaZALyreV/3DaioAAAAAAElFTkSuQmCC"
                alt=""
              />
              <Typography variant="h2Alt">Проблема</Typography>
            </Stack>
            <List
              sx={{
                listStyleType: 'disc',
                padding: '0 0 0 20px',
                fontSize: 20,
                color: theme.palette.text.secondary,
              }}
            >
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Аварийность оборудования
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Отсутствие датчиков дистанционного объективного контроля
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Большое время от момента аварии до момента её обнаружения
              </ListItem>
            </List>
          </PanelAlt>
        </Grid>
        <Grid item xs={4}>
          <PanelAlt sx={{ height: '100%' }}>
            <Stack direction="row" alignItems="center" gap={2} mb={2}>
              <img
                width={22}
                height={21}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAYAAABCIB6VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFASURBVHgBlVVtccMwDH03BIZgCIWQMSmDmME6BCmDhkHGIDcEK4OWQccgtVKpdlV/5d3phyW9Z0nnDyAP4815m7xdvC1sF/btOWcTem83JTazab9rETRMXljggHRVlmPSyYxC9RT448QJbW1azl2Ym+QMnDBiO0bmDjqwQ5hZCn2KpCDddrFT2tllSDL3E/LoEOa9wmhHAvH8S+JSgPlAKP23QPj39untjMf5zY1FNDoStrw4o4xY3EHNUmlYEjYRsQbK+ZaqMnHCOornAnVQzqCq0/F1AxK+8sKiLjpzHlX9k8iRU3UVwpZTMRbynqdCO7oMYWoQlUv20kmHcN9TOLCVIA/S2yU7on4Bcjgx95gK6tfNog6DhtdNEmXe1Fqf2YDyvhAe/eJ7HMPh9Tu6Ifwg2t/0g+iq9sj/eQ6FKu/IhHgY/GUMSwAAAABJRU5ErkJggg=="
                alt=""
              />
              <Typography variant="h2Alt">Последствия</Typography>
            </Stack>
            <List
              sx={{
                listStyleType: 'disc',
                padding: '0 0 0 20px',
                fontSize: 20,
                color: theme.palette.text.secondary,
              }}
            >
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Большие потери в результате порыва трубопроводов
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Высокий показатель тепловых потерь
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Отсутствие систем оперативного информирования
              </ListItem>
            </List>
          </PanelAlt>
        </Grid>
        <Grid item xs={4}>
          <PanelAlt
            sx={{
              height: '100%',
              background: '#5174A2',
              color: '#fff',
            }}
          >
            <Stack direction="row" alignItems="center" gap={2} mb={2}>
              <img
                width={25}
                height={24}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF0SURBVHgBrVWBcYMwDBSdgBE8AiMwQkYgG7ABbBA2oBu0GzBC2gncTkA3UO1ETj7GIEL4O59BlvWSLNlECpi5dKN3w7ox8h1nkR9oK9zmKjK6BOv1nzGeuzGAAf/dulH4NdDxEdZCgLpGIzCwaVybConaQlRmKQIL+Tb0BCIHbYg6VjqpnuhE6Ogp5UWAoRcgZxdQ4kIvwp52gBSKR4fCcY8owJ6B4slDs10OO6Gc00ZI8VxS9ub+C5H/RkqVm7wnjWKsEWNxuX/LbDxJ8PYrUvqTuZ0jEnlLafwgSRJZln266ThHFBEcRT8N6dbZyoJ1DkSQIuaZO8vJP2S9Xjz4GSLWCGTP7eBDl6olzNcmCxsHxkab6t5KGIWdCFvaAZxqbkiZR0EvgJeuKIjG8sYm5MebuEsp4A1qedtVf1Yd5embUK0kOEDx6A4K0QB59ZtqOTd8fn21NTx9qg2tBT8+qRp8FDVthaTiHfIdjFq+lmqp2fgHRjszdH96MwsAAAAASUVORK5CYII="
                alt=""
              />
              <Typography variant="h2Alt">Решение</Typography>
            </Stack>
            <Typography paragraph variant="paragraph1">
              Полный комплект оборудования на сети NB-IoT для объектов теплоснабжения (котельные,
              теплотрассы, МКД) и платформа, позволяют своевременно узнавать об авариях,
              автоматизировать процесс их устранения, прогнозировать возникновение внештатных
              ситуаций
            </Typography>
            <Typography variant="paragraph1">
              Сокращение времени получения информации необходимой для принятия решения
              ответственными лицами при аварийной ситуации до нескольких минут
            </Typography>
          </PanelAlt>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} mb={6}>
        <Box>
          <Typography variant="h2Alt" gutterBottom>
            Готовое решение
            <br /> для теплосетей
          </Typography>
          <Typography paragraph variant="paragraph1">
            По решению Научно-технического совета Министерства жилищно-коммунального хозяйства
            Московской области от 08.02.2024 № 64ВО/2024
          </Typography>
        </Box>
        <Stack direction="row" gap={2} alignItems="flex-end">
          <Link href="https://mgkh.mosreg.ru/download/document/16252848" target="_blank">
            <img src={doc1} width="288" height="auto" alt="" />
          </Link>
          <Link href="https://mgkh.mosreg.ru/download/document/16252848" target="_blank">
            <img src={doc2} width="459" height="auto" alt="" />
          </Link>
        </Stack>
      </Stack>
      <Panel
        mb={8}
        sx={{
          borderRadius: 4,
        }}
      >
        <Typography variant="h2Alt" align="center" gutterBottom>
          Реализованный функционал
        </Typography>
        <Stack direction="row" alignItems="flex-end">
          <Box>
            <Typography variant="h3" fontSize={24} mb={0.5}>
              Передача параметров в ВИС МВИТУ
            </Typography>
            <Typography variant="paragraph1" paragraph>
              Снижение аварийности в результате мониторинга и анализа информации с устройств
            </Typography>
            <Typography variant="h3" fontSize={24} mb={0.5}>
              Единая платформа сбора, консолидации и передачи данных
            </Typography>
            <List
              component="ol"
              sx={{ listStyleType: 'number', mb: 2, padding: '0 0 0 20px', fontSize: 20 }}
            >
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Real time мониторинг контролируемых параметров с мгновенной передачей информации
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Анализ полученных данных
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Предупреждение об аварийных ситуациях на базе анализа данных
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Оповещения об отклонении параметров
              </ListItem>
            </List>
            <Typography variant="h3" fontSize={24} mb={0.5}>
              Комплект оборудования
            </Typography>
            <List
              component="ol"
              sx={{ listStyleType: 'number', padding: '0 0 0 20px', fontSize: 20 }}
            >
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Беспроводные датчики для контроля ключевых параметров с длительным автономным
                питанием, защитой от воздействия внешней среды
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Анализ полученных данных
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 5px' }}>
                Счетчики метрологического класса «С» со встроенным модулем передачи данных
              </ListItem>
            </List>
          </Box>
          <img src={mockup} width="536" height="auto" alt="" />
        </Stack>
      </Panel>
      <Typography variant="h2Alt" align="center" gutterBottom>
        Комплект оборудования
      </Typography>
      <Typography variant="paragraph1" align="center" mb={6}>
        Два датчика давления, температуры и монтажный комплект
      </Typography>
      <Grid container spacing={8} mb={8}>
        <Grid item xs={5}>
          <Box
            sx={{
              minHeight: '100%',
              padding: 5,
              border: `5px solid ${theme.palette.background.secondary}`,
              borderBottom: 'none',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
          >
            <img src={device} width="100%" height="auto" alt="" />
            <Typography variant="paragraph1">
              Предназначен для измерения избыточного давления жидкостей и газов с передачей текущих
              и архивных (часовых) показаний на сервер с возможностью выдачи мгновенных и архивных
              значений давления и температуры теплоносителя, а также задания минимального
              и максимального пороговых значений давления и температуры, по превышении которых идет
              внеплановая передача показаний
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell width="50%">ПАРАМЕТР</StyledTableCell>
                <StyledTableCell width="50%" align="right">
                  ЗНАЧЕНИЕ
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>Тип связи</StyledTableCell>
                <StyledTableCell align="right">NB-IoT МТС</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Частотные диапазоны</StyledTableCell>
                <StyledTableCell align="right">Band 3, 8, 20 </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Режим работы</StyledTableCell>
                <StyledTableCell align="right">PSM</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Варианты SIM</StyledTableCell>
                <StyledTableCell align="right">SIM-чип МТС</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Протокол передачи</StyledTableCell>
                <StyledTableCell align="right">CoAP (JSON)</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Диапазон измерения давления, МПа</StyledTableCell>
                <StyledTableCell align="right">от 0 до 2,5</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Питание, В</StyledTableCell>
                <StyledTableCell align="right">4,5,12</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Энергопотребление в режиме сна, мкА</StyledTableCell>
                <StyledTableCell align="right">не более 10</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Тип антенны</StyledTableCell>
                <StyledTableCell align="right">
                  внешняя, на магнитном основании, длина кабеля не менее 2 м
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Диапазон рабочих температур, °С</StyledTableCell>
                <StyledTableCell align="right">-40…+85</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Диапазон температур измеряемой среды, °С</StyledTableCell>
                <StyledTableCell align="right">-20…+150</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Батарея</StyledTableCell>
                <StyledTableCell align="right">
                  Типоразмер С, 3,6 В, 8000 мАч. ER18505H-LD/-EHR-02
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Степень защиты корпуса</StyledTableCell>
                <StyledTableCell align="right">IP66</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Box ref={tariffSectionRef}>
        <Typography variant="h2Alt" align="center" gutterBottom>
          Тарификационная модель
        </Typography>
        <Typography variant="paragraph1" align="center" mb={6}>
          Комплект оборудования из двух датчиков давления и температуры, включая комплект питания
        </Typography>
        <Grid container spacing={2} mb={8}>
          <Grid item xs={4}>
            <Panel sx={{ height: '100%' }}>
              <Typography variant="h2Alt" gutterBottom>
                Классическая модель (CAPEX)
              </Typography>
              <Typography variant="paragraph1">
                Оборудование за единоразовый платеж и обслуживание сроком на 3 года
              </Typography>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel sx={{ height: '100%' }}>
              <Typography variant="h2Alt" gutterBottom>
                Сервисная модель (OPEX)
              </Typography>
              <Typography variant="paragraph1">
                Оборудование в аренду с правом выкупа через 3 года и обслуживание сроком на 3 года
              </Typography>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel sx={{ height: '100%' }}>
              <Typography variant="h2Alt" gutterBottom>
                Смежная модель (CAPEX+OPEX)
              </Typography>
              <Typography variant="paragraph1">
                Оборудование за единоразовый платеж и ежемесячная стоимость обслуживания за комплект
              </Typography>
            </Panel>
          </Grid>
        </Grid>
        <Typography variant="paragraph1" mb={8}>
          Обслуживание включает в себя:
          <br />
          −техническую поддержку работы комплекта оборудования
          <br />
          −стоимость платформы
          <br />
          −стоимость трафика
        </Typography>
      </Box>
      <Panel
        sx={{
          borderRadius: 4,
          marginBottom: 7,
          background: theme.palette.background.modalDark,
          color: theme.palette.text.inverted,
        }}
      >
        <Typography variant="h2Alt" align="center" gutterBottom>
          Свяжитесь с нами
        </Typography>
        <Typography variant="paragraph1" align="center">
          Напишите нам на почту{' '}
          <Link color="inherit" underline="always" href="mailto:support.vodokanal@mts.ru">
            support.vodokanal@mts.ru
          </Link>
          <br /> и мы свяжемся с вами в ближайшее время
        </Typography>
      </Panel>
    </Container>
  );
};

export default HeatingNetworksPage;
