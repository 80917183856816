import { useQuery } from 'react-query';

import { GET_NOTIFICATIONS_TEMPLATE_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchNotificationsTemplate = (id: string) => {
  return useQuery(['notificationsTemplatesData', id], () => fetchData(GET_NOTIFICATIONS_TEMPLATE_URL(id)));
};

export default useFetchNotificationsTemplate;
