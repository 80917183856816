import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentIconFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 44 44" {...props}>
      <path
        d="M29.7559 10.207C26.2539 6.70508 24.1455 5.01063 23.2094 4.4531C22.58 4.11703 21.54 4.00009 20.6436 4.00005C19.6033 3.9998 18.5791 4.01589 17.5375 4.09608C14.863 4.30197 13.5257 4.40492 11.1858 6.69517C8.84587 8.98543 8.69164 10.5495 8.38317 13.6777C8.15793 15.9618 8 18.738 8 22C8 24.7127 8.11192 27.0893 8.27916 29.1264C8.58474 32.8488 8.73754 34.7099 11.1333 37.0922C13.5291 39.4745 15.2799 39.6083 18.7814 39.8759C19.7996 39.9537 20.8773 40 22 40C23.1228 40 24.2005 39.9537 25.2188 39.8759C28.7202 39.6083 30.4708 39.4745 32.8666 37.0923C35.2624 34.71 35.4152 32.8489 35.7208 29.1267C35.8881 27.0896 36 24.7128 36 22C36 20.5088 36.0352 17.6289 35.5508 16.7773C35.4846 16.661 35.3615 16.5887 35.214 16.3981C34.4247 15.3781 32.7065 13.1577 29.7559 10.207Z"
        fill="url(#paint0_linear_412_76824)"
      />
      <path
        d="M15.75 21.5C15.0596 21.5 14.5 22.0596 14.5 22.75C14.5 23.4404 15.0596 24 15.75 24H23.75C24.4404 24 25 23.4404 25 22.75C25 22.0596 24.4404 21.5 23.75 21.5H15.75Z"
        fill="white"
      />
      <path
        d="M15.75 27.2949C15.0596 27.2949 14.5 27.8546 14.5 28.5449C14.5 29.2353 15.0596 29.7949 15.75 29.7949H28.25C28.9404 29.7949 29.5 29.2353 29.5 28.5449C29.5 27.8546 28.9404 27.2949 28.25 27.2949H15.75Z"
        fill="white"
      />
      <path
        d="M35.5711 16.8017C33.6427 11.4327 27.987 6.3383 23.168 4.42969C25.506 7.51031 24.457 12.8831 25.7983 14.2243C27.4018 15.8278 32.868 14.6089 35.5711 16.8017Z"
        fill="#779BFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_412_76824"
          x1="1.50481"
          y1="7.5"
          x2="43.1401"
          y2="15.9431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50A6F5" />
          <stop offset="1" stopColor="#010AEC" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default DocumentIconFilled;
