import {  useContext } from "react";

import { SnackbarsContext, ISnackbarsContext } from "../../components/Snackbar/SnackbarsProvider";


const useSnackbars = () => {
  const context = useContext(SnackbarsContext) as ISnackbarsContext;

  return context;
}

export default useSnackbars;
