import { Outlet } from 'react-router-dom';

// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

// import useRouteMatch from '../../utils/hooks/useRouteMatch';

const Notifications = () => {
  // const routeMatch = useRouteMatch(['/notifications', '/notifications/templates']);
  // const currentTab = routeMatch?.pattern?.path;

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Уведомления
      </Typography>
      {/* <Tabs value={currentTab}>
        <Tab
          label="Список уведомлений"
          value="/notifications"
          to="/notifications"
          component={NavLink}
        />
        <Tab
          label="Шаблоны уведомлений"
          value="/notifications/templates"
          to="/notifications/templates"
          component={NavLink}
        />
      </Tabs> */}
      <Outlet />
    </>
  );
};

export default Notifications;
