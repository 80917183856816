import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M15 5.01246C15 4.11226 13.9234 3.66144 13.2938 4.29798L10.1984 7.4273C8.0661 9.58289 7 10.6607 7 12C7 13.3393 8.0661 14.4171 10.1984 16.5727L13.2938 19.702C13.9234 20.3386 15 19.8877 15 18.9875C15 18.7196 14.8947 18.4625 14.7073 18.2731L11.6118 15.1437C10.5058 14.0255 9.8214 13.3276 9.3888 12.7544C8.9984 12.2371 8.9988 12.0565 8.999 12.0028V12V11.9973C8.9988 11.9435 8.9984 11.7629 9.3888 11.2456C9.8214 10.6724 10.5058 9.97446 11.6118 8.85627L14.7073 5.72695C14.8947 5.53745 15 5.28044 15 5.01246Z" />
    </SvgIcon>
  );
};

export default ArrowLeftIcon;
