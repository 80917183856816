import { useState, useEffect, useCallback } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  useForm,
  useFieldArray,
  useWatch,
  SubmitHandler,
  FormProvider,
  Controller,
} from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FormLabel from '../../components/FormLabel';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import ErrorIcon from '../../components/icons/ErrorIcon';
import MoreHorIcon from '../../components/icons/MoreHorIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { IBalanceGroup, IBalanceGroupBalanceGroup } from '../../types';

import BalanceGroupBalanceGroupField from './BalanceGroupBalanceGroupField';
import BalanceGroupDeviceField from './BalanceGroupDeviceField';
import useFetchBalanceGroupItems from './hooks/useFetchBalanceGroupItems';
import useFetchBalanceGroups from './hooks/useFetchBalanceGroups';

type IBalanceGroupFormProps = {
  defaultValues?: IBalanceGroup;
  isLoading: boolean;
  onFormSubmit: (data: IBalanceGroup) => void;
};

type IBalanceGroupDetails = IBalanceGroup & {
  child_balance_groups: IBalanceGroupBalanceGroup[];
};

type IFormButtons = {
  isLoading: boolean;
};

const FormButtons = (props: IFormButtons) => {
  const { isLoading } = props;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        mt: 3,
      }}
    >
      <Button color="secondary" type="button" component={RouterLink} to="/balance-groups">
        Отмена
      </Button>
      <LoadingButton loading={isLoading} type="submit">
        Сохранить
      </LoadingButton>
    </Stack>
  );
};

const BalanceGroupForm = (props: IBalanceGroupFormProps) => {
  const [addresses, setAddresses] = useState<string[]>([]);
  const [isBalanceGroupsVisible, setIsBalanceGroupsVisible] = useState(true);
  const [addChildElementMenuAnchorEl, setAddChildElementMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const addChildElementMenuOpen = Boolean(addChildElementMenuAnchorEl);

  const {
    defaultValues = {
      name: '',
      description: '',
      parent_items: [
        {
          item: null,
          channel: '',
          address: null,
        },
      ],
      child_items: [
        {
          item: null,
          channel: '',
          address: null,
        },
      ],
      child_balance_groups: [],
    },
    isLoading,
    onFormSubmit,
  } = props;

  const {
    isLoading: isLoadingBalanceGroupItems,
    isError: isErrorBalanceGroupItems,
    data: balanceGroupItemsData,
  } = useFetchBalanceGroupItems();

  const {
    isLoading: isLoadingBalanceGroups,
    isError: isErrorBalanceGroups,
    data: balanceGroupsData,
  } = useFetchBalanceGroups();

  useEffect(() => {
    if (Array.isArray(balanceGroupItemsData)) {
      let currentAddresses: string[] = [];
      balanceGroupItemsData.forEach((item) => {
        if (item.address) {
          currentAddresses.push(item.address);
        }
      });
      setAddresses(currentAddresses);
    }
  }, [balanceGroupItemsData]);

  const methods = useForm<IBalanceGroupDetails>({
    defaultValues,
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const {
    fields: parentDevicesFields,
    append: addParentDevice,
    remove: removeParentDevice,
  } = useFieldArray({
    name: 'parent_items',
    control,
  });

  const {
    fields: childDevicesFields,
    append: addChildDevice,
    remove: removeChildDevice,
  } = useFieldArray({
    name: 'child_items',
    control,
  });

  const {
    fields: childBalanceGroupsFields,
    append: addChildBalanceGroup,
    remove: removeChildBalanceGroup,
  } = useFieldArray({
    name: 'child_balance_groups',
    control,
  });

  // Подписка на обновления состояния списков родительских и дочерних элементов для ререндера
  useWatch({
    control,
    name: 'parent_items',
  });
  useWatch({
    control,
    name: 'child_items',
  });
  useWatch({
    control,
    name: 'child_balance_groups',
  });

  // Формирует данные доступных устройств и каналов для выбора
  const getDeviceFieldAvailableData = useCallback(
    (field) => {
      let devices = [];
      let channels: any = [];
      devices = balanceGroupItemsData.filter((item: any) => {
        let isAvailable = true;
        let isAddressMatch = false;

        // Определяем кол-во нахождений устройства в обоих списках
        let selectedInParentsList = getValues('parent_items').filter(
          (parentItem) => parentItem.item?.device_id === item.device_id,
        );
        let selectedInChildsList = getValues('child_items').filter(
          (childItem) => childItem.item?.device_id === item.device_id,
        );

        // Проверяем соответствует ли адрес элемента, выбранному адресу
        if (!field?.address || field?.address === item.address) {
          isAddressMatch = true;
        }

        // Делаем устройство недоступным если устройство уже выбрано, либо адрес не сопадает с выбранным.
        if (
          ((selectedInParentsList.length >= item.sensors.length ||
            selectedInChildsList.length >= item.sensors.length) &&
            item?.device_id !== field?.item?.device_id) ||
          !isAddressMatch
        ) {
          isAvailable = false;
        }

        // Определяем доступные каналы выбранного устройства
        if (item.device_id === field.item?.device_id) {
          item.sensors.forEach((sensor: any) => {
            const isChannelSelectedInParentsList = selectedInParentsList.some(
              (selectedItem) =>
                sensor[`${selectedItem.channel}`] && field.channel !== selectedItem.channel,
            );
            const isChannelSelectedInChildsList = selectedInChildsList.some(
              (selectedItem) =>
                sensor[`${selectedItem.channel}`] && field.channel !== selectedItem.channel,
            );
            if (!isChannelSelectedInParentsList && !isChannelSelectedInChildsList) {
              const entry = Object.entries(sensor)[0];
              const channel = {
                number_of_channel: entry[0],
                sensor_id: entry[1],
              };
              channels.push(channel);
            }
          });
        }

        return isAvailable;
      });

      return {
        devices,
        channels,
      };
    },
    [balanceGroupItemsData, getValues],
  );

  // Формирует список доступных для выбора балансовых групп
  const getBalanceGroupFieldAvailableData = useCallback(
    (field) => {
      let groups = [];
      groups = balanceGroupsData.filter((item: any) => {
        let isAvailable = true;

        // Определяем выбрана ли группа
        let isSelected = getValues('child_balance_groups').some(
          (childItem) => childItem.group?.uuid === item.uuid,
        );

        // Делаем группу недоступной если она уже выбрана.
        if (isSelected && item.uuid !== field?.group?.uuid) {
          isAvailable = false;
        }

        return isAvailable;
      });

      return groups;
    },
    [balanceGroupsData, getValues],
  );

  //Формирует данные для запроса
  const createSubmitData = (data: IBalanceGroupDetails) => {
    const { name, description, parent_items, child_items, child_balance_groups } = data;

    const parentItems = parent_items.map((parentItem) => ({
      device_id: parentItem.item.device_id,
      channel: parentItem.channel,
    }));

    const childItems = child_items.map((childItem) => ({
      device_id: childItem.item.device_id,
      channel: childItem.channel,
    }));

    const childBalanceGroups = child_balance_groups.map((childBalanceGroup) => ({
      balance_id: childBalanceGroup.group.uuid,
    }));

    let newData: IBalanceGroup = {
      name,
      description,
      parent_items: parentItems,
      child_items: [...childItems, ...childBalanceGroups],
    };

    return newData;
  };

  const onSubmit: SubmitHandler<IBalanceGroupDetails> = (formData) => {
    onFormSubmit(createSubmitData(formData));
  };

  // Добавляет родительское устройство
  const handleAddParentDeviceClick = () => {
    addParentDevice({
      item: null,
      channel: '',
      address: null,
    });
  };
  // Удаляет родительское устройство
  const handleRemoveParentDeviceClick = (index: number) => {
    removeParentDevice(index);
  };

  // Добавляет дочернее устройство
  const handleAddChildDeviceClick = () => {
    addChildDevice({
      item: null,
      channel: '',
      address: null,
    });
    setAddChildElementMenuAnchorEl(null);
  };
  // Удаляет дочернее устройство
  const handleRemoveChildDeviceClick = (index: number) => {
    removeChildDevice(index);
  };

  // Добавляет дочернюю балансовую группу
  const handleAddChildBalanceGroupClick = () => {
    addChildBalanceGroup({
      group: null,
    });
    setAddChildElementMenuAnchorEl(null);
    setIsBalanceGroupsVisible(true);
  };
  // Удаляет дочернюю баласнвую группу
  const handleRemoveChildBalanceGroupClick = (index: number) => {
    removeChildBalanceGroup(index);
  };

  // Сбрасывает поля "ID" и "Канал" при смене адреса
  const handleAddressChange = (
    value: string | null,
    fieldArrayName: 'parent_items' | 'child_items',
    fieldIndex: number,
  ) => {
    if (value && getValues(`${fieldArrayName}.${fieldIndex}`)?.item?.address !== value) {
      const items = getDeviceFieldAvailableData(
        getValues(`${fieldArrayName}.${fieldIndex}`),
      ).devices;
      if (items.length === 1) {
        setValue(`${fieldArrayName}.${fieldIndex}.item`, items[0]);
      } else {
        setValue(`${fieldArrayName}.${fieldIndex}.item`, null);
      }
      setValue(`${fieldArrayName}.${fieldIndex}.channel`, '');
    }
  };

  // Сбрасывает поле "Канал" при смене ID
  const handleIdChange = (fieldArrayName: 'parent_items' | 'child_items', fieldIndex: number) => {
    setValue(`${fieldArrayName}.${fieldIndex}.channel`, '');
  };

  const handleAddChildElementMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAddChildElementMenuAnchorEl(event.currentTarget);
  };
  const handleAddChildElementMenuClose = () => {
    setAddChildElementMenuAnchorEl(null);
  };

  const handleBalanceGroupsToggle = () => {
    setIsBalanceGroupsVisible((prev) => !prev);
  };

  if (isLoadingBalanceGroupItems || isLoadingBalanceGroups) {
    return <Spinner data-testid="loader" />;
  }

  if (balanceGroupItemsData?.detail || isErrorBalanceGroupItems || isErrorBalanceGroups) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные балансовой группы. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={4}
            sx={{
              marginBottom: 8,
            }}
          >
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>Название балансовой группы</FormLabel>
                <Controller
                  rules={{
                    required: 'Введите название балансовой группы',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputProps={{
                        'aria-label': 'Название',
                      }}
                      fullWidth
                      placeholder="Укажите название балансовой группы"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                  name="name"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>Описание</FormLabel>
                <Controller
                  render={({ field }) => (
                    <Input
                      inputProps={{
                        'aria-label': 'Описание',
                      }}
                      fullWidth
                      placeholder="Опишите балансовую группу"
                      {...field}
                    />
                  )}
                  name="description"
                  control={control}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={4}
            sx={(theme) => ({
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                left: `calc(50% + ${theme.spacing(2)})`,
                top: 0,
                bottom: 0,
                borderLeft: `1px solid ${theme.palette.background.stroke}`,
              },
            })}
          >
            <Grid item mobile={12} xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3" sx={{ fontSize: 17 }}>
                  Головные устройства
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  color="link"
                  onClick={handleAddParentDeviceClick}
                >
                  Добавить устройство
                </Button>
              </Box>
              {parentDevicesFields.map((field: any, index: number) => {
                const { devices, channels } = getDeviceFieldAvailableData(
                  getValues(`parent_items.${index}`),
                );

                return (
                  <BalanceGroupDeviceField
                    key={field.id}
                    fieldArrayName="parent_items"
                    fieldIndex={index}
                    devices={devices}
                    channels={channels}
                    addresses={addresses}
                    onRemove={handleRemoveParentDeviceClick}
                    onAddressChange={handleAddressChange}
                    onIdChange={handleIdChange}
                  />
                );
              })}
            </Grid>
            <Grid item mobile={12} xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3" sx={{ fontSize: 17 }}>
                  Устройства учета
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  color="link"
                  onClick={handleAddChildElementMenuClick}
                  endIcon={<MoreHorIcon fontSize="medium" />}
                >
                  Добавить
                </Button>
                <Menu
                  anchorEl={addChildElementMenuAnchorEl}
                  open={addChildElementMenuOpen}
                  onClose={handleAddChildElementMenuClose}
                >
                  <MenuItem key="add_device" onClick={handleAddChildDeviceClick}>
                    Добавить устройство
                  </MenuItem>
                  <MenuItem key="add_balance_group" onClick={handleAddChildBalanceGroupClick}>
                    Добавить балансовую группу
                  </MenuItem>
                </Menu>
              </Box>
              {childDevicesFields.map((field: any, index: number) => {
                const { devices, channels } = getDeviceFieldAvailableData(
                  getValues(`child_items.${index}`),
                );

                return (
                  <BalanceGroupDeviceField
                    key={field.id}
                    fieldArrayName="child_items"
                    fieldIndex={index}
                    devices={devices}
                    channels={channels}
                    addresses={addresses}
                    onRemove={handleRemoveChildDeviceClick}
                    onAddressChange={handleAddressChange}
                    onIdChange={handleIdChange}
                  />
                );
              })}
              {Array.isArray(childBalanceGroupsFields) && childBalanceGroupsFields.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ display: 'flex', alignItems: 'center', marginTop: 2, cursor: 'pointer' }}
                    onClick={handleBalanceGroupsToggle}
                  >
                    <Typography variant="h3" sx={{ fontSize: 17 }}>
                      Балансовые группы
                    </Typography>
                    <ArrowDownIcon
                      fontSize="small"
                      sx={{ transform: isBalanceGroupsVisible ? 'rotate(180deg)' : 'none' }}
                    />
                  </Stack>
                  {isBalanceGroupsVisible &&
                    childBalanceGroupsFields.map((field: any, index: number) => {
                      const groups = getBalanceGroupFieldAvailableData(
                        getValues(`child_balance_groups.${index}`),
                      );

                      return (
                        <BalanceGroupBalanceGroupField
                          key={field.id}
                          fieldArrayName="child_balance_groups"
                          fieldIndex={index}
                          groups={groups}
                          onRemove={handleRemoveChildBalanceGroupClick}
                        />
                      );
                    })}
                </>
              )}
            </Grid>
          </Grid>
          <FormButtons isLoading={isLoading} />
        </form>
      </FormProvider>
    </>
  );
};

export default BalanceGroupForm;
