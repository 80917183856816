import { useQuery } from 'react-query';

import { GET_TEMPLATE_ID } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchGetShablonId = (id: string) => {
  const {
    isLoading: isLoadingdataShablon,
    isError: isErrordataShablon,
    data: dataShablonId,
  } = useQuery(['saveGeneratorForm', id], () => fetchData(GET_TEMPLATE_ID(id)));

  return {
    isLoadingdataShablon,
    isErrordataShablon,
    dataShablonId,
  };
};

export default useFetchGetShablonId;
