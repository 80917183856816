import { useQuery } from 'react-query';

import { NOTIFICATIONS_TEMPLATE_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchNotificationsTemplateTypes = () => {
  return useQuery('notificationsTemplateTypesData', () => fetchData(NOTIFICATIONS_TEMPLATE_TYPES_URL));
};

export default useFetchNotificationsTemplateTypes;
