import { useQuery } from 'react-query';

import { GET_TREE_CHILDS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchObjectsTree = (node: Record<"id" | "type", string> | null, enabled: boolean, onSuccess?: (data: any) => void) => {
  const id = node?.id || '';
  const type = node?.type || '';

  return useQuery(
    ['objectsTreeData', id, type],
    () => fetchData(GET_TREE_CHILDS_URL(id, type)),
    {
      enabled,
      onSuccess,
    },
  );
};

export default useFetchObjectsTree;
