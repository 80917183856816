import { useQuery } from 'react-query';

import { PERIMETER5DEVICE_CONNECTION_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchPerimeter5ConnectionTypes = () => {
  return useQuery('Perimeter5ConnectionTypesData',
  () => fetchData(PERIMETER5DEVICE_CONNECTION_TYPES_URL));
}

export default useFetchPerimeter5ConnectionTypes;
