import { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import {
  Box,
  Card,
  CardContent,
  Fade,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

import ShapeIcon from '../../components/icons/ShapeIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { CHANGE_FAVORITE_CUSTOM_REPORT } from '../../constants';
import { IToggleFavoriteProps, ICustomReportTemplate, IUser } from '../../types';
import fetchData from '../../utils/fetchData';
import useDebounce from '../../utils/hooks/useDebounce';
import sendMetrik from '../../utils/sendMetrik';
import translitRuEn from '../../utils/translitRuEn';

import useFetchCustomReportShablons from './hooks/useFetchCustomReportShablons';

const ReportsAsideFavorites = ({ searchTemplate }: { searchTemplate: string }) => {
  const { dataCustomReportShablons, isLoading } = useFetchCustomReportShablons();

  const queryClient = useQueryClient();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const navigate = useNavigate();

  const debounceSearchTemplate = useDebounce(searchTemplate);

  const anchorElRef = useRef<{ [key: string]: HTMLElement | null }>({});

  const [, updateState] = useState<any>();
  const forceUpdate = () => updateState({});

  const open = Boolean(anchorElRef);

  const favoriteTamplates =
    dataCustomReportShablons
      ?.filter((template: { is_favorite: boolean }) => template.is_favorite === true)
      .filter((template: { name: string }) =>
        template?.name?.toLowerCase().includes(debounceSearchTemplate?.toLowerCase()),
      ) || [];

  const toggleFavoriteMutation = useMutation(
    (dataFavorite: IToggleFavoriteProps) => {
      return fetchData(CHANGE_FAVORITE_CUSTOM_REPORT(dataFavorite.uuid, dataFavorite.is_favorite), {
        method: 'PUT',
      });
    },
    {
      onSuccess: () => {
        // Обновляем кэш данных после успешного выполнения мутации
        queryClient.invalidateQueries(['saveGeneratorForm', 'customReportData']);
      },
    },
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>, template: ICustomReportTemplate) => {
    anchorElRef.current[template.uuid] = event.currentTarget;
    forceUpdate();
  };

  const handleClose = (template: ICustomReportTemplate) => {
    anchorElRef.current[template.uuid] = null;
    forceUpdate();
  };

  const handleDeleteTemplate = (template: ICustomReportTemplate) => {
    const newIsFavorite = !template.is_favorite;
    template.is_favorite = newIsFavorite;
    toggleFavoriteMutation.mutate(template);
    handleClose(template);
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      {Array.isArray(favoriteTamplates) && favoriteTamplates?.length > 0 ? (
        favoriteTamplates?.map((template: ICustomReportTemplate) => {
          const isMenuOpen = Boolean(anchorElRef.current[template.uuid]);

          return (
            <Card
              key={template?.uuid}
              sx={{ mb: 2 }}
              style={{
                borderRadius: 16,
                maxWidth: '280px',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '16px 10px 16px 24px',
                }}
              >
                <Stack sx={{ flexDirection: 'row' }}>
                  <Box>
                    <Stack sx={{ flexDirection: 'row', mb: 1, alignItems: 'center' }}>
                      <Link
                        style={{ color: '#1D2023', cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/reports/customReport/${template.uuid}`);
                          //отправка метрики
                          sendMetrik(
                            'vntVdknl',
                            'otchety',
                            'element_click',
                            translitRuEn(template.name),
                            null,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/reports',
                            template.uuid,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                      >
                        <Typography variant="h4" fontWeight={500} color="#626C77">
                          {template?.name}
                        </Typography>
                      </Link>
                      <Typography
                        ml={1}
                        bgcolor={template?.device_type?.uspd ? '#6384E0' : '#F37F19'}
                        variant="h3"
                        color="#FFFFFF"
                        fontSize="12px"
                        maxHeight="24px"
                        sx={{ padding: '0px 4px', borderRadius: '4px' }}
                      >
                        {Object.values(template.device_type)}
                      </Typography>
                    </Stack>
                    <Stack sx={{ flexDirection: 'row' }}>
                      <Typography variant="h3" fontSize="12px" color="#626C77">
                        Тип шаблона:{' '}
                      </Typography>
                      <Typography fontSize="12px" color="textSecondary" ml={0.5}>
                        {template.shablon_type === 'report_device'
                          ? 'Отчет по устройству'
                          : 'Отчет по группе'}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <IconButton
                  size="extraSmall"
                  sx={{ padding: 0, height: '4px' }}
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(event) => handleClick(event, template)}
                >
                  <ShapeIcon fontSize="small" />
                </IconButton>
                <Menu
                  sx={{ mt: 1 }}
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorElRef.current[template.uuid] || undefined}
                  open={isMenuOpen}
                  onClose={() => handleClose(template)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleDeleteTemplate(template)}>Удалить шаблон</MenuItem>
                </Menu>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Message>Избранные отчеты отсутствуют</Message>
      )}
    </>
  );
};

export default ReportsAsideFavorites;
