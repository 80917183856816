import { useState, useEffect } from 'react';

import { useQueryClient } from 'react-query';

import { useForm, Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { NUMBER_REGEX } from '../../../constants';
import { IUser } from '../../../types';
import useDebounce from '../../../utils/hooks/useDebounce';
import sendMetrik from '../../../utils/sendMetrik';

type IDevicesReportsSegmentFilterProps = {
  onChange: (segmentLowerThreshold: string, segmentUpperThreshold: string) => void;
};

type FormValues = {
  segment_lower_threshold: string;
  segment_upper_threshold: string;
};

const DevicesReportsSegmentFilter = (props: IDevicesReportsSegmentFilterProps) => {
  const { onChange } = props;

  const [segmentLowerThreshold, setSegmentLowerThreshold] = useState('');
  const [segmentUpperThreshold, setSegmentUpperThreshold] = useState('');
  const debouncedSegmentLowerThreshold = useDebounce(segmentLowerThreshold);
  const debouncedSegmentUpperThreshold = useDebounce(segmentUpperThreshold);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const methods = useForm<FormValues>({
    defaultValues: {
      segment_lower_threshold: '',
      segment_upper_threshold: '',
    },
  });
  const { control, formState, trigger } = methods;

  useEffect(() => {
    trigger();
  }, [debouncedSegmentLowerThreshold, debouncedSegmentUpperThreshold, trigger]);

  useEffect(() => {
    const { errors } = formState;
    if (errors.segment_lower_threshold || errors.segment_upper_threshold) {
      onChange('', '');
    } else {
      onChange(debouncedSegmentLowerThreshold, debouncedSegmentUpperThreshold);
    }
  }, [debouncedSegmentLowerThreshold, debouncedSegmentUpperThreshold, formState, onChange]);

  return (
    <Grid container columnSpacing={4}>
      <Grid item mobile={12} xs={4}>
        <FormControl>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                value={segmentLowerThreshold}
                fullWidth
                placeholder="Нижний порог сегмента отклонений"
                error={!!formState.errors.segment_lower_threshold?.message}
                helperText={formState.errors.segment_lower_threshold?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setSegmentLowerThreshold(e.target.value);
                  sendMetrik(
                    'vntVdknl',
                    'otchety_o_narushenii_ustavok',
                    'form_change',
                    'nizhnii_porog',
                    e.target.value,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/reports',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
              />
            )}
            name="segment_lower_threshold"
            control={control}
            rules={{
              max: {
                value: debouncedSegmentUpperThreshold,
                message: 'Значение должно быть меньше чем верхний порог',
              },
              pattern: {
                value: NUMBER_REGEX,
                message: 'Введите число',
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item mobile={12} xs={4}>
        <FormControl>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                value={segmentUpperThreshold}
                fullWidth
                placeholder="Верхний порог сегмента отклонений"
                error={!!formState.errors.segment_upper_threshold?.message}
                helperText={formState.errors.segment_upper_threshold?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setSegmentUpperThreshold(e.target.value);
                  sendMetrik(
                    'vntVdknl',
                    'otchety_o_narushenii_ustavok',
                    'form_change',
                    'vehnii_porog',
                    e.target.value,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/reports',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
              />
            )}
            name="segment_upper_threshold"
            control={control}
            rules={{
              min: {
                value: debouncedSegmentLowerThreshold,
                message: 'Значение должно быть больше чем нижний порог',
              },
              pattern: {
                value: NUMBER_REGEX,
                message: 'Введите число',
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DevicesReportsSegmentFilter;
