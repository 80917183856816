import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LOGOUT_URL } from '../../constants';
import noAccessBg from '../../images/noaccess.svg';

const NoAccessWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: `url(${noAccessBg}) no-repeat 50% 50%`,
  '.noaccess-inner': {
    width: '335px',
    marginLeft: '203px',
  },
}));

const NoAccess = () => {
  const handleLogoutClick = () => {
    window.location.replace(LOGOUT_URL);
  };

  return (
    <NoAccessWrapper>
      <Container>
        <Box className="noaccess-inner">
          <Typography variant="h2" gutterBottom>
            Что-то пошло не так…
          </Typography>
          <Typography paragraph>У вас нет доступа к данной странице</Typography>
          <Button fullWidth onClick={handleLogoutClick}>
            Сменить пользователя
          </Button>
        </Box>
      </Container>
    </NoAccessWrapper>
  );
};

export default NoAccess;
