import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { Bar, Cell } from 'recharts';

import getTimezoneTimestamp from '../../../utils/getTimezoneTimestamp';
import useFetchDashboard from '../hooks/useFetchDashboard';

import { useTooltipContext } from './CustomTooltip';
import { DashboardsBarChart, tooltipStyle, tooltipContent } from './DashboardsBarChart';
import DashboardsPieChart from './DashboardsPieChart';

const GroupDashboardsWidgets = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let deviceIdParam = searchParams.get('device_id') || '';
  let groupNameParam = searchParams.get('group_name') || null;
  let excludeDeviceIdsParam = searchParams.get('exc_device_ids');
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';

  const [newBar, setNewBar] = useState([]);

  const { data, isFetching } = useFetchDashboard(
    deviceTypeParam,
    dashboardTypeParam,
    dateFromParam,
    dateToParam,
    deviceIdParam,
    groupNameParam,
    excludeDeviceIdsParam,
    temperatureMeasureParam,
  );

  const PIE_COLORS = ['#007CFF', '#EA1F49', '#FAC031', '#00D9BC'];

  const { openTooltip, closeTooltip } = useTooltipContext();

  useEffect(() => {
    if (Array.isArray(data?.group_consumption_detail)) {
      let bar = data?.group_consumption_detail.reduce((acc: any, item: any) => {
        let accItem = acc.find((_item: any) => _item.group_name === item.group_name);

        if (accItem) {
          accItem.data.push({
            date: getTimezoneTimestamp(item.date, data.timezone),
            consumption: item.consumption,
          });
        } else {
          acc.push({
            group_name: item.group_name,
            data: [
              {
                date: getTimezoneTimestamp(item.date, data.timezone),
                consumption: item.consumption,
              },
            ],
          });
        }

        return acc;
      }, []);
      setNewBar(bar);
    }
  }, [data?.group_consumption_detail, data.timezone]);

  return (
    <>
      {data &&
        data?.total_sum !== null &&
        data?.group_consumption_detail &&
        data?.group_consumption &&
        !isFetching && (
          <>
            <Box sx={{ marginTop: '24px' }}>
              <Box sx={{ display: 'flex', marginBottom: '32px' }}>
                <Box
                  className="total-consumption"
                  sx={{
                    padding: '16px 0px 20px 32px',
                    width: '100%',
                    flex: '1 1 auto',
                    borderRadius: '24px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500,
                    }}
                  >
                    Потребление:
                  </Typography>
                  <Typography
                    sx={{
                      color: '#969FA8',
                      fontSize: '12px',
                      lineHeight: '16px',
                      margin: '16px 0 16px',
                    }}
                  >
                    Общее потребление за период
                  </Typography>
                  <Typography
                    sx={{
                      color: '#E04A17',
                      fontSize: '44px',
                      fontWeight: 1000,
                      lineHeight: '44px',
                    }}
                  >
                    {data?.total_sum} м³
                  </Typography>
                </Box>
                <Box
                  className="diagram-consumption"
                  sx={{
                    height: '150px',
                    width: '100%',
                    flex: '1 1 auto',
                    padding: '16px 0px 20px 32px',
                    borderRadius: '24px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                    margin: '0 48px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500,
                    }}
                  >
                    Потребление:
                  </Typography>
                  <DashboardsPieChart
                    data={data?.group_consumption}
                    name={'group_name'}
                    dataId={'consumption'}
                    pieCell={data?.group_consumption.map((entry: any, index: any) => (
                      <Cell key={`cell-${index}`} fill={PIE_COLORS[index]} />
                    ))}
                    payload={data?.group_consumption.map((item: any, index: any) => ({
                      id: item.group_name,
                      value: `${item.group_name} - ${Math.round(
                        (item.consumption * 100) / data?.total_sum,
                      )}%`,
                      color: PIE_COLORS[index],
                    }))}
                  ></DashboardsPieChart>
                </Box>
                <Box
                  className="group-consumption"
                  sx={{
                    padding: '16px 0px 24px 32px',
                    borderRadius: '24px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                    width: '100%',
                    flex: '1 1 auto',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '12px',
                      lineHeight: '15px',
                      fontWeight: 500,
                    }}
                    style={{ marginBottom: '12px' }}
                  >
                    Групповое потребление:
                  </Typography>
                  {data?.group_consumption.map((item: any, index: any) => (
                    <Typography key={index} sx={{ fontSize: '12px', color: '#969FA8' }}>
                      {item.group_name}
                      <span style={{ paddingLeft: '8px', fontWeight: 500 }}>
                        {item.consumption.toFixed(2)} м³
                      </span>
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: '32px',
                  padding: '16px 24px 30px',
                  borderRadius: '24px',
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: 500,
                    marginBottom: '30px',
                  }}
                >
                  График потребления (м³)
                </Typography>
                <DashboardsBarChart
                  data={newBar}
                  dataId="date"
                  payload={newBar?.map((item: any, index: any) => ({
                    id: item.group_name,
                    value: `${item.group_name}`,
                    color: PIE_COLORS[index],
                  }))}
                  dataBar={newBar.map((el: any, index: any) => (
                    <Bar
                      dataKey="consumption"
                      data={el.data}
                      key={el.group_name}
                      fill={PIE_COLORS[index]}
                      maxBarSize={8}
                      onMouseEnter={(e) =>
                        openTooltip({
                          content: tooltipContent(e),
                          style: tooltipStyle,
                        })
                      }
                      onMouseLeave={() => closeTooltip()}
                    ></Bar>
                  ))}
                ></DashboardsBarChart>
              </Box>
            </Box>
          </>
        )}
    </>
  );
};

export default GroupDashboardsWidgets;
