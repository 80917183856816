import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Badge from '../../components/Badge';
import IconBox from '../../components/IconBox';
import EggIcon from '../../components/icons/EggIcon';

type INotification = {
  name: string;
  deviceType: string;
  deviceTypeColor?: string;
  templateType: string;
  templateTypeColor?: string;
  templateTypeIcon?: ReactNode;
  deviceIds? : string;
  groupName: string;
  date?: string;
  actions?: ReactNode;
};

const Notification = (props: INotification) => {
  const { name, deviceType, deviceTypeColor, templateType, templateTypeColor, templateTypeIcon, deviceIds, groupName, date, actions } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          minHeight: 104,
          mb: 1,
          px: 3,
          py: 1.5,
          borderRadius: 4,
          boxShadow: 12,
        }}
      >
        <IconBox color={templateTypeColor}>
          {templateTypeIcon
            ? templateTypeIcon
            : <EggIcon />
          }
        </IconBox>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 0.5,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mr: 1,
                fontSize: 17,
              }}
            >
              {name}
            </Typography>
            <Badge color={deviceTypeColor}>{deviceType}</Badge>
          </Box>
          <Typography variant="body2">{templateType}</Typography>
          {deviceIds &&
            <Typography
              variant="body2"
              sx={{
                mt: 0.5,
                color: 'text.tertiary',
              }}
            >
              {deviceIds}
            </Typography>
          }
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography variant="body2">{groupName}</Typography>
        </Box>
        {date &&
          <Box>
            <Typography variant="body2">{date}</Typography>
          </Box>
        }
        {actions &&
          <Box>
            {actions}
          </Box>
        }
      </Box>
    </>
  );
};

export default Notification;
