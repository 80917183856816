import { useQuery } from 'react-query';

import { CURRENT_USER_DATA_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchUser = (enabled: boolean) => {
  return useQuery('userData', () => fetchData(CURRENT_USER_DATA_URL), {
    enabled: enabled
  });
};

export default useFetchUser;
