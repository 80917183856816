import { useQuery } from 'react-query';

import { GET_CUSTOM_REPORT_CHECKBOXES } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchCustomReports = () => {
  const {
    isLoading,
    isError,
    data: dataCustomDevices,
  } = useQuery('dataCustomDevices', () => fetchData(GET_CUSTOM_REPORT_CHECKBOXES));

  return {
    isLoading,
    isError,
    dataCustomDevices,
  };
};

export default useFetchCustomReports;
