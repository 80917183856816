import React, { FocusEvent } from 'react';

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type IChipsFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  value: string[];
  className?: string;
  placeholder?: string;
  onChange: (value: any) => void;
  onBlur: () => void;
  onOpen?: () => void;
};

const ChipsField = React.forwardRef(
  <T, FreeSolo extends boolean | undefined>(
    props: IChipsFieldProps<T, true, true, FreeSolo>,
    ref: any,
  ) => {
    const { placeholder, className, value, onChange } = props;

    return (
      <Autocomplete
        {...props}
        className="chipsField"
        multiple
        clearOnBlur
        ChipProps={{
          size: 'small',
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={value?.length ? '' : placeholder} className={className} />
        )}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        {...(props.freeSolo
          ? {
              onBlur: (e: FocusEvent<HTMLInputElement>) => {
                const query = e.target.value?.trim();
                if (query && value.indexOf(query) === -1) {
                  onChange([...value, query]);
                }
              },
            }
          : {})}
      />
    );
  },
);

export default ChipsField;
