import { useQueryClient } from 'react-query';

import { useFormContext, useFieldArray, Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FLOAT_NUMBER_REGEX } from '../../constants';

import type { IDeviceProfiles } from '../../types';

const DeviceSettings = () => {
  const queryClient = useQueryClient();
  const deviceProfilesData: IDeviceProfiles | undefined =
    queryClient.getQueryData('deviceProfilesData');

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields: settingsFields } = useFieldArray({
    name: 'settings',
    control,
  });

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          mt: 2,
        }}
      >
        Уставки
      </Typography>
      <Grid container columnSpacing={4}>
        {settingsFields.map((settingsField: any, index: number) => (
          <Grid item mobile={12} xs={6} key={settingsField.id}>
            <FormControl>
              <Controller
                name={`settings.${index}.attributeValue`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={
                      deviceProfilesData?.attributes_mapping[settingsField.attributeName] ||
                      settingsField.attributeName
                    }
                    error={!!errors.settings?.[index]?.attributeValue}
                    helperText={errors.settings?.[index]?.attributeValue?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: FLOAT_NUMBER_REGEX,
                    message: 'Введите число',
                  },
                }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DeviceSettings;
