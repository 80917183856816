import { SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import SpinnerIcon from '../icons/SpinnerIcon';

const StyledSpinner = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: 8,
  "& svg": {
    animation: "1s linear infinite spinner",
  },
  "@keyframes spinner": {
    "100%": {
      transform: "rotate(360deg)"
    }
  },
});

const Spinner = (props: SvgIconProps) => {
  return (
    <StyledSpinner>
      <SpinnerIcon {...props} />
    </StyledSpinner>
  )
}

export default Spinner;
