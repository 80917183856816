import { useQuery } from 'react-query';

import { REPORT_DEVICE_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchReportDeviceTypes = () => {
  return useQuery('reportDeviceTypesData', () => fetchData(REPORT_DEVICE_TYPES_URL));
};

export default useFetchReportDeviceTypes;
