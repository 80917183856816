import { useEffect, useState } from 'react';

import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { useQueryClient, useMutation } from 'react-query';

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ArrowRightIcon from '../../components/icons/ArrowRightIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { GET_UPDATE_OBJECT_URL } from '../../constants';
import { IObject } from '../../types';
import fetchData from '../../utils/fetchData';

import useFetchObjectInfo from './hooks/useFetchObjectInfo';

type FormValues = {
  name?: string;
  description?: string;
  parent?: {
    id: string,
    name: string,
  };
}

const defaultValues = {
  name: "",
  description: "",
};

const EditObjectForm = () => {
  let { objectId = "" } = useParams();
  let [searchParams] = useSearchParams();
  let objectType = searchParams.get("type") || "";

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const objectsTreeData = queryClient.getQueryData('objectsTreeData');

  const {
    isLoading,
    isError,
    data
  } = useFetchObjectInfo(objectType, objectId, true);

  const dataMutation = useMutation((updatedObjectData: FormValues) => {
    return fetchData(GET_UPDATE_OBJECT_URL(objectType, objectId), {
      method: "PUT",
      body: updatedObjectData
    })
  }, {
    onSuccess: (response: any) => {
      queryClient.setQueryData(['objectData', objectId], response);
      queryClient.invalidateQueries('objectsTreeData');
      setSuccessSnackbarOpen(true);
    },
    onError: () => {
      setErrorSnackbarOpen(true);
    }
  });

  const [availableParentObjects, setAvailableParentObjects] = useState<IObject[]>([]);
  const [currentParentObject, setCurrentParentObject] = useState<IObject | null>(null);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    defaultValues
  });

  useEffect(() => {
    if (data && !data.detail && objectsTreeData) {
      let objectsList: IObject[] = [];
      let isCurrentCustomer = false;
      const getObject = ({id, name, type, objects}: IObject, isCurrent: boolean) => {
        if ((type === "CUSTOMER" && data.customerId === id) || (type === "ASSET" && isCurrent && id !== data.id)) {
          isCurrent = true;
          objectsList.push({
            id,
            name,
            type: type || "",
          });
        } else {
          isCurrent = false;
        }
        if (Array.isArray(objects)) {
          objects.forEach(object => getObject(object, isCurrent));
        }
      }
      if (Array.isArray(objectsTreeData)) {
        objectsTreeData.forEach((item: IObject) => getObject(item, isCurrentCustomer));
      }

      setAvailableParentObjects(objectsList);
      setCurrentParentObject(objectsList.filter(item => item.id === data?.parentId)[0])
    }
  }, [objectsTreeData, data, setValue]);

  useEffect(() => {
    setValue("name", data?.name || "");
    setValue("description", data?.description || "");
  }, [data, setValue]);

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const newObjectData = {
      name: formData.name,
      description: formData.description,
      parentId: formData.parent?.id,
    }

    dataMutation.mutate(newObjectData);
  }

  const handleSuccessSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbarOpen(false);
  };

  const handleErrorSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />
  }

  if (isError) {
    return <Message>Не получилось загрузить данные объекта. Попробуйте еще раз.</Message>
  }

  return (
    <>
      <Breadcrumbs separator={<ArrowRightIcon fontSize="small" />}>
        <Link component="button" onClick={() => navigate(-1)}>Назад</Link>
        <span>{data?.name}</span>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h1'>Редактирование</Typography>
        <Grid container columnSpacing={4}>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel>Название</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="name"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel>Описание</InputLabel>
              <Controller
                render={({ field }) => <Input {...field} />}
                name="description"
                control={control}
              />
            </FormControl>
          </Grid>
          {(objectType === "asset" && currentParentObject) &&
            <Grid item xs={6}>
              <FormControl>
                <InputLabel>Родительский объект</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      defaultValue={currentParentObject}
                      options={availableParentObjects}
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={params => <TextField {...params} {...field} />}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) => {
                        if (option.id === value.id) return true;

                        return false;
                      }}
                    />
                  )}
                  name="parent"
                  control={control}
                />
              </FormControl>
            </Grid>
          }
        </Grid>
        <Stack direction="row" spacing={2} sx={{
          mt: 3
        }}>
          <Button
            color="secondary"
            type="button"
            onClick={() => navigate(-1)}
          >Отменить</Button>
          <LoadingButton
            loading={dataMutation.isLoading}
            type="submit"
          >Сохранить</LoadingButton>
        </Stack>
      </form>

      <Snackbar open={successSnackbarOpen} onClose={handleSuccessSnackbarClose}>
        <Alert severity="success" action={false}>Объект обновлен</Alert>
      </Snackbar>
      <Snackbar open={errorSnackbarOpen} onClose={handleErrorSnackbarClose}>
        <Alert severity="error" action={false}>Объект не обновлен</Alert>
      </Snackbar>
    </>
  );
};

export default EditObjectForm;
