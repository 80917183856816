import { useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import ArrowLeftSidebarIcon from '../icons/ArrowLeftSidebarIcon';

type IMainWithAsideProps = {
  aside?: React.ReactNode;
  main?: React.ReactNode;
};

const MainWithAsideBase = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',

  '.aside': {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '328px',
    height: '100%',
    paddingTop: '50px',
    borderRadius: '0px 8px 8px 0px',
    transitionDuration: '500ms',
    boxShadow: theme.shadows[12],
  },
  '.aside-close': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0,
    width: '48px',
    height: '100%',
    paddingTop: '24px',
    borderRadius: '0px 8px 8px 0px',
    transitionDuration: '500ms',
    boxShadow: theme.shadows[12],
    '.MuiBox-root': {
      width: 0,
      opacity: 0,
    },
  },
  '.main': {
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: '32px 0',
  },
}));

const MainWithAside = (props: IMainWithAsideProps) => {
  const { aside, main } = props;
  const [open, setOpen] = useState<boolean>(true);

  const handleSidebar = () => {
    setOpen(!open);
  };

  const rotateArrow = () => {
    if (!open) {
      return { transform: 'rotate(180deg)', left: '11px' };
    }
  };

  return (
    <MainWithAsideBase sx={{ position: 'relative' }}>
      {open ? <Box className="aside">{aside}</Box> : <Box className="aside-close">{aside}</Box>}
      <ArrowLeftSidebarIcon
        sx={{ cursor: 'pointer', position: 'absolute', top: '22px', left: '280px' }}
        style={rotateArrow()}
        type="button"
        onClick={() => handleSidebar()}
      ></ArrowLeftSidebarIcon>
      <Box className="main">
        <Container>{main}</Container>
      </Box>
    </MainWithAsideBase>
  );
};

export default MainWithAside;
