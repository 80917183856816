import Params from "./Params";
import Results from "./Results";

const ReportsStandart = () => {
  return (
    <>
      <Params />
      <Results />
    </>
  );
};

export default ReportsStandart;
