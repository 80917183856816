import { useCallback, useEffect, useState, useRef } from 'react';

import { useQueryClient } from 'react-query';

import { IUser } from '../../types';

export type IWebsocket = {
  url: string;
  queryKey?: any;
  enabled?: boolean;
};

const useWebsocket = (props: IWebsocket) => {
  const { url, queryKey, enabled = true } = props;

  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(enabled);
  const [data, setData] = useState<any | undefined>(undefined);

  const ws = useRef<WebSocket | null>(null);

  // Получение данных сессии
  const userData: IUser | undefined = queryClient.getQueryData('userData');
  const envData: any = queryClient.getQueryData('envData');

  let wsUrl = '';
  if (envData?.APP_ENV === 'localhost') {
    wsUrl = `wss://dev.vodokanal.msk.mts.ru${url}`;
  }
  if (envData?.APP_ENV === 'dev') {
    wsUrl = `wss://dev.vodokanal.msk.mts.ru${url}`;
  }
  if (envData?.APP_ENV === 'ift') {
    wsUrl = `wss://stage.vodokanal.msk.mts.ru${url}`;
  }
  if (envData?.APP_ENV === 'prod') {
    wsUrl = `wss://vodokanal.mts.ru${url}`;
  }

  const handleOpen = useCallback(() => {
    if (ws.current) {
      ws.current.send(JSON.stringify({ userData }));
    }
  }, [userData]);

  const handleMessage = useCallback((event: any) => {
    const receivedData = JSON.parse(event.data);
    if (queryKey) {
      setData(receivedData);
      queryClient.setQueryData(queryKey, receivedData);
    }
    setIsLoading(false);
  }, [queryClient, queryKey]);

  const handleClose = (event: CloseEvent) => {
    console.log('WebSocket close: ', event);
    if (event.code === 4001) {
      window.location.assign('/');
    }
  }

  const handleError = (error: Event) => {
    console.log('WebSocket error', error);
    setIsLoading(false);
  }

  useEffect(() => {
    if (enabled && wsUrl) {
      ws.current = new WebSocket(wsUrl);
      ws.current.onopen = () => handleOpen;
      ws.current.onmessage = (event) => handleMessage(event);
      ws.current.onclose = (event) => handleClose(event);
      ws.current.onerror = (error) => handleError(error);
    } else {
      if (ws.current) {
        ws.current.close();
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [enabled, wsUrl, handleMessage, handleOpen]);

  return { isLoading, data };
};

export default useWebsocket;
