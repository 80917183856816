import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fillRule="evenodd" d="M4.68597 4.68597C3.3731 5.99884 3.27575 7.30684 3.08105 9.92282C3.03058 10.601 3 11.3005 3 12C3 12.6995 3.03058 13.399 3.08105 14.0772C3.27575 16.6932 3.3731 18.0012 4.68597 19.314C5.99884 20.6269 7.30684 20.7243 9.92282 20.9189C10.601 20.9694 11.3005 21 12 21C12.6995 21 13.399 20.9694 14.0772 20.9189C16.6932 20.7243 18.0012 20.6269 19.314 19.314C20.6269 18.0012 20.7243 16.6932 20.9189 14.0772C20.9694 13.399 21 12.6995 21 12C21 11.3005 20.9694 10.601 20.9189 9.92282C20.7243 7.30684 20.6269 5.99884 19.314 4.68597C18.0012 3.3731 16.6932 3.27575 14.0772 3.08105C13.399 3.03058 12.6995 3 12 3C11.3005 3 10.601 3.03058 9.92282 3.08105C7.30684 3.27575 5.99884 3.3731 4.68597 4.68597ZM13.0001 7.99887C13.0001 8.68859 12.4409 9.24773 11.7512 9.24773C11.0615 9.24773 10.5023 8.68859 10.5023 7.99887C10.5023 7.30914 11.0615 6.75 11.7512 6.75C12.4409 6.75 13.0001 7.30914 13.0001 7.99887ZM12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 13H10.5C9.94772 13 9.5 12.5523 9.5 12C9.5 11.4477 9.94772 11 10.5 11H12C12.2652 11 12.5196 11.1054 12.7071 11.2929Z" />
    </SvgIcon>
  );
};

export default InfoIcon;
