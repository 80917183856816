import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

class CustomString extends String {
  charAt(_: number): string {
    return this.valueOf();
  }
}

const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
const customWeekDays = weekDays.map((day) => new CustomString(day) as string);

export class CustomDateAdapter extends AdapterDateFns {
  getWeekdays = (): string[] => customWeekDays;
}
