import { useQuery } from 'react-query';

import { DASHBOARD_DEVICE_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchDashboardDeviceTypes = () => {
  return useQuery('reportDeviceTypesData', () => fetchData(DASHBOARD_DEVICE_TYPES_URL));
};

export default useFetchDashboardDeviceTypes;
