import { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { Box, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef } from '@mui/x-data-grid';
import { Cell, Bar } from 'recharts';

import QuestionIcon from '../../../components/icons/QuestionIcon';
import WidgetBox from '../../../components/WidgetBox';
import { IUser } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import getTimezoneTimestamp from '../../../utils/getTimezoneTimestamp';
import useFetchDashboard from '../hooks/useFetchDashboard';

import { useTooltipContext } from './CustomTooltip';
import { DashboardsBarChart, tooltipStyle, tooltipContent } from './DashboardsBarChart';
import DashboardsConsumptionTable from './DashboardsConsumptionTable';
import DashboardsLeadParam from './DashboardsLeadParam';
import DashboardsPieChart from './DashboardsPieChart';

const DevicesDashboardsWidgets = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let deviceIdParam = searchParams.get('device_id') || '';
  let groupNameParam = searchParams.get('group_name') || null;
  let excludeDeviceIdsParam = searchParams.get('exc_device_ids');
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const PIE_COLORS = ['#007CFF', '#EA1F49', '#FAC031', '#00D9BC'];

  const [colsData, setColsData] = useState<GridColDef[] | []>([]);
  const [newBar, setNewBar] = useState([]);

  interface ChannelConsumption {
    channel_number: number;
    id_channel: number | string;
    consumption: number;
  }

  const { openTooltip, closeTooltip } = useTooltipContext();

  const { data, isFetching } = useFetchDashboard(
    deviceTypeParam,
    dashboardTypeParam,
    dateFromParam,
    dateToParam,
    deviceIdParam,
    groupNameParam,
    excludeDeviceIdsParam,
    temperatureMeasureParam,
  );

  const defaultCol = useMemo(
    () => [{ field: 'empty_field', headerName: '', sortable: false, flex: 1 }],
    [],
  );

  useEffect(() => {
    if (Array.isArray(data?.channel_consumption) && data?.channel_consumption.length > 0) {
      const newCol = data?.channel_consumption
        .map((c: ChannelConsumption) => {
          return {
            field: `${c.channel_number}`,
            headerName: `Канал ${c.channel_number}`,
            sortable: false,
            flex: 1,
          };
        })
        .sort((a: any, b: any) => a.channel_number - b.channel_number);
      const cols: GridColDef[] = [...defaultCol, ...newCol];
      setColsData(cols);
    }
  }, [data?.channel_consumption, defaultCol]);

  const defaultRow = [
    {
      id: 1,
      empty_field: 'Серийный номер счетчика',
    },
    {
      id: 2,
      empty_field: 'Потребление, м³',
    },
  ];

  let newRowNumber: any = {};
  if (Array.isArray(data?.channel_consumption)) {
    data?.channel_consumption.forEach((r: ChannelConsumption) => {
      newRowNumber[`${r.channel_number}`] = r.id_channel;
    });
  }

  let newRowConsumption: any = {};
  if (Array.isArray(data?.channel_consumption)) {
    data?.channel_consumption.forEach((r: ChannelConsumption) => {
      newRowConsumption[`${r.channel_number}`] = r.consumption;
    });
  }

  let rowsFirst = { ...defaultRow[0], ...newRowNumber };
  let rowsSecond = { ...defaultRow[1], ...newRowConsumption };

  let rowsData = [rowsFirst, rowsSecond];

  useEffect(() => {
    if (Array.isArray(data?.channel_consumption_detail) && data?.timezone !== undefined) {
      let bar = data?.channel_consumption_detail
        .reduce((acc: any, item: any) => {
          let accItem = acc.find((_item: any) => _item.channel_number === item.channel_number);

          if (accItem) {
            accItem.data.push({
              date: getTimezoneTimestamp(item.date, data.timezone),
              consumption: item.consumption,
            });
          } else {
            acc.push({
              channel_number: item.channel_number,
              id_channel: item.id_channel,
              data: [
                {
                  date: getTimezoneTimestamp(item.date, data.timezone),
                  consumption: item.consumption,
                },
              ],
            });
          }

          return acc;
        }, [])
        .sort((a: any, b: any) => a.channel_number - b.channel_number);
      setNewBar(bar);
    }
  }, [data?.channel_consumption_detail, data?.timezone]);

  return (
    <>
      {data &&
        data?.total_sum !== null &&
        data?.last_values &&
        data?.channel_consumption_detail &&
        data?.channel_consumption &&
        !isFetching && (
          <>
            <Grid container spacing={4}>
              {(dashboardTypeParam !== 'real_time' || userData?.customerId === 'a38be1e0-971e-11ed-a002-f7d9bab86219') &&
                <>
                  <Grid item mobile={12} xs={6}>
                    <DashboardsLeadParam
                      title="Общее потребление за период"
                      value={`${data.total_sum?.toFixed(2)} м³`}
                      sx={{
                        height: '100%'
                      }}
                    />
                  </Grid>
                  <Grid item mobile={12} xs={6}>
                    <WidgetBox title="Поканальное потребление:" sx={{ height: 170 }}>
                      <DashboardsPieChart
                        data={data?.channel_consumption}
                        name={'id_channel'}
                        dataId={'consumption'}
                        pieCell={data?.channel_consumption.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={PIE_COLORS[index]} />
                        ))}
                        payload={data?.channel_consumption.map((item: any, index: any) => ({
                          id: item.channel_number,
                          value: `${item.id_channel} - ${Math.round(
                            (item.consumption * 100) / data?.total_sum,
                          )}%`,
                          color: PIE_COLORS[index],
                        }))}
                      />
                    </WidgetBox>
                  </Grid>
                </>
              }
              <Grid item mobile={12} xs={12}>
                <Grid container item mobile={12} xs={12} spacing={4}>
                  {data.last_values.map((item: any, index: number) => (
                    <Grid key={index} item mobile={12} xs={2.4}>
                      <DashboardsLeadParam
                        title={`Счетчик ${item.id_channel}`}
                        mainHeader={
                          <Tooltip
                            arrow
                            title={`Показание на ${convertTimestampToDate(
                              getTimezoneTimestamp(item.date, data.timezone),
                              'dd.MM.Y HH:mm:ss',
                            )}`}
                          >
                            <div data-testid="filter-tooltip">
                              <QuestionIcon fontSize="small" color="secondary" />
                            </div>
                          </Tooltip>
                        }
                        value={`${item.value?.toFixed(2)} м³`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item mobile={12} xs={12}>
                <WidgetBox title="Поканальное потребление:">
                  <DashboardsConsumptionTable cols={colsData} rows={rowsData} />
                </WidgetBox>
              </Grid>
              <Grid item mobile={12} xs={12}>
                <Box
                  sx={{
                    marginBottom: '32px',
                    padding: '16px 24px 30px',
                    borderRadius: '24px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500,
                      marginBottom: '30px',
                    }}
                  >
                    График потребления поканально (м³)
                  </Typography>
                  <DashboardsBarChart
                    data={newBar}
                    dataId="date"
                    payload={newBar?.map((item: any, index: any) => ({
                      id: item.channel_number,
                      value: `${item.id_channel}`,
                      color: PIE_COLORS[index],
                    }))}
                    dataBar={newBar.map((el: any, index: any) => (
                      <Bar
                        dataKey="consumption"
                        data={el.data}
                        key={el.channel_number}
                        fill={PIE_COLORS[index]}
                        maxBarSize={8}
                        onMouseEnter={(e) =>
                          openTooltip({
                            content: tooltipContent(e),
                            style: tooltipStyle,
                          })
                        }
                        onMouseLeave={() => closeTooltip()}
                      ></Bar>
                    ))}
                  ></DashboardsBarChart>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
    </>
  );
};

export default DevicesDashboardsWidgets;
