import { useState, useEffect, ChangeEvent } from 'react';

import { Link } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import { useForm, useWatch, FormProvider, Controller } from 'react-hook-form';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ChipsField from '../../components/ChipsField';
import Dialog from '../../components/Dialog';
import FormLabel from '../../components/FormLabel';
import BellIcon from '../../components/icons/BellIcon';
import DeleteIcon from '../../components/icons/DeleteIcon';
import EditIcon from '../../components/icons/EditIcon';
import PlusIcon from '../../components/icons/PlusIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import {
  UPDATE_NOTIFICATIONS_TEMPLATE_URL,
  GET_DELETE_NOTIFICATIONS_TEMPLATE_URL,
} from '../../constants';
import theme from '../../theme';
import { INotificationsTemplate } from '../../types';
import fetchData from '../../utils/fetchData';
import useFetchGroupsNames from '../../utils/hooks/useFetchGroupsNames';
import useSnackbars from '../../utils/hooks/useSnackbars';

import useFetchNotificationsDeviceTypes from './hooks/useFetchNotificationsDeviceTypes';
import useFetchNotificationsTemplates from './hooks/useFetchNotificationsTemplates';
import useFetchNotificationsTemplateTypes from './hooks/useFetchNotificationsTemplateTypes';
import Notification from './Notification';

type FormValues = {
  device_type: string;
  asset_id: string | null;
  device_ids: string[];
};

const NotificationsTemplatesList = () => {
  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbars();

  const [templates, setTemplates] = useState<INotificationsTemplate[]>([]);
  const [isGroupsListOpen, setIsGroupsListOpen] = useState(false);
  const [removingTemplateId, setRemovingTemplateId] = useState('');
  const [removingTemplateName, setRemovingTemplateName] = useState('');
  const [isRemoveTemplateDialogOpen, setIsRemoveTemplateDialogOpen] = useState(false);

  const defaultValues = {
    device_type: '',
    asset_id: null,
    device_ids: [],
  };
  const methods = useForm<FormValues>({
    defaultValues,
  });
  const { control } = methods;

  const deviceTypeWatch = useWatch({
    name: 'device_type',
    control,
  });
  const groupNameWatch = useWatch({
    name: 'asset_id',
    control,
  });
  const deviceIdsWatch = useWatch({
    name: 'device_ids',
    control,
  });

  const { isLoading, data } = useFetchNotificationsTemplates();

  const { isLoading: isLoadingNotificationsDeviceTypes, data: notificationsDeviceTypes } =
    useFetchNotificationsDeviceTypes();

  const { isFetched: isFetchedGroups, data: groupsData } =
    useFetchGroupsNames(isGroupsListOpen);

  const { isLoading: isLoadingNotificationsTemplateTypes, data: notificationsTemplateTypes } =
    useFetchNotificationsTemplateTypes();

  const notificationsTemplateDataEdit = useMutation(
    (templateData: INotificationsTemplate) => {
      return fetchData(UPDATE_NOTIFICATIONS_TEMPLATE_URL, {
        method: 'PUT',
        body: templateData,
      });
    },
    {
      onSuccess: (responseData) => {
        const { name, basic_template_name, device_type, is_active } = responseData;
        const deviceType = notificationsDeviceTypes.find(
          (item: Record<string, string>) => item.device_type_short === device_type,
        ).device_type_full;
        const templateType = notificationsTemplateTypes.find(
          (item: Record<string, string>) => item.name === basic_template_name,
        ).full_name;
        showSnackbar(
          <Alert severity="info">
            <AlertTitle>Шаблон {is_active ? "активирован" : "отключен"}</AlertTitle>
            {`${name} | ${templateType} | ${deviceType}`}
          </Alert>,
        );
        queryClient.invalidateQueries(['notificationsTemplatesData']);
        queryClient.setQueryData(['notificationsTemplatesData', responseData.uuid], responseData);
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Шаблон не обновлен</Alert>);
      },
    },
  );

  const notificationsTemplatesDataDelete = useMutation(
    (id: string) => {
      return fetchData(GET_DELETE_NOTIFICATIONS_TEMPLATE_URL(id), {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        handleRemoveTemplateDialogClose();
        queryClient.invalidateQueries('notificationsTemplatesData');
      },
    },
  );

  useEffect(() => {
    if (data) {
      let newTemplates = [...data];
      newTemplates.sort((a, b) => {
        if (a.uuid < b.uuid) return -1;
        if (a.uuid > b.uuid) return 1;

        return 0;
      })
      newTemplates = newTemplates.filter((item) => {
        let deviceTypeMatch = false;
        let groupNameMatch = true;
        let deviceIdsMatch = false;
        if (item.device_type === deviceTypeWatch || !deviceTypeWatch) {
          deviceTypeMatch = true;
        }
        if (!deviceIdsWatch.length) {
          deviceIdsMatch = true;
        } else {
          item.device_ids.forEach((id: string) => {
            if (deviceIdsWatch.indexOf(id) !== -1) {
              deviceIdsMatch = true;
            }
          });
        }

        if (deviceTypeMatch && groupNameMatch && deviceIdsMatch) return true;

        return false;
      });
      setTemplates(newTemplates);
    }
  }, [data, deviceTypeWatch, groupNameWatch, deviceIdsWatch]);

  const handleGroupsListOpen = () => {
    setIsGroupsListOpen(true);
  };

  const handleRemoveTemplate = (id: string | undefined, name: string) => {
    if (id) {
      setIsRemoveTemplateDialogOpen(true);
      setRemovingTemplateId(id);
      setRemovingTemplateName(name);
    }
  };

  const removeTemplate = () => {
    notificationsTemplatesDataDelete.mutate(removingTemplateId);
  };

  const handleRemoveTemplateDialogClose = () => {
    setIsRemoveTemplateDialogOpen(false);
  };

  const handleTemplateToggle = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    let template = data.find((item: INotificationsTemplate) => item.uuid === id);
    template = { ...template, is_active: e.target.checked };
    notificationsTemplateDataEdit.mutate(template);
  };

  if (isLoading || isLoadingNotificationsDeviceTypes || isLoadingNotificationsTemplateTypes) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            columnSpacing={4}
            sx={{
              mb: 4,
            }}
          >
            <Grid item mobile={12} xs={3}>
              <FormControl>
                <FormLabel>Тип устройства</FormLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Выберите тип устройства</span>;
                        }
                        const selectedItem = notificationsDeviceTypes.find(
                          (item: any) => item.device_type_short === selected,
                        );

                        return selectedItem.device_type_full;
                      }}
                      {...field}
                    >
                      <MenuItem value="">Все устройства</MenuItem>
                      {notificationsDeviceTypes.map((item: Record<string, string>) => (
                        <MenuItem key={item.device_type_short} value={item.device_type_short}>
                          {item.device_type_full}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="device_type"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={3}>
              <FormControl>
                <FormLabel>Группа устройств</FormLabel>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={groupsData}
                      loading={!isFetchedGroups}
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={(params) => (
                        <TextField placeholder="Выберите группу устройства" {...params} />
                      )}
                      onOpen={handleGroupsListOpen}
                    />
                  )}
                  name="asset_id"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={3}>
              <FormControl>
                <FormLabel>Серийный номер устройства</FormLabel>
                <Controller
                  render={({ field }) => (
                    <ChipsField
                      options={[]}
                      freeSolo
                      placeholder="Укажите серийный номер"
                      {...field}
                    />
                  )}
                  name="device_ids"
                  control={control}
                />
              </FormControl>
            </Grid>
            {!!data.length && (
              <Grid
                item
                mobile={12}
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <FormControl>
                  <Button
                    color="secondary"
                    component={Link}
                    to="./create"
                    startIcon={<PlusIcon sx={{ width: 16, height: 16 }} />}
                  >
                    Добавить шаблон
                  </Button>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {!data.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 6,
            pb: 10,
          }}
        >
          <Message icon={<BellIcon sx={{ color: 'primary.lightBlackberry' }} />}>
            Шаблоны уведомлений не созданы
          </Message>
          <Button
            color="secondary"
            component={Link}
            to="./create"
            startIcon={<PlusIcon sx={{ width: 16, height: 16 }} />}
          >
            Добавить шаблон
          </Button>
        </Box>
      )}
      {templates.length ? (
        templates.map(
          (
            { uuid, name, device_type, basic_template_name, device_ids, asset_id, is_active },
            index: number,
          ) => {
            const deviceType = notificationsDeviceTypes.find(
              (item: Record<string, string>) => item.device_type_short === device_type,
            ).device_type_full;
            const deviceTypeColor =
              device_type === 'uspd'
                ? theme.palette.primary.normalBlackberry
                : device_type === 'pressure_sensor'
                ? theme.palette.primary.lightPlum
                : undefined;
            const templateType = notificationsTemplateTypes.find(
              (item: Record<string, string>) => item.name === basic_template_name,
            ).full_name;

            return (
              <Grid container key={uuid}>
                <Grid item mobile={12} xs={11}>
                  <Notification
                    name={name}
                    deviceType={deviceType}
                    deviceTypeColor={deviceTypeColor}
                    templateType={templateType}
                    deviceIds={Array.isArray(device_ids) ? device_ids.join(', ') : ''}
                    groupName={asset_id ? asset_id : ''}
                    actions={
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          color="secondary"
                          size="extraSmall"
                          component={Link}
                          to={`./${uuid}/edit`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          size="extraSmall"
                          onClick={() => handleRemoveTemplate(uuid, name)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid
                  item
                  mobile={12}
                  xs={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={is_active}
                    onChange={(e) => handleTemplateToggle(e, uuid as string)}
                  />
                </Grid>
              </Grid>
            );
          },
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 6,
            pb: 10,
          }}
        >
          <Message icon={<BellIcon sx={{ color: 'primary.lightBlackberry' }} />}>
            Шаблоны уведомлений не найдены
          </Message>
        </Box>
      )}

      <Dialog
        busy={notificationsTemplatesDataDelete.isLoading}
        maxWidth="md"
        heading="Удалить шаблон"
        actionButtonText="Удалить"
        open={isRemoveTemplateDialogOpen}
        hideCloseButton={true}
        onAction={removeTemplate}
        onClose={handleRemoveTemplateDialogClose}
      >
        Вы уверены, что хотите удалить шаблон “{removingTemplateName}”?
      </Dialog>
    </>
  );
};

export default NotificationsTemplatesList;
