import { Outlet } from 'react-router-dom';

import Main from "../../components/Layout/Main";

const DashboardsPage = () => {
  return (
    <Main>
      <Outlet />
    </Main>
  );
};

export default DashboardsPage;
