import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Layout from '../components/Layout';
import Main from '../components/Layout/Main';
import useAppContext from '../store/hooks/useAppContext';
import { IUser } from '../types';
import useRouteMetrik from '../utils/hooks/useRouteMetrik';
import sendMetrik from '../utils/sendMetrik';

import Admin from './admin';
import BalanceGroupsPage from './balanceGroups';
import BalanceGroupsList from './balanceGroups/BalanceGroupsList';
import CreateBalanceGroup from './balanceGroups/CreateBalanceGroup';
import EditBalanceGroup from './balanceGroups/EditBalanceGroup';
import DashboardsPage from './dashboards';
import Dashboards from './dashboards/Dashboards';
import HeatingNetworksPage from './heatingNetworks';
import Home from './home';
import LandingPage from './landing';
import Map from './map';
import NoAccess from './noaccess';
import NoRights from './norights';
import NotificationsPage from './notifications';
import CreateNotificationsTemplate from './notifications/CreateNotificationsTemplate';
import EditNotificationsTemplate from './notifications/EditNotificationsTemplate';
import Notifications from './notifications/Notifications';
// import NotificationsList from './notifications/NotificationsList';
import NotificationsTemplatesList from './notifications/NotificationsTemplatesList';
import Objects from './objects';
import DeviceInfo from './objects/DeviceInfo';
import EditDeviceForm from './objects/EditDeviceForm';
import EditObjectForm from './objects/EditObjectForm';
import ObjectDetails from './objects/ObjectDetails';
import ReportsPage from './reports';
import ReportsCustom from './reports/custom-reports';
import CreateCustomReport from './reports/custom-reports/CreateCustomReport';
import CustomReport from './reports/custom-reports/CustomReport';
import EditCustomReport from './reports/custom-reports/EditCustomReport';
import Reports from './reports/Reports';
import ReportsSpecial from './reports/special-reports';
import ReportsStandart from './reports/standart-reports';
import BalanceGroupReportDetails from './reports/standart-reports/BalanceGroupReportDetails';
import ReportDetails from './reports/standart-reports/ReportDetails';
import UsersList from './users/components/UsersList/UsersList';
import UserCreatePage from './users/routes/create/UserCreatePage';
import UsersPage from './users/UsersPage';

const ClosedRoute = () => {
  const { userRights } = useAppContext();

  if (userRights && !userRights.isAdmin) {
    return <Navigate to="/norights" replace />;
  }

  return <Outlet />;
};

const Pages = () => {
  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  useRouteMetrik();

  if (userData) {
    sendMetrik(
      'vntLogin',
      'avtorizaciya',
      'confirmed',
      'uspeshnaya_avtorizaciya',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/',
      null,
      null,
      null,
      'conversions',
      userData?.profile_type,
      'web',
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/teplo" element={<HeatingNetworksPage />} />
        <Route path="/map" element={<Map />} />
        <Route path="objects" element={<Objects />}>
          <Route path=":objectId" element={<ObjectDetails />} />
          <Route path=":objectId/edit" element={<EditObjectForm />} />
          <Route path=":objectId/devices/:deviceId" element={<DeviceInfo />} />
          <Route path=":objectId/devices/:deviceId/edit" element={<EditDeviceForm />} />
        </Route>
        <Route path="/reports" element={<ReportsPage />}>
          <Route element={<Reports />}>
            <Route index element={<ReportsStandart />} />
            <Route path="/reports/special" element={<ReportsSpecial />} />
            <Route path="/reports/custom" element={<ReportsCustom />} />
          </Route>
          <Route
            element={
              <Main>
                <Outlet />
              </Main>
            }
          >
            <Route path="/reports/:reportIds" element={<ReportDetails />} />
            <Route
              path="/reports/balance-group/:balanceGroupId"
              element={<BalanceGroupReportDetails />}
            />
          </Route>
          <Route path="/reports/customReportCreate" element={<CreateCustomReport />} />
          <Route path=":shablonId/customReportEdit" element={<EditCustomReport />} />
          <Route path="/reports/customReport/:customId" element={<CustomReport />} />
        </Route>
        <Route path="/dashboards" element={<DashboardsPage />}>
          <Route index element={<Dashboards />} />
        </Route>
        <Route path="notifications" element={<NotificationsPage />}>
          <Route element={<Notifications />}>
            {/* <Route index element={<NotificationsList />} /> */}
            <Route index element={<NotificationsTemplatesList />} />
          </Route>
          <Route path="create" element={<CreateNotificationsTemplate />} />
          <Route path=":templateId/edit" element={<EditNotificationsTemplate />} />
        </Route>
        <Route path="balance-groups" element={<BalanceGroupsPage />}>
          <Route index element={<BalanceGroupsList />} />
          <Route path="create" element={<CreateBalanceGroup />} />
          <Route path=":balanceGroupId/edit" element={<EditBalanceGroup />} />
        </Route>
        <Route path="/noaccess" element={<NoAccess />} />
        <Route path="/norights" element={<NoRights />} />
        <Route element={<ClosedRoute />}>
          <Route path="users" element={<UsersPage />}>
            <Route index element={<UsersList />} />
            <Route path="create" element={<UserCreatePage />} />
          </Route>
          <Route path="/admin" element={<Admin />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Pages;
