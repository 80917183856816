import { useEffect, useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import { Alert, Box, Breadcrumbs, Link, Typography } from '@mui/material';

import { CREATE_CUSTOM_REPORT_TEMPLATE } from '../../../constants';
import { IBodylAtrCheckboxes } from '../../../types';
import fetchData from '../../../utils/fetchData';
import useSnackbars from '../../../utils/hooks/useSnackbars';
import useFetchCustomReportShablons from '../hooks/useFetchCustomReportShablons';
import resetCheckboxesForm from '../hooks/useResetCheckboxesForm';

import ReportsCustomParams from './Params';

const CreateCustomReport = () => {
  const { dataCustomReportShablons } = useFetchCustomReportShablons();

  // чекбоксы в шапке отчета
  const initialHeaderAtrCheckboxes: any[] = [
    { name: 'client', label: 'Клиент', checked: false },
    { name: 'cl_model', label: 'Модель', checked: false },
    { name: 'sensor_id', label: 'ID счетчика', checked: false },
    { name: 'id', label: 'ID датчика', checked: false },
    { name: 'date', label: 'Период', checked: false },
    { name: 'asset', label: 'Название группы', checked: false },
    { name: 'additional_info', label: 'Описание группы', checked: false },
  ];

  const [isHeaderTextEnabled, setIsHeaderTextEnabled] = useState(false); // сост-ие чекбокса текстовое поле в шапке отчета
  const [settingFields, setSettingFields] = useState([{ name: '', value: '' }]); // поля название отчета и значение отчета в настройках
  const [numFieldsCreated, setNumFieldsCreated] = useState(1);

  const [headerAtrCheckboxes, setHeaderAtrCheckboxes] = useState<any>(initialHeaderAtrCheckboxes);

  // состояние выбора типа устройства в настройках отчета
  const [selectedDevice, setSelectedDevice] = useState('');

  // состояние выбора типа шаблона в настройках отчета
  const [selectedTemplate, setSelectedTemplate] = useState('');

  // состояние текста названия отчета в настройках отчета
  const [reportTitleText, setReportTitleText] = useState('');

  // состояние текста описания отчета в настройках отчета
  const [reportDescriptionText, setReportDescriptionText] = useState('');

  // объект для атрибутов (чекбоксов) в теле отчета
  const initialBodylAtrCheckboxes = {
    attr: {
      columnAtrNames: [],
      columnAtrFilters: [],
      columnAllChecked: false,
    },
  };

  // состояние для общих атрибутов в теле отчета
  const [bodyAtrCheckboxes, setBodyAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для пользовательских атрибутов в теле отчета
  const [bodyClientAtrCheckboxes, setBodyClientAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для поканальных атрибутов(УСПД) в теле отчета
  const [bodyChanneUspdlAtrCheckboxes, setBodyChannelUspdAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для поканальных атрибутов(Датчик давления) в теле отчета
  const [bodyChanneSenslAtrCheckboxes, setBodyChannelSensAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  const [selectDeviceError, setSelectDeviceError] = useState(false); // ошибка при не заполнении типа устройства
  const [selectTamplateError, setSelectTamplateError] = useState(false); // ошибка при не заполнении типа устройства
  const [reportTitleTextError, setReportTitleTextError] = useState(false); // ошибка при не заполнении названия отчета
  const [headerDateError, setHeaderDateError] = useState(false); // ошибка при не заполнении периода в шапке

  // сохранение формы

  const getCheckedAttributes = (dataCheckboxess: any) => {
    const columns =
      dataCheckboxess &&
      dataCheckboxess.attr.columnAtrNames
        .filter((attr: any) => attr.checked)
        .map((attr: any) => attr.name);

    const filters =
      dataCheckboxess &&
      dataCheckboxess.attr.columnAtrFilters
        .filter((attr: any) => attr.checked)
        .map((attr: any) => attr.name);

    return { columns, filters };
  };

  const common = getCheckedAttributes(bodyAtrCheckboxes);
  const client = getCheckedAttributes(bodyClientAtrCheckboxes);
  const channel_setting_uspd = getCheckedAttributes(bodyChanneUspdlAtrCheckboxes);
  const channel_setting_pressure_sensor = getCheckedAttributes(bodyChanneSenslAtrCheckboxes);

  const [headerFields, setHeaderFields] = useState<[string]>(['']);

  useEffect(() => {
    if (headerAtrCheckboxes && Array.isArray(headerAtrCheckboxes)) {
      const selectedHeaderFields: any = headerAtrCheckboxes
        .filter((item) => item.checked)
        .map((item) => item.name);
      setHeaderFields(selectedHeaderFields);
    } else {
      setHeaderFields(['']);
    }
  }, [headerAtrCheckboxes]);

  const savedForm: any = {
    device_type: selectedDevice,
    shablon_type: selectedTemplate,
    name: reportTitleText,
    description: reportDescriptionText,
    common,
    client,
    channel_setting_uspd,
    channel_setting_pressure_sensor,
    header_fields: headerFields,
    header_text_fields: settingFields,
  };

  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const saveFormMutation = useMutation(
    (data) => {
      return fetchData(CREATE_CUSTOM_REPORT_TEMPLATE, {
        method: 'POST',
        body: data,
      });
    },
    {
      onSuccess: () => {
        if (selectedDevice && selectedTemplate && reportTitleText) {
          showSnackbar(<Alert severity="success">Шаблон отчета создан</Alert>);
          queryClient.invalidateQueries('saveGeneratorForm', {
            refetchInactive: true,
          });
          navigate(`/reports/custom`);
        }
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Шаблон отчета не создан</Alert>);
      },
    },
  );

  const onSubmit: (event: React.FormEvent<HTMLFormElement>) => void = (event) => {
    let errorDeviceElement = document.getElementById('device-error');
    let errorTamplate = document.getElementById('template-error');
    let errorTitle = document.getElementById('title-error');
    let errorHeaderDate = document.getElementById('header-fields');

    event.preventDefault();

    if (!selectedDevice) {
      event.preventDefault();
      setSelectDeviceError(true);
      errorDeviceElement?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не создан</Alert>);
    } else if (!selectedTemplate) {
      event.preventDefault();
      setSelectTamplateError(true);
      errorTamplate?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не создан</Alert>);
    } else if (!reportTitleText) {
      event.preventDefault();
      setReportTitleTextError(true);
      errorTitle?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не создан</Alert>);
    } else if (!headerFields?.includes('date')) {
      event.preventDefault();
      setHeaderDateError(true);
      errorHeaderDate?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не создан</Alert>);
    } else if (
      dataCustomReportShablons?.some(
        (template: { name: string }) =>
          template.name.toLowerCase() === savedForm.name.toLowerCase(),
      )
    ) {
      showSnackbar(<Alert severity="error">Шаблон с таким названием уже существует</Alert>);
    } else {
      setSelectDeviceError(false);
      setSelectTamplateError(false);
      setReportTitleTextError(false);
      setHeaderDateError(false);
      setSelectedDevice('');
      setSelectedTemplate('');
      setReportTitleText('');
      setReportDescriptionText('');
      setNumFieldsCreated(1);
      setSettingFields([{ name: '', value: '' }]);
      setIsHeaderTextEnabled(false);
      setHeaderAtrCheckboxes(initialHeaderAtrCheckboxes);
      resetCheckboxesForm(bodyAtrCheckboxes, setBodyAtrCheckboxes);
      resetCheckboxesForm(bodyClientAtrCheckboxes, setBodyClientAtrCheckboxes);
      resetCheckboxesForm(bodyChanneUspdlAtrCheckboxes, setBodyChannelUspdAtrCheckboxes);
      resetCheckboxesForm(bodyChanneSenslAtrCheckboxes, setBodyChannelSensAtrCheckboxes);
      saveFormMutation.mutate(savedForm);
    }
  };

  return (
    <Box sx={{ padding: '32px 40px' }}>
      <Breadcrumbs style={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/reports/custom">
          Отчеты
        </Link>
        <span>Конструктор отчетов</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Конструктор отчетов
      </Typography>
      <ReportsCustomParams
        onFormSubmit={(event) => onSubmit(event)}
        selectedDevice={selectedDevice}
        selectedTemplate={selectedTemplate}
        reportTitleText={reportTitleText}
        reportTitleTextError={reportTitleTextError}
        setReportTitleTextError={setReportTitleTextError}
        reportDescriptionText={reportDescriptionText}
        headerAtrCheckboxes={headerAtrCheckboxes}
        numFieldsCreated={numFieldsCreated}
        isHeaderTextEnabled={isHeaderTextEnabled}
        selectDeviceError={selectDeviceError}
        selectTamplateError={selectTamplateError}
        setSelectDeviceError={setSelectDeviceError}
        setSelectTamplateError={setSelectTamplateError}
        headerDateError={headerDateError}
        setHeaderDateError={setHeaderDateError}
        setSelectedDevice={setSelectedDevice}
        setSelectedTemplate={setSelectedTemplate}
        setReportTitleText={setReportTitleText}
        setReportDescriptionText={setReportDescriptionText}
        setNumFieldsCreated={setNumFieldsCreated}
        setSettingFields={setSettingFields}
        setIsHeaderTextEnabled={setIsHeaderTextEnabled}
        setHeaderAtrCheckboxes={setHeaderAtrCheckboxes}
        bodyAtrCheckboxes={bodyAtrCheckboxes}
        setBodyAtrCheckboxes={setBodyAtrCheckboxes}
        bodyClientAtrCheckboxes={bodyClientAtrCheckboxes}
        setBodyClientAtrCheckboxes={setBodyClientAtrCheckboxes}
        bodyChanneUspdlAtrCheckboxes={bodyChanneUspdlAtrCheckboxes}
        setBodyChannelUspdAtrCheckboxes={setBodyChannelUspdAtrCheckboxes}
        bodyChanneSenslAtrCheckboxes={bodyChanneSenslAtrCheckboxes}
        setBodyChannelSensAtrCheckboxes={setBodyChannelSensAtrCheckboxes}
        settingFields={settingFields}
      />
    </Box>
  );
};

export default CreateCustomReport;
