import { useState } from 'react';

import { Link, useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  DataGrid,
  GridRenderCellParams,
  GridFooterContainer,
  GridPagination,
  GridFilterItem,
  GridFilterOperator,
  useGridSelector,
  useGridApiContext,
  gridRowSelectionStateSelector,
  gridRowsLookupSelector,
  gridFilterActiveItemsSelector,
  gridClasses,
} from '@mui/x-data-grid';
import axios from 'axios';

import DataGridPagination from '../../../components/DataGrid/DataGridPagination';
import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import Message from '../../../components/Message';
import {
  GET_DEVICE_REPORT_PAGE_URL,
  GET_DEVICE_REPORT_EXCEL_URL,
  GET_DEVICE_REPORT_SEGMENTS_EXCEL_URL,
  GET_BALANCE_GROUP_REPORT_PAGE_URL,
  DATETIME_FORMAT,
} from '../../../constants';
import { IUser } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import getTimezoneTimestamp from '../../../utils/getTimezoneTimestamp';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';
import sendMetrik from '../../../utils/sendMetrik';
import translitRuEn from '../../../utils/translitRuEn';
import useFetchBalanceGroups from '../../balanceGroups/hooks/useFetchBalanceGroups';
import useFetchReportDeviceTypes from '../hooks/useFetchReportDeviceTypes';
import useFetchStandartReportsResults from '../hooks/useFetchStandartReportsResults';

import ReportsSegmentFilter from './SegmentFilter';

const devianceOnlyOperators: GridFilterOperator[] = [
  {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => {
        return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1];
      };
    },
  },
];

export const getReportTypeTitle = (param: string): string => {
  if (param === 'daily') return 'otchety_obschii_pochasovoi';
  if (param === 'period' || param === 'periodDATD') return 'otchety_obschii_period';
  if (param === 'segment') return 'otchety_o_narushenii_ustavok';

  return 'neizvestnyi_tip_otcheta';
};

const ReportsDataGridFooter = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let reportTypeParam = searchParams.get('report_type') || '';
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');

  const apiRef = useGridApiContext();
  const selectedRows = useGridSelector(apiRef, gridRowSelectionStateSelector);
  const rowsLookup = useGridSelector(apiRef, gridRowsLookupSelector);
  const filterActiveItems = useGridSelector(apiRef, gridFilterActiveItemsSelector);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const deviceNamesArr: string[] = [];
  selectedRows.forEach((id: any) => deviceNamesArr.push(rowsLookup[id].name));

  const deviceNames = deviceNamesArr.join(',');

  const handleDownloadSegmentsReportClick = () => {
    const data = {
      segment_ranges: [[filterActiveItems[0].value[0], filterActiveItems[0].value[1]]],
    };

    axios
      .post(
        GET_DEVICE_REPORT_SEGMENTS_EXCEL_URL(
          dateFromParam ? getUTCTimestamp(parseInt(dateFromParam)) : '',
          dateToParam ? getUTCTimestamp(parseInt(dateToParam)) : '',
        ),
        data,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report_segment.xlsx';
        link.click();
      });
  };

  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '32px',
        }}
      >
        <Stack direction="row" spacing={2}>
          {selectedRows.length > 0 && (
            <>
              <Button
                color="secondary"
                component="a"
                onClick={() => {
                  sendMetrik(
                    'vntVdknl',
                    getReportTypeTitle(reportTypeParam),
                    'button_click',
                    'vygruzit_excel',
                    null,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/reports',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
                href={GET_DEVICE_REPORT_EXCEL_URL(
                  selectedRows as string[],
                  deviceTypeParam,
                  reportTypeParam,
                  dateFromParam ? getUTCTimestamp(parseInt(dateFromParam)) : '',
                  dateToParam ? getUTCTimestamp(parseInt(dateToParam)) : '',
                )}
                download
              >
                {`Выгрузить Excel ( ${selectedRows.length} )`}
              </Button>
              {reportTypeParam === 'period' && (
                <Button
                  component={Link}
                  to={GET_DEVICE_REPORT_PAGE_URL(
                    selectedRows as string[],
                    deviceTypeParam,
                    deviceNames,
                    temperatureMeasureParam,
                    reportTypeParam,
                    dateFromParam as string,
                    dateToParam,
                  )}
                >
                  Просмотреть отчеты
                </Button>
              )}
            </>
          )}
          {reportTypeParam === 'segment' &&
            filterActiveItems[0]?.value[0] &&
            filterActiveItems[0]?.value[1] && (
              <Button color="secondary" onClick={handleDownloadSegmentsReportClick}>
                Выгрузить Excel
              </Button>
            )}
        </Stack>
        <GridPagination />
      </Box>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const ReportsStandartResults = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let reportTypeParam = searchParams.get('report_type') || '';
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let groupNameParam = searchParams.get('asset_name') || null;
  let addressParam = searchParams.get('address') || null;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [segmentLowerThreshold, setSegmentLowerThreshold] = useState('');
  const [segmentUpperThreshold, setSegmentUpperThreshold] = useState('');

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { isFetching, isSuccess, isError, data } = useFetchStandartReportsResults(
    deviceTypeParam,
    reportTypeParam,
    temperatureMeasureParam,
    dateFromParam,
    dateToParam,
    groupNameParam,
    addressParam,
  );

  const {
    isFetching: isFetchingBalanceGroups,
    isSuccess: isSuccessBalanceGroups,
    isError: isErrorBalanceGroups,
    data: balanceGroupsData,
  } = useFetchBalanceGroups();

  const {
    isSuccess: isReportDeviceTypesSuccess,
    isError: isReportDeviceTypesError,
    data: reportDeviceTypesData,
  } = useFetchReportDeviceTypes();

  let columnsData: any = [];
  if (reportTypeParam !== 'balanceGroup' && reportDeviceTypesData && data?.length > 0) {
    columnsData = Object.keys(data[0]).map((item: string) => ({
      field: item,
      headerName: reportDeviceTypesData.attributes_mapping[item],
      flex: 1,
      minWidth: item === 'id_channel' || item === 'address_full' ? 250 : 170,
      type: item === 'deviance' ? 'number' : 'string',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const { field, value, row } = params;
        if (field === 'id' && reportTypeParam !== 'segment' && value) {
          return (
            <Link
              to={GET_DEVICE_REPORT_PAGE_URL(
                [value],
                deviceTypeParam,
                params.row.name,
                temperatureMeasureParam,
                reportTypeParam,
                dateFromParam as string,
                dateToParam,
              )}
              onClick={() => {
                sendMetrik(
                  'vntVdknl',
                  getReportTypeTitle(reportTypeParam),
                  'element_click',
                  'otchet',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/reports',
                  value,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              }}
            >
              {value}
            </Link>
          );
        }
        if (field === 'date' && value) {
          return convertTimestampToDate(
            getTimezoneTimestamp(parseInt(value), row.timezone),
            DATETIME_FORMAT,
          );
        }
        if (field === 'deviance' && value) {
          return parseFloat(value);
        }

        return value;
      },
      filterOperators: devianceOnlyOperators,
    }));
  } else {
    columnsData = [
      {
        field: 'name',
        headerName: 'Наименование',
        flex: 1,
        minWidth: 170,
        renderCell: (params: GridRenderCellParams<any, string>) => {
          const { row, value = '' } = params;

          return (
            <Link
              to={GET_BALANCE_GROUP_REPORT_PAGE_URL(
                row.uuid,
                dateFromParam as string,
                dateToParam as string,
                value,
              )}
            >
              {value}
            </Link>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        minWidth: 170,
      },
    ];
  }

  const handleSegmentThresholdChange = (lowerThreshold: string, upperThreshold: string) => {
    setSegmentLowerThreshold(lowerThreshold);
    setSegmentUpperThreshold(upperThreshold);
  };

  if (
    (reportTypeParam !== 'balanceGroup' && (isError || isReportDeviceTypesError)) ||
    (reportTypeParam === 'balanceGroup' && isErrorBalanceGroups)
  ) {
    return <Message>Не получилось загрузить данные отчетов</Message>;
  }

  if (reportTypeParam !== 'balanceGroup' && data?.length === 0) {
    return <Message>Отчетов по данным критериям нет</Message>;
  }

  if (reportTypeParam === 'balanceGroup' && balanceGroupsData?.length === 0) {
    return <Message>Нет созданных балансовых групп</Message>;
  }

  if (
    (reportTypeParam !== 'balanceGroup' &&
      (isFetching || !isReportDeviceTypesSuccess || !isSuccess || !columnsData.length)) ||
    (reportTypeParam === 'balanceGroup' && (isFetchingBalanceGroups || !isSuccessBalanceGroups))
  ) {
    return null;
  }

  return (
    <>
      {reportTypeParam === 'segment' && (
        <ReportsSegmentFilter onChange={handleSegmentThresholdChange} />
      )}
      <DataGrid
        columns={columnsData}
        rows={reportTypeParam !== 'balanceGroup' ? data : balanceGroupsData}
        onColumnHeaderClick={(e) => {
          let headerName: any = e.colDef.headerName;
          sendMetrik(
            'vntVdknl',
            getReportTypeTitle(reportTypeParam),
            'filter_click',
            translitRuEn(headerName).toLowerCase(),
            null,
            userData?.permissions[0].uuid,
            userData ? '1' : '0',
            '/reports',
            null,
            null,
            null,
            'interactions',
            userData?.profile_type,
            'web',
          );
        }}
        onRowSelectionModelChange={(value) => {
          sendMetrik(
            'vntVdknl',
            getReportTypeTitle(reportTypeParam),
            'element_click',
            'chekboks',
            null,
            userData?.permissions[0].uuid,
            userData ? '1' : '0',
            '/reports',
            value,
            null,
            null,
            'interactions',
            userData?.profile_type,
            'web',
          );
        }}
        checkboxSelection={
          reportTypeParam !== 'segment' && reportTypeParam !== 'balanceGroup' ? true : false
        }
        paginationModel={paginationModel}
        pageSizeOptions={[10, 50]}
        getRowId={(row) => {
          if (reportTypeParam === 'segment') {
            return `${row.date}-${row.id}-${row.id_channel}`;
          } else if (reportTypeParam === 'balanceGroup') {
            return row.uuid;
          }

          return row.id;
        }}
        getRowHeight={() => 'auto'}
        filterModel={{
          items: [
            {
              field: 'deviance',
              value: [segmentLowerThreshold, segmentUpperThreshold],
              operator: 'between',
            },
          ],
        }}
        slots={{
          columnSortedAscendingIcon: AscendingIcon,
          columnSortedDescendingIcon: DescendingIcon,
          footer: ReportsDataGridFooter,
        }}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        localeText={{
          noRowsLabel: 'Нет данных',
          noResultsOverlayLabel: 'Нет данных по заданным параметрам',
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </>
  );
};

export default ReportsStandartResults;
