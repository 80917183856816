import { useQuery } from 'react-query';

import { GET_DASHBOARD_DATA_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';

const useFetchDashboard = (
  deviceType: string,
  dashboardType: string,
  dateFrom: string | null,
  dateTo: string | null,
  deviceId: string,
  groupName: string | null,
  excludeDeviceIds: string | null,
  temperatureMeasure: string | null,
) => {
  const UTCDateFrom = dateFrom ? getUTCTimestamp(parseInt(dateFrom)) : null;
  const UTCDateTo = dateTo ? getUTCTimestamp(parseInt(dateTo)) : null;

  return useQuery(
    ['dashboardData'],
    () => fetchData(GET_DASHBOARD_DATA_URL(
      deviceType,
      dashboardType,
      UTCDateFrom,
      UTCDateTo,
      deviceId,
      groupName,
      excludeDeviceIds,
      temperatureMeasure,
    )),
    {
      enabled: !!(dashboardType && dashboardType !== 'real_time'),
      cacheTime: 0,
    },
  );
};

export default useFetchDashboard;
