import React, { useState } from 'react';

import { Link, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
  GridRenderCellParams,
  GridColDef,
} from '@mui/x-data-grid';
import { fromUnixTime, getUnixTime, setHours, setMinutes, setSeconds } from 'date-fns';

import DataGridPagination from '../../../components/DataGrid/DataGridPagination';
import AscendingIcon from '../../../components/icons/AscendingIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import WidgetBox from '../../../components/WidgetBox';
import { DATETIME_FORMAT, SETTING_TYPES } from '../../../constants';
import { IWidgetBoxProps } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import useFetchReportDeviceTypes from '../../reports/hooks/useFetchReportDeviceTypes';

type IDashboardsDeviationsTableProps = {
  data: any;
} & Omit<IWidgetBoxProps, 'children'>;

const TableFooter = () => {
  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '32px',
        }}
      >
        <GridPagination />
      </Box>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const DashboardsDeviationsTable = (props: IDashboardsDeviationsTableProps) => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';

  const { title, data } = props;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filter, setFilter] = useState<string>('');

  const { data: reportDeviceTypesData } = useFetchReportDeviceTypes();

  let columns: GridColDef[] = [];
  let rows = [];
  if (Array.isArray(data) && data.length > 0) {
    columns = Object.keys(data[0]).map((item: any) => ({
      field: item === 'id' ? 'deviceId' : item,
      headerName: reportDeviceTypesData?.attributes_mapping[item],
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const { field, value, row } = params;
        if (field === 'date' && value) {
          return convertTimestampToDate(value, DATETIME_FORMAT);
        }
        if (field === 'deviceId' && dashboardTypeParam === 'group' && value) {
          let dateTo;
          dateTo = setHours(fromUnixTime(row.date), 23);
          dateTo = setMinutes(dateTo, 59);
          dateTo = setSeconds(dateTo, 59);
          dateTo = getUnixTime(dateTo);

          return (
            <Link
              to={`/dashboards?device_type=${deviceTypeParam}&dashboard_type=device&date_from=${row.date}&date_to=${dateTo}&device_id=${row.deviceId}`}
            >
              {row.deviceId}
            </Link>
          );
        }

        return value;
      },
    }));

    if (dashboardTypeParam === 'device' || dashboardTypeParam === 'real_time') {
      if (deviceTypeParam === 'uspd') {
        rows = data.map((item: any) => ({
          ...item,
          id: `${item.date}-${item.channel_number}-${item.id_channel}`,
        }));
      } else if (deviceTypeParam === 'pressure_sensor') {
        rows = data.map((item: any) => ({
          ...item,
          id: `${item.date}`,
        }));
      }
    } else {
      if (deviceTypeParam === 'uspd') {
        rows = data.map((item: any) => ({
          ...item,
          deviceId: item.id,
          id: `${item.date}-${item.channel_number}-${item.id}`,
        }));
      } else if (deviceTypeParam === 'pressure_sensor') {
        rows = data.map((item: any) => ({
          ...item,
          deviceId: item.id,
          id: `${item.date}-${item.id}`,
        }));
      }
    }
  }

  const handleFilterChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setFilter(value);
  };

  return (
    <>
      <Select
        displayEmpty
        value={filter}
        onChange={handleFilterChange}
        renderValue={(selected: string) => {
          if (!selected) {
            return <span>Тип уставки</span>;
          }

          return SETTING_TYPES[selected];
        }}
        sx={{
          marginBottom: 1,
        }}
      >
        <MenuItem value="">Тип уставки</MenuItem>
        <MenuItem value="lowerSetting">Нижняя</MenuItem>
        <MenuItem value="upperSetting">Верхняя</MenuItem>
      </Select>
      <WidgetBox title={title}>
        <DataGrid
          columns={columns}
          rows={rows}
          paginationModel={paginationModel}
          pageSizeOptions={[10, 50]}
          filterModel={{
            items: [
              {
                field: 'setting_type',
                operator: 'equals',
                value: SETTING_TYPES[filter],
              },
            ],
          }}
          slots={{
            columnSortedAscendingIcon: AscendingIcon,
            columnSortedDescendingIcon: DescendingIcon,
            footer: TableFooter,
          }}
          onPaginationModelChange={(model) => setPaginationModel(model)}
        />
      </WidgetBox>
    </>
  );
};

export default DashboardsDeviationsTable;
