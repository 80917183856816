import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M13.8649 15.6334C14.3533 16.1218 15.1452 16.1218 15.6337 15.6334C16.1221 15.1449 16.1221 14.353 15.6337 13.8646L9.76894 7.99999L15.6336 2.13538C16.1221 1.64695 16.1221 0.855053 15.6336 0.366623C15.1452 -0.121807 14.3533 -0.121808 13.8648 0.366623L8.00015 6.23122L2.13515 0.366323C1.64671 -0.122107 0.854797 -0.122108 0.366359 0.366322C-0.122079 0.854753 -0.122079 1.64665 0.366359 2.13508L6.23136 7.99999L0.366329 13.8649C-0.12211 14.3533 -0.12211 15.1452 0.366329 15.6337C0.854767 16.1221 1.64668 16.1221 2.13512 15.6337L8.00015 9.76875L13.8649 15.6334Z" />
    </SvgIcon>
  );
};

export default CloseIcon;
