import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MoreHorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M3.01801 11.5384C3.06128 10.9571 3.08291 10.6664 3.37466 10.3747C3.66641 10.0829 3.95707 10.0613 4.53841 10.018C4.6891 10.0068 4.84455 10 5 10C5.15545 10 5.3109 10.0068 5.46159 10.018C6.04293 10.0613 6.33359 10.0829 6.62534 10.3747C6.91709 10.6664 6.93872 10.9571 6.98199 11.5384C6.9932 11.6891 7 11.8446 7 12C7 12.1554 6.9932 12.3109 6.98199 12.4616C6.93872 13.0429 6.91709 13.3336 6.62534 13.6253C6.33359 13.9171 6.04293 13.9387 5.46159 13.982C5.3109 13.9932 5.15545 14 5 14C4.84455 14 4.6891 13.9932 4.53841 13.982C3.95707 13.9387 3.66641 13.9171 3.37466 13.6253C3.08291 13.3336 3.06128 13.0429 3.01801 12.4616C3.0068 12.3109 3 12.1554 3 12C3 11.8446 3.0068 11.6891 3.01801 11.5384Z" />
      <path d="M10.018 11.5384C10.0613 10.9571 10.0829 10.6664 10.3747 10.3747C10.6664 10.0829 10.9571 10.0613 11.5384 10.018C11.6891 10.0068 11.8446 10 12 10C12.1554 10 12.3109 10.0068 12.4616 10.018C13.0429 10.0613 13.3336 10.0829 13.6253 10.3747C13.9171 10.6664 13.9387 10.9571 13.982 11.5384C13.9932 11.6891 14 11.8446 14 12C14 12.1554 13.9932 12.3109 13.982 12.4616C13.9387 13.0429 13.9171 13.3336 13.6253 13.6253C13.3336 13.9171 13.0429 13.9387 12.4616 13.982C12.3109 13.9932 12.1554 14 12 14C11.8446 14 11.6891 13.9932 11.5384 13.982C10.9571 13.9387 10.6664 13.9171 10.3747 13.6253C10.0829 13.3336 10.0613 13.0429 10.018 12.4616C10.0068 12.3109 10 12.1554 10 12C10 11.8446 10.0068 11.6891 10.018 11.5384Z" />
      <path d="M17.3747 10.3747C17.0829 10.6664 17.0613 10.9571 17.018 11.5384C17.0068 11.6891 17 11.8446 17 12C17 12.1554 17.0068 12.3109 17.018 12.4616C17.0613 13.0429 17.0829 13.3336 17.3747 13.6253C17.6664 13.9171 17.9571 13.9387 18.5384 13.982C18.6891 13.9932 18.8446 14 19 14C19.1554 14 19.3109 13.9932 19.4616 13.982C20.0429 13.9387 20.3336 13.9171 20.6253 13.6253C20.9171 13.3336 20.9387 13.0429 20.982 12.4616C20.9932 12.3109 21 12.1554 21 12C21 11.8446 20.9932 11.6891 20.982 11.5384C20.9387 10.9571 20.9171 10.6664 20.6253 10.3747C20.3336 10.0829 20.0429 10.0613 19.4616 10.018C19.3109 10.0068 19.1554 10 19 10C18.8446 10 18.6891 10.0068 18.5384 10.018C17.9571 10.0613 17.6664 10.0829 17.3747 10.3747Z" />
    </SvgIcon>
  );
};

export default MoreHorIcon;
