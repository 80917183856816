import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import InProgressIcon from '../../components/icons/InProgressIcon';
import SuccessIcon from '../../components/icons/SuccessIcon';

type IDeviceParamStatusProps = {
  statuses: Record<string, boolean>;
  field: string;
};

const DeviceParamStatusInner = (props: IDeviceParamStatusProps) => {
  const { statuses, field, ...rest } = props;

  let status = null;
  if (statuses && statuses.hasOwnProperty(field)) {
    const statusValue = statuses[field];
    status = (
      <Tooltip arrow title={statusValue ? 'Значение установлено' : 'Обновление значения'}>
        <Box {...rest}>
          {statusValue ? <SuccessIcon color="success" /> : <InProgressIcon color="info" />}
        </Box>
      </Tooltip>
    );
  }

  return status;
};

const DeviceParamStatus = styled(DeviceParamStatusInner, {
  name: 'DeviceParamStatus',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})<IDeviceParamStatusProps>(() => ({}));

export default DeviceParamStatus;
