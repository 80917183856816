import { useState, SyntheticEvent } from 'react';

import { useParams, Link as RouterLink } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import EditIcon from '../../components/icons/EditIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import TabPanel from '../../components/TabPanel';
import {
  PERIMETER5_DEVICES_TYPES,
  PRESSURE_SENSOR_PM_OPTIONS,
  PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS,
  PRESSURE_SENSOR_AM_OPTIONS,
  PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS,
  DATD_WITH_2_CHANNELS_PM_OPTIONS,
  DATD_WITH_4_CHANNELS_PM_OPTIONS,
  DATD_WITH_10_CHANNELS_PM_OPTIONS,
} from '../../constants';
import convertHEXToArray from '../../utils/convertHEXToArray';
import convertTimestampToDate from '../../utils/convertTimestampToDate';
import useFetchObjectGroups from '../../utils/hooks/useFetchObjectGroups';
import sendMetrik from '../../utils/sendMetrik';

import DeviceParamStatus from './DeviceParamStatus';
import useFetchDeviceInfo from './hooks/useFetchDeviceInfo';
import useFetchDeviceProfiles from './hooks/useFetchDeviceProfiles';
import useFetchPerimeter5ConnectionTypes from './hooks/useFetchPerimeter5ConnectionTypes';

import type { IDATDDevice, IPerimeter5Device, IUser } from '../../types';

const Panel = styled(Box)(({ theme }) => ({
  marginBottom: 16,
  padding: 36,
  background: theme.palette.background.secondary,
  borderRadius: 16,
}));

const DeviceInfo = () => {
  let { objectId, deviceId = '' } = useParams();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const [activeDevice, setActiveDevice] = useState(0);

  const { isLoading, isError, data } = useFetchDeviceInfo(deviceId);

  const {
    isLoading: isLoadingDeviceProfilesData,
    isError: isErrorDeviceProfilesData,
    data: deviceProfilesData,
  } = useFetchDeviceProfiles();

  const {
    isLoading: isLoadingObjectGroups,
    isError: isErrorObjectGroups,
    data: objectGroupsData,
  } = useFetchObjectGroups(!!data?.customerId, data?.customerId);

  const {
    isLoading: isLoadingPerimeter5ConnectionTypes,
    isError: isErrorPerimeter5ConnectionTypes,
    data: perimeter5ConnectionTypesData,
  } = useFetchPerimeter5ConnectionTypes();

  let clientAttributes = [];
  let liveModeAttributes: any[] = [];
  let settings: any[] = [];
  let serverAttributes = [];
  let DATDDevices: any[] = [];
  let Perimeter5Devices: any[] = [];
  if (data && objectGroupsData) {
    if (data.serverAttributes) {
      clientAttributes.push({
        id: 'name',
        title: 'Название',
        value: data.serverAttributes.name,
      });
      serverAttributes.push({
        id: 'address_full',
        title: 'Адрес',
        value: data.serverAttributes.address_full,
      });
      serverAttributes.push({
        id: 'address_coords',
        title: 'Координаты',
        value: data.serverAttributes.address_coords,
      });

      Object.entries(data.serverAttributes).forEach((item: any) => {
        if (item[0] !== 'name' && item[0] !== 'address_full' && item[0] !== 'address_coords') {
          serverAttributes.push({
            id: item[0],
            title: item[0],
            value: item[1],
          });
        }
      });
    }

    const parent = objectGroupsData.find((item: any) => item.id === data.parentId);
    if (parent) {
      clientAttributes.push({
        id: 'parent',
        title: 'Группа',
        value: parent.name,
      });
    }

    if (data.clientAttributes) {
      Object.entries(data.clientAttributes).forEach((item: any) => {
        if (item[0] === 'perm') {
          liveModeAttributes.push({
            id: 'live_mode',
            title: deviceProfilesData?.attributes_mapping.live_mod,
            value: data.clientAttributes.perm === 0 ? 'по расписанию' : 'интервал времени',
          });
          if (data.clientAttributes.perm > 0) {
            liveModeAttributes.push({
              id: item[0],
              title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
              value: item[1],
            });
          }
        } else if (item[0] === 'dm') {
          if (data.clientAttributes.perm === 0) {
            liveModeAttributes.push({
              id: item[0],
              title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
              value:
                convertHEXToArray(item[1], item[0]).indexOf('all') !== -1
                  ? 'все дни'
                  : convertHEXToArray(item[1], item[0]).join(', '),
            });
          }
        } else if (item[0] === 't') {
          if (data.clientAttributes.perm === 0) {
            liveModeAttributes.push({
              id: item[0],
              title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
              value:
                convertHEXToArray(item[1], item[0]).indexOf('all') !== -1
                  ? 'все часы'
                  : convertHEXToArray(item[1], item[0])
                      .map((hour: string) => (parseInt(hour) <= 9 ? `0${hour}:00` : `${hour}:00`))
                      .join(', '),
            });
          }
        } else if (item[0] === 'pm') {
          let pm;
          if (data?.deviceProfileId === 'uspd' || data?.deviceProfileId === 'perimiter-5') {
            pm =
              DATD_WITH_2_CHANNELS_PM_OPTIONS[
                data.clientAttributes.pm as keyof typeof DATD_WITH_2_CHANNELS_PM_OPTIONS
              ];
            if (data?.clientAttributes.channels === 4) {
              pm =
                DATD_WITH_4_CHANNELS_PM_OPTIONS[
                  data.clientAttributes.pm as keyof typeof DATD_WITH_4_CHANNELS_PM_OPTIONS
                ];
            } else if (data?.clientAttributes.channels === 10) {
              pm =
                DATD_WITH_10_CHANNELS_PM_OPTIONS[
                  data.clientAttributes.pm as keyof typeof DATD_WITH_10_CHANNELS_PM_OPTIONS
                ];
            }
          } else if (data?.deviceProfileId === 'pressure_sensor') {
            if (data.clientAttributes.pm === 0 || data.clientAttributes.pm === 1) {
              pm =
                PRESSURE_SENSOR_PM_OPTIONS[
                  data.clientAttributes.pm as keyof typeof PRESSURE_SENSOR_PM_OPTIONS
                ];
            } else if (data.clientAttributes.pm === 2) {
              pm =
                PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS[
                  data.clientAttributes.pm as keyof typeof PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS
                ];
            }
          }
          clientAttributes.push({
            id: item[0],
            title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
            value: pm,
          });
        } else if (item[0] === 'am') {
          let am;
          if (data?.deviceProfileId === 'uspd' || data?.deviceProfileId === 'perimiter-5') {
            am =
              convertHEXToArray(item[1], item[0]).indexOf('all') !== -1
                ? 'все каналы'
                : convertHEXToArray(item[1], item[0])
                    .map((channel) => `Вкл.кан.${channel}`)
                    .join(', ');
          } else if (data?.deviceProfileId === 'pressure_sensor') {
            if (data.clientAttributes.am === '0000' || data.clientAttributes.am === '8000') {
              am =
                PRESSURE_SENSOR_AM_OPTIONS[
                  data.clientAttributes.am as keyof typeof PRESSURE_SENSOR_AM_OPTIONS
                ];
            } else if (data.clientAttributes.am === 'C000') {
              am =
                PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS[
                  data.clientAttributes.am as keyof typeof PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS
                ];
            }
          }
          clientAttributes.push({
            id: item[0],
            title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
            value: am,
          });
        } else if (item[0] !== 'possible_names') {
          clientAttributes.push({
            id: item[0],
            title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
            value:
              item[0] === 'tsf'
                ? convertTimestampToDate(parseInt(item[1]))
                : item[0] === 'device_type'
                ? data.deviceProfileName
                : item[1],
          });
        }
      });
    }

    if (data.settings) {
      Object.entries(data.settings).forEach((item: any) => {
        settings.push({
          id: item[0],
          title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
          value: item[1],
        });
      });
    }

    if (Array.isArray(data.uspd_devices) && data.deviceProfileId === 'uspd') {
      data.uspd_devices
        .sort((a: IDATDDevice, b: IDATDDevice) => {
          if (a.channel_number && b.channel_number) {
            if (a.channel_number > b.channel_number) return 1;
            if (a.channel_number < b.channel_number) return -1;
          }

          return 0;
        })
        .forEach((DATDDevice: any) => {
          let DATDDeviceAttributes: any = [];
          let DATDDeviceSettings: any = [];

          Object.entries(DATDDevice).forEach((item: any) => {
            if (item[0] === 'settings') {
              Object.entries(DATDDevice.settings).forEach((setting: any) => {
                DATDDeviceSettings.push({
                  id: setting[0],
                  title: deviceProfilesData?.attributes_mapping[setting[0]] || setting[0],
                  value: setting[1],
                });
              });
            } else if (item[0] !== 'status_of_updated_fields') {
              DATDDeviceAttributes.push({
                id: item[0],
                title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
                value:
                  item[0] === 'verification_date'
                    ? convertTimestampToDate(parseInt(item[1]))
                    : item[1],
              });
            }
          });

          DATDDevices.push({
            id: DATDDevice.id,
            channel_number: DATDDevice.channel_number,
            attributes: DATDDeviceAttributes,
            settings: DATDDeviceSettings,
            statuses: DATDDevice.status_of_updated_fields,
          });
        });
    }

    if (
      Array.isArray(data.uspd_devices) &&
      data.deviceProfileId === 'perimeter-5' &&
      perimeter5ConnectionTypesData
    ) {
      data.uspd_devices
        .sort((a: IPerimeter5Device, b: IPerimeter5Device) => a.channel_number - b.channel_number)
        .slice(0, 5)
        .forEach((Perimeter5Device: any) => {
          let Perimeter5DeviceAttributes: any = [];

          Object.entries(Perimeter5Device).forEach((item: any) => {
            if (
              item[0] === 'sensor_type' ||
              item[0] === 'sensor_name' ||
              item[0] === 'connection_type'
            ) {
              Perimeter5DeviceAttributes.push({
                id: item[0],
                title: deviceProfilesData?.attributes_mapping[item[0]] || item[0],
                value:
                  item[0] === 'sensor_type'
                    ? PERIMETER5_DEVICES_TYPES[item[1]]
                    : item[0] === 'connection_type'
                    ? perimeter5ConnectionTypesData[item[1]]
                    : item[1],
              });
            }
          });

          Perimeter5Devices.push({
            channel_number: Perimeter5Device.channel_number,
            attributes: Perimeter5DeviceAttributes,
            statuses: Perimeter5Device.status_of_updated_fields,
          });
        });
    }
  }

  const handleDeviceChange = (event: SyntheticEvent, newValue: number) => {
    setActiveDevice(newValue);
  };

  if (
    isLoading ||
    isLoadingDeviceProfilesData ||
    isLoadingObjectGroups ||
    isLoadingPerimeter5ConnectionTypes
  ) {
    return <Spinner data-testid="loader" />;
  }

  if (
    isError ||
    isErrorDeviceProfilesData ||
    isErrorObjectGroups ||
    isErrorPerimeter5ConnectionTypes
  ) {
    return <Message>Не получилось загрузить данные устройства. Попробуйте еще раз.</Message>;
  }

  if (data?.detail === 'Not Found') {
    return <Message>Устройство с таким номером не найдено</Message>;
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to={`/objects/${data.parentId}?type=${
            data.customerId === data.parentId ? 'customer' : 'asset'
          }&devicesType=${data.deviceProfileId}`}
          onClick={() => {
            sendMetrik(
              'vntVdknl',
              'prosmotr_ustroistva',
              'element_click',
              'ustroistva',
              null,
              userData?.permissions[0].uuid,
              userData ? '1' : '0',
              '/objects',
              null,
              null,
              null,
              'interactions',
              userData?.profile_type,
              'web',
            );
          }}
        >
          Устройства
        </Link>
        <span>{data.serverAttributes.name}</span>
      </Breadcrumbs>

      <Typography variant="h1" gutterBottom>
        Просмотр устройства
        <IconButton
          component={RouterLink}
          size="extraSmall"
          color="secondary"
          sx={{ marginLeft: 1 }}
          to={`/objects/${objectId}/devices/${deviceId}/edit`}
          onClick={() => {
            sendMetrik(
              'vntVdknl',
              'prosmotr_ustroistva',
              'button_click',
              'redaktirovanie_ustroistva',
              null,
              userData?.permissions[0].uuid,
              userData ? '1' : '0',
              '/objects',
              data?.clientAttributes.id,
              null,
              null,
              'interactions',
              userData?.profile_type,
              'web',
            );
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Typography>

      <Panel>
        <Typography variant="h3" gutterBottom>
          Общая информация
        </Typography>
        <Grid container spacing={3}>
          {clientAttributes.map((item: any) => (
            <Grid item mobile={12} xs={4} key={item.id}>
              <Typography variant="paragraph3">
                <b>{item.title}:</b> {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Panel>

      <Panel>
        <Typography variant="h3" gutterBottom>
          Настройки связи
        </Typography>
        <Grid container spacing={3}>
          {liveModeAttributes.map((item: any) => (
            <Grid item mobile={12} xs={4} key={item.id}>
              <Typography variant="paragraph3">
                <b>{item.title}:</b> {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Panel>

      {settings.length > 0 && (
        <Panel>
          <Typography variant="h3" gutterBottom>
            Уставки
          </Typography>
          <Grid container spacing={3}>
            {settings.map((item: any) => (
              <Grid item mobile={12} xs={6} key={item.id}>
                <Typography variant="paragraph3">
                  <b>{item.title}:</b> {item.id === 'a_d1' || item.id === 'a_t1' ? item.value / 10000 : item.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Panel>
      )}
      <Panel>
        <Typography variant="h3" gutterBottom>
          Пользовательские атрибуты
        </Typography>
        <Grid container spacing={3}>
          {serverAttributes.map((item: any) => (
            <Grid item mobile={12} xs={4} key={item.id}>
              <Typography variant="paragraph3">
                <b>{item.title}:</b> {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Panel>

      {data.deviceProfileId === 'uspd' && (
        <Panel>
          <Typography variant="h3" gutterBottom>
            Поканальные настройки
          </Typography>
          {DATDDevices.length > 0 && (
            <>
              <Tabs
                value={activeDevice}
                variant="scrollable"
                scrollButtons={false}
                onChange={handleDeviceChange}
              >
                {DATDDevices.map((item: any) => (
                  <Tab key={item.id} label={`Счетчик ${item.channel_number || ''}`} />
                ))}
              </Tabs>
              {DATDDevices.map((DATDDevice: any, index: number) => (
                <TabPanel value={activeDevice} key={DATDDevice.id} index={index}>
                  <Grid container spacing={3}>
                    {DATDDevice.attributes.map((item: any) => (
                      <Grid item mobile={12} xs={4} key={item.id}>
                        <Typography variant="paragraph3">
                          <b>{item.title}:</b> {item.value}
                          <DeviceParamStatus statuses={DATDDevice.statuses} field={item.id} />
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="h3" gutterBottom sx={{ marginTop: 3 }}>
                    Уставки
                  </Typography>
                  <Grid container spacing={3}>
                    {DATDDevice.settings.map((item: any) => (
                      <Grid item mobile={12} xs={6} key={item.id}>
                        <Typography variant="paragraph3">
                          <b>{item.title}:</b> {item.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              ))}
            </>
          )}
        </Panel>
      )}

      {data.deviceProfileId === 'perimeter-5' && (
        <Panel>
          <Typography variant="h3" gutterBottom>
            Поканальные настройки
          </Typography>
          <Tabs
            value={activeDevice}
            variant="scrollable"
            scrollButtons={false}
            onChange={handleDeviceChange}
          >
            {Perimeter5Devices.map((item: any) => (
              <Tab key={item.channel_number} label={`Канал ${item.channel_number}`} />
            ))}
          </Tabs>
          {Perimeter5Devices.map((Perimeter5Device: any, index: number) => (
            <TabPanel value={activeDevice} key={Perimeter5Device.channel_number} index={index}>
              <Grid container spacing={3}>
                {Perimeter5Device.attributes.map((item: any) => (
                  <Grid item mobile={12} xs={4} key={item.id}>
                    <Typography variant="paragraph3">
                      <b>{item.title}:</b> {item.value}
                      <DeviceParamStatus statuses={Perimeter5Device.statuses} field={item.id} />
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          ))}
        </Panel>
      )}
    </>
  );
};

export default DeviceInfo;
