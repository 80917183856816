import Tag from '../../../components/Tag';
import { RSSI_LOW, RSSI_MID, RSSI_HIGH } from '../../../constants';
import { IColorVariants } from '../../../types';

type IDashboardsRSSI = {
  value: number;
}

const DashboardsRSSI = (props: IDashboardsRSSI) => {
  const { value } = props;

  let color: IColorVariants;
  if (value > RSSI_HIGH) {
    color = 'perfect';
  } else if (value > RSSI_MID && value <= RSSI_HIGH) {
    color = 'success';
  } else if (value > RSSI_LOW && value <= RSSI_MID) {
    color = 'warning';
  } else if (value <= RSSI_LOW) {
    color = 'error';
  }

  return (
    <Tag color={color} label={value} />
  )
};

export default DashboardsRSSI;
