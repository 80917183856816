import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { IUser } from '../../types';

// метрика просмотра страницы
const useRouteMetrik = () => {
  const location = useLocation();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  useEffect(() => {
    const metrikData = {
      event: 'scrn',
      userId: userData?.permissions[0].uuid,
      userAuth: userData ? '1' : '0',
      screenName: location.pathname,
      eventContent: null,
      eventContext: null,
      buttonLocation: null,
      actionGroup: 'non_interactions',
      accountType: userData?.profile_type,
      touchPoint: 'web',
    };
    window.dataLayer?.push(metrikData);
  }, [location.pathname, userData]);
};

export default useRouteMetrik;
