import { useCallback, useEffect } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { useForm, useWatch, Controller, SubmitHandler, FormProvider } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getUnixTime, fromUnixTime, isPast, isBefore, startOfMonth } from 'date-fns';

import FormLabel from '../../../components/FormLabel';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import ErrorIcon from '../../../components/icons/ErrorIcon';
import PlusIcon from '../../../components/icons/PlusIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { IUser } from '../../../types';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchGISGKHGroups from '../hooks/useFetchGISGKHGroups';

type FormValues = {
  date_from: Date | number | null;
  date_to: Date | number | null;
  group: IGISGKHGroup | null;
};

export type IGISGKHGroup = {
  name: string;
  parentGroupID: number;
};

const ReportsSpecialParams = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let groupIdParam = searchParams.get('group_id');

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const {
    isLoading: isLoadingGISGKHGroups,
    isError: isErrorGISGKHGroups,
    data: GISGKHGroupsData,
  } = useFetchGISGKHGroups();

  const getGroup = useCallback(
    (id: string | null) => {
      let group = null;
      if (GISGKHGroupsData && id) {
        const groups = GISGKHGroupsData.filter(
          (item: IGISGKHGroup) => item.parentGroupID === parseInt(id),
        );
        if (groups.length) {
          group = groups[0];
        }
      }

      return group;
    },
    [GISGKHGroupsData],
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      date_from: dateFromParam ? fromUnixTime(parseInt(dateFromParam)) : null,
      date_to: dateToParam ? fromUnixTime(parseInt(dateToParam)) : null,
      group: getGroup(groupIdParam),
    },
  });
  const { control, handleSubmit, reset, formState, setValue } = methods;

  const dateFromWatch = useWatch({
    control,
    name: 'date_from',
  });
  const dateToWatch = useWatch({
    control,
    name: 'date_to',
  });
  const groupIdWatch = useWatch({
    control,
    name: 'group',
  });

  useEffect(() => {
    if (GISGKHGroupsData && !GISGKHGroupsData?.detail && !groupIdWatch) {
      setValue('group', getGroup(groupIdParam));
    }
  }, [GISGKHGroupsData, getGroup, groupIdParam, groupIdWatch, setValue]);

  let isFilterReady = true;
  if (!dateFromWatch || !dateToWatch) {
    isFilterReady = false;
  }

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const { date_from, date_to, group } = formData;
    const params: any = {};
    if (date_from) {
      params.date_from = getUnixTime(date_from);
    }
    if (date_to) {
      params.date_to = getUnixTime(date_to);
    }
    if (group) {
      params.group_id = group.parentGroupID;
    }
    const newSearchParams = new URLSearchParams(params);
    setSearchParams(newSearchParams.toString());
  };

  const handleClearFilterClick = () => {
    if (searchParams.toString()) {
      navigate('/reports/special');
    } else {
      reset();
    }
  };

  if (isLoadingGISGKHGroups) {
    return <Spinner data-testid="loader" />;
  }

  if (GISGKHGroupsData?.detail || isErrorGISGKHGroups) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные фильтров. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <Box
      sx={{
        marginBottom: 3,
        padding: 3,
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '16px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container columnSpacing={2}>
            <Grid item mobile={12} xs={9}>
              <Grid container spacing={2}>
                <Grid item mobile={12} xs={4}>
                  <FormControl margin="none">
                    <FormLabel>Начало периода</FormLabel>
                    <Controller
                      rules={{
                        validate: (date) => {
                          if (date) {
                            return isPast(date) || 'Дата должна быть не позже текущего дня';
                          }
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <DatePicker
                          value={value}
                          views={['month', 'year']}
                          onChange={(newValue) => {
                            if (newValue) {
                              onChange(startOfMonth(newValue));
                            }
                            sendMetrik(
                              'vntVdknl',
                              'otchet_specialnyi',
                              'form_change',
                              'nachalo_perioda',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports/special',
                              newValue,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="MuiTextField-alt"
                              error={!!formState.errors.date_from?.message}
                              helperText={formState.errors.date_from?.message}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'Укажите начало периода',
                              }}
                            />
                          )}
                        />
                      )}
                      name="date_from"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item mobile={12} xs={4}>
                  <FormControl margin="none">
                    <FormLabel>Конец периода</FormLabel>
                    <Controller
                      rules={{
                        validate: {
                          notFuture: (date) => {
                            if (date) {
                              return isPast(date) || 'Дата должна быть не позже текущего дня';
                            }
                          },
                          minDate: (date) => {
                            if (dateFromWatch && date) {
                              return (
                                !isBefore(date, dateFromWatch) ||
                                'Дата должна быть позже начала периода'
                              );
                            }
                          },
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <DatePicker
                          value={value}
                          views={['month', 'year']}
                          onChange={(newValue) => {
                            if (newValue) {
                              onChange(startOfMonth(newValue));
                            }
                            sendMetrik(
                              'vntVdknl',
                              'otchet_specialnyi',
                              'form_change',
                              'konec_perioda',
                              null,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports/special',
                              newValue,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="MuiTextField-alt"
                              error={!!formState.errors.date_to?.message}
                              helperText={formState.errors.date_to?.message}
                              InputProps={{
                                ...params.InputProps,
                                className: 'MuiOutlinedAltInput-root',
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'Укажите конец периода',
                              }}
                            />
                          )}
                          minDate={dateFromWatch}
                        />
                      )}
                      name="date_to"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                {GISGKHGroupsData && (
                  <Grid item mobile={12} xs={4}>
                    <FormControl margin="none">
                      <FormLabel>Группа</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={GISGKHGroupsData}
                            onChange={(_, value) => {
                              field.onChange(value);
                              sendMetrik(
                                'vntVdknl',
                                'otchet_specialnyi',
                                'form_change',
                                'gruppa',
                                null,
                                userData?.permissions[0].uuid,
                                userData ? '1' : '0',
                                '/reports/special',
                                value,
                                null,
                                null,
                                'interactions',
                                userData?.profile_type,
                                'web',
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Выберите группу"
                                className="MuiTextField-alt"
                              />
                            )}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                          />
                        )}
                        name="group"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item mobile={12} xs={3}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'flex-end',
                  paddingTop: 2,
                }}
              >
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!isFilterReady}
                  type="submit"
                  title="Сформировать"
                  data-testid="submit"
                  onClick={() => {
                    sendMetrik(
                      'vntVdknl',
                      'otchet_specialnyi',
                      'button_click',
                      'sformirovat',
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/reports/special',
                      null,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                >
                  <PlusIcon />
                </IconButton>
                <IconButton size="small" onClick={handleClearFilterClick}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </Box>
  );
};

export default ReportsSpecialParams;
