import { useState, useEffect, SyntheticEvent } from 'react';

import { useQueryClient } from 'react-query';

import { useFormContext, useFieldArray } from 'react-hook-form';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import Dialog from '../../components/Dialog';
import PlusIcon from '../../components/icons/PlusIcon';
import TabPanel from '../../components/TabPanel';
import { IDATDDevice, IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

import DATDDevice from './DATDDevice';
import useFetchDATDConnectionTypes from './hooks/useFetchDATDConnectionTypes';

type IDATDDevicesProps = {
  devices: IDATDDevice[];
  channelsAmount: number;
};

const DATDDevices = (props: IDATDDevicesProps) => {
  const { devices, channelsAmount } = props;

  useFetchDATDConnectionTypes();

  const [availableChannels, setAvailableChannels] = useState<number[]>(
    Array.from({ length: channelsAmount }, (_, i) => i + 1),
  );
  const [activeDevice, setActiveDevice] = useState(0);
  const [removingDeviceIndex, setRemovingDeviceIndex] = useState<number>();
  const [removingDeviceChannel, setRemovingDeviceChannel] = useState<number | null>(null);
  const [isRemoveDeviceDialogOpen, setIsRemoveDeviceDialogOpen] = useState(false);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'DATDDevices',
    control,
  });

  useEffect(() => {
    if (devices.length) {
      const channels = Array.from({ length: channelsAmount }, (_, i) => i + 1);
      devices.forEach(({ channel_number }) => {
        if (channel_number) {
          const index = channels.indexOf(channel_number);
          channels.splice(index, 1);
        }
      });

      setAvailableChannels(channels);
    }
  }, [devices, channelsAmount]);

  const appendDevice = () => {
    append({
      model: '',
      id: '',
      channel_number: '',
      verification_date: null,
      verification_interval: '',
      connection_type: '',
      anti_shatter: '',
      initial_value: '',
      impulse_weight: '',
      settings: [
        {
          attributeName: 'setpoint_low',
          attributeValue: '',
        },
        {
          attributeName: 'setpoint_high',
          attributeValue: '',
        },
      ],
      status_of_updated_fields: {},
    });

    setActiveDevice(fields.length);

    sendMetrik(
      'vntVdknl',
      'redaktirovanie_ustroistva',
      'button_click',
      'dobavit',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/objects',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  const removeDevice = () => {
    setActiveDevice(0);
    remove(removingDeviceIndex);
    setIsRemoveDeviceDialogOpen(false);
  };

  const handleDeviceChange = (event: SyntheticEvent, newValue: number) => {
    setActiveDevice(newValue);
  };

  const handleChannelChange = (currentChannel: number, newChannel: number) => {
    const channels = [...availableChannels];
    if (currentChannel) {
      if (channels.indexOf(currentChannel) === -1) {
        channels.push(currentChannel);
      }
    }
    if (newChannel) {
      const index = channels.indexOf(newChannel);
      if (index !== -1) {
        channels.splice(index, 1);
      }
    }
    setAvailableChannels(channels);
  };

  const handleRemoveDeviceDialogOpen = (
    index: number,
    currentChannel: number,
    newChannel: number,
  ) => {
    setRemovingDeviceIndex(index);
    setRemovingDeviceChannel(currentChannel);
    setAvailableChannels((channels) => {
      const newChannels = [...availableChannels];
      if (newChannel && newChannels.indexOf(newChannel) === -1) {
        newChannels.push(newChannel);
      }

      return newChannels;
    });
    setIsRemoveDeviceDialogOpen(true);
  };

  const handleRemoveDeviceDialogClose = () => {
    setIsRemoveDeviceDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography variant="h3">Поканальные настройки</Typography>
          {fields.length < channelsAmount && (
            <IconButton
              size="extraSmall"
              color="primary"
              aria-label="Добавить устройство"
              onClick={appendDevice}
            >
              <PlusIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      {fields.length > 0 && (
        <>
          <Tabs
            value={activeDevice}
            variant="scrollable"
            scrollButtons={false}
            onChange={handleDeviceChange}
          >
            {fields.map((DATDDeviceField: any, index: number) => (
              <Tab
                key={DATDDeviceField.id}
                label={`Счетчик ${DATDDeviceField.currentChannelNumber || ''}`}
              />
            ))}
          </Tabs>
          {fields.map((DATDDeviceField: any, index: number) => (
            <TabPanel value={activeDevice} key={DATDDeviceField.id} index={index}>
              <DATDDevice
                deviceIndex={index}
                currentChannel={DATDDeviceField.currentChannelNumber}
                availableChannels={availableChannels}
                statuses={DATDDeviceField.status_of_updated_fields}
                onChannelChange={handleChannelChange}
                onRemove={handleRemoveDeviceDialogOpen}
              />
            </TabPanel>
          ))}
        </>
      )}

      <Dialog
        heading="Удалить счетчик"
        actionButtonText="Удалить"
        open={isRemoveDeviceDialogOpen}
        onAction={removeDevice}
        onClose={handleRemoveDeviceDialogClose}
      >
        <Typography variant="body2">
          <>Вы уверены, что хотите удалить </>
          <Box
            component="span"
            sx={{
              fontWeight: 'bold',
            }}
          >
            Счетчик {removingDeviceChannel || ''}
          </Box>
          ?
        </Typography>
      </Dialog>
    </>
  );
};

export default DATDDevices;
