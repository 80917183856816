import { useQuery } from 'react-query';

import { GET_OBJECT_GROUPS_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchObjectGroups = (enabled: boolean, id: string, onSuccess?: (data: any) => void) => {
  return useQuery(['objectGroupsData', id], () => fetchData(GET_OBJECT_GROUPS_URL(id)), {
    enabled: enabled,
    cacheTime: 0,
    onSuccess
  });
};

export default useFetchObjectGroups;
