import Tag from '../../../components/Tag';
import { BATTERY_VOLTAGE_HIGH, BATTERY_VOLTAGE_LOW } from '../../../constants';
import { IColorVariants } from '../../../types';

type IDashboardsBatteryVoltage = {
  value: number;
}

const DashboardsBatteryVoltage = (props: IDashboardsBatteryVoltage) => {
  const { value } = props;

  let color: IColorVariants;
  if (value > BATTERY_VOLTAGE_HIGH) {
    color = 'success';
  } else if (value >= BATTERY_VOLTAGE_LOW && value <= BATTERY_VOLTAGE_HIGH) {
    color = 'warning';
  } else if (value < BATTERY_VOLTAGE_LOW) {
    color = 'error';
  }

  return (
    <Tag color={color} label={value && value.toFixed(1)} />
  )
};

export default DashboardsBatteryVoltage;
