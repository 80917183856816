import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EggIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M4 14.0005C4 8.39242 8.26201 2 11.9982 2C15.7375 2 20 8.39242 20 14.0005C20 17.9827 17.5277 22 11.9982 22C6.47079 22 4 17.9827 4 14.0005Z" />
    </SvgIcon>
  );
};

export default EggIcon;
