import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { IWidgetBoxProps } from '../../types';

const WidgetBoxInner = (props: IWidgetBoxProps) => {
  const {title, mainHeader, secondaryHeader, children, ...rest} = props;

  return (
    <Box {...rest}>
      {(title || mainHeader || secondaryHeader) &&
        <Box className="widgetBox-header">
          <Stack className="widgetBox-mainHeader" direction="row" gap={1} alignItems="center">
            {title && <Typography className="widgetBox-title">{title}</Typography>}
            {mainHeader}
          </Stack>
          {secondaryHeader && <Box className="widgetBox-secondaryHeader">{secondaryHeader}</Box>}
        </Box>
      }
      {children}
    </Box>
  );
};

const WidgetBox = styled(WidgetBoxInner, {
  name: 'WidgetBox',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})<IWidgetBoxProps>(() => ({}));

export default WidgetBox;
