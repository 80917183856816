import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DocumentIcon from '../icons/DocumentIcon';

export type IMessageProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
};

const StyledMessage = styled('div', {
  name: 'Message',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})<IMessageProps>(({theme}) => ({}));

const Message = (props: IMessageProps) => {
  const { children, icon } = props;

  return (
    <StyledMessage>
      <Box className="Message-icon">{icon ? icon : <DocumentIcon />}</Box>
      <Typography variant='body2'>{children}</Typography>
    </StyledMessage>
  );
};

export default Message;
