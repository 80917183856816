import { Card, CardContent, Stack, Typography } from '@mui/material';

type IDashboardsBigBadge = {
  title: string | number;
  subtitle: string;
  status: string;
};

const DashboardsBadgeBig = (props: IDashboardsBigBadge) => {
  const { title, subtitle, status } = props;

  return (
    <Card
      sx={{ mb: 4 }}
      style={{
        width: '228px',
        height: '116px',
        borderRadius: '20px',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <CardContent
        style={{
          padding: '16px',
        }}
      >
        <Stack direction="column">
          <Typography mb={1.8} mt={0.8} variant="paragraph4" color="#626C77">
            {title}
          </Typography>
          <Typography mb={0.8} variant="paragraphBold3" color="#626C77">
            {subtitle}
          </Typography>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100px',
              padding: '2px 8px',
              background:
                status === 'Закрыт' || status === 'Норма'
                  ? 'rgba(18, 178, 63, 0.20)'
                  : 'rgba(236, 59, 3, 0.20)',
            }}
          >
            <Typography
              component="p"
              sx={{ fontSize: '10px' }}
              color={status === 'Закрыт' || status === 'Норма' ? '#12B23F' : '#EC3B03'}
            >
              {status}
            </Typography>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DashboardsBadgeBig;
