import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AscendingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M8.78087 5.97609C8.38054 5.47568 7.61946 5.47568 7.21913 5.97609L5.29976 8.3753C4.77595 9.03007 5.24212 10 6.08062 10H9.91938C10.7579 10 11.2241 9.03007 10.7002 8.37531L8.78087 5.97609Z" />
    </SvgIcon>
  );
};

export default AscendingIcon;
