import convertNumbers from './convertNumbers';

const convertHEXToArray = (HEX: string, paramType: string) => {
  let param: string[] = [];
  if (HEX) {
    if (/^[fF]*$/.test(HEX)) {
      param = ['all'];
    } else {
      let binary = convertNumbers.hex2bin(HEX);
      if (paramType === 'dm' && binary.length < 32) {
        binary = binary.padStart(32, '0');
      } else if (paramType === 't' && binary.length < 24) {
        binary = binary.padStart(24, '0');
      } else if (paramType === 'am' && binary.length < 16) {
        binary = binary.padStart(16, '0');
      }
      binary
        .split('')
        .forEach((item, index) => {
          if (item === '1') {
            param.push((paramType === 't' ? index : index + 1).toString());
          }
        });
    }
  }

  return param;
};

export default convertHEXToArray;
