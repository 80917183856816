import * as ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";

import App from './App';

import 'leaflet/dist/leaflet.css';

Sentry.init({
  dsn: 'https://bf430f7e3d024ccb81b4fa77f0164ecb@sentry.obs.mts.ru/526',
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/vodokanal\.mts\.ru/],
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(<App />, document.getElementById('root'));
