import { LOGIN_URL, CURRENT_USER_DATA_URL, ENV_URL } from '../constants';

type IFetchDataParams = {
  method: string;
  body?: unknown;
}

const fetchData = (path: string, options?: IFetchDataParams) => {
  return fetch(path, {
    method: options?.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: options?.body ? JSON.stringify(options.body) : undefined,
  }).then(async (response) => {
    const { status, ok, url, redirected } = response;
    const data = await response.json();

    if (redirected || url === '/noaccess') {
      window.location.assign(url);
    } else if (ok || status === 404) {
      return data;
    } else if (status === 401) {
      if (path === CURRENT_USER_DATA_URL || path === ENV_URL) {
        window.location.assign('/landing');
      } else {
        window.location.assign(LOGIN_URL);
      }
      throw new Error(`Пользователь не авторизован: ${data.detail}`)
    } else {
      throw new Error(`Ошибка выполнения запроса: ${data.detail}`)
    }
  });
};

export default fetchData;
