import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { PieChart, Pie, Cell, Sector } from 'recharts';

import WidgetBox from '../../../components/WidgetBox';
import { IWidgetBoxProps } from '../../../types';

type IDashboardsGaugeProps = {
  description?: string;
  value: number;
  min: number;
  max: number;
  unit: string;
} & Omit<IWidgetBoxProps, 'children'>;

const ActiveShape = (props: any) => {
  const theme = useTheme();

  const RADIAN = Math.PI / 180;
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload } = props;

  const sin = Math.sin(-RADIAN * endAngle);
  const cos = Math.cos(-RADIAN * endAngle);
  const ey = cy + outerRadius;
  const sx = cx + (outerRadius - 25) * cos;
  const sy = cy + (outerRadius - 25) * sin;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={outerRadius - 15}
        stroke="none"
        fill="#fff"
        filter="url(#shadow)"
      />
      {payload.description && (
        <text
          x={cx}
          y={cy}
          dy={-20}
          textAnchor="middle"
          fontSize="12"
          color={theme.palette.text.secondary}
        >
          {payload.description}
        </text>
      )}
      <text
        x={cx}
        y={cy}
        dy={10}
        textAnchor="middle"
        fontFamily="MTSWide, sans-serif"
        fontSize="20"
      >
        {`${payload.value} ${payload.unit}`}
      </text>
      {payload.value > 0 && (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill="#ff0000"
          stroke="none"
          mask="url(#barMask)"
        />
      )}
      <circle cx={sx} cy={sy} r={2} fill={theme.palette.primary.main} stroke="none" />
      <text x={cx - 60} y={ey} textAnchor="end" fontSize="12" color={theme.palette.text.secondary}>
        {payload.min}
      </text>
      <text
        x={cx + 60}
        y={ey}
        textAnchor="start"
        fontSize="12"
        color={theme.palette.text.secondary}
      >
        {payload.max}
      </text>
      <line
        x1={cx - 53}
        x2={cx - 57}
        y1={ey - 23}
        y2={ey - 18}
        stroke={theme.palette.icons.secondary}
      />
      <line
        x1={cx + 53}
        x2={cx + 57}
        y1={ey - 23}
        y2={ey - 18}
        stroke={theme.palette.icons.secondary}
      />
      <mask id="barMask">
        <circle
          cx={cx}
          cy={cy}
          r={outerRadius}
          strokeWidth="10"
          strokeDasharray="2 4"
          stroke="#fff"
          fill="none"
        />
      </mask>
      <filter id="shadow">
        <feDropShadow dx="0" dy="8" stdDeviation="6" floodColor="#000" floodOpacity="0.08" />
        <feDropShadow dx="0" dy="4" stdDeviation="8" floodColor="#000" floodOpacity="0.12" />
      </filter>
    </g>
  );
};

const DashboardsGauge = (props: IDashboardsGaugeProps) => {
  const { description, value, min, max, unit, ...rest } = props;

  const START_ANGLE = 230;

  const data = [
    {
      description: description,
      value: value,
      fakeValue: 1,
      min: min,
      max: max,
      unit: unit,
    },
  ];

  let endAngle = ((((value + Math.abs(min)) * 100) / (max + Math.abs(min))) * -280) / 100 + 230;

  return (
    <WidgetBox {...rest} align="center">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 200,
        }}
      >
        <PieChart width={178} height={200}>
          <Pie
            dataKey={value > 0 ? 'value' : 'fakeValue'}
            data={data}
            innerRadius={84}
            outerRadius={89}
            startAngle={START_ANGLE}
            endAngle={endAngle}
            activeIndex={0}
            activeShape={<ActiveShape />}
          >
            <Cell />
          </Pie>
        </PieChart>
      </Box>
    </WidgetBox>
  );
};

export default DashboardsGauge;
