import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d="M7.24999 12.2496C7.24999 12.664 7.58578 13 7.99999 13C8.41421 13 8.74999 12.664 8.74999 12.2496V8.74707L12.25 8.74707C12.6642 8.74707 13 8.4111 13 7.99666C13 7.58223 12.6642 7.24626 12.25 7.24626L8.74999 7.24626L8.75 3.7504C8.75 3.33597 8.41421 3 8 3C7.58579 3 7.25 3.33597 7.25 3.7504L7.24999 7.24626L3.75 7.24627C3.33579 7.24627 3 7.58223 3 7.99667C3 8.41111 3.33579 8.74707 3.75 8.74707L7.24999 8.74707V12.2496Z" />
    </SvgIcon>
  );
};

export default PlusIcon;
