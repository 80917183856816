import { styled } from '@mui/material/styles';

type IBadgeProps = {
  color?: string;
  textColor?: string;
  size?: 'extrasmall' | 'small' | 'medium' | 'large';
};

const Badge = styled('div', {
  name: 'Badge',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'textColor' && prop !== 'size',
  overridesResolver: (props, styles) => [styles.root],
})<IBadgeProps>(({ theme, color, textColor, ...props }) => ({
  backgroundColor: color ?? theme.palette.background.secondary,
  color: textColor
    ? textColor
    : color
    ? theme.palette.getContrastText(color)
    : theme.palette.text.primary,
}));
export default Badge;
