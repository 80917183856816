import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Typography from '@mui/material/Typography';

import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

import DashboardsParams from './DashboardsParams';
import DashboardsWidgets from './DashboardsWidgets';
import useFetchDashboard from './hooks/useFetchDashboard';
import useWebSocketDashboard from './hooks/useWebSocketDashboard';

const Dashboards = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let dashboardTypeParam = searchParams.get('dashboard_type') || '';
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let deviceIdParam = searchParams.get('device_id') || '';
  let groupNameParam = searchParams.get('group_name') || null;
  let excludeDeviceIdsParam = searchParams.get('exc_device_ids');
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { isFetching, isError, data, refetch } = useFetchDashboard(
    deviceTypeParam,
    dashboardTypeParam,
    dateFromParam,
    dateToParam,
    deviceIdParam,
    groupNameParam,
    excludeDeviceIdsParam,
    temperatureMeasureParam,
  );

  const { isLoading } = useWebSocketDashboard(
    deviceTypeParam,
    dashboardTypeParam,
    deviceIdParam,
    temperatureMeasureParam,
  );

  useEffect(() => {
    //Перезагружаем данные при использовании браузерной навигации
    if (Array.from(searchParams.entries()).length) {
      if (dashboardTypeParam !== 'real_time') {
        refetch();
      }
      //Удаляем данные дашборда при сбросе параметров
    } else {
      queryClient.setQueryData('dashboardData', null);
    }
  }, [dashboardTypeParam, refetch, searchParams, queryClient]);

  useEffect(() => {
    if (data) {
      sendMetrik(
        'vntVdknl',
        'dashbordy',
        'element_show',
        'sformirovannyi_dashbord',
        null,
        userData?.permissions[0].uuid,
        userData ? '1' : '0',
        '/dashboards',
        null,
        null,
        null,
        'interactions',
        userData?.profile_type,
        'web',
      );
    }
  }, [data, userData]);

  let results = null;

  if (isFetching || isLoading) {
    results = <Spinner data-testid="loader" />;
  } else if (isError) {
    results = <Message>Не получилось загрузить данные дашборда</Message>;
  } else if (data?.detail === 'Device not found' || data?.detail === 'Data not found') {
    results = <Message>Устройство с таким номером не найдено</Message>;
  } else if (data?.total_sum === null) {
    results = <Message>Данные по устройству отсутствуют</Message>;
  } else if (data) {
    results = <DashboardsWidgets />;
  }

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Дашборды
      </Typography>
      <DashboardsParams isLoading={isFetching || isLoading} />
      {results}
    </>
  );
};

export default Dashboards;
