export const REQUIRED_FIELD = 'Обязательно для заполнения';
export const MAX_LENGTH_STR = 'Превышено максимальное количество символов';
export const EMAIL_REGEXP = /^[_a-z0-9-\\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;
export const NAME_REGEXP = /^[а-яё]+(?:[ -][а-яё]+)*$/i; // только кириллица

export const nameValidation = {
    required: REQUIRED_FIELD,
    validate: (value: string) => {

        if (!value.match(NAME_REGEXP)) return 'Имя введено некорректно'
        if (value.length > 71) return MAX_LENGTH_STR

        return true;
    } 
};

export const phoneValidation = {
    required: REQUIRED_FIELD,
    validate: (value: string) => {

        // вытаскиваем числа из строки и считаем их количество
        let i = value.length - value.replace(/\d/gm,'').length;
        if( i < 11) return 'Введено минимальное количество символов'

        return true;
    } 
};

export const emailValidation = {
    required: REQUIRED_FIELD,
    validate: (value: string) => {

        if (!value.match(EMAIL_REGEXP)) return 'Email введен некорректно'
        if (value.length > 71) return MAX_LENGTH_STR

        return true;
    } 
};

export const companyValidation = {
    required: REQUIRED_FIELD,
    validate: (value: string) => {

        if (value.length > 71) return MAX_LENGTH_STR

        return true
    }
};

export const commentValidation = {
    validate: (value: string) => {

        if (value.length > 512) return MAX_LENGTH_STR

        return true
    }
};