import { isValid, fromUnixTime, format } from 'date-fns';

import { DATE_FORMAT } from '../constants';

const convertTimestampToDate = (timestamp: string | number, dateFormat?: string) => {
    if (typeof timestamp === "string") {
      timestamp = parseInt(timestamp);
    }
    const date = fromUnixTime(timestamp);
    const formattedDate = isValid(date) ? format(date, dateFormat || DATE_FORMAT) : "";

    return formattedDate;
  };

export default convertTimestampToDate;