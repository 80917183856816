import React, { useRef, useState } from 'react';

import { useQueryClient } from 'react-query';

import { Controller, useForm, SubmitHandler, useFormState } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';

import counterImg from '../../images/counterImg.png';
import macbookImg from '../../images/macbookImg.png';
import nbiotImg from '../../images/nbiotImg.png';
import sensorauthoImg from '../../images/sensorauthoImg.png';
import sensortempImg from '../../images/sensortempImg.png';
import signaldevice from '../../images/signaldevice.png';
import { IFeedbackForm, IUser } from '../../types';
import {
  nameValidation,
  phoneValidation,
  emailValidation,
  companyValidation,
  commentValidation,
} from '../../utils/feedbackFormValidation';
import sendMetrik from '../../utils/sendMetrik';
import { ShieldIcon, BatteryIcon, ClockIcon, StarIcon } from '../icons/LandingIcons';
import {
  CounterModal,
  NbIotModal,
  AuthoSensorModal,
  TempSensorModal,
  SignalDeviceModal,
  FeedbackModal,
} from '../LandingModals';

import LandingStyled from './LandingStyled';

const Landing = () => {
  //состояния модалок для устройств
  const [openCounterModal, setOpenCounterModal] = useState(false);
  const [openNbIotModal, setOpenNbIotModal] = useState(false);
  const [openAuthoSensorModal, setOpenAuthoSensorModal] = useState(false);
  const [openTempSensorModal, setOpenTempSensorModal] = useState(false);
  const [openSignalDeviceModal, setOpenSignalDeviceModal] = useState(false);

  // состояние модалки обратной связи
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

  // плавный переход к нужному блоку
  const toDesiredSection = useRef<HTMLInputElement>(null);
  const scrollDown = () => toDesiredSection.current?.scrollIntoView({ behavior: 'smooth' });

  // обработка формы обратной связи
  const { handleSubmit, control, reset } = useForm<IFeedbackForm>({ mode: 'onChange' });
  const { errors } = useFormState({ control });

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const onSubmit: SubmitHandler<IFeedbackForm> = async (data) => {
    axios.post('/api/services/registration_request', {
      name: data.name,
      phone_number: data.phone,
      email: data.email,
      company_name: data.company,
      comment: data.comment,
    });
    setOpenFeedbackModal(true);
  };

  return (
    <div className="landing-wrapper" style={{ width: '100%', overflow: 'auto' }}>
      <Container>
        <LandingStyled>
          <Box className="cover">
            <Box className="cover-text-block">
              <Typography variant="h1" className="cover-header">
                Цифровой
                <br />
                водоканал
              </Typography>
              <Typography className="content-text">
                Решение для сбора и анализа информации с приборов учета воды и датчиков давления
              </Typography>
              <Button className="btn-action" onClick={scrollDown}>
                Воспользоваться услугой
              </Button>
            </Box>
          </Box>
          <Box className="landing-description">
            <Typography variant="h2" className="description-header">
              Цифровой водоканал
            </Typography>
            <Typography className="content-text" style={{ paddingBottom: 0, textAlign: 'center' }}>
              <span style={{ fontWeight: 700 }}>IoT-решение</span> для сбора и анализа данных с
              приборов учета, контроля систем водоснабжения. Позволяет сократить время реагирования
              на аварийную ситуацию и помогает определить размер недополученной выручки. Решение
              включает в себя: разные виды оборудования (счетчики воды, УСПД, датчики давления и
              датчики уровня воды).
            </Typography>
          </Box>
          <Box className="landing-benefits">
            <Typography variant="h2" className="benefits-header description-header">
              Наши преимущества
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={1}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '535px', marginRight: '122px' }}>
                <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                  <ShieldIcon></ShieldIcon>
                  <div>
                    <Typography variant="h3" className="benefits-content">
                      Эффективность
                    </Typography>
                    <Typography className="content-text" style={{ padding: '0 0 0 26px' }}>
                      Снижение аварийности в результате мониторинга и анализа информации с устройств
                    </Typography>
                  </div>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <BatteryIcon></BatteryIcon>
                  <div>
                    <Typography variant="h3" className="benefits-content">
                      Автономность
                    </Typography>
                    <Typography className="content-text" style={{ padding: '0 0 0 26px' }}>
                      Работа от автономного источника питания до 6 лет
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '485px' }}>
                <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                  <ClockIcon></ClockIcon>
                  <div>
                    <Typography variant="h3" className="benefits-content">
                      Оперативность
                    </Typography>
                    <Typography className="content-text" style={{ padding: '0 0 0 26px' }}>
                      Гибкая настройка системы реагирования на возникновение внештатных ситуаций
                    </Typography>
                  </div>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <StarIcon></StarIcon>
                  <div>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' }}
                      variant="h3"
                      className="benefits-content"
                    >
                      Новые технологии
                      <div className="benefits-content soon">
                        <Typography className="soon-text" sx={{ color: '#FFFFFF' }}>
                          Скоро!
                        </Typography>
                      </div>
                    </Typography>
                    <Typography className="content-text" style={{ padding: '0 0 0 26px' }}>
                      Развитая сеть NB-IoT
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box className="landing-devices">
            <Typography className="description-header" style={{ fontSize: '32px' }}>
              Устройства
            </Typography>
            <Typography
              className="content-text"
              style={{ paddingBottom: '0', marginBottom: '32px', textAlign: 'center' }}
            >
              С полным списком устройств вы сможете ознакомиться при подаче заявки.
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="baseline">
              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '508px', marginRight: '41px' }}>
                <Box className="device-block" sx={{ marginBottom: '24px' }}>
                  <Box className="device-block-content">
                    <Typography className="device-content" style={{ paddingLeft: '0' }}>
                      Счетчик воды
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 500,
                        color: '#626C77',
                      }}
                    >
                      Параметры ПУ:
                    </Typography>
                    <ul
                      className="list parameter-text"
                      style={{ paddingLeft: '22px', margin: '2px 0 0' }}
                    >
                      <li>
                        <Typography className="list parameter-text">
                          метрологические характеристики, превосходящие класс «С»
                        </Typography>
                      </li>
                      <li>
                        <Typography className="list parameter-text">
                          индукционный (бесконтактный) съём сигнала с турбины и электронное
                          индикаторное устройство
                        </Typography>
                      </li>
                      <li>
                        <Typography className="list parameter-text">
                          невосприимчивость к сильным магнитным полям
                        </Typography>
                      </li>
                    </ul>
                    <Button
                      className="parameter-btn"
                      variant="text"
                      color="link"
                      onClick={() => setOpenCounterModal(true)}
                    >
                      Все параметры
                    </Button>
                  </Box>
                  <div>
                    <img
                      className="device-img"
                      src={counterImg}
                      alt="counter"
                      style={{ top: '16px', left: '374px' }}
                    />
                  </div>
                </Box>
                <Box className="device-block">
                  <Box className="device-block-content">
                    <Typography className="device-content" style={{ paddingLeft: '0' }}>
                      Датчик давления с автономным питанием
                    </Typography>
                    <Typography className="parameter-text">
                      Работает на сети NB-IoT и предназначен для измерения избыточного давления
                      жидкостей и газов с передачей текущих и архивных (часовых) показаний на
                      сервер. Данные передаются на сервер ПАО «МТС» с возможностью выгрузки данных с
                      использованием API функций
                    </Typography>
                    <Button
                      className="parameter-btn"
                      variant="text"
                      color="link"
                      onClick={() => setOpenAuthoSensorModal(true)}
                    >
                      Все параметры
                    </Button>
                  </Box>
                  <div>
                    <img
                      className="device-img"
                      src={sensorauthoImg}
                      alt="autho sensor"
                      style={{ left: '360px', top: '70px' }}
                    />
                  </div>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '508px' }}>
                <Box
                  className="device-block"
                  style={{ marginBottom: '24px', paddingBottom: '38px' }}
                >
                  <Box className="device-block-content">
                    <Typography className="device-content" style={{ paddingLeft: '0' }}>
                      Модуль передачи данных NB-IOT
                    </Typography>
                    <Typography className="parameter-text">
                      Модуль передачи данных NB-IoT (далее - Модуль) является оконечным устройством
                      беспроводной сети NB-IoT и предназначен для работы в качестве автономного
                      счетчика импульсов с нарастающим итогом. Данные передаются на сервер ПАО «МТС»
                      с возможностью выгрузки данных с использованием API функций
                    </Typography>
                    <Button
                      className="parameter-btn"
                      variant="text"
                      color="link"
                      onClick={() => setOpenNbIotModal(true)}
                    >
                      Все параметры
                    </Button>
                  </Box>
                  <div>
                    <img
                      className="device-img"
                      src={nbiotImg}
                      alt="NB Iot"
                      style={{ top: '73px', left: '380px' }}
                    />
                  </div>
                </Box>
                <Box className="device-block">
                  <Box className="device-block-content">
                    <Typography className="device-content" style={{ paddingLeft: '0' }}>
                      Датчик давления с внешним датчиком температуры
                    </Typography>
                    <Typography className="parameter-text">
                      Работает на сети NB-IoT и предназначен для измерения избыточного давления
                      жидкостей и газов с передачей текущих и архивных (часовых) показаний на
                      сервер. Есть возможность выдачи мгновенных и архивных значений давления
                    </Typography>
                    <Button
                      className="parameter-btn"
                      variant="text"
                      color="link"
                      onClick={() => setOpenTempSensorModal(true)}
                    >
                      Все параметры
                    </Button>
                  </Box>
                  <div>
                    <img
                      className="device-img"
                      src={sensortempImg}
                      alt="sensor temp"
                      style={{ top: '35px', left: '365px' }}
                    />
                  </div>
                </Box>
              </Box>
            </Stack>
            <Box className="device-block" sx={{ width: '508px', margin: '24px auto 0' }}>
              <Box className="device-block-content">
                <Typography className="device-content" style={{ paddingLeft: '0' }}>
                  Устройства сигнализатор уровня воды
                </Typography>
                <Typography className="parameter-text">
                  Предназначен для непрерывного выявления факта и измерения значения уровня
                  повышения жидкости (затопления), а так же для контроля подтопления в помещениях,
                  технологических объектах и на улицах.
                </Typography>
                <Button
                  className="parameter-btn"
                  variant="text"
                  color="link"
                  onClick={() => setOpenSignalDeviceModal(true)}
                >
                  Все параметры
                </Button>
              </Box>
              <div>
                <img
                  className="device-img"
                  src={signaldevice}
                  alt="signaling device"
                  style={{ top: '72px', left: '365px' }}
                />
              </div>
            </Box>
          </Box>
          <Box className="landing-interface">
            <Typography className="description-header" style={{ fontSize: '32px' }}>
              Интерфейс
            </Typography>
            <Stack direction="row" justifyContent="flex-start">
              <Box sx={{ marginTop: '93px', width: '650px' }}>
                <Typography
                  className="interface benefits-content"
                  style={{ fontWeight: 900, paddingLeft: '0' }}
                >
                  Современное решение
                </Typography>
                <Typography className="interface parameter-text">
                  Мы предлагаем решение, при котором вы самостоятельно определяете правила, при
                  которых изменение тех или иных показателей требует внимания инженеров или других
                  специалистов. Такие события фиксируются в онлайн-журнале для последующей обработки
                  и принятия мер по устранению. Для событий, требующих особого внимания, можно
                  настроить E-mail или уведомления в Telegram. Возможна интеграция с{' '}
                  <a
                    href="https://support.mts.ru/mts_koordinator"
                    style={{ color: '#007CFF', textDecoration: 'underline solid #007CFF' }}
                  >
                    МТС Координатор
                  </a>
                </Typography>
                <ul className="list parameter-text" style={{ paddingLeft: '24px' }}>
                  <li className="list parameter-text">
                    Данные с устройств передаются в заранее заданном режиме
                  </li>
                  <li className="list parameter-text">
                    Расчет потребления по часам/дням/неделям/месяцам или за выбранный период
                  </li>
                  <li className="list parameter-text">
                    История показаний, переданных ранее хранится на платформе
                  </li>
                  <li className="list parameter-text">
                    Автоматический контроль заранее установленных параметров датчиков, с внештатной
                    передачей показаний при нарушении
                  </li>
                  <li className="list parameter-text">
                    Проведение сверки текущих показаний счетчика с данными принятыми от владельца
                    узла к учету по фотографии в режиме онлайн
                  </li>
                  <li className="list parameter-text">Подготовка отчетов</li>
                </ul>
              </Box>
              <div>
                <img className="device-img" src={macbookImg} alt="macbook" />
              </div>
            </Stack>
          </Box>
          <Box className="landing-feedback" ref={toDesiredSection}>
            <Typography className="description-header" style={{ color: '#FFFFFF' }}>
              Свяжитесь с нами
            </Typography>
            <Typography
              sx={{
                fontSize: '17px',
                lineHeight: '24px',
                fontWeight: 400,
                color: '#FFFFFF',
                textAlign: 'center',
              }}
            >
              Оставьте свои данные и мы свяжемся с вами в ближайшее время
            </Typography>
            <form className="feedback-form" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                defaultValue={''}
                control={control}
                name="name"
                rules={nameValidation}
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: '32px', width: '400px' }}
                    margin="normal"
                    label="Имя"
                    InputLabelProps={{ style: { color: '#FAFAFA' } }}
                    placeholder="Введите ваше имя"
                    fullWidth={true}
                    type="text"
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'lending',
                        'form_change',
                        'imya',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/landing',
                        field.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    value={field.value}
                    error={!!errors.name?.message}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                defaultValue={''}
                control={control}
                name="phone"
                rules={phoneValidation}
                render={({ field }) => (
                  <InputMask
                    mask="+7 (999)9999999"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'lending',
                        'form_change',
                        'telefon',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/landing',
                        field.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    <TextField
                      style={{ marginTop: '32px', width: '400px' }}
                      label="Телефон"
                      InputLabelProps={{ style: { color: '#FAFAFA' } }}
                      placeholder="Введите ваш номер"
                      fullWidth={true}
                      type="tel"
                      error={!!errors.phone?.message}
                      helperText={errors.phone?.message}
                    />
                  </InputMask>
                )}
              />
              <Controller
                defaultValue={''}
                control={control}
                name="email"
                rules={emailValidation}
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: '32px', width: '400px' }}
                    label="E-mail"
                    InputLabelProps={{ style: { color: '#FAFAFA' } }}
                    placeholder="Введите ваш E-mail"
                    fullWidth={true}
                    type="email"
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'lending',
                        'form_change',
                        'email',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/landing',
                        field.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    value={field.value}
                    error={!!errors.email?.message}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Controller
                defaultValue={''}
                control={control}
                name="company"
                rules={companyValidation}
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: '32px', width: '400px' }}
                    label="Название компании"
                    InputLabelProps={{ style: { color: '#FAFAFA' } }}
                    placeholder="Введите название компании"
                    fullWidth={true}
                    type="text"
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'lending',
                        'form_change',
                        'nazvanie_kompanii',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/landing',
                        field.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    value={field.value}
                    error={!!errors.company?.message}
                    helperText={errors.company?.message}
                  />
                )}
              />
              <Controller
                defaultValue={''}
                control={control}
                name="comment"
                rules={commentValidation}
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: '32px', width: '400px' }}
                    label="Комментарий"
                    InputLabelProps={{ style: { color: '#FAFAFA' } }}
                    placeholder="Оставьте комментарий"
                    fullWidth={true}
                    type="text"
                    onChange={(e) => {
                      field.onChange(e);
                      sendMetrik(
                        'vntVdknl',
                        'lending',
                        'form_change',
                        'kommentarii',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/landing',
                        field.value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    value={field.value}
                    error={!!errors.comment?.message}
                    helperText={errors.comment?.message}
                  />
                )}
              />
              <div style={{ width: '400px' }}></div>
              <Button
                type="submit"
                className="btn-action"
                style={{ width: '400px', margin: '24px auto 0px' }}
                onClick={() => {
                  sendMetrik(
                    'vntVdknl',
                    'lending',
                    'button_click',
                    'svyazatsya',
                    null,
                    userData?.permissions[0].uuid,
                    userData ? '1' : '0',
                    '/landing',
                    null,
                    null,
                    null,
                    'interactions',
                    userData?.profile_type,
                    'web',
                  );
                }}
              >
                Связаться
              </Button>
            </form>
          </Box>
        </LandingStyled>
        <CounterModal
          open={openCounterModal}
          handleClose={() => setOpenCounterModal(false)}
          onClick={() => setOpenCounterModal(false)}
        ></CounterModal>
        <NbIotModal open={openNbIotModal} handleClose={() => setOpenNbIotModal(false)}></NbIotModal>
        <AuthoSensorModal
          open={openAuthoSensorModal}
          handleClose={() => setOpenAuthoSensorModal(false)}
        ></AuthoSensorModal>
        <TempSensorModal
          open={openTempSensorModal}
          handleClose={() => setOpenTempSensorModal(false)}
        ></TempSensorModal>
        <SignalDeviceModal
          open={openSignalDeviceModal}
          handleClose={() => setOpenSignalDeviceModal(false)}
        ></SignalDeviceModal>
        <FeedbackModal
          open={openFeedbackModal}
          handleClose={() => {
            setOpenFeedbackModal(false);
            reset();
          }}
        ></FeedbackModal>
      </Container>
    </div>
  );
};

export default Landing;
