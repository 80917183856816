import ObjectDevices from './ObjectDevices';
import ObjectInfo from './ObjectInfo';

const ObjectDetails = () => {
  return (
    <>
      <ObjectInfo />
      <ObjectDevices />
    </>
  );
};

export default ObjectDetails;
