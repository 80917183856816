import { styled } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

const DataGridPanels = styled(DataGrid, {
  name: 'DataGridPanels',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})<DataGridProps>(() => ({}));

export default DataGridPanels;
