import React, { useCallback, useEffect, useState } from 'react';

import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { FormProvider, useForm, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { endOfDay, format, fromUnixTime, getUnixTime, isBefore, startOfDay } from 'date-fns';

import AscendingIcon from '../../../components/icons/AscendingIcon';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import PlusIcon from '../../../components/icons/PlusIcon';
import QuestionIcon from '../../../components/icons/QuestionIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { CUSTOM_REPORT_TABLE } from '../../../constants';
import { IUser } from '../../../types';
import fetchExcel from '../../../utils/fetchExcel';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchCustomReportData from '../hooks/useFetchCustomReportData';

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-footerContainer ': {
    justifyContent: 'flex-end',
  },
});

const CustomReport = () => {
  let { customId = '' } = useParams();

  // данные для фильтров
  const { dataCustomReport, isLoading, isError } = useFetchCustomReportData(customId);

  // формы для отправки и формирования таблицы и ее файла
  const [formData, setFormData] = useState<any>({ filters: {}, uuid: customId, url: false });
  const [fileFormData, setFileFormData] = useState<any>({});

  // полученные данные для отрисовки таблицы
  const [dataTable, setDataTable] = useState<any>();
  const [isTableError, setIsTableError] = useState(false);

  const [showTable, setShowTable] = useState(false);
  const [showTableSpinner, setShowTableSpinner] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [showFilters, setShowFilters] = useState(false);
  const [hiddenFiltersCount, setHiddenFiltersCount] = useState<number>(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  useEffect(() => {
    if (dataTable) {
      setShowTable(true);
      setShowTableSpinner(false);
      setIsTableError(false);
      setFormData({
        filters: {},
        uuid: customId,
        url: false,
      });

      setSearchParams('');

      sendMetrik(
        'vntVdknl',
        'otchety',
        'element_show',
        'sformirovannyi_otchet',
        null,
        userData?.permissions[0].uuid,
        userData ? '1' : '0',
        '/reports/customReportCreate',
        null,
        null,
        null,
        'interactions',
        userData?.profile_type,
        'web',
      );
    }
  }, [customId, dataTable, setSearchParams, userData]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: newPage - 1,
    }));
  };

  const getHeaderName = (key: string) => {
    if (key === 'id') {
      return '№';
    }

    return key;
  };

  const columns =
    dataTable &&
    Object.keys(dataTable?.data[0])
      .map((key, index) => {
        const columnData = dataTable?.data.map((item: { [x: string]: any }) => item[key]);
        const hasValues = columnData.some(
          (value: null | undefined) => value !== null && value !== undefined,
        );

        if (!hasValues) {
          return null;
        }

        return {
          field: key,
          headerName: getHeaderName(key),
          flex: 1,
          minWidth: key === 'id' ? 85 : 175,
          maxWidth: key === 'id' ? 85 : 175,
          sortable: true,
          renderCell: (params: GridCellParams<any, number>) => {
            const value = params.value;
            if (key === 'Дата и время' || key === 'Дата изготовления датчика') {
              const dateTime = fromUnixTime(value as number);

              return <span>{format(dateTime, 'dd.MM.yyyy HH:mm')}</span>;
            }
          },
        };
      })
      .filter(Boolean);

  if (columns) {
    columns.sort((a: { field: string }, b: { field: string }) => {
      if (a.field === 'id') {
        return -1;
      } else if (b.field === 'id') {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const methods = useForm();
  const { control, reset, setValue, handleSubmit } = methods;

  let watchFields = useWatch({
    control: control,
  });

  useEffect(() => {
    let count = 0;
    dataCustomReport?.filters?.slice(3).forEach((filter: { tech_name: string | number }) => {
      if (watchFields[filter.tech_name]) {
        count++;
      }
    });
    setHiddenFiltersCount(count);
  }, [dataCustomReport?.filters, watchFields]);

  useEffect(() => {
    const isDateSelected = watchFields.date;
    const isIdSelected = watchFields.id;
    const isAssetSelected = watchFields.asset;

    if (dataCustomReport?.shablon_type === 'report_device') {
      setIsButtonDisabled(!isDateSelected?.[0] || !isDateSelected?.[1] || !isIdSelected);

      if (isDateSelected?.[0] && isDateSelected?.[1] && isIdSelected) {
        setIsButtonDisabled(false);
      }
    }

    if (dataCustomReport?.shablon_type === 'report_group') {
      setIsButtonDisabled(!isDateSelected?.[0] || !isDateSelected?.[1] || !isAssetSelected);

      if (isDateSelected?.[0] && isDateSelected?.[1] && isAssetSelected) {
        setIsButtonDisabled(false);
      }
    }
  }, [dataCustomReport?.shablon_type, watchFields]);

  const [dateError, setDateError] = useState('');

  const validateDates = (startDate: number | Date, endDate: number | Date) => {
    if (isBefore(endDate, startDate)) {
      setDateError('Дата должна быть позже начала периода');
    } else {
      setDateError('');
    }
  };

  // отправка формы
  const onSubmit = useCallback(
    async (data: Record<string, unknown>) => {
      setShowTableSpinner(true);
      Object.entries(data).forEach(([key, value]: any) => {
        if (key === 'date') {
          const [startDate, endDate] = value as Date[];
          const unixStartTime = startDate ? getUnixTime(startDate) : '';
          const unixEndTime = endDate ? getUnixTime(endDate) : '';
          formData.filters[key] = [unixStartTime, unixEndTime];
        } else if (value !== '' && value !== null) {
          formData.filters[key] = value;
        }
      });

      setFileFormData({ ...formData, url: true });
      if (dateError) {
        return;
      }

      const response = await fetch(CUSTOM_REPORT_TABLE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        // Обработка полученных данных и обновление состояния таблицы

        setDataTable(result);
      } else {
        // Обработка ошибок при запросе
        setShowTableSpinner(false);
        setFormData({
          filters: {},
          uuid: customId,
          url: false,
        });
        setIsTableError(true);
        console.error('Error:', response.status);
      }
    },
    [customId, dateError, formData],
  );

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    if (Object.keys(params).length > 0) {
      for (const [key, value] of Object.entries(params)) {
        if (key === 'date') {
          const dateArray = JSON.parse(value);
          const startDate = fromUnixTime(dateArray[0]);
          const endDate = fromUnixTime(dateArray[1]);

          setValue('date', [startDate, endDate]);
        } else {
          setValue(key, value);
        }
      }
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setValue]);

  useEffect(() => {
    setFormData((prevState: any) => ({
      ...prevState,
      uuid: customId,
      url: false,
    }));
  }, [customId]);

  const toggleFilters = (prevState: boolean) => {
    setShowFilters(!prevState);
  };

  // отправка и скачивание файла таблицы
  const handleDownloadClick = () =>
    fetchExcel(CUSTOM_REPORT_TABLE, `Отчет ${dataCustomReport?.name}.xlsx`, fileFormData);

  const handleClearFilterClick = () => {
    reset();
    setFormData({
      filters: {},
      uuid: customId,
    });
    setFileFormData({
      filters: {},
      uuid: customId,
    });
  };

  // рендер разных видов фильтров в зависимости от типа полученного фильтра
  const renderFilter = (filter: any) => {
    // обрезаем заголовок до 25 символов
    const truncatedName =
      filter?.name && filter.name.length > 25 ? filter.name.substring(0, 25) + '...' : filter.name;

    // Функция для рендера тултипа
    const renderTooltip = () => {
      if (filter?.name && filter.name.length > 25) {
        return (
          <Tooltip title={filter.name} arrow data-testid="filter-tooltip">
            <div data-testid="filter-tooltip" style={{ height: '11px' }}>
              <QuestionIcon fontSize="small" color="secondary" />
            </div>
          </Tooltip>
        );
      }
    };

    if (filter?.tech_name === 'date') {
      return (
        <>
          <Grid item mobile={12} xs={3}>
            <FormControl margin="none">
              <FormLabel>Начало периода</FormLabel>
              <DatePicker
                value={watchFields[filter.tech_name]?.[0] || null}
                onChange={(date) => {
                  setValue(
                    filter.tech_name,
                    date ? [startOfDay(date), watchFields[filter.tech_name]?.[1] ?? null] : null,
                  );
                  validateDates(date, watchFields[filter.tech_name]?.[1]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="MuiTextField-alt"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Укажите начало периода',
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item mobile={12} xs={3}>
            <FormControl margin="none">
              <FormLabel>Конец периода</FormLabel>
              <DatePicker
                value={watchFields[filter.tech_name]?.[1] || null}
                onChange={(date) => {
                  setValue(
                    filter.tech_name,
                    date ? [watchFields[filter.tech_name]?.[0], endOfDay(date)] : null,
                  );
                  validateDates(watchFields[filter.tech_name]?.[0], date);
                }}
                minDate={watchFields[filter.tech_name]?.[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="MuiTextField-alt"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Укажите конец периода',
                    }}
                    error={!!dateError}
                    helperText={dateError}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    } else if (filter?.values) {
      return (
        <Grid item mobile={12} xs={3}>
          <FormControl margin="none">
            <FormLabel sx={{ display: 'flex' }}>
              {truncatedName} {renderTooltip()}
            </FormLabel>
            <Autocomplete
              value={watchFields[filter?.tech_name] || null}
              onChange={(e, value) => setValue(filter?.tech_name, value)}
              options={filter?.values || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="MuiTextField-alt"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const inputElement = e.target as HTMLInputElement;
                      const inputValue = inputElement.value;
                      const option = filter?.values.find((value: string) =>
                        value.toLowerCase().startsWith(inputValue.toLowerCase()),
                      );
                      if (option) {
                        setValue(filter?.tech_name, option);
                      }
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      );
    } else {
      return (
        <Grid item mobile={12} xs={3}>
          <FormControl margin="none">
            <FormLabel sx={{ display: 'flex' }}>
              {truncatedName} {renderTooltip()}
            </FormLabel>
            <TextField
              {...methods.register(filter?.tech_name)}
              value={watchFields[filter?.tech_name] || ''}
              fullWidth
              className="MuiTextField-alt"
            />
          </FormControl>
        </Grid>
      );
    }
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <Box sx={{ padding: '32px 40px' }}>
      <Breadcrumbs style={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/reports/custom">
          Отчеты
        </Link>
        <span>Просмотр отчета</span>
      </Breadcrumbs>
      {isError ? (
        <Message>Не получилось загрузить данные</Message>
      ) : (
        <>
          <Stack flexDirection="row" alignItems="baseline">
            <Typography variant="h1" gutterBottom>
              Просмотр отчета
            </Typography>
            {dataTable && (
              <IconButton
                color="primary"
                size="small"
                component="a"
                href="#"
                onClick={(e: any) => {
                  e.preventDefault();
                  handleDownloadClick();
                }}
                download
                sx={{
                  ml: 2,
                }}
              >
                <DownloadIcon />
              </IconButton>
            )}
          </Stack>
          <Box
            sx={{
              marginBottom: 3,
              padding: 3,
              background: (theme) => theme.palette.background.secondary,
              borderRadius: '16px',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormProvider {...methods}>
                <Grid container columnSpacing={2}>
                  <Grid item mobile={12} xs={8}>
                    <Grid container spacing={2}>
                      {dataCustomReport?.filters?.slice(0, 3).map((filter: any, index: number) => (
                        <React.Fragment key={index}>{renderFilter(filter)}</React.Fragment>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid item mobile={12} xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingTop: 2,
                      }}
                    >
                      {dataCustomReport?.filters?.length > 3 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginRight: 3,
                          }}
                        >
                          <Button
                            variant="text"
                            size="small"
                            color="link"
                            onClick={() => toggleFilters(showFilters)}
                          >
                            {showFilters ? `Скрыть фильтры` : `Дополнительные фильтры`}
                            {hiddenFiltersCount > 0 && ` (${hiddenFiltersCount})`}
                          </Button>
                          <Tooltip
                            arrow
                            title={
                              <>
                                {showFilters ? 'Скрыть ' : 'Показать '}
                                дополнительные фильтры для отчетов :
                                <Box>
                                  {dataCustomReport?.filters
                                    .slice(3)
                                    .map((filter: any, index: number) => (
                                      <Typography key={index} variant="inherit" component="span">
                                        - {filter?.name}
                                        <br />
                                      </Typography>
                                    ))}
                                </Box>
                              </>
                            }
                          >
                            <div data-testid="filter-tooltip">
                              <QuestionIcon fontSize="small" color="secondary" />
                            </div>
                          </Tooltip>
                        </Box>
                      )}
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={isButtonDisabled}
                          type="submit"
                          title="Сформировать"
                          data-testid="submit"
                        >
                          {showTableSpinner ? <Spinner /> : <PlusIcon />}
                        </IconButton>
                        <IconButton size="small" onClick={handleClearFilterClick}>
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item mobile={12} xs={8} mt={2}>
                    <Grid container spacing={2}>
                      {showFilters &&
                        dataCustomReport?.filters
                          ?.slice(3)
                          .map((filter: any, index: number) => (
                            <React.Fragment key={index}>{renderFilter(filter)}</React.Fragment>
                          ))}
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </form>
          </Box>
          {isTableError ? (
            <Message>Не получилось загрузить данные отчета</Message>
          ) : (
            showTable && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: 3,
                    padding: 3,
                    background: (theme) => theme.palette.background.secondary,
                    borderRadius: '16px',
                  }}
                >
                  {Object.entries(dataTable?.header || {}).map(
                    ([key, value]: [string, any], index) => {
                      if (!key || !value) {
                        return null;
                      }

                      return (
                        <Typography
                          key={index}
                          sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            marginLeft: index !== 0 ? '60px' : 0,
                          }}
                        >
                          {`${key}: `}
                          <Typography component="span" sx={{ fontSize: '14px' }}>
                            {value}
                          </Typography>
                        </Typography>
                      );
                    },
                  )}
                </Box>

                <Box
                  sx={{
                    padding: 3,
                    background: (theme) => theme.palette.background.secondary,
                    borderRadius: '16px',
                  }}
                >
                  <Typography variant="h1" mb={1.5}>
                    {dataTable?.name}
                  </Typography>
                  <Stack direction="row" mb={1.5}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 500 }}>
                      Описание:&nbsp;
                    </Typography>
                    <Typography sx={{ fontSize: '17px', fontWeight: 400 }}>
                      {dataTable?.description}
                    </Typography>
                  </Stack>
                  <StyledDataGrid
                    rows={dataTable.data}
                    columns={columns}
                    paginationModel={paginationModel}
                    pageSizeOptions={[10, 50]}
                    // rowCount={dataTable?.data?.length}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    components={{
                      ColumnSortedAscendingIcon: AscendingIcon,
                      ColumnSortedDescendingIcon: DescendingIcon,
                    }}
                    localeText={{
                      noRowsLabel: 'Нет данных',
                      noResultsOverlayLabel: 'Нет данных по заданным параметрам',
                    }}
                  />
                  <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                      count={Math.ceil(dataTable?.data?.length / paginationModel.pageSize)}
                      page={paginationModel.page + 1}
                      onChange={handleChangePage}
                      shape="rounded"
                    />
                  </Box>
                </Box>
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default CustomReport;
