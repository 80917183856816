import { useQuery } from 'react-query';

import { GET_STANDART_REPORTS_RESULTS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';

const useFetchStandartReportsResults = (
  deviceType: string,
  reportType: string,
  temperatureMeasure: string | null,
  dateFrom: string | null,
  dateTo: string | null,
  groupName: string | null,
  address: string | null,
) => {
  const UTCDateFrom = dateFrom ? getUTCTimestamp(parseInt(dateFrom)) : null;
  const UTCDateTo = dateTo ? getUTCTimestamp(parseInt(dateTo)) : null;

  return useQuery(
    [
      'reportsStandartResultsData',
      {
        deviceType,
        reportType,
        temperatureMeasure,
        dateFrom,
        dateTo,
        groupName,
        address,
      }
    ],
    () =>
      fetchData(
        GET_STANDART_REPORTS_RESULTS_URL(
          deviceType,
          reportType,
          temperatureMeasure,
          UTCDateFrom,
          UTCDateTo,
          groupName,
          address,
        ),
      ),
    {
      enabled: false,
      cacheTime: 0,
    },
  );
};

export default useFetchStandartReportsResults;
