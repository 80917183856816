import { useQuery } from 'react-query';

import { GET_OBJECT_DATA_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchObjectInfo = (type: string, id: string, enabled: boolean) => {
  const {
    isLoading,
    isError,
    data
  } = useQuery(['objectData', id],
  () => fetchData(GET_OBJECT_DATA_URL(type, id)), {
    enabled
  });

  return {
    isLoading,
    isError,
    data
  }
}

export default useFetchObjectInfo;
