import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 44 44" {...props}>
      <g clipPath="url(#clip0_1583_240616)">
        <path
          fillRule="evenodd"
          d="M0.194233 16.9251C0.670174 10.5302 0.908144 7.33275 4.11752 4.12337C7.3269 0.913998 10.5243 0.676029 16.9192 0.20009C18.577 0.0767148 20.287 0.00195312 21.997 0.00195312C23.707 0.00195312 25.417 0.0767161 27.0747 0.200092C33.4696 0.676033 36.6671 0.914004 39.8765 4.12338C43.0858 7.33276 43.3238 10.5302 43.7997 16.9251C43.9231 18.5828 43.9979 20.2928 43.9979 22.0029C43.9979 23.7129 43.9231 25.4229 43.7997 27.0806C43.3238 33.4755 43.0858 36.6729 39.8765 39.8823C36.6671 43.0917 33.4696 43.3297 27.0747 43.8056C25.417 43.929 23.707 44.0037 21.997 44.0037C20.287 44.0037 18.577 43.929 16.9192 43.8056C10.5243 43.3297 7.32689 43.0917 4.11751 39.8823C0.908139 36.6729 0.670169 33.4755 0.194231 27.0806C0.0708554 25.4229 -0.00390625 23.7129 -0.00390625 22.0028C-0.00390625 20.2928 0.0708567 18.5828 0.194233 16.9251Z"
          fill="#FAC031"
        />
        <path
          fillRule="evenodd"
          d="M22.0027 24.1317C23.3294 24.1317 24.4049 23.0394 24.4049 21.6921L24.4049 11.9396C24.4049 10.5922 23.3294 9.5 22.0027 9.5C20.676 9.5 19.6005 10.5923 19.6005 11.9396L19.6005 21.6921C19.6005 23.0394 20.676 24.1317 22.0027 24.1317ZM25 31.4533C25 29.7706 23.6569 28.4065 22 28.4065C20.3431 28.4065 19 29.7706 19 31.4533C19 33.1359 20.3431 34.5 22 34.5C23.6569 34.5 25 33.1359 25 31.4533Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1583_240616">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default WarningIcon;
