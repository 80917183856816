import { useQuery } from 'react-query';

import { NOTIFICATIONS_DEVICE_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchNotificationsDeviceTypes = () => {
  return useQuery('notificationsDeviceTypesData', () => fetchData(NOTIFICATIONS_DEVICE_TYPES_URL));
};

export default useFetchNotificationsDeviceTypes;
