import { useQuery } from 'react-query';

import { ENV_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchEnv = (enabled: boolean) => {
  return useQuery('envData', () => fetchData(ENV_URL), {
    enabled: enabled
  });
};

export default useFetchEnv;
