import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DragAndDropIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 6 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8C1.55228 8 2 8.44772 2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44772 0.447715 8 1 8ZM1 4C1.55228 4 2 4.44772 2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4ZM2 1C2 0.447716 1.55228 0 1 0C0.447715 0 0 0.447716 0 1C0 1.55229 0.447715 2 1 2C1.55228 2 2 1.55229 2 1ZM5 8.00093C5.55228 8.00093 6 8.44865 6 9.00093C6 9.55322 5.55228 10.0009 5 10.0009C4.44772 10.0009 4 9.55322 4 9.00093C4 8.44865 4.44772 8.00093 5 8.00093ZM5 4.00093C5.55228 4.00093 6 4.44865 6 5.00093C6 5.55322 5.55228 6.00093 5 6.00093C4.44772 6.00093 4 5.55322 4 5.00093C4 4.44865 4.44772 4.00093 5 4.00093ZM6 1.00093C6 0.448647 5.55228 0.00093174 5 0.00093174C4.44772 0.00093174 4 0.448647 4 1.00093C4 1.55322 4.44772 2.00093 5 2.00093C5.55228 2.00093 6 1.55322 6 1.00093Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default DragAndDropIcon;
