import { Outlet } from "react-router-dom";

import Main from "../../components/Layout/Main";

const NotificationsPage = () => {
  return (
    <Main>
      <Outlet />
    </Main>
  );
};

export default NotificationsPage;
