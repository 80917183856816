import { BarChart, Legend, ResponsiveContainer, CartesianGrid, XAxis, YAxis } from 'recharts';

import convertTimestampToDate from '../../../utils/convertTimestampToDate';

type IDashboardsBarChartProps = {
  data: any;
  dataId: string;
  payload: any;
  dataBar: any;
};

export const tooltipStyle = {
  backgroundColor: '#1D2023',
  fontSize: '12px',
  color: '#FFFFFF',
  padding: '.5%',
};

export const tooltipContent = (e: any) => {
  return <div>{'Потребление: ' + e.consumption + ' м³'}</div>;
};

export const DashboardsBarChart = (props: IDashboardsBarChartProps) => {
  const { data, dataId, payload, dataBar } = props;

  return (
    <>
      <ResponsiveContainer width="100%" height={385}>
        <BarChart height={300} data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            height={36}
            axisLine={false}
            tickLine={false}
            dataKey={dataId}
            allowDuplicatedCategory={false}
            tickFormatter={(tick) => convertTimestampToDate(tick, 'dd.MM')}
            angle={90}
            tick={{ fontSize: '12px', color: '#626C77' }}
            textAnchor="start"
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            width={80}
            tick={{ fontSize: '12px', color: '#626C77' }}
          />
          <Legend
            align="right"
            verticalAlign="top"
            iconSize={6}
            formatter={(value, entry, index) => <span style={{ color: '#969FA8' }}>{value}</span>}
            wrapperStyle={{ fontSize: '12px', top: -51 }}
            payload={payload}
          />
          {dataBar}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
