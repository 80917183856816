import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import WidgetBox from '../../../components/WidgetBox';
import { IWidgetBoxProps } from '../../../types';

type IDashboardsLeadParamProps = {
  value: any;
} & Omit<IWidgetBoxProps, 'children'>;

const DashboardsLeadParamStyled = styled(Typography)(({theme}) => ({
  marginTop: 16,
  fontFamily: 'MTSWide',
  fontSize: 25,
  lineHeight: 1,
  color: theme.palette.primary.main,
}));

const DashboardsLeadParam = (props: IDashboardsLeadParamProps) => {
  const { value, ...rest } = props;

  return (
    <WidgetBox {...rest}>
      <DashboardsLeadParamStyled>
        {value}
      </DashboardsLeadParamStyled>
    </WidgetBox>
  );
};

export default DashboardsLeadParam;
