import { useParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { useFormContext, useFieldArray, useWatch, Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ChipsField from '../../components/ChipsField';
import FormLabel from '../../components/FormLabel';
import QuestionIcon from '../../components/icons/QuestionIcon';
import {
  NUMBER_REGEX,
  PRESSURE_SENSOR_PM_OPTIONS,
  PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS,
  PRESSURE_SENSOR_AM_OPTIONS,
  PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS,
  DATD_WITH_2_CHANNELS_PM_OPTIONS,
  DATD_WITH_4_CHANNELS_PM_OPTIONS,
  DATD_WITH_10_CHANNELS_PM_OPTIONS,
} from '../../constants';

import type { IDeviceProfiles, IDevice } from '../../types';

const DeviceClientAttributes = () => {
  let { deviceId } = useParams();

  const queryClient = useQueryClient();
  const deviceProfilesData: IDeviceProfiles | undefined =
    queryClient.getQueryData('deviceProfilesData');
  const deviceData: IDevice | undefined = queryClient.getQueryData(['deviceData', deviceId]);
  const groupsData: any[] | undefined = queryClient.getQueryData([
    'objectGroupsData',
    deviceData?.customerId,
  ]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields: clientAttributeFields } = useFieldArray({
    name: 'clientAttributes',
    control,
  });

  const liveModeWatch = useWatch({
    control,
    name: 'live_mode',
  });

  const liveModeDaysOptions = ['all', ...Array.from({ length: 31 }, (_, i) => (i + 1).toString())];
  const liveModeHoursOptions = ['all', ...Array.from({ length: 24 }, (_, i) => i.toString())];
  let pmOptions: Record<'value' | 'title', string | number>[] = [];
  const amChannelsOptions = [
    'all',
    ...Array.from({ length: deviceData?.clientAttributes.channels as number }, (_, i) =>
      (i + 1).toString(),
    ),
  ];
  let amOptions: Record<'value' | 'title', string>[] = [];
  if (deviceData?.deviceProfileId === 'uspd' || deviceData?.deviceProfileId === 'perimiter-5') {
    pmOptions = Object.entries(DATD_WITH_2_CHANNELS_PM_OPTIONS).map((item) => ({
      value: item[0],
      title: item[1],
    }));
    if (deviceData?.clientAttributes.channels === 4) {
      pmOptions = Object.entries(DATD_WITH_4_CHANNELS_PM_OPTIONS).map((item) => ({
        value: item[0],
        title: item[1],
      }));
    } else if (deviceData?.clientAttributes.channels === 10) {
      pmOptions = Object.entries(DATD_WITH_10_CHANNELS_PM_OPTIONS).map((item) => ({
        value: item[0],
        title: item[1],
      }));
    }
  } else if (deviceData?.deviceProfileId === 'pressure_sensor') {
    pmOptions = Object.entries(PRESSURE_SENSOR_PM_OPTIONS).map((item) => ({
      value: item[0],
      title: item[1],
    }));
    amOptions = Object.entries(PRESSURE_SENSOR_AM_OPTIONS).map((item) => ({
      value: item[0],
      title: item[1],
    }));
    if (deviceData?.clientAttributes.temperature_measure === 'Да') {
      pmOptions = Object.entries(PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS).map((item) => ({
        value: item[0],
        title: item[1],
      }));
      amOptions = Object.entries(PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS).map((item) => ({
        value: item[0],
        title: item[1],
      }));
    }
  }

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item mobile={12} xs={6}>
          <FormControl disabled={!deviceData?.clientAttributes.possible_names}>
            <FormLabel disabled={!deviceData?.clientAttributes.possible_names}>
              {deviceProfilesData?.attributes_mapping.name}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  {Array.isArray(deviceData?.clientAttributes.possible_names)
                    ? deviceData?.clientAttributes.possible_names.map((item, i) => (
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : []}
                </Select>
              )}
              name="name"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.asset}</FormLabel>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={groupsData as any[]}
                  renderInput={(params) => <TextField {...params} placeholder="Выберите группу" />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Нет доступных групп"
                  onChange={(_, value) => field.onChange(value)}
                />
              )}
              name="parent"
              control={control}
            />
          </FormControl>
        </Grid>
        {clientAttributeFields.map((clientAttributeField: any, index: number) => (
          <Grid item mobile={12} xs={6} key={clientAttributeField.id}>
            <FormControl disabled>
              <FormLabel disabled>
                {deviceProfilesData?.attributes_mapping[clientAttributeField.attributeName] ||
                  clientAttributeField.attributeName}
              </FormLabel>
              <Controller
                name={`clientAttributes.${index}.attributeValue`}
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </FormControl>
          </Grid>
        ))}
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.pm}</FormLabel>
            <Controller
              name="pm"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <span>Значение не установлено</span>;
                    }
                    const selectedValue = pmOptions.find((item) => item.value === selected);

                    return selectedValue?.title || selected;
                  }}
                >
                  {pmOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {(deviceData?.deviceProfileId === 'pressure_sensor')
          ?
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>{deviceProfilesData?.attributes_mapping.am}</FormLabel>
                <Controller
                  name="am"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Значение не установлено</span>;
                        }
                        const selectedValue = amOptions.find((item) => item.value === selected);

                        return selectedValue?.title || selected;
                      }}
                    >
                      {amOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          :
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>{deviceProfilesData?.attributes_mapping.am}</FormLabel>
                <Controller
                  name="amChannels"
                  control={control}
                  render={({ field }) => (
                    <ChipsField
                      {...field}
                      options={amChannelsOptions}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue
                          .sort((a: any, b: any) => a - b)
                          .map((option: any, index) => (
                            <Chip
                              size="small"
                              label={option === 'all' ? 'Все каналы' : `Вкл.кан.${option}`}
                              {...getTagProps({ index })}
                            />
                          ))
                      }
                      renderOption={(optionProps, option: any, { selected }) => (
                        <li {...optionProps}>
                          <Stack direction="row">
                            <Checkbox size="small" checked={selected} sx={{ marginRight: 1 }} />
                            {option === 'all' ? 'Все каналы' : `Вкл.кан.${option}`}
                          </Stack>
                        </li>
                      )}
                      disableCloseOnSelect
                      placeholder="Каналы не указаны"
                      onChange={(newValue) => {
                        let value = [...newValue];
                        const allItemIndex = newValue.findIndex((item: any) => item === 'all');
                        if (newValue.length > 1) {
                          if (allItemIndex === newValue.length - 1) {
                            value = [value[value.length - 1]];
                          } else if (allItemIndex !== -1) {
                            value.splice(allItemIndex, 1);
                          }
                        }
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
        }
      </Grid>
      <Typography variant="h3" mt={2}>
        Настройки связи
      </Typography>
      <Grid container columnSpacing={4}>
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>
              <Stack direction="row" gap={1}>
                {deviceProfilesData?.attributes_mapping.live_mod}
                <Tooltip
                  arrow
                  title={
                    <>
                      <Typography variant="inherit">
                        <b>По расписанию:</b> Установка режима выхода в эфир в заданные дни и часы
                        месяца
                      </Typography>
                      <Typography variant="inherit">
                        <b>Интервал времени:</b> Установка режима выхода в эфир по периоду, в
                        минутах
                      </Typography>
                    </>
                  }
                >
                  <Box>
                    <QuestionIcon fontSize="small" color="secondary" />
                  </Box>
                </Tooltip>
              </Stack>
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="schedule">По расписанию</MenuItem>
                  <MenuItem value="interval">Интервал времени</MenuItem>
                </Select>
              )}
              name="live_mode"
              control={control}
            />
          </FormControl>
        </Grid>
        {liveModeWatch === 'schedule' ? (
          <>
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>{deviceProfilesData?.attributes_mapping.dm}</FormLabel>
                <Controller
                  name="dm"
                  control={control}
                  render={({ field }) => (
                    <ChipsField
                      {...field}
                      options={liveModeDaysOptions}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue
                          .sort((a: any, b: any) => a - b)
                          .map((option: any, index) => (
                            <Chip
                              size="small"
                              label={option === 'all' ? 'Все дни' : option}
                              {...getTagProps({ index })}
                            />
                          ))
                      }
                      renderOption={(optionProps, option: any, { selected }) => (
                        <li {...optionProps}>
                          <Stack direction="row">
                            <Checkbox size="small" checked={selected} sx={{ marginRight: 1 }} />
                            {option === 'all' ? 'Все дни' : option}
                          </Stack>
                        </li>
                      )}
                      disableCloseOnSelect
                      placeholder="Дни не указаны"
                      onChange={(newValue) => {
                        let value = [...newValue];
                        const allItemIndex = newValue.findIndex((item: any) => item === 'all');
                        if (newValue.length > 1) {
                          if (allItemIndex === newValue.length - 1) {
                            value = [value[value.length - 1]];
                          } else if (allItemIndex !== -1) {
                            value.splice(allItemIndex, 1);
                          }
                        }
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item mobile={12} xs={6}>
              <FormControl>
                <FormLabel>{deviceProfilesData?.attributes_mapping.t}</FormLabel>
                <Controller
                  name="t"
                  control={control}
                  render={({ field }) => (
                    <ChipsField
                      {...field}
                      options={liveModeHoursOptions}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue
                          .sort((a: any, b: any) => a - b)
                          .map((option: any, index) => (
                            <Chip
                              size="small"
                              label={
                                option === 'all'
                                  ? 'Все часы'
                                  : option <= 9
                                  ? `0${option}:00`
                                  : `${option}:00`
                              }
                              {...getTagProps({ index })}
                            />
                          ))
                      }
                      renderOption={(optionProps, option: any, { selected }) => (
                        <li {...optionProps}>
                          <Stack direction="row">
                            <Checkbox size="small" checked={selected} sx={{ marginRight: 1 }} />
                            {option === 'all'
                              ? 'Все часы'
                              : option <= 9
                              ? `0${option}:00`
                              : `${option}:00`}
                          </Stack>
                        </li>
                      )}
                      disableCloseOnSelect
                      placeholder="Часы не указаны"
                      onChange={(newValue) => {
                        let value = [...newValue];
                        const allItemIndex = newValue.findIndex((item: any) => item === 'all');
                        if (newValue.length > 1) {
                          if (allItemIndex === newValue.length - 1) {
                            value = [value[value.length - 1]];
                          } else if (allItemIndex !== -1) {
                            value.splice(allItemIndex, 1);
                          }
                        }
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          <Grid item mobile={12} xs={6}>
            <FormControl>
              <FormLabel>
                <Stack direction="row" gap={1}>
                  {deviceProfilesData?.attributes_mapping.perm}
                  <Tooltip
                    arrow
                    title={
                      <Typography variant="inherit">
                        Интервал выхода в эфир от 1 до 1440 минут
                      </Typography>
                    }
                  >
                    <Box>
                      <QuestionIcon fontSize="small" color="secondary" />
                    </Box>
                  </Tooltip>
                </Stack>
              </FormLabel>
              <Controller
                name="perm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Введите интервал"
                    error={!!errors.perm}
                    helperText={errors.perm?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: NUMBER_REGEX,
                    message: 'Введите число от 1 до 1440',
                  },
                  max: {
                    value: 1440,
                    message: 'Введите число от 1 до 1440',
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DeviceClientAttributes;
