import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import convertTimestampToDate from '../../utils/convertTimestampToDate';

const StyledChartTooltip = styled("div", {
  name: 'ChartTooltip',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})(({theme}) => ({}));

const ChartTooltip = (props: any) => {
  const {active, payload, label} = props;

  if (active && payload && payload.length) {
    return (
      <StyledChartTooltip>
        {payload.length === 1
            ? `${convertTimestampToDate(label, 'dd.MM HH:mm')} : ${payload[0].value}`
            : (
              <>
                {`${convertTimestampToDate(label, 'dd.MM HH:mm')}`}
                <br />
                {payload.map((item: any) => (
                  <Typography key={item.name} variant="paragraph3">
                    {`${item.name}: ${item.value} -`}
                    <Box className='chartTooltip-icon' sx={{backgroundColor: item.stroke}} />
                    <br />
                  </Typography>
                ))}
              </>
            )
          }
      </StyledChartTooltip>
    );
  }

  return null;
};

export default ChartTooltip;
