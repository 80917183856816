import { useQuery } from 'react-query';

import { GET_BALANCE_GROUP_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchBalanceGroup = (id: string) => {
  return useQuery(['balanceGroupData', id], () => fetchData(GET_BALANCE_GROUP_URL(id)));
};

export default useFetchBalanceGroup;
