import { useState, SyntheticEvent } from 'react';

import { useParams, useSearchParams, useNavigate, Link } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { useForm, useFormContext, Controller, FormProvider } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridRowParams,
  GridActionsCellItem,
  GridFooterContainer,
  gridClasses,
  GridColumnMenuProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { getUnixTime, startOfDay } from 'date-fns';

import DataGridPagination from '../../components/DataGrid/DataGridPagination';
import DataGridPanels from '../../components/DataGrid/DataGridPanels';
import AscendingIcon from '../../components/icons/AscendingIcon';
import DescendingIcon from '../../components/icons/DescendingIcon';
import FilterIcon from '../../components/icons/FilterIcon';
import SearchIcon from '../../components/icons/SquareSearchIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import StatusBadge from '../../components/StatusBadge';
import {
  DEVICE_STATUSES,
  RSSI_LOW,
  RSSI_MID,
  RSSI_HIGH,
  BATTERY_VOLTAGE_LOW,
  BATTERY_VOLTAGE_HIGH,
} from '../../constants';
import { IUser, IColorVariants } from '../../types';
import sendMetrik from '../../utils/sendMetrik';
import translitRuEn from '../../utils/translitRuEn';

import useFetchDeviceProfiles from './hooks/useFetchDeviceProfiles';
import useWSObjectDevices from './hooks/useWSObjectDevices';

type FormValues = {
  device_type: string[];
  status: string[];
};

type IDevicesFilterProps = GridColumnMenuProps & {
  onFilterChange: () => void;
};

const DevicesListToolbar = () => {
  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter
        size="small"
        className="MuiDataGrid-toolbarQuickFilter"
        onClick={() => {
          sendMetrik(
            'vntVdknl',
            'spisok_ustroistv_i_obektov',
            'form_click',
            'poisk_po_ustroistvam',
            null,
            userData?.permissions[0].uuid,
            userData ? '1' : '0',
            '/objects',
            null,
            null,
            null,
            'interactions',
            userData?.profile_type,
            'web',
          );
        }}
      />
    </GridToolbarContainer>
  );
};

const DevicesFilter = (props: IDevicesFilterProps) => {
  const { onFilterChange, hideMenu, ...rest } = props;

  const queryClient = useQueryClient();
  const deviceProfilesData: any = queryClient.getQueryData('deviceProfilesData');

  const { control, setValue } = useFormContext();

  const deviceStatusFilterClear = () => {
    setValue('status', ['all']);
  };
  const deviceTypeFilterClear = () => {
    setValue('device_type', ['all']);
  };

  const handleDevicesFilterApplyClick = (event: SyntheticEvent) => {
    onFilterChange();
    hideMenu(event);
  };

  return (
    <Box
      sx={{
        width: 256,
        p: 1.5,
      }}
    >
      {rest.colDef.field === 'status' && (
        <>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <>
                <FormControl margin="dense">
                  <FormGroup row={true}>
                    <FormControlLabel
                      label="Все"
                      control={
                        <Checkbox
                          value="all"
                          checked={field.value.some(
                            (existingValue: string) => existingValue === 'all',
                          )}
                          onChange={(event) => {
                            field.onChange([event.target.value]);
                          }}
                        />
                      }
                      componentsProps={{
                        typography: {
                          variant: 'body2',
                        },
                      }}
                    />
                  </FormGroup>
                </FormControl>
                {DEVICE_STATUSES.map((status: string) => (
                  <FormControl key={status} margin="dense">
                    <FormGroup row={true}>
                      <FormControlLabel
                        label={status}
                        control={
                          <Checkbox
                            value={status}
                            checked={field.value.some(
                              (existingValue: string) => existingValue === status,
                            )}
                            onChange={(event, checked) => {
                              if (checked) {
                                const allItemIndex = field.value.indexOf('all');
                                if (allItemIndex > -1) {
                                  field.value.splice(allItemIndex, 1);
                                }
                                field.onChange([...field.value, event.target.value]);
                              } else {
                                field.onChange(
                                  field.value.filter((value: any) => value !== event.target.value),
                                );
                              }
                            }}
                          />
                        }
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                ))}
              </>
            )}
          />
          <Stack direction="row" spacing={1} mt={2}>
            <Button
              fullWidth
              size="small"
              color="secondary"
              type="button"
              onClick={deviceStatusFilterClear}
            >
              Очистить
            </Button>
            <Button
              fullWidth
              size="small"
              color="primary"
              type="button"
              onClick={handleDevicesFilterApplyClick}
            >
              Применить
            </Button>
          </Stack>
        </>
      )}
      {rest.colDef.field === 'device_type' && (
        <>
          <Controller
            name="device_type"
            control={control}
            render={({ field }) => (
              <>
                <FormControl margin="dense">
                  <FormGroup row={true}>
                    <FormControlLabel
                      label="Все"
                      control={
                        <Checkbox
                          value="all"
                          checked={field.value.some(
                            (existingValue: string) => existingValue === 'all',
                          )}
                          onChange={(event) => {
                            field.onChange([event.target.value]);
                          }}
                        />
                      }
                      componentsProps={{
                        typography: {
                          variant: 'body2',
                        },
                      }}
                    />
                  </FormGroup>
                </FormControl>
                {deviceProfilesData.profiles.map((profile: Record<'name' | 'id', string>) => (
                  <FormControl key={profile.id} margin="dense">
                    <FormGroup row={true}>
                      <FormControlLabel
                        label={profile.name}
                        control={
                          <Checkbox
                            value={profile.id}
                            checked={field.value.some(
                              (existingValue: string) => existingValue === profile.id,
                            )}
                            onChange={(event, checked) => {
                              if (checked) {
                                const allItemIndex = field.value.indexOf('all');
                                if (allItemIndex > -1) {
                                  field.value.splice(allItemIndex, 1);
                                }
                                field.onChange([...field.value, event.target.value]);
                              } else {
                                field.onChange(
                                  field.value.filter((value: any) => value !== event.target.value),
                                );
                              }
                            }}
                          />
                        }
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                ))}
              </>
            )}
          />
          <Stack direction="row" spacing={1} mt={2}>
            <Button
              fullWidth
              size="small"
              color="secondary"
              type="button"
              onClick={deviceTypeFilterClear}
            >
              Очистить
            </Button>
            <Button
              fullWidth
              size="small"
              color="primary"
              type="button"
              onClick={handleDevicesFilterApplyClick}
            >
              Применить
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

const DevicesListFooter = () => {
  return (
    <GridFooterContainer>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const ObjectDevices = () => {
  let { objectId = '' } = useParams();
  let [searchParams] = useSearchParams();
  let objectTypeParam = searchParams.get('type') || '';

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedStatus, setSelectedStatus] = useState(['all']);
  const [selectedDeviceType, setSelectedDeviceType] = useState(['all']);

  const startOfDayTimestamp = getUnixTime(startOfDay(new Date()));

  const methods = useForm<FormValues>({
    defaultValues: {
      device_type: ['all'],
      status: ['all'],
    },
  });
  const { getValues } = methods;

  const { isLoading, data } = useWSObjectDevices(objectTypeParam, objectId);

  const {
    isLoading: isDeviceProfilesDataLoading,
    isError: isDeviceProfilesDataError,
    data: deviceProfilesData,
  } = useFetchDeviceProfiles();

  const handleFilterChange = () => {
    const filterValues = getValues(['status', 'device_type']);
    setSelectedStatus(filterValues[0]);
    setSelectedDeviceType(filterValues[1]);
  };

  let columnsData: GridColDef[] = [];
  if (deviceProfilesData) {
    columnsData = [
      {
        field: 'id',
        flex: 1,
        headerName: deviceProfilesData.attributes_mapping.id,
        minWidth: 210,
        disableColumnMenu: true,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Box>
            <Typography component="p" variant="paragraph4">
              {deviceProfilesData.attributes_mapping.id}
            </Typography>
            <Typography component="p" variant="paragraph4">
              {deviceProfilesData.attributes_mapping.description_name}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<any>) => {
          const { value, row } = params;

          return (
            <Box>
              {value}
              {row.description_name ? (
                <Typography component="p" variant="paragraph4">
                  {row.description_name}
                </Typography>
              ) : (
                <Typography component="p" variant="paragraph4">
                  Поле не задано:
                  {' '}
                  <Link
                    to={`/objects/${objectId}/devices/${params.id}/edit`}
                    onClick={() => {
                      sendMetrik(
                        'vntVdknl',
                        'spisok_ustroistv_i_obektov',
                        'button_click',
                        'redaktirovanie_ustroistva',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/objects',
                        params.row.deviceId,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                  >
                    редактировать
                  </Link>
                </Typography>
              )}
            </Box>
          );
        },
      },
      {
        field: 'address_full',
        headerName: deviceProfilesData.attributes_mapping.address_full,
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: deviceProfilesData.attributes_mapping.status,
        headerClassName: 'MuiDataGrid-columnHeader--statusColumn',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<any>) => {
          const { value } = params;
          let color: IColorVariants;
          if (DEVICE_STATUSES.indexOf(value) === 0) {
            color = 'success';
          } else if (DEVICE_STATUSES.indexOf(value) === 1) {
            color = 'warning';
          } else if (DEVICE_STATUSES.indexOf(value) === 2 || DEVICE_STATUSES.indexOf(value) === 3) {
            color = 'error';
          }

          return (
            <Tooltip arrow title={`Статус: ${value}`}>
              <StatusBadge color={color} />
            </Tooltip>
          );
        },
      },
      {
        field: 'device_type',
        headerName: deviceProfilesData.attributes_mapping.device_type,
        headerClassName: 'MuiDataGrid-columnHeader--deviceTypeColumn',
        flex: 1,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<any>) => {
          const profile = deviceProfilesData.profiles.find((item: any) => item.id === params.value);
          if (profile) {
            return profile.name;
          }

          return params.value;
        },
      },
      {
        field: 'last_activity',
        headerName: deviceProfilesData.attributes_mapping.last_activity,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          const { row } = params;

          return (
            <Box>
              {row.last_activity_date}
              <Typography component="p" variant="paragraph4">
                {row.last_activity_time}
              </Typography>
            </Box>
          );
        },
        valueGetter: (params) => {
          const { row } = params;

          return `${row.last_activity_date} ${row.last_activity_time}`;
        },
      },
      {
        field: 'status_rssi',
        headerName: deviceProfilesData.attributes_mapping.status_rssi,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          const { value } = params;
          let color: IColorVariants;
          if (value > RSSI_HIGH) {
            color = 'perfect';
          } else if (value > RSSI_MID && value <= RSSI_HIGH) {
            color = 'success';
          } else if (value > RSSI_LOW && value <= RSSI_MID) {
            color = 'warning';
          } else if (value <= RSSI_LOW) {
            color = 'error';
          }

          return (
            <Tooltip arrow title={`${deviceProfilesData.attributes_mapping.status_rssi}: ${value}`}>
              <StatusBadge color={color} />
            </Tooltip>
          );
        },
      },
      {
        field: 'status_urf',
        headerName: deviceProfilesData.attributes_mapping.status_urf,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          const { value } = params;
          let color: IColorVariants;
          if (value > BATTERY_VOLTAGE_HIGH) {
            color = 'success';
          } else if (value >= BATTERY_VOLTAGE_LOW && value <= BATTERY_VOLTAGE_HIGH) {
            color = 'warning';
          } else if (value < BATTERY_VOLTAGE_LOW) {
            color = 'error';
          }

          return (
            <Tooltip arrow title={`${deviceProfilesData.attributes_mapping.status_urf}: ${value}`}>
              <StatusBadge color={color} />
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            onClick={() => {
              navigate(
                `/dashboards?device_type=${params.row.device_type}&dashboard_type=real_time&device_id=${params.row.id}`,
              );
              sendMetrik(
                'vntVdknl',
                'spisok_ustroistv_i_obektov',
                'button_click',
                'dashbord',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/objects',
                params.row.deviceId,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              );
            }}
            label="Перейти на дашборд"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() =>
              navigate(
                `/reports/${params.row.id}?device_type=${params.row.device_type}&report_type=daily&date_from=${startOfDayTimestamp}&device_names=${params.row.name}`,
              )
            }
            label="Перейти на отчет"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => navigate(`/objects/${objectId}/devices/${params.id}`)}
            label="Карточка устройства"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              navigate(`/objects/${objectId}/devices/${params.id}/edit`);
              sendMetrik(
                'vntVdknl',
                'spisok_ustroistv_i_obektov',
                'button_click',
                'redaktirovanie_ustroistva',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/objects',
                params.row.deviceId,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              );
            }}
            label="Редактирование"
            showInMenu
          />,
        ],
      },
    ];
  }

  let rowsData: any = [];
  if (Array.isArray(data)) {
    rowsData = data.filter((item: any) => {
      return (
        (selectedStatus.includes(item.status) || selectedStatus.includes('all')) &&
        (selectedDeviceType.includes(item.device_type) || selectedDeviceType.includes('all'))
      );
    });
  }

  if (isLoading || isDeviceProfilesDataLoading) {
    return <Spinner data-testid="loader" />;
  }

  if (isDeviceProfilesDataError) {
    return <Message>Не получилось загрузить список устройств объекта. Попробуйте еще раз.</Message>;
  }

  return (
    <FormProvider {...methods}>
      <DataGridPanels
        columns={columnsData}
        rows={rowsData}
        getRowId={(row) => row.entity_id}
        paginationModel={paginationModel}
        disableColumnMenu={false}
        slots={{
          toolbar: DevicesListToolbar,
          quickFilterIcon: SearchIcon,
          columnMenu: (props) => <DevicesFilter onFilterChange={handleFilterChange} {...props} />,
          columnMenuIcon: () => (
            <FilterIcon
              {...(!selectedStatus.includes('all') ? { className: 'statusFilter--active' } : {})}
              {...(!selectedDeviceType.includes('all') ? { className: 'deviceTypeFilter--active' } : {})}
              fontSize="small"
            />
          ),
          columnSortedAscendingIcon: AscendingIcon,
          columnSortedDescendingIcon: DescendingIcon,
          footer: DevicesListFooter,
        }}
        slotProps={{
          toolbar: {
            quickFilterProps: { debounceMs: 500 },
          },
          basePopper: {
            placement: 'top-start',
          },
        }}
        getRowHeight={() => 'auto'}
        localeText={{
          toolbarQuickFilterPlaceholder: 'Найти устройство',
          noRowsLabel: 'Нет устройств',
          noResultsOverlayLabel: 'Устройства не найдены',
          columnMenuLabel: '',
        }}
        getRowSpacing={(params: any) => ({
          top: params.isFirstVisible ? 10 : 0,
          bottom: params.isLastVisible ? 16 : 8,
        })}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        onFilterModelChange={(e) => {
          sendMetrik(
            'vntVdknl',
            'spisok_ustroistv_i_obektov',
            'form_change',
            'poisk_po_ustroistvam',
            null,
            userData?.permissions[0].uuid,
            userData ? '1' : '0',
            '/objects',
            e.quickFilterValues?.toString(),
            null,
            null,
            'interactions',
            userData?.profile_type,
            'web',
          );
        }}
        onColumnHeaderClick={(e) => {
          let headerName: any = e.colDef.headerName;
          sendMetrik(
            'vntVdknl',
            'spisok_ustroistv_i_obektov',
            'filter_click',
            translitRuEn(headerName).toLowerCase(),
            null,
            userData?.permissions[0].uuid,
            userData ? '1' : '0',
            '/objects',
            null,
            null,
            null,
            'interactions',
            userData?.profile_type,
            'web',
          );
        }}
      />
    </FormProvider>
  );
};

export default ObjectDevices;
