import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InProgressIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fillRule="evenodd" d="M4.68597 4.68597C3.3731 5.99884 3.27575 7.30684 3.08105 9.92282C3.03058 10.601 3 11.3005 3 12C3 12.6995 3.03058 13.399 3.08105 14.0772C3.27575 16.6932 3.3731 18.0012 4.68597 19.314C5.99884 20.6269 7.30684 20.7243 9.92282 20.9189C10.601 20.9694 11.3005 21 12 21C12.6995 21 13.399 20.9694 14.0772 20.9189C16.6932 20.7243 18.0012 20.6269 19.314 19.314C20.6269 18.0012 20.7243 16.6932 20.9189 14.0772C20.9694 13.399 21 12.6995 21 12C21 11.3005 20.9694 10.601 20.9189 9.92282C20.7243 7.30684 20.6269 5.99884 19.314 4.68597C18.0012 3.3731 16.6932 3.27575 14.0772 3.08105C13.399 3.03058 12.6995 3 12 3C11.3005 3 10.601 3.03058 9.92282 3.08105C7.30684 3.27575 5.99884 3.3731 4.68597 4.68597ZM14.7052 13.2929L13 11.5877V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8L11 11.9567C10.9881 12.2271 11.0854 12.5015 11.2919 12.7081L13.2909 14.7071C13.6815 15.0976 14.3146 15.0976 14.7052 14.7071C15.0957 14.3166 15.0957 13.6834 14.7052 13.2929Z" />
    </SvgIcon>
  );
};

export default InProgressIcon;
