// сброс чекбоксов в форме
const resetCheckboxesForm = (dataAttr: any, setDataAttrState: any) => {
  const newColumnAtrNames = dataAttr.attr.columnAtrNames.map((item: any) => ({
    ...item,
    checked: false,
  }));
  const newColumnAtrFilters = dataAttr.attr.columnAtrFilters.map((item: any) => ({
    ...item,
    checked: false,
  }));
  setDataAttrState({
    attr: {
      columnAtrNames: newColumnAtrNames,
      columnAtrFilters: newColumnAtrFilters,
      columnAllChecked: false,
    },
  });
};

export default resetCheckboxesForm;
