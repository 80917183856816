import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

import { YMaps } from 'react-yandex-maps';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ruLocale from 'date-fns/locale/ru';

import { CustomDateAdapter } from './components/DatePicker/CustomDateAdapter';
import ModalResize from './components/ModalResize';
import SnackbarProvider from './components/Snackbar/SnackbarsProvider';
import Pages from './routes';
import AppContextProvider from './store';
import theme from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <SnackbarProvider>
          <ModalResize />
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={CustomDateAdapter} adapterLocale={ruLocale}>
              <CssBaseline />
              <YMaps query={{apikey: '32c46d1a-dffe-4457-a14b-184a1a26e2ee'}}>
                <BrowserRouter>
                  <Pages />
                </BrowserRouter>
              </YMaps>
            </LocalizationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </SnackbarProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default App;
