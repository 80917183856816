import { useNavigate } from 'react-router-dom';

import { Card, CardContent, Link, Stack, Typography } from '@mui/material';

import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { REPORT_TYPES } from '../../constants';
import { IReportsHistoryData } from '../../types';
import useDebounce from '../../utils/hooks/useDebounce';

import useFetchReportsHistory from './hooks/useFetchReportsHistory';

const ReportsAsideHistory = ({ searchHistoryReport }: { searchHistoryReport: string }) => {
  const { data, isLoading } = useFetchReportsHistory();

  const debounceSearchTemplate = useDebounce(searchHistoryReport);

  const navigate = useNavigate();

  const dataReportsHistory =
    data?.filter((template: { name: string }) =>
      template?.name?.toLowerCase().includes(debounceSearchTemplate?.toLowerCase()),
    ) || [];

  const getReportType = (reportType: string) => {
    if (reportType === 'daily') return REPORT_TYPES.daily;
    if (reportType === 'period') return REPORT_TYPES.period;
    if (reportType === 'segment') return REPORT_TYPES.segment;

    return reportType;
  };

  const trimUrl = (input: string) => {
    let splitted = input.split('/reports/');
    if (splitted.length > 1) {
      return splitted.slice(1).join('/');
    }

    return input;
  };

  if (isLoading) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <>
      {Array.isArray(dataReportsHistory) && dataReportsHistory?.length > 0 ? (
        dataReportsHistory?.map((report: IReportsHistoryData) => {
          return (
            <Card
              key={report?.history_id}
              sx={{ mb: 2 }}
              style={{
                borderRadius: 16,
                maxWidth: '280px',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '16px 24px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: '#969FA8',
                    mb: 0.5,
                  }}
                >
                  {report?.report_template ? 'Пользовательский отчет' : 'Общий отчет'}
                </Typography>
                <Stack sx={{ flexDirection: 'row', mb: 1, alignItems: 'center' }}>
                  {report?.report_type && (
                    <Typography variant="h4" fontWeight={500} color="#626C77">
                      Отчет:&nbsp;
                    </Typography>
                  )}
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(trimUrl(report?.url))}
                  >
                    <Typography
                      variant="h4"
                      fontWeight={report.report_type ? 400 : 500}
                      color="#626C77"
                    >
                      {report?.name}
                    </Typography>
                  </Link>
                  <Typography
                    ml={1.5}
                    bgcolor={report?.device_type === 'uspd' ? '#6384E0' : '#F37F19'}
                    variant="h3"
                    color="#FFFFFF"
                    fontSize="12px"
                    maxHeight="24px"
                    sx={{ padding: '0px 4px', borderRadius: '4px' }}
                  >
                    {report?.device_type === 'uspd' ? 'УСПД' : 'ДД'}
                  </Typography>
                </Stack>
                <Stack sx={{ flexDirection: 'row' }}>
                  <Typography variant="h3" fontSize="12px" color="#626C77">
                    {report?.report_template ? 'Тип шаблона: ' : 'Тип отчета: '}
                  </Typography>
                  <Typography fontSize="12px" color="textSecondary" ml={0.5}>
                    {report?.report_template
                      ? report.report_template
                      : report?.report_type && getReportType(report?.report_type)}
                  </Typography>
                </Stack>
                <Stack sx={{ flexDirection: 'row' }}>
                  <Typography variant="h3" fontSize="12px" color="#626C77">
                    Период:{' '}
                  </Typography>
                  <Typography fontSize="12px" color="textSecondary" ml={0.5}>
                    {report?.period}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Message>История отчетов отсутствует</Message>
      )}
    </>
  );
};

export default ReportsAsideHistory;
