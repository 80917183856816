import { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { useForm, useFormContext, useWatch, SubmitHandler, FormProvider } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';

import Dialog from '../../components/Dialog';
import Spinner from '../../components/Spinner';
// import TabPanel from '../../components/TabPanel';
import { Override, INotificationsTemplate } from '../../types';

import useFetchNotificationsDeviceTypes from './hooks/useFetchNotificationsDeviceTypes';
import useFetchNotificationsTemplateTypes from './hooks/useFetchNotificationsTemplateTypes';
import NotificationsTemplateInfo from './NotificationsTemplateInfo';
import NotificationsTemplatePreview from './NotificationsTemplatePreview';
// import NotificationsTemplateSchedule from './NotificationsTemplateSchedule';

type INotificationsTemplateForm = Override<INotificationsTemplate, { asset_id: {id: string, name: string} | null }>

type INotificationsTemplateFormProps = {
  defaultValues?: INotificationsTemplateForm;
  isLoading: boolean;
  isSettled: boolean;
  onFormSubmit: (data: INotificationsTemplate) => void;
  onSubmitButtonClick?: () => void;
};

type IFormButtons = {
  isLoading: boolean;
};

const FormButtons = (props: IFormButtons) => {
  const { isLoading } = props;

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const { control } = useFormContext();

  const nameWatch = useWatch({
    name: 'name',
    control,
  });
  const deviceTypeWatch = useWatch({
    name: 'device_type',
    control,
  });
  const basicTemplateNameWatch = useWatch({
    name: 'basic_template_name',
    control,
  });

  useEffect(() => {
    if (!nameWatch || !deviceTypeWatch || !basicTemplateNameWatch) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [nameWatch, deviceTypeWatch, basicTemplateNameWatch]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        mt: 3,
      }}
    >
      <Button color="negative" type="button" component={RouterLink} to="/notifications">
        Отмена
      </Button>
      <LoadingButton disabled={isSubmitDisabled} loading={isLoading} type="submit">
        Сохранить
      </LoadingButton>
    </Stack>
  );
};

const NotificationsTemplateForm = (props: INotificationsTemplateFormProps) => {
  const {
    defaultValues = {
      name: '',
      device_type: '',
      asset_id: null,
      device_ids: [],
      address_full: null,
      users: [],
      basic_template_name: '',
      is_active: true,
      is_telegram: false,
      is_web: false,
      cron_schedule: '* * * * *',
      // always: true,
      // mondayStart: '',
      // mondayEnd: '',
      // tuesdayStart: '',
      // tuesdayEnd: '',
      // wednesdayStart: '',
      // wednesdayEnd: '',
      // thursdayStart: '',
      // thursdayEnd: '',
      // fridayStart: '',
      // fridayEnd: '',
      // saturdayStart: '',
      // saturdayEnd: '',
      // sundayStart: '',
      // sundayEnd: '',
    },
    isLoading,
    isSettled,
    onFormSubmit,
    onSubmitButtonClick,
  } = props;

  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
  // const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (isSaveTemplateDialogOpen && isSettled) {
      setIsSaveTemplateDialogOpen(false);
    }
  }, [isSaveTemplateDialogOpen, isSettled]);

  const { isLoading: isLoadingNotificationsDeviceTypes } = useFetchNotificationsDeviceTypes();

  const { isLoading: isLoadingNotificationsTemplateTypes } = useFetchNotificationsTemplateTypes();

  const methods = useForm<INotificationsTemplateForm>({
    defaultValues,
  });
  const { handleSubmit, getValues } = methods;

  const getNewTemplateData = (data: INotificationsTemplateForm) => {
    const {
      name,
      device_type,
      basic_template_name,
      device_ids,
      users,
      is_active,
      is_telegram,
      is_web,
      asset_id,
      address_full,
    } = data;

    let newTemplateData: INotificationsTemplate = {
      name,
      device_type,
      basic_template_name,
      device_ids,
      is_active,
      is_telegram,
      is_web,
    };
    newTemplateData.users = Array.isArray(users) ? users.map((user: any) => user.msisdn) : [];
    if (asset_id) {
      newTemplateData.asset_id = asset_id.id;
    }
    if (address_full) {
      newTemplateData.address_full = address_full;
    }
    newTemplateData.cron_schedule = '* * * * *';

    return newTemplateData;
  }

  const onSubmit: SubmitHandler<INotificationsTemplateForm> = (formData) => {
    const {is_telegram, users} = formData;
    if (onSubmitButtonClick) {
      onSubmitButtonClick();
    }
    if (is_telegram && users?.filter((user: any) => user.telegram_chat_activated === null).length) {
      setIsSaveTemplateDialogOpen(true);
    } else {
      onFormSubmit(getNewTemplateData(formData));
    }
  };

  // const handleTabChange = (event: SyntheticEvent, newValue: number) => {
  //   setActiveTab(newValue);
  // };

  const handleSaveTemplateDialogClose = () => {
    setIsSaveTemplateDialogOpen(false);
  };

  if (isLoadingNotificationsDeviceTypes || isLoadingNotificationsTemplateTypes) {
    return <Spinner data-testid="loader" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Tabs
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab label="Информация о шаблоне" />
          <Tab label="График срабатывания" />
        </Tabs> */}
        <NotificationsTemplatePreview />
        <NotificationsTemplateInfo />
        {/* <TabPanel value={activeTab} index={0}>
          <NotificationsTemplatePreview />
          <NotificationsTemplateInfo />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <NotificationsTemplateSchedule />
        </TabPanel> */}
        <FormButtons isLoading={isLoading} />
      </form>
      <Dialog
        busy={isLoading}
        maxWidth="md"
        heading="Сохранение шаблона"
        actionButtonText="Сохранить"
        open={isSaveTemplateDialogOpen}
        hideCloseButton={true}
        onAction={() => onFormSubmit(getNewTemplateData(getValues()))}
        onClose={handleSaveTemplateDialogClose}
      >
        Если выбранные пользователи не авторизованы в телеграм боте @notify_srv_bot, оповещения не будут приходить
      </Dialog>
    </FormProvider>
  );
};

export default NotificationsTemplateForm;
