import { useState } from 'react';

import { useQuery } from 'react-query';

import { useForm, useWatch, SubmitHandler, Controller } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridFooterContainer, GridPagination, DataGrid } from '@mui/x-data-grid';

import DataGridPagination from '../../../../components/DataGrid/DataGridPagination';
import QuestionIcon from '../../../../components/icons/QuestionIcon';
import SearchIcon from '../../../../components/icons/SearchIcon';
import Message from '../../../../components/Message';
import { FIND_ARKI_OBJECTS_URL } from '../../../../constants';
import fetchData from '../../../../utils/fetchData';

type FormValues = {
  addresses: string[];
};

const defaultValues = {
  addresses: [],
};

const DataGridFooter = () => {
  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <GridPagination />
      </Box>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const FindARKIObjects = () => {
  const [enabled, setEnabled] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { handleSubmit, control, getValues } = useForm<FormValues>({
    defaultValues,
  });

  const addressesWatch = useWatch({
    name: 'addresses',
    control,
  });

  const { data, isFetching, isError, refetch } = useQuery(
    ['objectsTreeData'],
    () => fetchData(FIND_ARKI_OBJECTS_URL, { method: 'POST', body: getValues('addresses') }),
    {
      enabled,
      initialData: [],
    },
  );

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (formData.addresses) {
      if (!enabled) {
        setEnabled(true);
      } else {
        refetch();
      }
    }
  };

  const cols = [
    {
      field: 'Адрес',
      flex: 1,
      minWidth: 400,
    },
    {
      field: 'Код ГАР',
      flex: 1,
      minWidth: 400,
    },
    {
      headerName: 'Object ID',
      field: 'object_id',
      flex: 1,
    },
    {
      headerName: 'Тип',
      field: 'type',
      flex: 1,
    },
  ];

  let result;

  if (isError || data.hasOwnProperty('detail')) {
    result = <Message>Не получилось загрузить данные</Message>;
  } else {
    result = (
      <DataGrid
        columns={cols}
        rows={data}
        loading={isFetching}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 50]}
        getRowId={(row) => `${row['Адрес']}_${row['Код ГАР']}`}
        slots={{
          footer: DataGridFooter,
        }}
        onPaginationModelChange={(model) => setPaginationModel(model)}
      />
    );
  }

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3" gutterBottom>
          Поиск объектов
        </Typography>
        <Stack direction="row" alignItems="flex-end" gap={1}>
          <FormControl margin="none">
            <Stack direction="row" gap={1} alignItems="center">
              <InputLabel>Адреса</InputLabel>
              <Tooltip
                arrow
                title={
                  <Typography variant="inherit" component="span">
                    Для добавления адреса используйте клавишу "Enter". Для добавления нескольких
                    адресов, значения должны быть разделены символом ";"
                  </Typography>
                }
              >
                <div>
                  <QuestionIcon fontSize="small" color="secondary" />
                </div>
              </Tooltip>
            </Stack>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  renderInput={(params) => <TextField {...params} />}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={<Typography style={{ whiteSpace: 'normal' }}>{option}</Typography>}
                        style={{ height: '100%', padding: 4 }}
                      />
                    ))
                  }
                  onChange={(_, value) => {
                    const modifiedData = value.flatMap((item) => {
                      if (typeof item == 'string') {
                        if (item.includes(';')) {
                          if (item.slice(-1) === ';') {
                            item = item.slice(0, -1);
                          }

                          return item.trim().split(';');
                        }
                      }

                      return item;
                    });

                    field.onChange(modifiedData);
                  }}
                />
              )}
              name="addresses"
              control={control}
            />
          </FormControl>
          <LoadingButton type="submit" loading={isFetching} disabled={!addressesWatch.length}>
            <SearchIcon />
          </LoadingButton>
        </Stack>
      </form>
      {result}
    </Box>
  );
};

export default FindARKIObjects;
