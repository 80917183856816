import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { fromUnixTime, getUnixTime, isThisMonth, addMonths } from 'date-fns';

import DocumentIconFilled from '../../../components/icons/DocumentIconFilled';
import DownloadIcon from '../../../components/icons/DownloadIcon2';
import { GET_SPECIAL_REPORT_EXCEL_URL } from '../../../constants';
import { IUser } from '../../../types';
import fetchExcel from '../../../utils/fetchExcel';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';
import useSnackbars from '../../../utils/hooks/useSnackbars';
import sendMetrik from '../../../utils/sendMetrik';

type IReportsSpecialResultItemProps = {
  title: string;
  onClick: () => void;
};

const DownloadReportButtonStyled = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 468,
  marginBottom: 8,
  padding: '4px 12px',
  background: theme.palette.background.secondary,
  borderRadius: 8,
  color: theme.palette.text.primary,
  cursor: 'pointer',
  '.specialReportItem-description': {
    flexGrow: 1,
    paddingLeft: '12px',
    lineHeight: 1,
  },
}));

const DownloadReportButton = (props: IReportsSpecialResultItemProps) => {
  const { title, onClick } = props;

  return (
    <DownloadReportButtonStyled onClick={onClick}>
      <DocumentIconFilled fontSize="extralarge" />
      <Box className="specialReportItem-description">
        <Typography component={'p'} variant="paragraph3">
          {title}
        </Typography>
      </Box>
      <DownloadIcon color="secondary" />
    </DownloadReportButtonStyled>
  );
};

const ReportsSpecialResults = () => {
  let [searchParams] = useSearchParams();
  let dateFromParam = searchParams.get('date_from');
  let dateToParam = searchParams.get('date_to');
  let groupIdParam = searchParams.get('group_id') || null;

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { showSnackbar } = useSnackbars();

  const getReport = () => {
    const dateFrom = getUTCTimestamp(parseInt(dateFromParam as string)).toString();
    let dateTo;

    if (isThisMonth(fromUnixTime(parseInt(dateToParam as string)))) {
      dateTo = getUTCTimestamp(getUnixTime(new Date())).toString();
    } else {
      dateTo = getUnixTime(
        addMonths(fromUnixTime(getUTCTimestamp(parseInt(dateToParam as string))), 1),
      ).toString();
    }

    const fetchRequest = fetchExcel(
      GET_SPECIAL_REPORT_EXCEL_URL(dateFrom, dateTo, groupIdParam),
      'Междуреченский водоканал.xls',
    );

    fetchRequest.catch((error) => {
      if (error.response.status === 404) {
        showSnackbar(<Alert severity="error">Данные не найдены</Alert>);
      }
    });

    sendMetrik(
      'vntVdknl',
      'otchet_specialnyi',
      'element_click',
      'zagruzka',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports',
      groupIdParam,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  return (
    <>
      {dateFromParam && dateToParam && (
        <DownloadReportButton title="Междуреченский водоканал.xls" onClick={getReport} />
      )}
    </>
  );
};

export default ReportsSpecialResults;
