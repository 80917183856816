import { useQuery } from 'react-query';

import { GET_BALANCE_GROUP_REPORT_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';
// import getUTCTimestamp from '../../../utils/getUTCTimestamp';

const useFetchBalanceGroupReport = (
  id: string,
  dateFrom: string | null,
  dateTo: string | null,
  download: boolean,
) => {
  // const UTCDateFrom = dateFrom ? getUTCTimestamp(parseInt(dateFrom)).toString() : null;
  // const UTCDateTo = dateTo ? getUTCTimestamp(parseInt(dateTo)).toString() : null;

  return useQuery(
    ['balanceGroupReportData', id],
    () =>
      fetchData(
        GET_BALANCE_GROUP_REPORT_URL(
          id,
          dateFrom,
          dateTo,
          download,
        ),
      ),
    {
      refetchOnMount: true,
    },
  );
};

export default useFetchBalanceGroupReport;
