import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fillRule="evenodd" d="M19.3179 4.68597C18.6129 3.98098 17.9094 3.62649 17.0039 3.41921V2C17.0039 1.44772 16.5562 1 16.0039 1C15.4516 1 15.0039 1.44772 15.0039 2V3.15211C14.7151 3.12824 14.4083 3.10541 14.0811 3.08105C13.403 3.03058 12.7034 3 12.0039 3C11.3044 3 10.6048 3.03059 9.92664 3.08106C9.59945 3.10541 9.29273 3.12824 9.00391 3.15211V2C9.00391 1.44772 8.55619 1 8.00391 1C7.45162 1 7.00391 1.44772 7.00391 2V3.41921C6.09845 3.62649 5.39486 3.98098 4.68987 4.68597C3.377 5.99884 3.27965 7.30684 3.08496 9.92282C3.03449 10.601 3.00391 11.3005 3.00391 12C3.00391 12.6995 3.03449 13.399 3.08496 14.0772C3.27965 16.6932 3.377 18.0012 4.68987 19.314C6.00275 20.6269 7.31074 20.7243 9.92673 20.9189C10.6049 20.9694 11.3044 21 12.0039 21C12.7034 21 13.403 20.9694 14.0811 20.9189C16.6971 20.7243 18.0051 20.6269 19.3179 19.314C20.6308 18.0012 20.7282 16.6932 20.9229 14.0772C20.9733 13.399 21.0039 12.6995 21.0039 12C21.0039 11.3005 20.9733 10.601 20.9229 9.92282C20.7282 7.30684 20.6308 5.99884 19.3179 4.68597ZM6.00096 9C5.44868 9 5.00096 9.44771 5.00096 10C5.00096 10.5523 5.44868 11 6.00096 11H18.001C18.5532 11 19.001 10.5523 19.001 10C19.001 9.44771 18.5532 9 18.001 9H6.00096ZM15.001 16.4C15.7742 16.4 16.401 15.7732 16.401 15C16.401 14.2268 15.7742 13.6 15.001 13.6C14.2278 13.6 13.601 14.2268 13.601 15C13.601 15.7732 14.2278 16.4 15.001 16.4Z" />
    </SvgIcon>
  );
};

export default CalendarIcon;
