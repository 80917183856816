import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fillRule="evenodd" d="M4.68597 4.68792C3.3731 6.00079 3.27575 7.30879 3.08105 9.92478C3.03058 10.6029 3 11.3024 3 12.002C3 12.7015 3.03058 13.401 3.08105 14.0791C3.27575 16.6951 3.3731 18.0031 4.68597 19.316C5.99884 20.6289 7.30684 20.7262 9.92282 20.9209C10.601 20.9714 11.3005 21.002 12 21.002C12.6995 21.002 13.399 20.9714 14.0772 20.9209C16.6932 20.7262 18.0012 20.6289 19.314 19.316C20.6269 18.0031 20.7243 16.6951 20.9189 14.0791C20.9694 13.401 21 12.7015 21 12.002C21 11.3024 20.9694 10.6029 20.9189 9.92478C20.7243 7.30879 20.6269 6.00079 19.314 4.68792C18.0012 3.37505 16.6932 3.2777 14.0772 3.08301C13.399 3.03254 12.6995 3.00195 12 3.00195C11.3005 3.00195 10.601 3.03254 9.92282 3.08301C7.30684 3.2777 5.99884 3.37505 4.68597 4.68792ZM15.2071 8.79289C15.5976 9.18342 15.5976 9.81658 15.2071 10.2071L13.414 12.0002L15.2071 13.7932C15.5976 14.1838 15.5976 14.8169 15.2071 15.2074C14.8166 15.598 14.1834 15.598 13.7929 15.2074L11.9998 13.4144L10.2071 15.2071C9.81658 15.5976 9.18342 15.5976 8.79289 15.2071C8.40237 14.8166 8.40237 14.1834 8.79289 13.7929L10.5856 12.0002L8.7929 10.2074C8.40237 9.81692 8.40237 9.18375 8.7929 8.79323C9.18342 8.40271 9.81658 8.40271 10.2071 8.79323L11.9998 10.586L13.7929 8.79289C14.1834 8.40237 14.8166 8.40237 15.2071 8.79289Z" />
    </SvgIcon>
  );
};

export default ErrorIcon;
