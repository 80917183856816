import { useEffect, useRef, useCallback } from 'react';

export type useThrottledFunctionProps = {
  callbackFn: <T, >(args?: T) => any
  throttleMs?: number
}

const DEFAULT_THROTTLE_MS = 800;

const getRemainingTime = (lastTriggeredTime: number, throttleMs: number) => {
  const elapsedTime = Date.now() - lastTriggeredTime;
  const remainingTime = throttleMs - elapsedTime;

  return (remainingTime < 0) ? 0 : remainingTime;
}

const useThrottleFunction = ({callbackFn, throttleMs = DEFAULT_THROTTLE_MS}: useThrottledFunctionProps) => {
  const lastTriggered = useRef<number>(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const throttleFn = useCallback(<T extends Event>(args?: T) => {
    if (args) {
      args.preventDefault();
    }
    let remainingTime = getRemainingTime(lastTriggered.current, throttleMs);
    if (remainingTime === 0) {
      lastTriggered.current = Date.now();
      callbackFn(args);
      cancel();
    } else if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        remainingTime = getRemainingTime(lastTriggered.current, throttleMs);
        if (remainingTime === 0) {
          lastTriggered.current = Date.now();
          callbackFn(args);
          cancel();
        }
      }, remainingTime);
    }
  }, [callbackFn, cancel, throttleMs]);

  useEffect(() => cancel, [cancel]);

  return {cancel, throttleFn};
}

export default useThrottleFunction;
