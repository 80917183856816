import React, { createContext, useContext, useMemo, useState } from 'react';

import useMousePosition from '../../../utils/hooks/useMousePosition';

const TooltipContext = createContext<any>({} as any);

export function useTooltipContext() {
  const tooltipContext = useContext(TooltipContext);

  return tooltipContext;
}

export default function CustomTooltip({ children }: any) {
  const emptyTooltip = {
    open: false,
    content: null,
    style: null,
  };
  const [tooltip, setTooltip] = useState(emptyTooltip);

  const openTooltip = ({ content, style }: any) => {
    setTooltip({
      open: true,
      content: content,
      style: style,
    });
  };

  const closeTooltip = () => {
    setTooltip(emptyTooltip);
  };

  return (
    <div>
      <Tooltip tooltip={tooltip} />
      <TooltipProvider openTooltip={openTooltip} closeTooltip={closeTooltip}>
        {children}
      </TooltipProvider>
    </div>
  );
}

const Tooltip = ({ tooltip }: any) => {
  const position = useMousePosition();

  const left = tooltip.open ? position.x : -9999;
  const top = tooltip.open ? position.y : -9999;

  return (
    <div
      style={{
        position: 'fixed',
        left: left + 5,
        top: top + 5,
        zIndex: 9999,
        borderRadius: '8px',
        ...tooltip.style,
      }}
    >
      {tooltip.content}
    </div>
  );
};

function TooltipProvider({ children, openTooltip, closeTooltip }: any) {
  const tooltipContext = useMemo(() => {
    return {
      openTooltip: openTooltip,
      closeTooltip: closeTooltip,
    };
  }, [openTooltip, closeTooltip]);

  return <TooltipContext.Provider value={tooltipContext}>{children}</TooltipContext.Provider>;
}
