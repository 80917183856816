import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IsFavoriteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M8.16521 17.9666C8.83194 18.1672 9.83248 17.4571 11.8336 16.0369L12 15.9187L12.1664 16.0369C14.1675 17.4571 15.1681 18.1672 15.8348 17.9666C16.112 17.8831 16.3557 17.7174 16.532 17.4924C16.9561 16.9512 16.6216 15.7881 15.9527 13.4619L16.0221 13.4067C18.0882 11.7654 19.1212 10.9447 18.9887 10.1636C18.9653 10.0261 18.9206 9.89292 18.8559 9.76868C18.4886 9.06296 17.1586 9.00043 14.4986 8.87537C13.4917 6.34867 12.9883 5.08532 12.1316 5.00607C12.044 4.99798 11.956 4.99798 11.8684 5.00607C11.0117 5.08532 10.5083 6.34867 9.50144 8.87537C6.84144 9.00043 5.51145 9.06296 5.14411 9.76868C5.07944 9.89292 5.03466 10.0261 5.01133 10.1636C4.87879 10.9447 5.91184 11.7654 7.97793 13.4068L8.04729 13.4619C7.37837 15.7881 7.04391 16.9512 7.46796 17.4924C7.64428 17.7174 7.88799 17.8831 8.16521 17.9666ZM13.0346 14.8136C14.0011 15.4996 14.6552 15.9617 15.1452 16.2496C15.0363 15.7091 14.8244 14.9657 14.5111 13.8764L14.2337 12.9117L15.089 12.2323C16.0379 11.4784 16.6849 10.9616 17.1116 10.5566C16.5172 10.4741 15.6715 10.4322 14.4281 10.3737L13.4628 10.3283L13.1051 9.43064C12.6136 8.19718 12.2866 7.38425 12 6.84741C11.7134 7.38425 11.3864 8.19718 10.8949 9.43064L10.5372 10.3283L9.57188 10.3737C8.32847 10.4322 7.48284 10.4741 6.88836 10.5566C7.31512 10.9616 7.9621 11.4784 8.91099 12.2323L9.76626 12.9117L9.48887 13.8764C9.17564 14.9657 8.96367 15.7091 8.85476 16.2496C9.34483 15.9617 9.99888 15.4996 10.9654 14.8136L12 14.0793L13.0346 14.8136Z"
      />
    </SvgIcon>
  );
};

export default IsFavoriteIcon;
