import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M5 2C5 1.45 4.55 1 4 1C3.45 1 3 1.45 3 2V6H1V16C1 17.3 1.84 18.4 3 18.82V23H5V18.82C6.16 18.4 7 17.3 7 16V6H5V2ZM4 17C3.45 17 3 16.55 3 16V14H5V16C5 16.55 4.55 17 4 17ZM3 8V12H5V8H3ZM13 2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V6H9V16C9 17.3 9.84 18.4 11 18.82V23H13V18.82C14.16 18.4 15 17.3 15 16V6H13V2ZM11 16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V14H11V16ZM11 12V8H13V12H11ZM21 2V6H23V16C23 17.3 22.16 18.4 21 18.82V23H19V18.82C17.84 18.4 17 17.3 17 16V6H19V2C19 1.45 19.45 1 20 1C20.55 1 21 1.45 21 2ZM20 17C19.45 17 19 16.55 19 16V14H21V16C21 16.55 20.55 17 20 17ZM19 8V12H21V8H19Z" />
    </SvgIcon>
  );
};

export default SettingsIcon;
