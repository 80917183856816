import { useQuery } from 'react-query';

import { GET_USERS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchUsers = () => {
  return useQuery('usersData', () => fetchData(GET_USERS_URL));
};

export default useFetchUsers;
