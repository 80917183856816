import { useQuery } from 'react-query';

import { GET_CUSTOM_REPORT_DATA } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchCustomReportData = (id: string) => {

  const {
    isLoading,
    isError,
    data: newData,
  } = useQuery(['saveGeneratorForm', 'customReportData', id], () => fetchData(GET_CUSTOM_REPORT_DATA(id)));

  const idFilter = newData?.filters.find(
    (filter: { tech_name: string }) => filter.tech_name === 'id',
  );
  const dateFilter = newData?.filters.find(
    (filter: { tech_name: string }) => filter.tech_name === 'date',
  );

  const assetFilter = newData?.filters.find(
    (filter: { tech_name: string }) => filter.tech_name === 'asset',
  );

  const otherFilters = Array.isArray(newData?.filters)
    ? newData.filters.filter((filter: { tech_name: string }) => {
        if (newData?.shablon_type === 'report_device') {
          return filter.tech_name !== 'id' && filter.tech_name !== 'date';
        } else if (newData?.shablon_type === 'report_group') {
          return filter.tech_name !== 'asset' && filter.tech_name !== 'date';
        }

        return false;
      })
    : [];

  const reorderedFilters = [];

  if (newData?.shablon_type === 'report_device' && idFilter) {
    reorderedFilters.push(idFilter);
  }
  if (newData?.shablon_type === 'report_group' && assetFilter) {
    reorderedFilters.push(assetFilter);
  }
  if (dateFilter) {
    reorderedFilters.push(dateFilter);
  }
  reorderedFilters.push(...otherFilters);

  const dataCustomReport = { ...newData, filters: reorderedFilters };

  return {
    isLoading,
    isError,
    dataCustomReport,
  };
};

export default useFetchCustomReportData;
