import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import {
  Alert,
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Dialog from '../../../components/Dialog';
import IconBox from '../../../components/IconBox';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import EditIcon from '../../../components/icons/EditIcon';
import FolderIcon from '../../../components/icons/FolderIcon';
import IsFavoriteBlackIcon from '../../../components/icons/IsFavoriteBlackIcon';
import IsFavoriteIcon from '../../../components/icons/IsFavoriteIcon';
import { CHANGE_FAVORITE_CUSTOM_REPORT, DELETE_CUSTOM_REPORT_GENERATOR } from '../../../constants';
import { ICustomReportTemplate, IToggleFavoriteProps, IUser } from '../../../types';
import fetchData from '../../../utils/fetchData';
import useSnackbars from '../../../utils/hooks/useSnackbars';
import sendMetrik from '../../../utils/sendMetrik';

const CustomReportsList = ({ filteredTemplates }: any) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const [removingId, setRemovingId] = useState('');
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // добавление в избранное
  const toggleFavoriteMutation = useMutation(
    (dataFavorite: IToggleFavoriteProps) => {
      return fetchData(CHANGE_FAVORITE_CUSTOM_REPORT(dataFavorite.uuid, dataFavorite.is_favorite), {
        method: 'PUT',
      });
    },
    {
      onSuccess: () => {
        // Обновляем кэш данных после успешного выполнения мутации
        queryClient.invalidateQueries(['saveGeneratorForm', 'customReportData']);
      },
    },
  );

  // отображение в списке сначала избранных, потом всех остальных
  const sortTemplates = (templates: []) => {
    const favoriteTemplates =
      Array.isArray(templates) &&
      templates.filter((template: { is_favorite: boolean }) => template.is_favorite);
    const otherTemplates =
      Array.isArray(templates) &&
      templates.filter((template: { is_favorite: boolean }) => !template.is_favorite);

    return (
      Array.isArray(favoriteTemplates) &&
      Array.isArray(otherTemplates) && [...favoriteTemplates, ...otherTemplates]
    );
  };

  const sortedTemplates: any = sortTemplates(filteredTemplates);
  const paginatedTemplates =
    Array.isArray(sortedTemplates) && sortedTemplates.slice(startIndex, endIndex);

  // добавление в избранное
  const handleFavoriteClick = (template: ICustomReportTemplate) => {
    const newIsFavorite = !template.is_favorite;
    template.is_favorite = newIsFavorite;
    toggleFavoriteMutation.mutate(template);
    template.is_favorite === true &&
      showSnackbar(<Alert severity="info">Шаблон отчета добавлен в избранное</Alert>);

    sendMetrik(
      'vntVdknl',
      'otchet_polzovatelskii',
      'element_click',
      'izbrannoe',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports/custom',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  // удаление шаблона
  const customReportDelete = useMutation(
    (id: string) => {
      return fetchData(DELETE_CUSTOM_REPORT_GENERATOR(id), {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        handleRemoveDialogClose();
        queryClient.invalidateQueries('saveGeneratorForm');
      },
    },
  );

  // При нажатии на кнопку удаления, показываем диалогове окно для подтверждения
  const handleDeleteClick = (id: string) => {
    setIsRemoveDialogOpen(true);
    setRemovingId(id);

    sendMetrik(
      'vntVdknl',
      'otchet_polzovatelskii',
      'element_click',
      'udalit',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports/custom',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  // Инициируем удаление шаблона
  const remove = () => {
    customReportDelete.mutate(removingId);
  };

  // Закрывает диалогове окно удаления
  const handleRemoveDialogClose = () => {
    setIsRemoveDialogOpen(false);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {Array.isArray(paginatedTemplates) &&
        Array.from(paginatedTemplates)?.map((template: any) => (
          <Card
            key={template.uuid}
            sx={{ mb: 2 }}
            style={{
              borderRadius: 16,
              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
              minHeight: '76px',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '6px',
                paddingTop: '6px',
              }}
            >
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <IconBox color={theme.palette.primary.greyscale200}>
                  <FolderIcon />
                </IconBox>
                <Box ml={3}>
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Link
                      style={{ color: '#1D2023', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/reports/customReport/${template.uuid}`);
                        sendMetrik(
                          'vntVdknl',
                          'otchet_polzovatelskii',
                          'element_click',
                          'otchet',
                          null,
                          userData?.permissions[0].uuid,
                          userData ? '1' : '0',
                          '/reports/custom',
                          template.uuid,
                          null,
                          null,
                          'interactions',
                          userData?.profile_type,
                          'web',
                        );
                      }}
                    >
                      <Typography
                        variant="h3"
                        fontSize="17px"
                        style={{
                          maxWidth: '600px',
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                        }}
                      >
                        {template.name}
                      </Typography>
                    </Link>
                    <Typography
                      ml={1}
                      bgcolor={template?.device_type?.uspd ? '#6384E0' : '#F37F19'}
                      variant="h3"
                      color="#FFFFFF"
                      fontSize="12px"
                      sx={{ padding: '0px 4px', borderRadius: '4px', height: 'max-content' }}
                    >
                      {Object.values(template.device_type)}
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Typography variant="h3" fontSize="14px" color="#626C77">
                      Тип шаблона:{' '}
                    </Typography>
                    <Typography fontSize="14px" color="textSecondary" ml={0.5}>
                      {template.shablon_type === 'report_device'
                        ? 'Отчет по устройству'
                        : 'Отчет по группе'}
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Typography variant="h3" fontSize="14px" color="#626C77">
                      Описание:{' '}
                    </Typography>
                    <Typography fontSize="14px" color="textSecondary" ml={0.5}>
                      {template.description}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row" spacing={1}>
                <IconButton
                  size="extraSmall"
                  color="secondary"
                  title="Редактировать"
                  onClick={() => {
                    navigate(`/reports/${template.uuid}/customReportEdit`);
                    sendMetrik(
                      'vntVdknl',
                      'otchet_polzovatelskii',
                      'element_click',
                      'redaktirovat',
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/reports/custom',
                      null,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="extraSmall"
                  color="secondary"
                  title="Избранное"
                  onClick={() => handleFavoriteClick(template)}
                >
                  {template.is_favorite ? <IsFavoriteBlackIcon /> : <IsFavoriteIcon />}
                </IconButton>
                <IconButton
                  size="extraSmall"
                  color="secondary"
                  title="Удалить"
                  onClick={() => handleDeleteClick(template.uuid)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            </CardContent>
          </Card>
        ))}
      {Array.isArray(sortedTemplates) && sortedTemplates.length < 11 ? null : (
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(sortedTemplates.length / pageSize)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
      <Dialog
        busy={customReportDelete.isLoading}
        maxWidth="md"
        heading="Удалить шаблон отчета?"
        actionButtonText="Удалить"
        open={isRemoveDialogOpen}
        hideCloseButton={true}
        onAction={remove}
        onClose={handleRemoveDialogClose}
      >
        Вы действительно хотите удалить созданный шаблон?
      </Dialog>
    </>
  );
};

export default CustomReportsList;
