import { useQuery } from 'react-query';

import { BALANCE_GROUPS_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchBalanceGroups = () => {
  return useQuery('balanceGroupsData', () => fetchData(BALANCE_GROUPS_URL));
};

export default useFetchBalanceGroups;
