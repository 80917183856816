import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M2.75 4.5C2.75 4.08579 3.08579 3.75 3.5 3.75H12.5C12.9142 3.75 13.25 4.08579 13.25 4.5C13.25 4.91421 12.9142 5.25 12.5 5.25H3.5C3.08579 5.25 2.75 4.91421 2.75 4.5Z" />
      <path d="M4.25 8C4.25 7.58579 4.58579 7.25 5 7.25H11C11.4142 7.25 11.75 7.58579 11.75 8C11.75 8.41421 11.4142 8.75 11 8.75H5C4.58579 8.75 4.25 8.41421 4.25 8Z" />
      <path d="M6.5 10.75C6.08579 10.75 5.75 11.0858 5.75 11.5C5.75 11.9142 6.08579 12.25 6.5 12.25H9.5C9.91421 12.25 10.25 11.9142 10.25 11.5C10.25 11.0858 9.91421 10.75 9.5 10.75H6.5Z" />
    </SvgIcon>
  );
};

export default FilterIcon;
