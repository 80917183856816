import { useMemo } from 'react';

import { useQueryClient } from 'react-query';

import { GET_WS_MAP_URL } from '../../../constants';
import { IUser } from '../../../types';
import useWebsocket from '../../../utils/hooks/useWebsocket';

const useWSMapDevices = () => {
  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { isLoading, data } = useWebsocket({
    url: GET_WS_MAP_URL(userData?.customerId),
    queryKey: 'mapDevicesData',
    enabled: true,
  });

  const updateData = useMemo(() => {
    let modifiedDevices: any[] = [];
    if (Array.isArray(data?.devices)) {
      modifiedDevices = data.devices.reduce(
        (acc: any[], curr: { device_id: any; asset_name: string | null }) => {
          const found = acc.some((el) => el.device_id === curr.device_id);
          if (!found) {
            if (curr.asset_name === null) {
              curr.asset_name = 'Нет названия';
            }
            acc.push(curr);
          }

          return acc;
        },
        [],
      )
    };

    return {
      ...data,
      devices: modifiedDevices
    }
  }, [data]);
  queryClient.setQueryData('mapDevicesData', updateData);

  return {
    isLoading,
    data: updateData,
  };
};

export default useWSMapDevices;
