import { useQueryClient } from 'react-query';

import { Controller, useForm } from 'react-hook-form';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

export const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
  },
});

// настройки конструкотора отчета
const CustomSettingsReport = ({
  dataCustomDevices,
  isSettingsReportOpen,
  setIsSettingsReportOpen,
  selectedDevice,
  selectDeviceError,
  setSelectedDevice,
  setSelectDeviceError,
  selectTamplateError,
  selectedTemplate,
  reportTemplates,
  handleTemplateCheckbox,
  reportTitleText,
  setReportTitleText,
  reportDescriptionText,
  setReportDescriptionText,
  reportTitleTextError,
}: any) => {
  const methods = useForm();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  return (
    <Box
      sx={{
        marginBottom: 3,
        padding: 3,
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '16px',
      }}
    >
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h3">Настройки отчета</Typography>
        <div style={{ cursor: 'pointer', lineHeight: 0 }}>
          {isSettingsReportOpen ? (
            <KeyboardArrowUp
              style={{ fontSize: '2.5rem' }}
              onClick={() => setIsSettingsReportOpen((prevState: any) => !prevState)}
            />
          ) : (
            <KeyboardArrowDown
              style={{ fontSize: '2.5rem' }}
              onClick={() => setIsSettingsReportOpen((prevState: any) => !prevState)}
            />
          )}
        </div>
      </Stack>
      {isSettingsReportOpen && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none" error={selectDeviceError}>
              <FormLabel>Тип устройства*</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    id="device-error"
                    value={selectedDevice}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span>Выберите тип устройства</span>;
                      }

                      const selectedItem = dataCustomDevices?.devices[selected];

                      return selectedItem;
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      setSelectedDevice(value);
                      setSelectDeviceError(false);

                      sendMetrik(
                        'vntVdknl',
                        'otchety_konstruktor',
                        'form_change',
                        'tip_ustroistva',
                        null,
                        userData?.permissions[0].uuid,
                        userData ? '1' : '0',
                        '/reports/customReportCreate',
                        value,
                        null,
                        null,
                        'interactions',
                        userData?.profile_type,
                        'web',
                      );
                    }}
                    SelectDisplayProps={{
                      className: 'MuiSelect-alt',
                    }}
                  >
                    {dataCustomDevices &&
                      Object.entries(dataCustomDevices?.devices).map(([id, name]: [any, any]) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                name=""
                control={methods.control}
              />
              {selectDeviceError && (
                <FormHelperText>Выбор типа устройства обязателен для заполнения</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none" error={selectTamplateError}>
              <FormLabel>Тип шаблона*</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    id="template-error"
                    value={selectedTemplate}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span>Выберите тип шаблона</span>;
                      }

                      const selectedItem = reportTemplates?.templates[selected];

                      return selectedItem;
                    }}
                    onChange={handleTemplateCheckbox}
                    SelectDisplayProps={{
                      className: 'MuiSelect-alt',
                    }}
                  >
                    {reportTemplates &&
                      Object.entries(reportTemplates?.templates).map(([id, name]: [any, any]) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                name=""
                control={methods.control}
              />
              {selectTamplateError && (
                <FormHelperText>Выбор типа шаблона обязателен для заполнения</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none" error={reportTitleTextError}>
              <FormLabel>Название отчета</FormLabel>
              <Controller
                render={({ field }) => (
                  <WhiteTextField
                    {...field}
                    id="title-error"
                    value={reportTitleText}
                    onChange={(e) => setReportTitleText(e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Задайте название отчета"
                  />
                )}
                name=""
                control={methods.control}
              />
              {reportTitleTextError && (
                <FormHelperText>Название отчета обязательно для заполнения</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none">
              <FormLabel>Описание отчета</FormLabel>
              <Controller
                render={({ field }) => (
                  <WhiteTextField
                    {...field}
                    value={reportDescriptionText}
                    onChange={(e) => setReportDescriptionText(e.target.value)}
                    fullWidth
                    id="outlined"
                    placeholder="Задайте описание отчета"
                  />
                )}
                name=""
                control={methods.control}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CustomSettingsReport;
