import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryInnerProps = {
  hasError: boolean;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

class ErrorBoundaryInner extends React.Component<ErrorBoundaryInnerProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryInnerProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryInnerProps, _previousState: any) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(_error: any, _errorInfo: any) {
    this.props.setHasError(true);
    console.error('Ошибка:', _error, _errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '32px 0 0 40px' }}>
          <Typography variant="h1" style={{ fontFamily: 'MTSCompact' }}>
            К сожалению произошла ошибка
            <br></br>
            <span
              style={{
                fontSize: '17px',
                lineHeight: '24px',
                fontWeight: 400,
                fontFamily: 'MTSCompact',
              }}
            >
              Попробуйте перезагрузить страницу или обратитесь в службу поддержки.
            </span>
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
