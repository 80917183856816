import { useState, useEffect } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ErrorIcon from '../../components/icons/ErrorIcon';
import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { UPDATE_BALANCE_GROUP_URL } from '../../constants';
import { IBalanceGroup } from '../../types';
import fetchData from '../../utils/fetchData';
import useSnackbars from '../../utils/hooks/useSnackbars';

import BalanceGroupForm from './BalanceGroupForm';
import useFetchBalanceGroup from './hooks/useFetchBalanceGroup';
import useFetchBalanceGroups from './hooks/useFetchBalanceGroups';

const EditBalanceGroup = () => {
  let { balanceGroupId = '' } = useParams();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const [defaultValues, setDefaultValues] = useState(null);

  const { isLoading, isError, data } = useFetchBalanceGroup(balanceGroupId);

  const {
    isLoading: isLoadingBalanceGroups,
    isError: isErrorBalanceGroups,
    data: balanceGroupsData,
  } = useFetchBalanceGroups();

  useEffect(() => {
    if (data && balanceGroupsData) {
      setDefaultValues({
        ...data,
        parent_items: Array.isArray(data?.parent_items)
          ? data.parent_items.map((item: any) => ({
              item: {
                device_id: parseInt(item.device_id)
              },
              channel: item.channel,
              address: null,
            }))
          : [],
        child_items: Array.isArray(data?.child_items)
          ? data.child_items
              .filter((item: any) => item.device_id)
              .map((item: any) => ({
                item: {
                  device_id: parseInt(item.device_id)
                },
                channel: item.channel,
                address: null,
            }))
          : [],
        child_balance_groups: Array.isArray(data?.child_items)
          ? data.child_items
              .filter((item: any) => item.balance_id)
              .map((item: any) => ({
                group: {
                  uuid: item.balance_id,
                  name: balanceGroupsData.filter((group: any) => group.uuid === item.balance_id)[0].name
                },
            }))
          : [],
      });
    }
  }, [data, balanceGroupsData]);

  const balanceGroupDataMutation = useMutation(
    (newData: IBalanceGroup) => {
      return fetchData(UPDATE_BALANCE_GROUP_URL, {
        method: 'PUT',
        body: newData,
      });
    },
    {
      onSuccess: () => {
        showSnackbar(<Alert severity="success">Балансовая группа обновлена</Alert>);
        queryClient.invalidateQueries(['balanceGroupsData'], {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(['balanceGroupData', balanceGroupId]);
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Балансовая группа не обновлена</Alert>);
      },
    },
  );

  const handleSubmit = (newData: IBalanceGroup) => {
    balanceGroupDataMutation.mutate({ ...newData, uuid: balanceGroupId });
  };

  if (isLoading || isLoadingBalanceGroups || !defaultValues) {
    return <Spinner data-testid="loader" />;
  }

  if (isError || isErrorBalanceGroups) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные балансовых групп. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <>
      <Breadcrumbs>
        <Link component={RouterLink} to="/balance-groups">
          Балансовые группы
        </Link>
        <span>{data.name}</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        {data.name}
      </Typography>
      <BalanceGroupForm
        defaultValues={defaultValues}
        isLoading={balanceGroupDataMutation.isLoading}
        onFormSubmit={handleSubmit}
      />
    </>
  );
};

export default EditBalanceGroup;
