import { useState } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Message from '../../components/Message';
import Spinner from '../../components/Spinner';
import { UPDATE_NOTIFICATIONS_TEMPLATE_URL } from '../../constants';
import { INotificationsTemplate } from '../../types';
import fetchData from '../../utils/fetchData';
import useFetchGroups from '../../utils/hooks/useFetchGroups';
import useFetchUsers from '../../utils/hooks/useFetchUsers';
import useSnackbars from '../../utils/hooks/useSnackbars';

import useFetchNotificationsTemplate from './hooks/useFetchNotificationsTemplate';
import NotificationsTemplateForm from './NotificationsTemplateForm';

const EditNotificationsTemplate = () => {
  let { templateId = '' } = useParams();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const [isSettled, setIsSettled] = useState(false);

  const { isLoading, isError, data } = useFetchNotificationsTemplate(templateId);

  const {
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
    data: groupsData,
  } = useFetchGroups(true);

  const { isLoading: isLoadingUsers, isError: isErrorUsers, data: usersData } = useFetchUsers(true);

  const notificationsTemplateDataMutation = useMutation(
    (templateData: INotificationsTemplate) => {
      return fetchData(UPDATE_NOTIFICATIONS_TEMPLATE_URL, {
        method: 'PUT',
        body: templateData,
      });
    },
    {
      onSuccess: (responseData) => {
        setIsSettled(true);
        if (!responseData.detail) {
          showSnackbar(<Alert severity="success">Шаблон обновлен</Alert>);
          queryClient.invalidateQueries(['notificationsTemplatesData'], {
            refetchInactive: true,
          });
        } else {
          showSnackbar(<Alert severity="error">Шаблон не обновлен</Alert>);
        }
      },
      onError: () => {
        setIsSettled(true);
        showSnackbar(<Alert severity="error">Шаблон не обновлен</Alert>);
      },
    },
  );

  const handleSubmit = (newTemplateData: INotificationsTemplate) => {
    notificationsTemplateDataMutation.mutate({ ...newTemplateData, uuid: templateId });
  };

  const handleSubmitButtonClick = () => {
    setIsSettled(false);
  };

  let defaultValues = null;
  if (data && groupsData && usersData.length && !defaultValues) {
    const users = usersData.filter((item: any) => {
      return data.users.indexOf(item.msisdn) !== -1;
    });
    defaultValues = {
      ...data,
      asset_id: groupsData.filter((group: any) => group.id === data.asset_id)[0],
      users,
      // always: true,
      // mondayStart: '',
      // mondayEnd: '',
      // tuesdayStart: '',
      // tuesdayEnd: '',
      // wednesdayStart: '',
      // wednesdayEnd: '',
      // thursdayStart: '',
      // thursdayEnd: '',
      // fridayStart: '',
      // fridayEnd: '',
      // saturdayStart: '',
      // saturdayEnd: '',
      // sundayStart: '',
      // sundayEnd: '',
    };
  }

  if (isLoading || isLoadingGroups || isLoadingUsers || !defaultValues) {
    return <Spinner data-testid="loader" />;
  }

  if (isError || isErrorGroups || isErrorUsers) {
    return <Message>Не получилось загрузить данные шаблона. Попробуйте еще раз.</Message>;
  }

  return (
    <>
      <Breadcrumbs>
        <Link component={RouterLink} to="/notifications">
          Шаблоны уведомлений
        </Link>
        <span>Редактирование шаблона</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Редактирование шаблона
      </Typography>
      <NotificationsTemplateForm
        defaultValues={defaultValues}
        isLoading={notificationsTemplateDataMutation.isLoading}
        isSettled={isSettled}
        onFormSubmit={handleSubmit}
        onSubmitButtonClick={handleSubmitButtonClick}
      />
    </>
  );
};

export default EditNotificationsTemplate;
