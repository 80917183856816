import { useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { MapContainer, Popup, Tooltip, useMap } from 'react-leaflet';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import L, { LatLngExpression } from 'leaflet';

import StatusBadge from '../../components/StatusBadge';
import { getDeviceType, getDeviceStatus } from '../../routes/map';
import { IUser } from '../../types';
import useScript from '../../utils/hooks/useScript';
import sendMetrik from '../../utils/sendMetrik';
import { Marker, MarkerClusterGroup } from '../DonutCluster/index';

type IMapObjectsProps = {
  data: any;
  position?: number[];
};

type IDeviceInfoProps = {
  device: any;
  short?: boolean;
};

const MapPopupStyled = styled(Popup)({
  '.leaflet-popup-content': {
    width: '335px!important' as string,
    margin: '0px',
    padding: '24px',
    '& p': {
      margin: '0 0 8px',
      fontSize: 14,
      lineHeight: '20px',
      '& .value': {
        display: 'block',
      },
    },
  },
});

const MapTooltipStyled = styled(Tooltip)({
  width: '335px!important' as string,
  marginTop: '-12px',
  padding: '24px',
  borderRadius: '12px',
  whiteSpace: 'normal',
  textAlign: 'left',
  '& p': {
    margin: '0 0 8px',
    fontSize: 14,
    lineHeight: '20px',
  },
});

const DeviceInfo = (props: IDeviceInfoProps) => {
  const {
    device: {
      device_type,
      device_id,
      status,
      address_full,
      asset_name,
      latitude,
      longitude,
      last_value,
    },
    short
  } = props;

  const getLastValue = (value: any) => {
    let lastValue =
      typeof value === 'object' && Object.keys(value).length > 0
        ? Object.keys(value).map((key, index) => {
            return (
              <Box className='value' component="span" key={index}>
                Канал {key}: {value[key]}
              </Box>
            );
          })
        : typeof value === 'object' && Object.keys(value).length === 0
        ? 'Нет значений'
        : value;

    return lastValue;
  };

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ fontSize: 17 }}>
        {device_type}
      </Typography>
      <Stack direction="row" alignItems="center" gap={1} sx={{marginBottom: 1}}>
        <StatusBadge color={getDeviceStatus(status)} />
        <Typography variant="paragraph3">{status}</Typography>
      </Stack>
      <Typography variant="paragraph3" paragraph>
        <b>Серийный номер: </b>
        {getDeviceType(device_type) ? (
          <Link
            to={`/dashboards?device_type=${getDeviceType(
              device_type,
            )}&dashboard_type=real_time&device_id=${device_id}`}
          >
            {device_id}
          </Link>
        ) : (
          device_id
        )}
      </Typography>
      <Typography variant="paragraph3" paragraph>
        <b>Адрес: </b>
        {address_full}
      </Typography>
      {!short &&
        <>
          <Typography variant="paragraph3" paragraph>
            <b>Группа: </b>
            {asset_name}
          </Typography>
          <Typography variant="paragraph3" paragraph>
            <b>Координаты: </b>
            {latitude}°, {longitude}°
          </Typography>
          <Divider sx={{ margin: '8px 0' }} />
          <Typography variant="paragraph3" paragraph>
            <b>Текущее значение: </b>
            {getLastValue(last_value)}
          </Typography>
        </>
      }
    </>
  );
};

const Objects = (props: IMapObjectsProps) => {
  const { data, position } = props;

  const theme = useTheme();

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const map = useMap();
  const groupRef: any = useRef(null);

  const { isLoaded: isYandexApiScriptLoaded } = useScript({
    src: 'https://api-maps.yandex.ru/2.1/?apikey=32c46d1a-dffe-4457-a14b-184a1a26e2ee&lang=ru_RU',
  });

  const { isLoaded: isYandexTilesForLeafletScriptLoaded } = useScript({
    src: '/YandexTilesForLeaflet.js',
  });

  const statusColors: Record<string, string> = {
    Доступен: theme.palette.success.main,
    Недоступен: theme.palette.secondary.main,
    'Превышение уставки': theme.palette.primary.main,
    'Низкий заряд батареи': theme.palette.warning.main,
  };

  const MarkerIcon = (color: string) =>
    L.divIcon({
      html: `<svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <circle
    cx="34"
    cy="34"
    r="32"
    fill="${color}"
    fill-opacity="0.4"
    stroke="${color}"
    stroke-width="4"
  />
  <circle cx="34" cy="34" r="8" fill="white" />
  <circle cx="34" cy="34" r="3" fill="black" />
    </svg>`,
      iconSize: [68, 68],
      className: '',
    });

  useEffect(() => {
    if (isYandexApiScriptLoaded && isYandexTilesForLeafletScriptLoaded) {
      // @ts-ignore: Unreachable code error
      L.yandex().addTo(map);
    }
  }, [isYandexApiScriptLoaded, isYandexTilesForLeafletScriptLoaded, map]);

  useEffect(() => {
    if (groupRef.current) {
      map.fitBounds(groupRef.current.getBounds());
    }
  }, [map]);

  useEffect(() => {
    if (position) {
      map.flyTo([position[0], position[1]], map.getZoom(), {
        animate: true,
      });
    }
  }, [map, position]);

  return (
    <MarkerClusterGroup groupColors={statusColors} ref={groupRef}>
      {data?.map((d: any) => {
        return (
          <Marker
            //@ts-ignore
            group={d.status}
            key={d.device_id}
            position={[d.latitude, d.longitude]}
            icon={MarkerIcon(statusColors[d.status])}
            eventHandlers={{
              click: () => {
                sendMetrik(
                  'vntVdknl',
                  'karta',
                  'element_click',
                  'pie_chart',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/map',
                  d.device_id,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              },
            }}
          >
            <MapPopupStyled>
              <DeviceInfo device={d} />
            </MapPopupStyled>
            <MapTooltipStyled direction="top" opacity={1}>
              <DeviceInfo device={d} short={true} />
            </MapTooltipStyled>
          </Marker>
        );
      })}
    </MarkerClusterGroup>
  );
};

const MapObjects = (props: IMapObjectsProps) => {
  const { data, position } = props;

  const defaultCoordinates: LatLngExpression = [56.326483, 44.00605];
  const southWest = L.latLng(-90, -170);
  const northEast = L.latLng(90, 190);
  const maxBounds = L.latLngBounds(southWest, northEast);

  return (
    <>
      <MapContainer
        style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}
        center={defaultCoordinates}
        zoom={5}
        maxBoundsViscosity={1.0}
        maxBounds={maxBounds}
        minZoom={3}
        maxZoom={20}
        scrollWheelZoom={true}
        crs={L.CRS.EPSG900913}
        attributionControl={false}
      >
        <Objects data={data} position={position} />
      </MapContainer>
    </>
  );
};

export default MapObjects;
