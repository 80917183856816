import { useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import useAppContext from '../../store/hooks/useAppContext';
import useFetchEnv from '../../utils/hooks/useFetchEnv';
import useFetchUser from '../../utils/hooks/useFetchUser';
import ErrorBoundary from '../ErrorBoundary';
import Footer from '../Footer';
import Header from '../Header';
import Spinner from '../Spinner';

const Layout = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const isPublicPage = location.pathname === '/landing' || location.pathname === '/teplo';
  const isNoAccessPage = location.pathname === '/noaccess';
  const isNotificationsPage = location.pathname.includes('/notifications');
  const isBalanceGroupsPage = location.pathname.includes('/balance-groups');
  const isUsersPage = location.pathname.includes('/users');

  const {
    isLoading: isLoadingEnvData,
    isError: isErrorEnvData,
    data: envData,
  } = useFetchEnv(!(isNoAccessPage || isPublicPage));
  const {
    isLoading: isLoadingUserData,
    isError: isErrorUserData,
    data: userData,
  } = useFetchUser(!(isNoAccessPage || isPublicPage));

  const { setUserRights } = useAppContext();

  useEffect(() => {
    if (userData?.permissions && Array.isArray(userData.permissions)) {
      const rights = {
        isAdmin: false,
      };
      userData.permissions.forEach((item: any) => {
        if (item.rule_short === 'admin') {
          rights.isAdmin = true;
        }
      });
      setUserRights(rights);
    }
  }, [setUserRights, userData]);

  useEffect(() => {
    if (
      (isNotificationsPage &&
        envData?.APP_ENV_NOTIFICATIONS === '0' &&
        userData?.customerId !== '792d52e3-3630-4fbb-bfe9-03f93e7d19f4' &&
        userData?.customerId !== 'c32142b0-5dfd-11ee-935a-2da7766d1a9b') ||
      (isBalanceGroupsPage && envData?.APP_ENV_BALANCEGROUPS === '0') ||
      (isUsersPage && envData?.APP_ENV_USERS === '0')
    ) {
      navigate(`/landing`, { replace: true });
    }
  }, [envData, userData, navigate, isNotificationsPage, isBalanceGroupsPage, isUsersPage]);

  if (isLoadingEnvData || isLoadingUserData) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Spinner color="primary" fontSize="large" />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'max-content auto max-content',
          overflow: 'hidden',
          height: '100vh',
        }}
      >
        <Header />
        <ErrorBoundary>
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {isErrorEnvData || isErrorUserData ? null : <Outlet />}
          </Box>
        </ErrorBoundary>
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
