import { Link, LinkProps } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BellIcon from '../../components/icons/BellIcon';
import GroupsIcon from '../../components/icons/GroupsIcon';
import ListIcon from '../../components/icons/ListIcon';
import LocationIcon from '../../components/icons/LocationIcon';
import SettingsIcon from '../../components/icons/SettingsIcon';
import WidgetsIcon from '../../components/icons/WidgetsIcon';
import Main from '../../components/Layout/Main';
import navBg from '../../images/figure.svg';
import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';

const IntroLink = styled(Link)<LinkProps>(({ theme }) => ({
  display: 'block',
  height: '100%',
  padding: theme.spacing(3),
  paddingBottom: '44px',
  background: `url(${navBg}) no-repeat 100% 100% / 186px 36px`,
  boxShadow: theme.shadows[12],
  borderRadius: theme.spacing(2),
  textDecoration: 'none',
  color: 'inherit',
  h2: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.headline,
  },
  p: {
    margin: '0',
    color: theme.palette.text.secondary,
  },
  '.MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    color: theme.palette.icons.tertiary,
  },
}));

const Home = () => {
  const queryClient = useQueryClient();

  const envData: any = queryClient.getQueryData('envData');
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  return (
    <Main>
      <Typography variant="h1" gutterBottom>
        Добро пожаловать в Цифровой водоканал
      </Typography>
      <Typography paragraph>Выберите вкладку, на которую хотели бы перейти.</Typography>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item xs={4}>
          <IntroLink
            to="/map"
            onClick={() =>
              //отправка метрики
              sendMetrik(
                'vntVdknl',
                'main',
                'element_click',
                'karta_ustroistv',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/',
                null,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              )
            }
          >
            <Typography variant="h2" gutterBottom>
              <LocationIcon />
              Карта устройств
            </Typography>
            <Typography variant="body2">
              Отображение всех подключенных устройств с возможностью мониторинга аварийных ситуаций
            </Typography>
          </IntroLink>
        </Grid>
        <Grid item xs={4}>
          <IntroLink
            to="/objects"
            onClick={() =>
              //отправка метрики
              sendMetrik(
                'vntVdknl',
                'main',
                'element_click',
                'spisok_ustroistv',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/',
                null,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              )
            }
          >
            <Typography variant="h2" gutterBottom>
              <SettingsIcon />
              Список устройств
            </Typography>
            <Typography variant="body2">
              Список всех устройств с техническими и пользовательскими параметрами
            </Typography>
          </IntroLink>
        </Grid>
        <Grid item xs={4}>
          <IntroLink
            to="/reports"
            onClick={() =>
              //отправка метрики
              sendMetrik(
                'vntVdknl',
                'main',
                'element_click',
                'otchety',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/',
                null,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              )
            }
          >
            <Typography variant="h2" gutterBottom>
              <ListIcon />
              Отчеты
            </Typography>
            <Typography variant="body2">Выгрузка разных видов отчетов</Typography>
          </IntroLink>
        </Grid>
        <Grid item xs={4}>
          <IntroLink
            to="/dashboards"
            onClick={() =>
              //отправка метрики
              sendMetrik(
                'vntVdknl',
                'main',
                'element_click',
                'dashbordy',
                null,
                userData?.permissions[0].uuid,
                userData ? '1' : '0',
                '/',
                null,
                null,
                null,
                'interactions',
                userData?.profile_type,
                'web',
              )
            }
          >
            <Typography variant="h2" gutterBottom>
              <WidgetsIcon />
              Дашборды
            </Typography>
            <Typography variant="body2">Формирование дашбордов по всем типам устройств</Typography>
          </IntroLink>
        </Grid>
        {((envData && envData.APP_ENV_NOTIFICATIONS !== '0') ||
          (userData && userData.customerId === '792d52e3-3630-4fbb-bfe9-03f93e7d19f4')) && (
          <Grid item xs={4}>
            <IntroLink to="/notifications">
              <Typography variant="h2" gutterBottom>
                <BellIcon />
                Уведомления
              </Typography>
              <Typography variant="body2">
                Поступившие уведомления и конструктор уведомлений
              </Typography>
            </IntroLink>
          </Grid>
        )}
        <Grid item xs={4}>
          <IntroLink to="/balance-groups">
            <Typography variant="h2" gutterBottom>
              <GroupsIcon />
              Балансовые группы
            </Typography>
            <Typography variant="body2">
              Создавайте и отсматривайте свои балансовые группы
            </Typography>
          </IntroLink>
        </Grid>
      </Grid>
    </Main>
  );
};

export default Home;
