import { useQueryClient } from 'react-query';

import { useForm, useFieldArray, Controller } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import StatusBadge from '../../components/StatusBadge';
import { SearchOption, IMapFilter, getDeviceStatus } from '../../routes/map';
import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';
import translitRuEn from '../../utils/translitRuEn';
import SearchIcon from '../icons/SearchIcon';

type IMapSidebarProps = {
  onFilterChange: (data: IMapFilter) => void;
};

const MapSidebar = (props: IMapSidebarProps) => {
  const { onFilterChange } = props;

  const queryClient = useQueryClient();

  const userData: IUser | undefined = queryClient.getQueryData('userData');
  const mapDevicesData: any = queryClient.getQueryData('mapDevicesData');

  let searchOptions: SearchOption[] = [];
  let groups: Set<string> = new Set();
  let deviceTypes: any[] = [
    {
      id: 'uspd_type',
      title: 'УСПД',
      amount: 0,
      value: false,
    },
    {
      id: 'pressure_sensor_type',
      title: 'Датчик давления',
      amount: 0,
      value: false,
    },
    {
      id: 'perimeter5_type',
      title: 'Периметр-5',
      amount: 0,
      value: false,
    },
    {
      id: 'undefined_type',
      title: 'Неизвестный тип устройства',
      amount: 0,
      value: false,
    },
  ];
  let statuses = [
    {
      id: 'success_status',
      title: 'Доступен',
      amount: 0,
      value: false,
    },
    {
      id: 'error_status',
      title: 'Превышение уставки',
      amount: 0,
      value: false,
    },
    {
      id: 'warning_status',
      title: 'Низкий заряд батареи',
      amount: 0,
      value: false,
    },
    {
      id: 'undefined_status',
      title: 'Недоступен',
      amount: 0,
      value: false,
    },
  ];
  if (Array.isArray(mapDevicesData.devices)) {
    mapDevicesData.devices.forEach((item: any) => {
      const searchOptionFound = searchOptions.find(
        (option: any) => option.label === item.address_full,
      );
      if (searchOptionFound) {
        searchOptionFound.id.push(item.device_id);
      } else {
        searchOptions.push({
          label: item.address_full,
          id: [item.device_id],
        });
      }

      groups.add(item.asset_name);

      const deviceTypeFound = deviceTypes.find((type: any) => item.device_type === type.title);
      if (deviceTypeFound) {
        deviceTypeFound.amount++;
      }

      const statusFound = statuses.find((status: any) => item.status === status.title);
      if (statusFound) {
        statusFound.amount++;
      }
    });
  }

  const methods = useForm<IMapFilter>({
    defaultValues: {
      search: null,
      group: null,
      deviceTypes: deviceTypes,
      statuses: statuses,
    },
  });
  const { control, getValues } = methods;

  const { fields: deviceTypeFields } = useFieldArray({
    control,
    name: 'deviceTypes',
  });
  const { fields: statusFields } = useFieldArray({
    control,
    name: 'statuses',
  });

  const searchFilterOptions = (options: SearchOption[], { inputValue }: { inputValue: string }) =>
    options.filter((item: SearchOption) => {
      return (
        item.label.toLowerCase().includes(inputValue) || item.id.find((id) => id === inputValue)
      );
    });

  return (
    <Box
      sx={{
        overflow: 'auto',
        overflowX: 'hidden',
        height: '100%',
      }}
    >
      <Box
        sx={{
          p: 3,
          pb: 1,
        }}
      >
        <form>
          <FormControl margin="none">
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={searchOptions}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  filterOptions={searchFilterOptions}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Поиск по адресу или по ID" />
                  )}
                  popupIcon={<SearchIcon />}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onFilterChange(getValues());
                    sendMetrik(
                      'vntVdknl',
                      'karta',
                      'form_change',
                      'poisk_po_adresu_ili_po_aidi',
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/map',
                      value,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                  sx={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                      transform: 'none',
                    },
                  }}
                />
              )}
              name="search"
              control={control}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Группа устройств</FormLabel>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={Array.from(groups)}
                  disableClearable={false}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Выберите группу устройства" />
                  )}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onFilterChange(getValues());
                  }}
                />
              )}
              name="group"
              control={control}
            />
          </FormControl>
          <Box sx={{ py: 1 }}>
            <Typography variant="paragraphBold3" paragraph>
              Тип устройства
            </Typography>
            {deviceTypeFields.map((item: any, index: number) => (
              <FormControl key={item.id} margin="dense">
                <Controller
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        {...field}
                        control={<Checkbox size="small" />}
                        label={
                          <Typography variant="paragraph3">
                            {item.title}: <b>{item.amount}</b>
                          </Typography>
                        }
                        labelPlacement="start"
                        onChange={(_, value) => {
                          field.onChange(value);
                          onFilterChange(getValues());
                          sendMetrik(
                            'vntVdknl',
                            'karta',
                            'checkbox_click',
                            translitRuEn(item.title).toLowerCase(),
                            value,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/map',
                            null,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                      />
                    </>
                  )}
                  name={`deviceTypes.${index}.value`}
                  control={control}
                />
              </FormControl>
            ))}
          </Box>
          <Divider />
          <Box sx={{ pt: 2 }}>
            <Typography variant="paragraphBold3" paragraph>
              Статус устройства
            </Typography>
            {statusFields.map((item: any, index: number) => (
              <FormControl key={item.id} margin="dense">
                <Controller
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        {...field}
                        control={<Checkbox size="small" />}
                        label={
                          <Stack direction="row" gap={1} alignItems="center">
                            <StatusBadge color={getDeviceStatus(item.title)} />
                            <Typography variant="paragraph3">
                              {item.title}: <b>{item.amount}</b>
                            </Typography>
                          </Stack>
                        }
                        labelPlacement="start"
                        onChange={(_, value) => {
                          field.onChange(value);
                          onFilterChange(getValues());
                          sendMetrik(
                            'vntVdknl',
                            'karta',
                            'checkbox_click',
                            translitRuEn(item.title).toLowerCase(),
                            value,
                            userData?.permissions[0].uuid,
                            userData ? '1' : '0',
                            '/map',
                            null,
                            null,
                            null,
                            'interactions',
                            userData?.profile_type,
                            'web',
                          );
                        }}
                      />
                    </>
                  )}
                  name={`statuses.${index}.value`}
                  control={control}
                />
              </FormControl>
            ))}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default MapSidebar;
