import { useQuery } from 'react-query';

import { GET_CUSTOM_REPORT_TEMPLATES } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchCustomReportShablons = () => {
  const {
    isLoading,
    isError,
    data: dataCustomReportShablons,
  } = useQuery(['saveGeneratorForm', 'customReportData'], () =>
    fetchData(GET_CUSTOM_REPORT_TEMPLATES),
  );

  return {
    isLoading,
    isError,
    dataCustomReportShablons,
  };
};

export default useFetchCustomReportShablons;
