import { Outlet } from "react-router-dom";

import MainWithAside from '../../components/Layout/MainWithAside';
import ObjectsTree from '../../components/ObjectsTree';

const Objects = () => {

  return (
    <MainWithAside
      main={<Outlet />}
      aside={<ObjectsTree />}
    />
  );
};

export default Objects;
