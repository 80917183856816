export const ADMIN_CUSTOMER_ID = '00000000-0000-0000-0000-000000000000';

// API
export const API_PREFIX = '/api';

export const GET_GEO_DEVICES = `${API_PREFIX}/geo/devices`;

export const LOGIN_URL = `${API_PREFIX}/services/login`;
export const LOGOUT_URL = `${API_PREFIX}/services/logout`;

export const ENV_URL = `${API_PREFIX}/services/get_env`;

export const CURRENT_USER_DATA_URL = `${API_PREFIX}/services/get_user_fullname`;
export const CREATE_USER_URL = `${API_PREFIX}/users/create_user`;
export const GET_USERS_URL = `${API_PREFIX}/users/get_users`;
export const GET_USER_URL = (msisdn: string) => `${API_PREFIX}/users/get_user/${msisdn}`;
export const UPDATE_USER_URL = `${API_PREFIX}/users/update_user`;
export const GET_DELETE_USER_URL = (msisdn: string) => `${API_PREFIX}/users/delete_user/${msisdn}`;
export const GET_USER_ROLES_URL = `${API_PREFIX}/users/get_user_roles`;

export const OBJECTS_TREE_URL = `${API_PREFIX}/tree`;
export const GET_TREE_CHILDS_URL = (id: string, type: string) => {
  let url = `${API_PREFIX}/childs`;

  if (id && id !== 'root') {
    url += `?node_id=${id}`;
  }
  if (type) {
    url += `&node_type=${type}`;
  }

  return url;
};
export const GET_OBJECT_GROUPS_URL = (id: string) => {
  let url = `${API_PREFIX}/child_names?node_id=${id}`;

  return url;
};
export const GET_CREATE_GROUP_URL = `${API_PREFIX}/asset/`;
export const GET_DELETE_GROUP_URL = (id: string) => `${API_PREFIX}/asset/${id}`;
export const GET_MOVE_TO_GROUP_URL = (id: string, targetId: string) =>
  `${API_PREFIX}/asset/${id}/move_to/${targetId}`;

export const GET_OBJECT_DATA_URL = (type: string, id: string) => `${API_PREFIX}/${type}/${id}`;
export const GET_OBJECT_DEVICES_URL = (type: string, id: string) => `${API_PREFIX}/tree/${type}/${id}`;
export const GET_OBJECT_DEVICES_WS_URL = (type: string, id: string) => `${API_PREFIX}/union/ws/tree?node_type=${type}&node_id=${id}`;
export const GET_DEVICE_DATA_URL = (id: string) => `${API_PREFIX}/device/${id}`;
export const DEVICE_PROFILES_URL = `${API_PREFIX}/device_profiles`;
export const GET_UPDATE_OBJECT_URL = (type: string, id: string) => `${API_PREFIX}/${type}/${id}`;
export const GET_UPDATE_DEVICE_URL = (id: string) => `${API_PREFIX}/device/${id}`;
export const DATDDEVICE_CONNECTION_TYPES_URL = `${API_PREFIX}/choises/uspd_connection_types`;
export const PERIMETER5DEVICE_CONNECTION_TYPES_URL = `${API_PREFIX}/choises/perimeter_connection_types`;

export const REPORT_DEVICE_TYPES_URL = `${API_PREFIX}/reports/get_devices_types`;
export const GROUPS_NAMES_URL = `${API_PREFIX}/reports/get_possible_assets`;
export const GROUPS_URL = `${API_PREFIX}/reports/get_possible_assets_with_uuid`;
export const ADDRESSES_URL = `${API_PREFIX}/reports/get_possible_addresses`;
export const GISGKH_GROUPS_URL = `${API_PREFIX}/reports/special/mezhdurechensk/get_groups`;
export const GET_CUSTOM_REPORT_CHECKBOXES = `${API_PREFIX}/report_generator_endpoint/get_checkboxes`;
export const GET_TEMPLATE_ID = (id: string) =>
  `${API_PREFIX}/report_generator_endpoint/get_template/${id}`;
export const CREATE_CUSTOM_REPORT_TEMPLATE = `${API_PREFIX}/report_generator_endpoint/save_template`;
export const UPDATE_CUSTOM_REPORT_TEMPLATE = `${API_PREFIX}/report_generator_endpoint/update_template`;
export const GET_CUSTOM_REPORT_TEMPLATES = `${API_PREFIX}/report_generator_endpoint/get_templates`;
export const GET_CUSTOM_REPORT_DATA = (id: string) =>
  `${API_PREFIX}/report_generator_endpoint/get_template_for_generate_report?template_id=${id}`;
export const CUSTOM_REPORT_TABLE = `${API_PREFIX}/report_generator_endpoint/download_report`;
export const GET_REPORTS_HISTORY = `${API_PREFIX}/reports/get_history_of_report`;
export const DELETE_CUSTOM_REPORT_GENERATOR = (id: string) =>
  `${API_PREFIX}/report_generator_endpoint/delete_template/${id}`;
export const CHANGE_FAVORITE_CUSTOM_REPORT = (id: string, is_favorite: boolean) =>
  `${API_PREFIX}/report_generator_endpoint/change_favorite/${id}?is_favorite=${is_favorite}`;
export const GET_STANDART_REPORTS_RESULTS_URL = (
  deviceType: string,
  reportType: string,
  temperatureMeasure: string | null,
  dateFrom: string | number | null,
  dateTo: string | number | null,
  groupName: string | null,
  address: string | null,
) => {
  let url = `${API_PREFIX}/reports/get_devices_list?device_type=${deviceType}&report_type=${reportType}&date_from=${dateFrom}`;

  if (deviceType === 'pressure_sensor' && temperatureMeasure && temperatureMeasure !== 'all') {
    url += `&temperature_measure=${temperatureMeasure}`;
  }
  if (dateTo) {
    url += `&date_to=${dateTo}`;
  }
  if (groupName) {
    url += `&asset_name=${groupName}`;
  }
  if (address) {
    url += `&address=${address}`;
  }

  return url;
};
export const GET_SPECIAL_REPORTS_RESULTS_URL = (
  dateFrom: string | null,
  dateTo: string | null,
  groupName: string | null,
) => {
  let url = `${API_PREFIX}/reports/special/mezhdurechensk/init_period_data?dateFrom=${dateFrom}&dateTo=${dateTo}&asset_name=${groupName}`;

  return url;
};
export const GET_REPORT_ITEMS_PAGE_URL = (
  deviceType: string,
  reportType: string,
  temperatureMeasure: string | null,
  dateFrom: string | null,
  dateTo: string | null,
  groupName: string | null,
  address: string | null,
) => {
  let url = `/reports/?device_type=${deviceType}&report_type=${reportType}&date_from=${dateFrom}`;

  if (deviceType === 'pressure_sensor' && temperatureMeasure !== null) {
    url += `&temperature_measure=${temperatureMeasure}`;
  }
  if (dateTo) {
    url += `&date_to=${dateTo}`;
  }
  if (groupName) {
    url += `&asset_name=${groupName}`;
  }
  if (address) {
    url += `&address=${address}`;
  }

  return url;
};
export const GET_REPORT_SEGMENT_URL = () => {
  let url = `${API_PREFIX}/reports/get_devices_list?device_type=uspd&report_type=daily&date_from=1660251600`;

  return url;
};
export const GET_REPORT_URL = (
  ids: string[],
  deviceType: string,
  reportType: string,
  dateFrom: string | null,
  dateTo: string | null,
) => {
  let url = `${API_PREFIX}/reports/get_device_info?device_type=${deviceType}&report_type=${reportType}&date_from=${dateFrom}`;
  if (dateTo) {
    url += `&date_to=${dateTo}`;
  }
  ids.forEach((id) => (url += `&device_ids=${id}`));

  return url;
};
export const GET_DEVICE_REPORT_PAGE_URL = (
  ids: string[],
  deviceType: string,
  deviceNames: string,
  temperatureMeasure: string,
  reportType: string,
  dateFrom: string,
  dateTo: string | null,
) => {
  let url = `/reports/${ids.map(
    (id) => id,
  )}?device_type=${deviceType}&report_type=${reportType}&date_from=${dateFrom}`;
  if (dateTo) {
    url += `&date_to=${dateTo}`;
  }
  if (deviceNames) {
    url += `&device_names=${deviceNames}`;
  }
  if (temperatureMeasure) {
    url += `&temperature_measure=${temperatureMeasure}`;
  }

  return url;
};
export const GET_DEVICE_REPORT_EXCEL_URL = (
  ids: string[],
  deviceType: string,
  reportType: string,
  dateFrom: string | number,
  dateTo: string | number,
) => {
  let url = `${API_PREFIX}/reports/get_device_info_excel?device_type=${deviceType}&report_type=${reportType}&date_from=${dateFrom}`;
  if (dateTo) {
    url += `&date_to=${dateTo}`;
  }
  ids.forEach((id) => (url += `&device_ids=${id}`));

  return url;
};
export const GET_DEVICE_REPORT_SEGMENTS_EXCEL_URL = (
  dateFrom: string | number,
  dateTo: string | number,
) => {
  let url = `${API_PREFIX}/reports/get_uspd_segment_report_excel?date_from=${dateFrom}&date_to=${dateTo}`;

  return url;
};
export const GET_SPECIAL_REPORT_EXCEL_URL = (
  dateFrom: string,
  dateTo: string,
  groupId: string | null,
) => {
  let url = `${API_PREFIX}/reports/special/mezhdurechensk/get_excel?date_from=${dateFrom}&date_to=${dateTo}`;

  if (groupId) {
    url += `&group_id=${groupId}`;
  }

  return url;
};
export const GET_BALANCE_GROUP_REPORT_URL = (
  id: string,
  dateFrom: string | null,
  dateTo: string | null,
  download: boolean,
) => {
  let url = `${API_PREFIX}/balance_groups/download_report?balance_group_id=${id}&date_from=${dateFrom}&date_to=${dateTo}&url=${download}`;

  return url;
};
export const GET_BALANCE_GROUP_REPORT_PAGE_URL = (
  id: string,
  dateFrom: string | number,
  dateTo: string | number,
  name: string,
) => {
  let url = `/reports/balance-group/${id}?date_from=${dateFrom}&date_to=${dateTo}&name=${name}`;

  return url;
};

export const DASHBOARD_DEVICE_TYPES_URL = `${API_PREFIX}/dashboards/get_devices_types`;

export const GET_DASHBOARD_DATA_URL = (
  deviceType: string,
  dashboardType: string,
  dateFrom: string | number | null,
  dateTo: string | number | null,
  deviceId?: string,
  groupName?: string | null,
  excludeDeviceIds?: string | null,
  temperatureMeasure?: string | null,
) => {
  let url = `${API_PREFIX}/reports/`;

  if (deviceType === 'uspd') {
    if (dashboardType === 'device') {
      url += `get_uspd_device_dashboard?device_id=${deviceId}`;
      url += `&date_from=${dateFrom}&date_to=${dateTo}`;
    }
    if (dashboardType === 'group') {
      url += `get_uspd_group_dashboard?group_name=${groupName}`;

      if (excludeDeviceIds) {
        const ids = excludeDeviceIds.split(',');
        ids.forEach((id) => (url += `&exc_device_ids=${id}`));
      }
      url += `&date_from=${dateFrom}&date_to=${dateTo}`;
    }
  }

  if (deviceType === 'pressure_sensor') {
    if (dashboardType === 'device') {
      url += `get_pressure_sensor_device_dashboard?device_id=${deviceId}`;
      url += `&date_from=${dateFrom}&date_to=${dateTo}`;
    }
    if (dashboardType === 'group') {
      url += `get_pressure_sensor_group_dashboard?group_name=${groupName}`;
      if (excludeDeviceIds) {
        const ids = excludeDeviceIds.split(',');
        ids.forEach((id) => (url += `&exc_device_ids=${id}`));
      }
      if (deviceType === 'pressure_sensor' && temperatureMeasure && temperatureMeasure !== 'all') {
        url += `&temperature_measure=${temperatureMeasure}`;
      }
      url += `&date_from=${dateFrom}&date_to=${dateTo}`;
    }
  }

  return url;
};

export const GET_DASHBOARD_REALTIME_DATA_URL = (
  deviceType: string,
  deviceId?: string,
  temperatureMeasure?: string | null,
) => {
  let url = `${API_PREFIX}/reports/ws`;

  if (deviceType === 'pressure_sensor') {
    url += `/get_pressure_sensor_dashboard_rt?device_id=${deviceId}`;
    if (temperatureMeasure && temperatureMeasure !== 'all') {
      url += `&temperature_measure=${temperatureMeasure}`;
    }
  } else if (deviceType === 'uspd') {
    url += `/get_uspd_device_dashboard_rt?device_id=${deviceId}`;
  } else if (deviceType === 'perimeter-5') {
    url += `/get_perimeter_device_dashboard_rt?device_id=${deviceId}`;
  }

  return url;
};

export const NOTIFICATIONS_DEVICE_TYPES_URL = `${API_PREFIX}/notifications/get_device_types`;
export const NOTIFICATIONS_TEMPLATE_TYPES_URL = `${API_PREFIX}/notifications/get_basic_notification_templates`;
export const CREATE_NOTIFICATIONS_TEMPLATE_URL = `${API_PREFIX}/notifications/create_notification_template`;
export const UPDATE_NOTIFICATIONS_TEMPLATE_URL = `${API_PREFIX}/notifications/update_notification_template`;
export const GET_DELETE_NOTIFICATIONS_TEMPLATE_URL = (id: string) =>
  `${API_PREFIX}/notifications/delete_notification_template/${id}`;
export const NOTIFICATIONS_USERS_URL = `${API_PREFIX}/dashboards/get_devices_types`;
export const NOTIFICATIONS_TEMPLATES_URL = `${API_PREFIX}/notifications/get_notification_templates`;
export const GET_NOTIFICATIONS_TEMPLATE_URL = (id: string) =>
  `${API_PREFIX}/notifications/get_notification_templates/${id}`;
export const NOTIFICATIONS_URL = `${API_PREFIX}/notifications/get_notification_templates`;

export const BALANCE_GROUPS_URL = `${API_PREFIX}/balance_groups/get_balance_groups`;
export const BALANCE_GROUP_ITEMS_URL = `${API_PREFIX}/balance_groups/get_devices`;
export const CREATE_BALANCE_GROUP_URL = `${API_PREFIX}/balance_groups/save_balance_group`;
export const GET_BALANCE_GROUP_URL = (id: string) =>
  `${API_PREFIX}/balance_groups/get_balance_groups/${id}`;
export const UPDATE_BALANCE_GROUP_URL = `${API_PREFIX}/balance_groups/update_balance_group`;
export const GET_DELETE_BALANCE_GROUP_URL = (id: string) =>
  `${API_PREFIX}/balance_groups/delete_balance_group/${id}`;

export const GET_COORDINATES_URL = (address: string) =>
  `${API_PREFIX}/geo/coords?address=${address}`;
export const GET_ADDRESS_URL = (latitude: string, longitude: string) =>
  `${API_PREFIX}/geo/reverse?lat=${latitude}&lon=${longitude}`;

export const TMS_MAP_URL = `${API_PREFIX}/geo/map/{z}/{x}/{y}`;

export const GET_WS_MAP_URL = (customerId: string | undefined) =>
`${API_PREFIX}/geo/ws/devices?customer_id=${customerId}`;

export const FIND_ARKI_OBJECTS_URL = `${API_PREFIX}/arki/get_object_ids`;
export const CURRENT_ARKI_OBJECTS_URL = `${API_PREFIX}/arki/get_all_objects`;

// REGEX
export const COORDINATES_REGEX =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const NUMBER_REGEX = /^\d+$/;
export const FLOAT_NUMBER_REGEX = /^\d+([,.]\d+)?$/;
export const PHONE_REGEX = /^\+7\s\([0-9]{3}\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}$/;

// DATES
export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';

// OTHER
export const DEVICE_TEMP_MEASURE_TYPES: Record<string, string> = {
  all: 'Все',
  true: 'С датчиком температуры',
  false: 'Без датчика температуры',
};
export const REPORT_TYPES: Record<string, string> = {
  daily: 'Почасовой отчет за сутки',
  period: 'Почасовой отчет за период',
  periodHour: 'Почасовой отчет за период',
  periodDATD: 'Потребление за период (нарастающим итогом)',
  segment: 'Отчет по нарушению уставок',
  balanceGroup: 'Отчет по балансовой группе',
};
export const DASHBOARD_TYPES: Record<string, string> = {
  device: 'Дашборд по устройству',
  group: 'Дашборд по группе',
  real_time: 'Дашборд в реальном времени',
};
export const SETTING_TYPES: Record<string, string> = {
  lowerSetting: 'Нижняя',
  upperSetting: 'Верхняя',
};
export const WEEK_DAYS = [
  {
    name: 'monday',
    title: 'Понедельник',
  },
  {
    name: 'tuesday',
    title: 'Вторник',
  },
  {
    name: 'wednesday',
    title: 'Среда',
  },
  {
    name: 'thursday',
    title: 'Четверг',
  },
  {
    name: 'friday',
    title: 'Пятница',
  },
  {
    name: 'saturday',
    title: 'Суббота',
  },
  {
    name: 'sunday',
    title: 'Воскресенье',
  },
];
export const TIME_MASK = [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/];
export const RESERVED_NAMES = [
  'name',
  'device_type',
  'asset',
  'power_type',
  'id',
  'connection_type',
  'ccid',
  'channels',
  'tsf',
  'permo',
  'cl_model',
  'serv_model',
  'date',
  'channel_number',
  'verification_date',
  'sensor_id',
  'verification_interval',
  'setpoint_low',
  'setpoint_high',
  'in[1-10]',
  'dms[1-10]',
  'zero[1-10]',
  'initial_value',
  'impulse_weight',
  'anti_shatter',
  'consumption',
  'a_d[1-10]',
  'a_t[1-10]',
  'setpoint_low_temp',
  'setpoint_high_temp',
  'te',
  'tz',
  'model',
];

export const DEVICE_STATUSES = [
  'Доступен',
  'Низкий заряд батареи',
  'Превышение уставки',
  'Авария',
  'Недоступен',
];
export const BATTERY_VOLTAGE_HIGH = 3.6;
export const BATTERY_VOLTAGE_LOW = 3;
export const RSSI_LOW = -105;
export const RSSI_MID = -95;
export const RSSI_HIGH = -85;
export const PERIMETER5_DEVICES_TYPES: Record<string, string> = {
  opening_sensor: 'Датчик открытия',
  flooding_sensor: 'Датчик подтопления',
};
export const USER_ROLES = [
  {
    id: 'admin',
    name: 'Администратор'
  },
  {
    id: 'devices_pannel_rw',
    name: 'Пользователь'
  }
]

export const PRESSURE_SENSOR_PM_OPTIONS = {
  0: 'Выкл.',
  1: 'Вкл.',
}
export const PRESSURE_SENSOR_WITH_TEMP_PM_OPTIONS = {
  0: 'Выкл.',
  2: 'Вкл.'
}
export const PRESSURE_SENSOR_AM_OPTIONS = {
  '0000': 'Выкл.',
  '8000': 'Вкл.',
}
export const PRESSURE_SENSOR_WITH_TEMP_AM_OPTIONS = {
  '0000': 'Выкл.',
  'C000': 'Вкл.'
}
export const DATD_WITH_2_CHANNELS_PM_OPTIONS = {
  0: 'Выкл.',
  1: 'Вкл.кан.1',
  2: 'Вкл.кан.2',
  3: 'Вкл.кан.1,2',
}
export const DATD_WITH_4_CHANNELS_PM_OPTIONS = {
  0: 'Выкл.',
  1: 'Вкл.кан.1,2',
  2: 'Вкл.кан.3,4',
  3: 'Вкл.кан.1-4',
}
export const DATD_WITH_10_CHANNELS_PM_OPTIONS = {
  0: 'Выкл.',
  1: 'Вкл.кан.1-5',
  2: 'Вкл.кан.6-10',
  3: 'Вкл.кан.1-10',
}
