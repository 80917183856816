import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GroupsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fillRule="evenodd" d="M18.8855 9.71504C18.9475 10.4759 19 11.2376 19 12.0015C19 12.7644 18.9477 13.5251 18.8858 14.2851C20.1295 14.8219 21 16.0593 21 17.5C21 19.433 19.433 21 17.5 21C16.0598 21 14.8227 20.1301 14.2856 18.8871C13.525 18.949 12.7636 19.0015 12 19.0015C11.2364 19.0015 10.475 18.949 9.71439 18.8871C9.17727 20.1301 7.94022 21 6.5 21C4.567 21 3 19.433 3 17.5C3 16.0593 3.8705 14.8219 5.11423 14.2851C5.05235 13.5251 5 12.7644 5 12.0015C5 11.2376 5.05245 10.4759 5.11446 9.71505C3.87061 9.17828 3 7.94081 3 6.5C3 4.567 4.567 3 6.5 3C7.94131 3 9.17915 3.87122 9.71561 5.11576C10.4758 5.05384 11.2368 5.00146 12 5.00146C12.7632 5.00146 13.5242 5.05384 14.2844 5.11576C14.8209 3.87122 16.0587 3 17.5 3C19.433 3 21 4.567 21 6.5C21 7.94081 20.1294 9.17828 18.8855 9.71504ZM6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8ZM7.10249 9.94834C8.5522 9.69681 9.69601 8.55337 9.9481 7.10386C10.631 7.0489 11.3144 7.00146 12 7.00146C12.6856 7.00146 13.369 7.0489 14.0519 7.10386C14.304 8.55337 15.4478 9.69681 16.8975 9.94833C16.9525 10.6317 17 11.3155 17 12.0015C17 12.6864 16.9526 13.3693 16.8977 14.0516C15.4469 14.3032 14.3024 15.4481 14.0514 16.8991C13.3686 16.9541 12.6854 17.0015 12 17.0015C11.3146 17.0015 10.6314 16.9541 9.94862 16.8991C9.69756 15.4481 8.55306 14.3032 7.10226 14.0516C7.04737 13.3693 7 12.6864 7 12.0015C7 11.3155 7.04749 10.6317 7.10249 9.94834ZM17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16C16.6716 16 16 16.6716 16 17.5C16 18.3284 16.6716 19 17.5 19ZM8 17.5C8 18.3284 7.32843 19 6.5 19C5.67157 19 5 18.3284 5 17.5C5 16.6716 5.67157 16 6.5 16C7.32843 16 8 16.6716 8 17.5ZM19 6.5C19 7.32843 18.3284 8 17.5 8C16.6716 8 16 7.32843 16 6.5C16 5.67157 16.6716 5 17.5 5C18.3284 5 19 5.67157 19 6.5Z" />
    </SvgIcon>
  );
};

export default GroupsIcon;
