import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ModalAlert = styled("div")(({theme}) => ({
  position: 'absolute' as 'absolute',
  boxSizing: 'border-box',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '204px',
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  padding: '32px',
}));

const ModalResize = () => {
  const [open, setOpen] = useState(false);
  const [widthClient, setWidthClient] = useState(0);

  const handleModal = () => {
    let newWidth = document.documentElement.clientWidth;
    setWidthClient(newWidth)
    if (newWidth < 1440) {
      setOpen(true)
    } else if (newWidth >= 1440) {
      setOpen(false);
    }
};

  useEffect(() => {
    window.addEventListener('resize', handleModal)

    return () => {
        window.removeEventListener("resize", handleModal);
      };
  }, [widthClient])

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalAlert>
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Используйте экран большего размера
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Веб приложение оптимизировано под устройства с большим экраном, используйте компьютер или планшет
            </Typography>
          </Box>
        </ModalAlert>
      </Modal>
    </div>
  )
}

export default ModalResize;