import { useQuery } from 'react-query';

import { GET_DEVICE_DATA_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchDeviceInfo = (id: string, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['deviceData', id],
    () => fetchData(GET_DEVICE_DATA_URL(id)),
    {
      cacheTime: 0,
      onSuccess,
    },
  );
};

export default useFetchDeviceInfo;
