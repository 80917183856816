import { useState, SyntheticEvent } from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import TabPanel from '../../components/TabPanel';

import useFetchPerimeter5ConnectionTypes from './hooks/useFetchPerimeter5ConnectionTypes';
import Perimeter5Device from './Perimeter5Device';

const Perimeter5Devices = () => {
  useFetchPerimeter5ConnectionTypes();

  const [activeDevice, setActiveDevice] = useState(0);

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: 'Perimeter5Devices',
    control,
  });

  const handleDeviceChange = (event: SyntheticEvent, newValue: number) => {
    setActiveDevice(newValue);
  };

  return (
    <>
      <Typography variant="h3" sx={{
        my: 2
      }}>Поканальные настройки</Typography>
      {fields.length > 0 && (
        <>
          <Tabs
            value={activeDevice}
            variant="scrollable"
            scrollButtons={false}
            onChange={handleDeviceChange}
          >
            {fields.map((Perimeter5DeviceField: any) => (
              <Tab
                key={Perimeter5DeviceField.id}
                label={`Канал ${Perimeter5DeviceField.channel_number || ''}`}
              />
            ))}
          </Tabs>
          {fields.map((Perimeter5DeviceField: any, index: number) => (
            <TabPanel value={activeDevice} key={Perimeter5DeviceField.id} index={index}>
              <Perimeter5Device
                deviceIndex={index}
                statuses={Perimeter5DeviceField.status_of_updated_fields}
              />
            </TabPanel>
          ))}
        </>
      )}
    </>
  );
};

export default Perimeter5Devices;
