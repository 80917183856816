import { styled } from '@mui/material/styles';

type IIconBoxProps = {
  color?: string;
  children: React.ReactNode;
};

const StyledIconBox = styled('div')(({ theme, ...props }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  background: props.color ?? theme.palette.background.secondary,
  borderRadius: 6,
  color: theme.palette.text.inverted,
}));

const IconBox = ({ children, ...rest }: IIconBoxProps) => {
  return <StyledIconBox {...rest}>{children}</StyledIconBox>;
};

export default IconBox;
