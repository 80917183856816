import React from 'react';

import Landing from '../../components/Landing';

const LandingPage = () => {

  return (
    <Landing />
  )
}

export default LandingPage;