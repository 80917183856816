import { useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { CREATE_NOTIFICATIONS_TEMPLATE_URL } from '../../constants';
import { INotificationsTemplate } from '../../types';
import fetchData from '../../utils/fetchData';
import useSnackbars from '../../utils/hooks/useSnackbars';

import NotificationsTemplateForm from './NotificationsTemplateForm';

const CreateNotificationsTemplate = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const [isSettled, setIsSettled] = useState(false);

  const notificationsTemplateDataMutation = useMutation(
    (templateData: INotificationsTemplate) => {
      return fetchData(CREATE_NOTIFICATIONS_TEMPLATE_URL, {
        method: 'POST',
        body: templateData,
      });
    },
    {
      onSuccess: (responseData) => {
        setIsSettled(true);
        if (!responseData.detail) {
          showSnackbar(<Alert severity="success">Шаблон добавлен</Alert>);
          queryClient.invalidateQueries('notificationsTemplatesData', {
            refetchInactive: true,
          });
        } else {
          showSnackbar(<Alert severity="error">Шаблон не добавлен</Alert>);
        }
        navigate(`/notifications`);
      },
      onError: () => {
        setIsSettled(true);
        showSnackbar(<Alert severity="error">Шаблон не добавлен</Alert>);
      },
    },
  );

  const handleSubmit = (data: INotificationsTemplate) => {
    // получаем все существующие шаблоны уведомлений
    const existingTemplates =
      queryClient.getQueryData<INotificationsTemplate[]>('notificationsTemplatesData') ?? [];

    // проверяем, есть ли уже шаблон с таким же названием
    const isExistingTemplate = existingTemplates.some(
      (template) => template.name.toLowerCase() === data.name.toLowerCase(),
    );

    if (isExistingTemplate) {
      showSnackbar(<Alert severity="warning">Шаблон с таким названием уже существует</Alert>);
    } else {
      // создаем новый шаблон уведомления
      notificationsTemplateDataMutation.mutate(data);
    }
  };

  const handleSubmitButtonClick = () => {
    setIsSettled(false);
  };

  return (
    <>
      <Breadcrumbs>
        <Link component={RouterLink} to="/notifications">
          Шаблоны уведомлений
        </Link>
        <span>Добавить шаблон</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Добавить шаблон
      </Typography>
      <NotificationsTemplateForm
        isLoading={notificationsTemplateDataMutation.isLoading}
        isSettled={isSettled}
        onFormSubmit={handleSubmit}
        onSubmitButtonClick={handleSubmitButtonClick}
      />
    </>
  );
};

export default CreateNotificationsTemplate;
