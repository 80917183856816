import { useQuery } from 'react-query';

import { ADDRESSES_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchAddresses = (enabled: boolean) => {
  const queryInfo = useQuery('addressesData', () => fetchData(ADDRESSES_URL), {
    placeholderData: [],
    enabled,
  });

  let modifiedData = queryInfo.data;
  // Удаляем дубли и null
  if (queryInfo.data && Array.isArray(queryInfo.data)) {
    modifiedData = queryInfo.data.filter(
      (el: string, index: number) => (queryInfo.data.indexOf(el) === index && el !== null),
    );
  }

  return {
    ...queryInfo,
    data: modifiedData,
  };
};

export default useFetchAddresses;
