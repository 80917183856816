import { useRef } from 'react';

import { useQueryClient } from 'react-query';

import { Controller, useForm } from 'react-hook-form';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { IUser } from '../../types';
import sendMetrik from '../../utils/sendMetrik';
import translitRuEn from '../../utils/translitRuEn';
import DragAndDropIcon from '../icons/DragAndDropIcon';
import LockIcon from '../icons/LockIcon';
import QuestionIcon from '../icons/QuestionIcon';

import { WhiteTextField } from './CustomSettingsReport';

// шапка конструктора отчета
const CustomHeaderReport = ({
  isHeaderReportOpen,
  setIsHeaderReportOpen,
  isHeaderTextEnabled,
  handleIsHeaderTextEnabled,
  settingFields,
  handleFieldChange,
  handleAddField,
  numFieldsCreated,
  handleDeleteFields,
  setHeaderAtrCheckboxes,
  headerAtrCheckboxes,
  handleHeaderAtr,
  selectedTemplate,
  setHeaderDateError,
}: any) => {
  const methods = useForm();

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  // сортировка при перетаскивании чекбоксов
  const handleSort = () => {
    setHeaderAtrCheckboxes &&
      setHeaderAtrCheckboxes((prevCheckboxes: any) => {
        const newCheckboxes = [...prevCheckboxes];
        const draggedItem = newCheckboxes[dragItem.current];

        newCheckboxes.splice(dragItem.current, 1);
        newCheckboxes.splice(dragOverItem.current, 0, draggedItem);

        return newCheckboxes;
      });
  };

  return (
    <Box
      sx={{
        marginBottom: 3,
        padding: 3,
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '16px',
      }}
    >
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h3">Шапка отчета</Typography>
          <Tooltip
            arrow
            title={
              <Typography variant="inherit" component="span">
                Выбрать поля для отображения в шапке отчета. Поля выбираются на основание атрибутов
                устройства.
              </Typography>
            }
            style={{ marginLeft: '6px' }}
          >
            <div data-testid="filter-tooltip" style={{ lineHeight: 0, marginLeft: 4 }}>
              <QuestionIcon fontSize="small" color="secondary" />
            </div>
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer', lineHeight: 0 }}>
          {isHeaderReportOpen ? (
            <KeyboardArrowUp
              style={{ fontSize: '2.5rem' }}
              onClick={() => setIsHeaderReportOpen((prevState: any) => !prevState)}
            />
          ) : (
            <KeyboardArrowDown
              style={{ fontSize: '2.5rem' }}
              onClick={() => setIsHeaderReportOpen((prevState: any) => !prevState)}
            />
          )}
        </div>
      </Stack>
      {isHeaderReportOpen && (
        <div style={{ position: 'relative' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.5} style={{ position: 'absolute', top: '28px' }}>
              <FormControlLabel
                sx={{ '& .MuiTypography-root': { fontSize: 14 } }}
                control={
                  <Checkbox checked={isHeaderTextEnabled} onChange={handleIsHeaderTextEnabled} />
                }
                label="Текстовое поле"
              />
            </Grid>
            <Grid item sx={{ mt: 2, paddingLeft: '160px' }}>
              {settingFields?.map((field: any, index: any) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={index}
                  sx={{ paddingLeft: '160px' }}
                >
                  <Grid item>
                    <Controller
                      name={`input-name-${index}`}
                      control={methods.control}
                      defaultValue={field.name}
                      render={({ field: fieldProps }) => (
                        <WhiteTextField
                          {...fieldProps}
                          sx={{ minWidth: '260px' }}
                          size="small"
                          value={field.name}
                          placeholder="Введите название поля"
                          disabled={!isHeaderTextEnabled}
                          label={
                            <div style={{ display: 'flex' }}>
                              Название поля
                              {!isHeaderTextEnabled && <LockIcon fontSize="small" />}
                            </div>
                          }
                          type="text"
                          onChange={handleFieldChange(index, 'name')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name={`input-value-${index}`}
                      control={methods.control}
                      defaultValue={field.value}
                      render={({ field: fieldProps }) => (
                        <WhiteTextField
                          {...fieldProps}
                          sx={{ minWidth: '260px' }}
                          size="small"
                          value={field.value}
                          placeholder="Введите значение поля"
                          disabled={!isHeaderTextEnabled}
                          label={
                            <div style={{ display: 'flex' }}>
                              Значение поля
                              {!isHeaderTextEnabled && <LockIcon fontSize="small" />}
                            </div>
                          }
                          type="text"
                          onChange={handleFieldChange(index, 'value')}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              sx={{ mt: 2 }}
              style={{ display: 'flex', position: 'absolute', left: '690px', top: '11px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="text"
                  size="small"
                  color="link"
                  disabled={!isHeaderTextEnabled}
                  onClick={handleAddField}
                  style={{ paddingRight: '4px', backgroundColor: '#F2F3F7' }}
                >
                  Добавить поля
                </Button>
                <Tooltip
                  arrow
                  title={
                    <Typography variant="inherit" component="span">
                      Создать текстовое поле(поля) с названием и указать его значение.
                    </Typography>
                  }
                >
                  <div data-testid="filter-tooltip">
                    <QuestionIcon fontSize="small" color="secondary" />
                  </div>
                </Tooltip>
              </Box>
              {numFieldsCreated >= 2 && (
                <Button
                  variant="text"
                  size="small"
                  color="error"
                  disabled={!isHeaderTextEnabled}
                  onClick={handleDeleteFields}
                  style={{ backgroundColor: '#F2F3F7' }}
                >
                  Удалить поля
                </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <FormControl sx={{ display: 'flex', flexDirection: 'row' }} id="header-fields">
              {Array.isArray(headerAtrCheckboxes) &&
                headerAtrCheckboxes?.map((parameter: any, index: any) => (
                  <FormControlLabel
                    key={index}
                    draggable={true}
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                    control={
                      <>
                        <DragAndDropIcon
                          sx={{ width: '6px', height: '10px', marginRight: '6px' }}
                        />
                        <Checkbox
                          checked={parameter?.checked}
                          onChange={(e) => {
                            handleHeaderAtr(e);
                            setHeaderDateError(false);

                            sendMetrik(
                              'vntVdknl',
                              'otchety_konstruktor',
                              'checkbox_click',
                              translitRuEn(parameter.label).toLowerCase(),
                              e.target.checked,
                              userData?.permissions[0].uuid,
                              userData ? '1' : '0',
                              '/reports/customReportCreate',
                              null,
                              null,
                              null,
                              'interactions',
                              userData?.profile_type,
                              'web',
                            );
                          }}
                          name={parameter?.name}
                          required={
                            (selectedTemplate === 'report_group' && parameter.name === 'asset') ||
                            (selectedTemplate === 'report_device' && parameter.name === 'id') ||
                            parameter.name === 'date'
                          }
                        />
                      </>
                    }
                    label={parameter.label}
                    sx={{
                      ...(index > 0 && { ml: 4 }),
                      '& .MuiTypography-root': { fontSize: 14 },
                    }}
                  />
                ))}
            </FormControl>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default CustomHeaderReport;
