import { useQuery } from 'react-query';

import { GROUPS_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchGroups = (enabled: boolean) => {
  const queryInfo = useQuery(['groupsData'], () => fetchData(GROUPS_URL), {
    enabled: enabled,
    cacheTime: 0,
    placeholderData: [],
  });

  let modifiedData = queryInfo.data;
  // Удаляем дубли и null
  if (queryInfo.data && Array.isArray(queryInfo.data)) {
    modifiedData = queryInfo.data.filter(
      (el: any, index: number) => queryInfo.data.findIndex((item: any) => item.id === el.id) === index,
    );
  }

  return {
    ...queryInfo,
    data: modifiedData
  }
};

export default useFetchGroups;
