import { useState } from 'react';

import { NavLink, Outlet } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import SquareSearchIcon from '../../components/icons/SquareSearchIcon';
import MainWithAside from '../../components/Layout/MainWithAside';
import { ADMIN_CUSTOMER_ID } from '../../constants';
import { IUser } from '../../types';
import useRouteMatch from '../../utils/hooks/useRouteMatch';
import sendMetrik from '../../utils/sendMetrik';

import ReportsAsideFavorites from './ReportsAsideFavorites';
import ReportsAsideHistory from './ReportsAsideHistory';

const Reports = () => {
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTemplate, setSearchTemplate] = useState('');
  const [searchHistoryReport, setSearchHistoryReport] = useState('');

  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const routeMatch = useRouteMatch(['/reports', '/reports/special', '/reports/custom']);
  const currentTab = routeMatch?.pattern?.path;

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setSelectedTab(newValue);

    sendMetrik(
      'vntVdknl',
      'otchety',
      'element_click',
      newValue === 0 ? 'izbrannoe' : 'istoriya',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  const handleSearchChange = (value: string) => {
    selectedTab === 0 ? setSearchTemplate(value) : setSearchHistoryReport(value);
    //отправка метрики
    sendMetrik(
      'vntVdknl',
      'otchety',
      'form_change',
      'poisk_po_otchetam',
      null,
      userData?.permissions[0].uuid,
      userData ? '1' : '0',
      '/reports',
      null,
      null,
      null,
      'interactions',
      userData?.profile_type,
      'web',
    );
  };

  return (
    <MainWithAside
      main={
        <>
          <Typography variant="h1" gutterBottom>
            Отчеты
          </Typography>
          <Tabs value={currentTab}>
            <Tab
              label="Общий"
              value="/reports"
              to="/reports"
              component={NavLink}
              onClick={() => {
                sendMetrik(
                  'vntVdknl',
                  'otchety',
                  'element_click',
                  'Obshchiy',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/reports',
                  null,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              }}
            />
            {userData &&
              (userData.customerId === '12499850-ab80-11ed-816b-ef4525099864' ||
                userData.customerId === ADMIN_CUSTOMER_ID) && (
                <Tab
                  label="Специальный"
                  value="/reports/special"
                  to="/reports/special"
                  component={NavLink}
                  onClick={() => {
                    sendMetrik(
                      'vntVdknl',
                      'otchety',
                      'element_click',
                      'Spetsialnyy',
                      null,
                      userData?.permissions[0].uuid,
                      userData ? '1' : '0',
                      '/reports/special',
                      null,
                      null,
                      null,
                      'interactions',
                      userData?.profile_type,
                      'web',
                    );
                  }}
                />
              )}
            <Tab
              label="Пользовательский"
              value="/reports/custom"
              to="/reports/custom"
              component={NavLink}
              onClick={() => {
                sendMetrik(
                  'vntVdknl',
                  'otchety',
                  'element_click',
                  'Polzovatelskiy',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/reports/custom',
                  null,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              }}
            />
          </Tabs>
          <Outlet />
        </>
      }
      aside={
        <>
          <Box
            sx={{
              padding: '24px 24px 0',
            }}
          >
            <TextField
              sx={{ maxWidth: '280px', paddingBottom: '30px' }}
              fullWidth
              placeholder="Поиск по отчетам"
              value={selectedTab === 0 ? searchTemplate : searchHistoryReport}
              onChange={(e) => handleSearchChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ pt: '12px' }}>
                      <SquareSearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab key={0} label="Избранное"></Tab>
              <Tab key={1} label="История"></Tab>
            </Tabs>
          </Box>

          <Box
            sx={{
              padding: '6px 24px 24px',
              overflow: 'auto',
              height: '100%',
              overflowX: 'hidden',
            }}
          >
            {selectedTab === 0 ? (
              <ReportsAsideFavorites searchTemplate={searchTemplate} />
            ) : (
              <ReportsAsideHistory searchHistoryReport={searchHistoryReport} />
            )}
          </Box>
        </>
      }
    />
  );
};

export default Reports;
