import { useQuery } from 'react-query';

import { NOTIFICATIONS_TEMPLATES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchNotificationsTemplates = () => {
  return useQuery(['notificationsTemplatesData'], () => fetchData(NOTIFICATIONS_TEMPLATES_URL));
};

export default useFetchNotificationsTemplates;
