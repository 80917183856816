import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { getYear } from 'date-fns';

const StyledFooter = styled('div')(({ theme }) => ({
  background: theme.palette.background.secondary,
  '.footer-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
  },
}));

const Footer = () => {
  const year = getYear(new Date());

  return (
    <StyledFooter>
      <Container>
        <Box className="footer-root">
          <Typography variant='body2'>© {year} ПАО «МТС». 18+</Typography>
          <Typography variant='body2'>Круглосуточная служба поддержки: 8 800 250 11 11. Бесплатно по России</Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
