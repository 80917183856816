import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useQueryClient, useMutation } from 'react-query';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderIcon from '@mui/icons-material/Folder';
import LoadingButton from '@mui/lab/LoadingButton';
import TreeItem, {
  TreeItemProps,
  TreeItemContentProps,
  treeItemClasses,
  useTreeItem,
} from '@mui/lab/TreeItem';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import PlusIcon from '../../components/icons/PlusIcon';
import { GET_CREATE_GROUP_URL, GET_MOVE_TO_GROUP_URL } from '../../constants';
import fetchData from '../../utils/fetchData';
import useSnackbars from '../../utils/hooks/useSnackbars';
import Spinner from '../Spinner';

type FormValues = {
  parentId?: string;
  name?: string;
};

type MoveIds = {
  currentId: string;
  targetId: string;
};

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & {
    type?: string;
    amount?: number;
    parentId?: string;
    customerId?: string;
    onCreateGroup?: (data: any) => void;
  },
  ref,
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    type,
    amount,
    parentId,
    customerId,
    onCreateGroup,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);
  let navigate = useNavigate();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const objectsTreeDataCreateMutation = useMutation(
    (groupData: FormValues) => {
      return fetchData(GET_CREATE_GROUP_URL, {
        method: 'POST',
        body: groupData,
      });
    },
    {
      onSuccess: (responseData: any) => {
        queryClient.invalidateQueries(['objectsTreeData', responseData.parentId]);
        queryClient.invalidateQueries(['objectGroupsData', responseData.customerId]);
        showSnackbar(<Alert severity="success">Группа создана</Alert>);
        if (onCreateGroup) onCreateGroup(responseData);
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Ошибка | Группа не создана</Alert>);
      }
    },
  );

  const objectsTreeDataMovingMutation = useMutation(
    (idsData: MoveIds) => {
      return fetchData(GET_MOVE_TO_GROUP_URL(idsData.currentId, idsData.targetId), {
        method: 'PUT',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('objectsTreeData');
        showSnackbar(<Alert severity="success">Группа перенесена</Alert>);
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Ошибка | Группа не перенесена</Alert>);
      },
    },
  );

  const [newAssetName, setNewAssetName] = useState<string>('');

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(e);
  };

  const handleExpansionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleExpansion(e);
  };

  const handleSelectionClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    objectType?: string,
  ) => {
    if (objectType) {
      navigate(`/objects/${nodeId}?type=${type?.toLowerCase()}`);
    }
    handleSelection(e);
  };

  const handleNewAssetNameChange = (e: React.BaseSyntheticEvent) => {
    setNewAssetName(e.currentTarget.value);
  };

  const handleNewAssetInputKeyDown = (e: React.KeyboardEvent) => {
    const { code } = e;
    if (code === 'Enter') {
      createAsset();
    }
  };

  const handleNewAssetButtonClick = () => {
    createAsset();
  };

  const createAsset = () => {
    if (newAssetName) {
      const newAssetData = {
        parentId,
        name: newAssetName,
      };

      objectsTreeDataCreateMutation.mutate(newAssetData);
    }
  };

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, id: string, currentType: any) => {
    if (currentType === 'CUSTOMER') {
      e.preventDefault();
    }
    e.currentTarget.style.cursor = 'grab';
    e.dataTransfer.setData('current-id', id);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.style.background = 'red';
    e.currentTarget.style.opacity = '0.8';
    e.currentTarget.style.color = 'white';
  };

  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.background = 'white';
    e.currentTarget.style.opacity = '1';
    e.currentTarget.style.color = '#1D2023';
  };

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, targetId: string) => {
    e.preventDefault();
    const currentId = e.dataTransfer.getData('current-id');

    objectsTreeDataMovingMutation.mutate({ currentId, targetId });

    e.currentTarget.style.background = 'white';
    e.currentTarget.style.opacity = '1';
    e.currentTarget.style.color = '#1D2023';
  };

  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = 'default';
  };

  return type !== 'INPUT' ? (
    <div className="tree-dnd" style={{ display: 'flex' }}>
      <Box
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        draggable={true}
        onDragStart={(e) => dragStartHandler(e, nodeId, type)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDragEnd={(e) => dragEndHandler(e)}
        onDragOver={(e) => dragOverHandler(e)}
        onDrop={(e) => dropHandler(e, nodeId)}
        data-type={type}
        data-customer-id={customerId}
        data-node-id={nodeId}
        onClick={(e) => handleSelectionClick(e, type)}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        <Box className={classes.iconContainer} onClick={handleExpansionClick}>
          {icon}
        </Box>
        <Box className="MuiTreeItem-contentWrapper">
          <Box className="MuiTreeItem-contentIcon">
            {type === 'CUSTOMER' ? <AccountCircleIcon /> : <FolderIcon />}
          </Box>
          <Badge color="secondary" badgeContent={amount} showZero={true}>
            {
              <Typography component="div" className={classes.label}>
                {label}
              </Typography>
            }
          </Badge>
        </Box>
      </Box>
      {objectsTreeDataMovingMutation.isLoading && (
        <Spinner sx={{ justifyContent: 'flex-start' }}></Spinner>
      )}
    </div>
  ) : (
    <Box
      className={clsx(className, classes.root, 'MuiTreeItem-input')}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      <Input
        value={newAssetName}
        fullWidth
        size="small"
        placeholder="Название группы"
        disabled={objectsTreeDataCreateMutation.isLoading}
        onChange={handleNewAssetNameChange}
        onKeyDown={handleNewAssetInputKeyDown}
      />
      <LoadingButton
        size="small"
        variant="contained"
        loading={objectsTreeDataCreateMutation.isLoading}
        onClick={handleNewAssetButtonClick}
        sx={{
          minWidth: '32px',
          ml: 1,
          p: 0,
        }}
      >
        <PlusIcon />
      </LoadingButton>
    </Box>
  );
});

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover, &:hover': {
      backgroundColor: theme.palette.action.tertiary,
    },
    '&.Mui-selected': {
      '&:before': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '&.MuiTreeItem-input': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      alignItems: 'center',
      width: '24px',
      height: '24px',
      svg: {
        fontSize: '24px',
      },
    },
    [`& .${treeItemClasses.label}`]: {
      flex: '1',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '14px',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(4),
    },
    [`& & .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(5),
    },
    [`& & & .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(6),
    },
  },
  '& .MuiBadge-root': {
    flexShrink: 1,
    overflow: 'hidden',
  },
  '& .MuiTreeItem-contentWrapper': {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '5px 0',
  },
  '& .MuiTreeItem-contentIcon': {
    display: 'flex',
    color: theme.palette.icons.tertiary,
  },
  '& .MuiIconButton-root': {
    marginLeft: '4px',
  },
  '& .MuiBadge-badge': {
    position: 'relative',
    marginLeft: theme.spacing(1),
    transform: 'none',
  },
}));

const CustomTreeItem = (
  props: TreeItemProps & {
    ContentProps?: {
      type?: string;
      amount?: number;
      parentId: string | null;
      customerId: string | null;
      onCreateGroup: (data: any) => void;
    };
  },
) => {
  return <StyledTreeItem ContentComponent={CustomContent} {...props} />;
};

const MemoizedCustomTreeItem = React.memo(CustomTreeItem);

export default MemoizedCustomTreeItem;
