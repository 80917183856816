import { useQuery } from 'react-query';

import { GROUPS_NAMES_URL } from '../../constants';
import fetchData from '../fetchData';

const useFetchGroupsNames = (enabled: boolean) => {
  const queryInfo = useQuery('groupsNamesData', () => fetchData(GROUPS_NAMES_URL), {
    initialData: [],
    enabled,
  });

  let modifiedData = queryInfo.data;
  // Удаляем дубли и null
  if (queryInfo.data && Array.isArray(queryInfo.data)) {
    modifiedData = queryInfo.data.filter(
      (el: string, index: number) => (queryInfo.data.indexOf(el) === index && el !== null),
    );
  }

  return {
    ...queryInfo,
    data: modifiedData,
  };
};

export default useFetchGroupsNames;
