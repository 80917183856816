import { useState } from 'react';

import { useQuery } from 'react-query';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridFooterContainer, GridPagination } from '@mui/x-data-grid';

import DataGridPagination from '../../../../components/DataGrid/DataGridPagination';
import Message from '../../../../components/Message';
import { CURRENT_ARKI_OBJECTS_URL } from '../../../../constants';
import fetchData from '../../../../utils/fetchData';

const DataGridFooter = () => {
  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <GridPagination />
      </Box>
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const CurrentARKIObjects = () => {
  const [enabled, setEnabled] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data, isFetching, isError, refetch } = useQuery(
    ['currentARKIObjects'],
    () => fetchData(CURRENT_ARKI_OBJECTS_URL),
    {
      enabled,
      initialData: [],
    },
  );

  const onSubmit = () => {
    if (!enabled) {
      setEnabled(true);
    } else {
      refetch();
    }
  };

  const cols = [
    {
      field: 'inn',
      headerName: 'ИНН',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'type',
      headerName: 'Тип',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'address',
      headerName: 'Адрес',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'object_id',
      headerName: 'Object ID',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'guid_fias',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'lat',
      headerName: 'Широта',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'lon',
      headerName: 'Долгота',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'data',
      headerName: 'Данные',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 't_forward',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'p_forward',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 't_revers',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'p_revers',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'point',
      flex: 1,
      minWidth: 200,
    },
  ];

  let result;

  if (isError) {
    result = <Message>Не получилось загрузить данные</Message>;
  } else {
    result = (
      <DataGrid
        columns={cols}
        rows={data}
        loading={isFetching}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 50]}
        slots={{
          footer: DataGridFooter,
        }}
        getRowId={(row) => row.uuid}
        onPaginationModelChange={(model) => setPaginationModel(model)}
      />
    );
  }

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Текущие объекты
      </Typography>
      <LoadingButton type="submit" loading={isFetching} onClick={onSubmit}>
        Получить данные
      </LoadingButton>
      {result}
    </Box>
  );
};

export default CurrentARKIObjects;
