import { useQuery } from 'react-query';

import { DATDDEVICE_CONNECTION_TYPES_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';

const useFetchDATDConnectionTypes = () => {
  return useQuery('DATDConnectionTypesData',
  () => fetchData(DATDDEVICE_CONNECTION_TYPES_URL));
}

export default useFetchDATDConnectionTypes;
