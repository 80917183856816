import convertNumbers from './convertNumbers';

const convertArrayToHEX = (value: string[], paramType: string) => {
  let HEX = '';
  const length = paramType === 'dm' ? 32 : paramType === 't' ? 24 : 16;
  if (value.length) {
    const allItemIndex = value.findIndex((item) => item === 'all');
    let paramBitsArray: any[] = new Array(length);
    paramBitsArray.fill(0);
    if (allItemIndex !== -1) {
      paramBitsArray.forEach((item, index) => {
        if (!(paramType === 'am' && index > 9)) {
          paramBitsArray[index] = 1;
        }
      });
    } else {
      value.forEach((item: any) => {
        paramBitsArray[paramType === 't' ? item : item - 1] = 1;
      });
    }
    HEX = convertNumbers.bin2hex(paramBitsArray.join(''));
    if (HEX.length < 8 && paramType === 'dm') {
      HEX = HEX.padStart(8, '0');
    } else if (HEX.length < 6 && paramType === 't') {
      HEX = HEX.padStart(6, '0');
    } else if (HEX.length < 4 && paramType === 'am') {
      HEX = HEX.padStart(4, '0');
    }
  }

  return HEX;
};

export default convertArrayToHEX;
