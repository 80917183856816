import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FolderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="6" fill="#E2E5EB" />
      <g clipPath="url(#clip0_1545_25296)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8128 14.5845C23.9312 15.2777 24 15.9323 24 16.5C24 16.8154 23.987 17.1361 23.9635 17.4575C23.8894 18.4712 23.8452 18.932 23.7295 19.3078C23.6456 19.58 23.5012 19.8748 23.0339 20.3485C22.5869 20.8017 22.2842 20.9574 21.9656 21.0557C21.5366 21.1881 21.0019 21.244 19.9065 21.3392C18.8077 21.4346 17.5056 21.5 16 21.5C14.6367 21.5 13.4403 21.4464 12.4106 21.3654C11.2646 21.2753 10.6827 21.2207 10.2182 21.086C9.86664 20.9841 9.55718 20.8269 9.12077 20.3944C8.68784 19.9654 8.52638 19.6556 8.41939 19.296C8.27872 18.8232 8.21783 18.2286 8.11599 17.0687C8.045 16.2603 8 15.3944 8 14.5C8 13.4757 8.11238 12.472 8.27698 11.555C8.50373 10.2919 8.55999 10.1362 8.80037 9.81102C8.92739 9.63921 9.33089 9.30169 9.52244 9.20702C9.85794 9.04121 10.1391 9 11.8756 9H13.1466C13.724 9 14.2713 9.25767 14.6391 9.70272C15.5799 10.841 16.9796 11.5 18.4563 11.5H19.5756C21.8717 11.5 22.2605 11.5629 22.634 11.7889C22.7644 11.8678 23.0111 12.076 23.1109 12.1913C23.4112 12.5382 23.5045 12.7825 23.8084 14.5586C23.8098 14.5672 23.8113 14.5758 23.8128 14.5845ZM8.63631 7.41404C8.15944 7.64973 7.50839 8.19432 7.19216 8.62205C6.63661 9.37348 6.52722 9.98288 6.30844 11.2017C6.12763 12.2089 6 13.3332 6 14.5C6 15.4606 6.04829 16.3854 6.12365 17.2437C6.31576 19.4316 6.41182 20.5256 7.71299 21.815C9.01417 23.1044 10.094 23.1894 12.2538 23.3592C13.3355 23.4443 14.5842 23.5 16 23.5C17.5636 23.5 18.9235 23.4321 20.0796 23.3317C22.1365 23.153 23.1649 23.0636 24.4577 21.7531C25.7505 20.4425 25.8198 19.4961 25.9582 17.6034C25.9848 17.2393 26 16.8697 26 16.5C26 15.7836 25.9145 15.0101 25.7842 14.2478C25.4971 12.5669 25.3535 11.7264 24.6233 10.8825C24.3865 10.609 23.9789 10.265 23.6694 10.0778C22.7146 9.5 21.6682 9.5 19.5756 9.5H18.4563C17.576 9.5 16.7416 9.10713 16.1807 8.42857C15.4329 7.52383 14.3204 7 13.1466 7H11.8756C10.2746 7 9.47408 7 8.63631 7.41404Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_25296">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FolderIcon;
