import { useState, SyntheticEvent, useEffect } from 'react';

import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridFooterContainer,
  useGridApiContext,
  useGridSelector,
  gridRowsLookupSelector,
  gridClasses,
} from '@mui/x-data-grid';
import { differenceInDays, fromUnixTime } from 'date-fns';

import DataGridPagination from '../../../components/DataGrid/DataGridPagination';
import AscendingIcon from '../../../components/icons/AscendingIcon';
import ClockIcon from '../../../components/icons/ClockIcon';
import DescendingIcon from '../../../components/icons/DescendingIcon';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import ErrorIcon from '../../../components/icons/ErrorIcon';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import TabPanel from '../../../components/TabPanel';
import {
  REPORT_TYPES,
  GET_REPORT_ITEMS_PAGE_URL,
  GET_DEVICE_REPORT_EXCEL_URL,
  DATETIME_FORMAT,
  DEVICE_TEMP_MEASURE_TYPES,
} from '../../../constants';
import { IUser } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import getTimezoneTimestamp from '../../../utils/getTimezoneTimestamp';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';
import sendMetrik from '../../../utils/sendMetrik';
import useFetchReport from '../hooks/useFetchReport';
import useFetchReportDeviceTypes from '../hooks/useFetchReportDeviceTypes';

import { getReportTypeTitle } from './Results';

const ReportDetailsFooter = () => {
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let reportTypeParam = searchParams.get('report_type') || '';

  const apiRef = useGridApiContext();
  const rowsState = useGridSelector(apiRef, gridRowsLookupSelector);

  let total: number = 0.00;

  if (deviceTypeParam === 'uspd') {
    Object.values(rowsState).forEach((row: any) => {
      if (typeof row.consumption === 'number') {
        total += row.consumption;
      }
    });
  }

  return (
    <GridFooterContainer
      sx={{
        flexWrap: 'wrap',
      }}
    >
      {deviceTypeParam === 'uspd' && (reportTypeParam === 'daily' || reportTypeParam === 'periodHour') && (
        <Box
          sx={{
            width: '100%',
            mb: 4,
            pt: 1,
            fontWeight: '700',
            textTransform: 'uppercase',
            textAlign: 'right',
          }}
        >
          Итого: {total.toFixed(2)}
        </Box>
      )}
      <DataGridPagination />
    </GridFooterContainer>
  );
};

const ReportDetails = () => {
  let { reportIds = '' } = useParams();
  let [searchParams] = useSearchParams();
  let deviceTypeParam = searchParams.get('device_type') || '';
  let deviceNamesParam = searchParams.get('device_names') || '';
  let temperatureMeasureParam = searchParams.get('temperature_measure') || '';
  let reportTypeParam = searchParams.get('report_type') || '';
  let dateFromParam = searchParams.get('date_from') || '';
  let dateToParam = searchParams.get('date_to') || '';
  let groupNameParam = searchParams.get('asset_name') || null;
  let addressParam = searchParams.get('address') || null;

  const [activeDATDChannel, setActiveDATDChannel] = useState(0);

  const queryClient = useQueryClient();
  const userData: IUser | undefined = queryClient.getQueryData('userData');

  const { isLoading, isError, data } = useFetchReport(
    deviceTypeParam,
    reportTypeParam,
    dateFromParam,
    dateToParam,
    reportIds,
  );

  useEffect(() => {
    if (data) {
      sendMetrik(
        'vntVdknl',
        'otchety',
        'element_show',
        'sformirovannyi_otchet',
        null,
        userData?.permissions[0].uuid,
        userData ? '1' : '0',
        '/reports',
        null,
        null,
        null,
        'interactions',
        userData?.profile_type,
        'web',
      );
    }
  }, [data, userData]);

  const {
    isLoading: isLoadingReportDeviceTypes,
    isError: isErrorReportDeviceTypes,
    data: reportDeviceTypesData,
  } = useFetchReportDeviceTypes();

  let DATDchannels: number[] = [];
  if (data && deviceTypeParam === 'uspd' && (reportTypeParam === 'daily' || reportTypeParam === 'periodHour')) {
    data.forEach((item: any) => {
      if (DATDchannels.indexOf(item.channel_number) === -1) {
        DATDchannels.push(item.channel_number);
      }
    });
    DATDchannels.sort((a, b) => a - b);
  }

  let deviceTypeName = '';
  if (reportDeviceTypesData && deviceTypeParam) {
    deviceTypeName = reportDeviceTypesData.device_types.find(
      (item: { id: string; name: string }) => item.id === deviceTypeParam,
    ).name;
  }

  let timezone;
  if (Array.isArray(data) && data[0].hasOwnProperty('timezone')) {
    timezone = data[0].timezone;
  }

  const getReportColumns = () => {
    let columnsData: GridColDef[] = [];

    columnsData.push({
      field: 'number',
      headerName: '№',
      width: 70,
    });

    if (deviceTypeParam === 'uspd') {
      if (reportTypeParam === 'daily' || reportTypeParam === 'periodHour') {
        columnsData.push({
          field: 'date',
          headerName: 'Дата',
          flex: 1,
          minWidth: 200,
          renderCell: (params: GridRenderCellParams<any, string>) => {
            const { field, value, row } = params;
            if (field === 'date' && value) {
              return convertTimestampToDate(
                getTimezoneTimestamp(parseInt(value), row.timezone),
                DATETIME_FORMAT,
              );
            }

            return value;
          },
        });
      } else if (reportTypeParam === 'period') {
        columnsData = columnsData.concat([
          {
            field: 'name',
            headerName: reportDeviceTypesData.attributes_mapping.name,
            flex: 1,
            minWidth: 200,
          },
          {
            field: 'serial',
            headerName: 'Серийный номер',
            flex: 1,
            minWidth: 200,
          },
          {
            field: 'address_full',
            headerName: reportDeviceTypesData.attributes_mapping.address_full,
            flex: 1,
            minWidth: 250,
          },
          {
            field: 'id_channel',
            headerName: reportDeviceTypesData.attributes_mapping.id_channel,
            flex: 1,
            minWidth: 250,
          },
          {
            field: 'indication_begin',
            headerName: reportDeviceTypesData.attributes_mapping.indication_begin,
            flex: 1,
            minWidth: 270,
            renderCell: (params: GridRenderCellParams<any, string>) => {
              const { row, value } = params;
              const timestamp = getTimezoneTimestamp(parseInt(row.min_date), row.timezone);
              const date = convertTimestampToDate(timestamp, 'dd.MM.Y');
              const time = convertTimestampToDate(timestamp, 'HH:mm:ss');
              const diff = Math.abs(
                differenceInDays(fromUnixTime(parseInt(dateFromParam)), fromUnixTime(row.min_date)),
              );

              return (
                <>
                  {value}
                  <Tooltip arrow title={`Активность ${date} в ${time}`}>
                    <Box sx={{ marginLeft: 1, lineHeight: 1 }}>
                      <ClockIcon color={diff > 1 ? 'error' : 'secondary'} />
                    </Box>
                  </Tooltip>
                </>
              );
            },
          },
        ]);
      }
      columnsData = columnsData.concat([
        {
          field: 'indication_end',
          headerName: reportDeviceTypesData.attributes_mapping.indication_end,
          flex: 1,
          minWidth: 270,
          renderCell: (params: GridRenderCellParams<any, string>) => {
            const { row, value } = params;

            if (reportTypeParam === 'period') {
              const timestamp = getTimezoneTimestamp(parseInt(row.max_date), row.timezone);
              const date = convertTimestampToDate(timestamp, 'dd.MM.Y');
              const time = convertTimestampToDate(timestamp, 'HH:mm:ss');
              const diff = Math.abs(
                differenceInDays(fromUnixTime(parseInt(dateToParam)), fromUnixTime(row.max_date)),
              );

              return (
                <>
                  {value}
                  <Tooltip arrow title={`Активность ${date} в ${time}`}>
                    <Box sx={{ marginLeft: 1, lineHeight: 1 }}>
                      <ClockIcon color={diff > 1 ? 'error' : 'secondary'} />
                    </Box>
                  </Tooltip>
                </>
              );
            }

            return value;
          },
        },
        {
          field: 'consumption',
          headerName: reportDeviceTypesData.attributes_mapping.consumption,
          flex: 1,
          minWidth: 200,
        },
      ]);
    } else if (deviceTypeParam === 'pressure_sensor') {
      columnsData.push({
        field: 'date',
        headerName: 'Дата',
        flex: 1,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<any, string>) => {
          const { field, value, row } = params;
          if (field === 'date' && value && value && row.timezone !== undefined) {
            return convertTimestampToDate(
              getTimezoneTimestamp(parseInt(value), row.timezone),
              DATETIME_FORMAT,
            );
          }

          return value;
        },
      });
      columnsData = columnsData.concat([
        {
          field: 'pressure_indication',
          headerName: reportDeviceTypesData.attributes_mapping.pressure_indication,
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'inter_lower_pressure_count',
          headerName: reportDeviceTypesData.attributes_mapping.inter_lower_pressure_count,
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'inter_upper_pressure_count',
          headerName: reportDeviceTypesData.attributes_mapping.inter_upper_pressure_count,
          flex: 1,
          minWidth: 200,
        },
      ]);

      if (temperatureMeasureParam === 'true') {
        columnsData = columnsData.concat([
          {
            field: 'temp_indication',
            headerName: reportDeviceTypesData.attributes_mapping.temp_indication,
            flex: 1,
            minWidth: 200,
          },
          {
            field: 'inter_lower_temp_count',
            headerName: reportDeviceTypesData.attributes_mapping.inter_lower_temp_count,
            flex: 1,
            minWidth: 200,
          },
          {
            field: 'inter_upper_temp_count',
            headerName: reportDeviceTypesData.attributes_mapping.inter_upper_temp_count,
            flex: 1,
            minWidth: 200,
          },
        ]);
      }
    }

    return columnsData;
  };

  const getReportRows = (channel?: number) => {
    let rowsData: any = data;

    if (reportTypeParam === 'daily' || reportTypeParam === 'periodHour') {
      if (channel) {
        rowsData = rowsData.filter((item: any) => item.channel_number === channel);
      }
      rowsData = rowsData.map((item: any, index: number) => ({
        ...item,
        id: index,
        number: index + 1,
      }));
    } else if (reportTypeParam === 'period') {
      if (deviceTypeParam === 'uspd') {
        let currentDeviceNumber = 0;
        let currentDeviceId = '';
        rowsData = rowsData
          .sort((a: any, b: any) => {
            if (a.id === b.id) {
              return a.channel_number > b.channel_number ? 1 : -1;
            } else {
              return a.id > b.id ? 1 : -1;
            }
          })
          .map((item: any) => {
            const itemData = {
              ...item,
              id: `${item.id}-${item.channel_number}`,
              serial: item.id,
              number: '',
            }

            if (item.id !== currentDeviceId) {
              currentDeviceNumber++;
              currentDeviceId = item.id;
              itemData.number = currentDeviceNumber;
            }

            return itemData;
          });
      } else {
        rowsData = rowsData.map((item: any, index: number) => {
          return {
            ...item,
            id: index,
            number: index + 1,
          };
        });
      }
    }

    return rowsData;
  };

  const handleDATDChannelChange = (event: SyntheticEvent, newValue: number) => {
    setActiveDATDChannel(newValue);
  };

  if (isLoading || isLoadingReportDeviceTypes) {
    return <Spinner />;
  }

  if (isError || isErrorReportDeviceTypes || !data) {
    return (
      <Message icon={<ErrorIcon />}>
        Не получилось загрузить данные отчета. Попробуйте еще раз.
      </Message>
    );
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to={GET_REPORT_ITEMS_PAGE_URL(
            deviceTypeParam,
            reportTypeParam,
            temperatureMeasureParam,
            dateFromParam,
            dateToParam,
            groupNameParam,
            addressParam,
          )}
        >
          Отчеты
        </Link>
        <span>{deviceTypeName}</span>
      </Breadcrumbs>
      {reportDeviceTypesData && (
        <>
          <Box
            sx={{
              display: 'flex',
              mb: 3,
            }}
          >
            <Typography variant="h1">{deviceTypeName}</Typography>
            <IconButton
              color="primary"
              size="small"
              component="a"
              onClick={() => {
                sendMetrik(
                  'vntVdknl',
                  getReportTypeTitle(reportTypeParam),
                  'button_click',
                  'vygruzit',
                  null,
                  userData?.permissions[0].uuid,
                  userData ? '1' : '0',
                  '/reports',
                  null,
                  null,
                  null,
                  'interactions',
                  userData?.profile_type,
                  'web',
                );
              }}
              href={GET_DEVICE_REPORT_EXCEL_URL(
                reportIds.split(','),
                deviceTypeParam,
                reportTypeParam,
                getUTCTimestamp(parseInt(dateFromParam)),
                dateToParam ? getUTCTimestamp(parseInt(dateToParam)) : '',
              )}
              download
              sx={{
                ml: 2,
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              marginBottom: 3,
              padding: 3,
              background: (theme) => theme.palette.background.secondary,
              borderRadius: '16px',
            }}
          >
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item>
                <Typography variant="paragraph3">
                  <b>Тип устройства:</b> {deviceTypeName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="paragraph3">
                  <b>Тип отчета:</b>{' '}
                  {deviceTypeParam === 'uspd' && reportTypeParam === 'period'
                    ? REPORT_TYPES.periodDATD
                    : REPORT_TYPES[reportTypeParam]}
                </Typography>
              </Grid>
              {temperatureMeasureParam && (
                <Grid item>
                  <Typography variant="paragraph3">
                    <b>Подтип устройства:</b> {DEVICE_TEMP_MEASURE_TYPES[temperatureMeasureParam]}
                  </Typography>
                </Grid>
              )}
              {deviceNamesParam && (
                <Grid item>
                  <Typography variant="paragraph3">
                    <b>Устройство:</b> {deviceNamesParam.replaceAll(',', ', ')}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="paragraph3">
                  <b>Серийный номер:</b> {reportIds.replaceAll(',', ', ')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="paragraph3">
                  <b>Период:</b> {convertTimestampToDate(dateFromParam)}
                  {dateToParam && `-${convertTimestampToDate(dateToParam)}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: 3,
            }}
          >
            <Typography color="text.secondary" variant="paragraph3">
              UTC{timezone >= 0 ? '+' : '-'}
              {timezone}
            </Typography>
            <ClockIcon color="secondary" />
          </Box>
          {data && DATDchannels.length > 0 ? (
            <>
              <Tabs value={activeDATDChannel} onChange={handleDATDChannelChange}>
                {DATDchannels.map((item) => (
                  <Tab key={item} label={`Счетчик ${item}`} />
                ))}
              </Tabs>
              {DATDchannels.map((item, index: number) => (
                <TabPanel value={activeDATDChannel} key={item} index={index}>
                  <DataGrid
                    columns={getReportColumns()}
                    rows={getReportRows(item)}
                    getRowHeight={() => 'auto'}
                    slots={{
                      columnSortedAscendingIcon: AscendingIcon,
                      columnSortedDescendingIcon: DescendingIcon,
                      footer: ReportDetailsFooter,
                    }}
                    sx={{
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    }}
                  />
                </TabPanel>
              ))}
            </>
          ) : (
            <DataGrid
              columns={getReportColumns()}
              rows={getReportRows()}
              getRowHeight={() => 'auto'}
              slots={{
                columnSortedAscendingIcon: AscendingIcon,
                columnSortedDescendingIcon: DescendingIcon,
                footer: ReportDetailsFooter,
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReportDetails;
