interface IMetrik {
  event?: string | null;
  eventCategory?: string | null;
  eventAction?: string | null;
  eventLabel?: string | null;
  eventValue?: string | boolean | null;
  userId?: string | null;
  userAuth?: string | null;
  screenName?: string | null;
  eventContent?: any;
  eventContext?: string | null;
  buttonLocation?: string | null;
  actionGroup?: string | null;
  accountType?: string | null;
  touchPoint?: string | null;
}

// функция отправки метрики
const sendMetrik = (
  eventPar: string | null,
  eventCategoryPar: string | null,
  eventActionPar: string | null,
  eventLabelPar: string | null,
  eventValuePar: string | boolean | null,
  userIdPar: string | null | undefined,
  userAuthPar: string | null,
  screenNamePar: string | null,
  eventContentPar: any,
  eventContextPar: string | null,
  buttonLocationPar: string | null,
  actionGroupPar: string | null,
  accountTypePar: string | null | undefined,
  touchPointPar: string | null,
): void => {
  const metrikData: IMetrik = {
    event: eventPar,
    eventCategory: eventCategoryPar,
    eventAction: eventActionPar,
    eventLabel: eventLabelPar,
    eventValue: eventValuePar,
    userId: userIdPar,
    userAuth: userAuthPar,
    screenName: screenNamePar,
    eventContent: eventContentPar,
    eventContext: eventContextPar,
    buttonLocation: buttonLocationPar,
    actionGroup: actionGroupPar,
    accountType: accountTypePar,
    touchPoint: touchPointPar,
  };
  window.dataLayer?.push(metrikData);
};

export default sendMetrik;
