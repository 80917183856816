import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const StatusBadge = styled(Chip, {
  name: 'StatusBadge',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})(({ theme, ...props }) => ({}));

export default StatusBadge;
