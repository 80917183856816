import { useState, useRef, useEffect, useCallback } from 'react';

import ZoomOut from '@mui/icons-material/ZoomOut';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceArea,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import ChartLegend from '../../../components/charts/ChartLegend';
import ChartTooltip from '../../../components/charts/ChartTooltip';
import WidgetBox from '../../../components/WidgetBox';
import { IWidgetBoxProps } from '../../../types';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import useThrottledFunction from '../../../utils/hooks/useThrottledFunction';

type IDashboardsLineChartProps = {
  dataParams: {
    key: string;
    color?: string;
  }[];
  data: any;
  type?:
    | 'basis'
    | 'basisClosed'
    | 'basisOpen'
    | 'linear'
    | 'linearClosed'
    | 'natural'
    | 'monotoneX'
    | 'monotoneY'
    | 'monotone'
    | 'step'
    | 'stepBefore'
    | 'stepAfter'
    | Function;
  showLegend?: boolean;
} & Omit<IWidgetBoxProps, 'children'>;

type IChartState = {
  left: any;
  right: any;
  refAreaLeft: string | number;
  refAreaRight: string | number;
};

const DashboardsLineChart = (props: IDashboardsLineChartProps) => {
  const { title, dataParams, data, type, showLegend = false } = props;

  const theme = useTheme();
  const colors = [
    theme.palette.primary.normalApple,
    theme.palette.primary.normalBanana,
    theme.palette.primary.normalBlackberry,
    theme.palette.primary.normalBlueberry,
    theme.palette.primary.normalCranberry,
    theme.palette.primary.normalLime,
    theme.palette.primary.normalMint,
    theme.palette.primary.normalOrange,
    theme.palette.primary.normalPlum,
    theme.palette.primary.normalRaspberry,
  ];

  const wrapperRef = useRef<null | HTMLElement>(null);
  const activeTooltipIndex = useRef<null | number>(null);

  const [chartState, setChartState] = useState<IChartState>({
    left: data && data[0],
    right: data && data[data.length - 1],
    refAreaLeft: '',
    refAreaRight: '',
  });

  const legendData = dataParams.map((item: any, index: number) => ({
    value: item.key,
    color: item.color ?? colors[index],
  }));

  const zoomIn = useCallback(() => {
    setChartState((prev: any) => {
      const { left, right } = prev;
      const currentLeftIndex = data?.findIndex((item: any) => item.date === left.date);
      const currentRightIndex = data?.findIndex((item: any) => item.date === right.date);
      let newLeft = left;
      let newRight = right;

      if (data)
        // Если осталось менее 3-ч точек
        if (currentRightIndex - currentLeftIndex < 2) {
          // Если осталось 3 точки
        } else if (currentRightIndex - currentLeftIndex === 2) {
          if (activeTooltipIndex.current === currentRightIndex) {
            newLeft = data[currentLeftIndex + 1];
            newRight = data[currentRightIndex];
          } else {
            newLeft = data[currentLeftIndex];
            newRight = data[currentRightIndex - 1];
          }
          // Если активна первая точка
        } else if (activeTooltipIndex.current === currentLeftIndex) {
          newLeft = left;
          newRight = data[currentRightIndex - 1];
          // Если активна последняя точка
        } else if (activeTooltipIndex.current === currentRightIndex) {
          newLeft = data[currentLeftIndex + 1];
          newRight = data[currentRightIndex];
        } else if (
          activeTooltipIndex.current !== null &&
          activeTooltipIndex.current > currentLeftIndex &&
          activeTooltipIndex.current < currentRightIndex
          ) {
          newLeft = data[currentLeftIndex + 1];
          newRight = data[currentRightIndex - 1];
        }

      return {
        ...prev,
        left: newLeft,
        right: newRight,
      };
    });
  }, [data]);

  const zoomOut = useCallback(() => {
    setChartState((prev: any) => {
      const { left, right } = prev;
      const currentLeftIndex = data?.findIndex((item: any) => item === left);
      const currentRightIndex = data?.findIndex((item: any) => item === right);
      let newLeft = left;
      let newRight = right;

      if (data) {
        if (currentLeftIndex > 0) {
          newLeft = data[currentLeftIndex - 1];
        }
        if (currentRightIndex < data.length - 1) {
          newRight = data[currentRightIndex + 1];
        }
      }

      return {
        ...prev,
        left: newLeft,
        right: newRight,
      };
    });
  }, [data]);

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = chartState;

    if (refAreaLeft === refAreaRight || refAreaLeft === '' || refAreaRight === '') {
      setChartState((prev) => ({
        ...prev,
        refAreaLeft: '',
        refAreaRight: '',
      }));

      return;
    }

    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    const fromIndex = data?.findIndex((item: any) => item.date === refAreaLeft);
    const toIndex = data?.findIndex((item: any) => item.date === refAreaRight);

    if (data) {
      setChartState({
        left: data[fromIndex],
        right: data[toIndex],
        refAreaLeft: '',
        refAreaRight: '',
      });
    }
  };

  const resetZoom = () => {
    if (data) {
      setChartState({
        left: data[0],
        right: data[data.length - 1],
        refAreaLeft: '',
        refAreaRight: '',
      });
    }
  };

  const handleMouseEnter = (e: any) => {
    activeTooltipIndex.current = e.activeTooltipIndex;
  };
  const handleMouseDown = (e: any) => {
    if (e) {
      setChartState((prev) => ({
        ...prev,
        refAreaLeft: e.activeLabel,
      }));
    }
  };
  const handleMouseMove = (e: any) => {
    activeTooltipIndex.current = e.activeTooltipIndex;
    if (chartState.refAreaLeft) {
      setChartState((prev) => ({
        ...prev,
        refAreaRight: e.activeLabel,
      }));
    }
  };
  const handleMouseUp = (e: any) => {
    zoom();
  };
  const handleMouseLeave = (e: any) => {
    activeTooltipIndex.current = null;
  };

  const ticks = data?.map((item: any) => item.date);

  const setWrapperRef = useCallback((e: any) => {
    if (
      typeof e === 'object' &&
      e !== null &&
      e.hasOwnProperty('current') &&
      e.current instanceof HTMLElement
    ) {
      wrapperRef.current = e.current;
    }
  }, []);

  const wheelHandler = useCallback(
    (e: WheelEvent) => {
      const delta = Math.sign(e.deltaY);
      if ((e.ctrlKey && delta > 0) || (!e.ctrlKey && delta < 0)) {
        zoomOut();
      } else {
        zoomIn();
      }
    },
    [zoomIn, zoomOut],
  );
  const callbackFnToThrottle = useCallback(
    (args) => {
      wheelHandler(args);
    },
    [wheelHandler],
  );
  const { throttleFn } = useThrottledFunction({
    callbackFn: callbackFnToThrottle,
    throttleMs: 100,
  });

  useEffect(() => {
    const ref = wrapperRef.current;

    if (ref) {
      ref.addEventListener('wheel', throttleFn);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('wheel', throttleFn);
      }
    };
  }, [zoomIn, zoomOut, throttleFn]);

  return (
    <>
      <WidgetBox
        title={title}
        mainHeader={
          <IconButton size="extraSmall" color="secondary" onClick={resetZoom}>
            <ZoomOut fontSize="small" />
          </IconButton>
        }
        secondaryHeader={
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
            {showLegend && <ChartLegend payload={legendData} />}
          </Stack>
        }
      >
        <ResponsiveContainer width="100%" height={300} ref={setWrapperRef}>
          <LineChart
            data={data}
            margin={{ left: 0, right: 0, top: 10, bottom: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              allowDataOverflow
              type="number"
              domain={[chartState?.left?.date, chartState?.right?.date]}
              height={40}
              dataKey="date"
              angle={90}
              textAnchor="start"
              axisLine={false}
              tickLine={false}
              minTickGap={0}
              ticks={ticks}
              tick={{ fontSize: 12, color: theme.palette.text.secondary }}
              tickFormatter={(value) => convertTimestampToDate(value, 'dd.MM')}
            />
            <YAxis
              yAxisId="1"
              width={50}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, color: theme.palette.text.secondary }}
            />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              content={<ChartTooltip />}
              isAnimationActive={false}
            />
            {dataParams.map(({ key, color }, index) => (
              <Line
                key={key}
                yAxisId="1"
                dataKey={key}
                type={type ?? 'linear'}
                dot={false}
                stroke={color ?? colors[index]}
                strokeWidth={4}
                isAnimationActive={false}
              />
            ))}
            {chartState.refAreaLeft && chartState.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={chartState.refAreaLeft}
                x2={chartState.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </WidgetBox>
    </>
  );
};

export default DashboardsLineChart;
