import { useState, useEffect } from 'react';

import { useQueryClient } from 'react-query';

import { useWatch, useFormContext } from 'react-hook-form';

import theme from '../../theme';

import Notification from './Notification';

const NotificationsTemplatePreview = () => {
  const queryClient = useQueryClient();
  const notificationsDeviceTypes = queryClient.getQueryData('notificationsDeviceTypesData');
  const notificationsTemplateTypes = queryClient.getQueryData('notificationsTemplateTypesData');

  const [deviceTypeName, setDeviceTypeName] = useState('');
  const [deviceTypeColor, setDeviceTypeColor] = useState<string | undefined>();
  const [templateTypeName, setTemplateTypeName] = useState('');
  // const [scheduleName, setScheduleName] = useState('');

  const { control } = useFormContext();

  const nameWatch = useWatch({
    control,
    name: 'name',
  });
  const deviceTypeWatch = useWatch({
    control,
    name: 'device_type',
  });
  const templateTypeWatch = useWatch({
    control,
    name: 'basic_template_name',
  });
  const deviceIdsWatch = useWatch({
    control,
    name: 'device_ids',
  });
  const groupWatch = useWatch({
    control,
    name: 'asset_id',
  });

  useEffect(() => {
    if (deviceTypeWatch && Array.isArray(notificationsDeviceTypes)) {
      const deviceType = notificationsDeviceTypes.find(
        (item: Record<string, string>) => item.device_type_short === deviceTypeWatch,
      );
      const color =
        deviceTypeWatch === 'uspd'
          ? theme.palette.primary.normalBlackberry
          : deviceTypeWatch === 'pressure_sensor'
          ? theme.palette.primary.lightPlum
          : undefined;
      setDeviceTypeName(deviceType.device_type_full);
      setDeviceTypeColor(color);
    }
  }, [deviceTypeWatch, notificationsDeviceTypes]);

  useEffect(() => {
    if (templateTypeWatch && Array.isArray(notificationsTemplateTypes)) {
      const templateType = notificationsTemplateTypes.find(
        (item: Record<string, string>) => item.name === templateTypeWatch,
      );
      setTemplateTypeName(templateType.full_name);
    }
  }, [templateTypeWatch, notificationsTemplateTypes]);

  return (
    <Notification
      name={nameWatch || 'Название'}
      deviceType={deviceTypeName || 'Тип устройства'}
      deviceTypeColor={deviceTypeColor}
      templateType={templateTypeName || 'Шаблон уведомления'}
      deviceIds={deviceIdsWatch.join(', ') || 'Серийный номер устройства'}
      groupName={groupWatch?.name || 'Группа устройства'}
      // date={scheduleName || 'Дата | Время'}
    />
  );
};

export default NotificationsTemplatePreview;
