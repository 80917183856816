import { useQuery } from 'react-query';

import { GET_REPORT_URL } from '../../../constants';
import fetchData from '../../../utils/fetchData';
import getUTCTimestamp from '../../../utils/getUTCTimestamp';

const useFetchReport = (
  deviceType: string,
  reportType: string,
  dateFrom: string | null,
  dateTo: string | null,
  reportIds: string,
) => {
  const UTCDateFrom = dateFrom ? getUTCTimestamp(parseInt(dateFrom)).toString() : null;
  const UTCDateTo = dateTo ? getUTCTimestamp(parseInt(dateTo)).toString() : null;

  return useQuery(
    ['reportData', reportIds, reportType],
    () =>
      fetchData(
        GET_REPORT_URL(
          reportIds.split(','),
          deviceType,
          reportType,
          UTCDateFrom,
          UTCDateTo,
        ),
      ),
    {
      refetchOnMount: true,
    },
  );
};

export default useFetchReport;
