/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import DOMPurify from 'dompurify';
import L from 'leaflet';
import 'leaflet.markercluster';
import './Leaflet.DonutCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const DONAT_SIZE = 72;
const DONAT_RING_WEIGHT = 10;

const LOW_COLOR = '#ffffff';
const MIDDLE_COLOR = '#ffffff';
const HIGH_COLOR = '#ffffff';

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

function readable(val) {
  if (val >= 1000 && val < 1000000) val = roundToTwo(val / 1000) + 'K';
  else if (val >= 1000000 && val < 1000000000) val = roundToTwo(val / 1000000) + 'M';
  else if (val >= 1000000000) val = roundToTwo(val / 1000000000) + 'B';

  return val;
}

let doc = document,
  M = Math,
  donutData = {},
  dataIndex = 0;

DOMPurify.sanitize.defaults = { RETURN_DOM: true };

function donut(options) {
  let div = doc.createElement('div'),
    size = options.size || 100,
    size0 = size + 10,
    data = options.data || [
      {
        value: 1,
      },
    ],
    weight = options.weight || 20,
    colors = options.colors || ['#555'],
    fillColor = options.fillColor || '#f1d357',
    el = options.el,
    r = size / 2,
    PI = M.PI,
    sin = M.sin,
    cos = M.cos,
    sum = 0,
    i,
    value,
    arc,
    text,
    legend,
    setAttribute = function (el, o) {
      for (let j in o) {
        el.setAttribute(j, o[j]);
      }
    };

  for (i = 0; i < data.length; i++) {
    sum += data[i].value;
  }

  if (sum == 0) {
    for (i = 0; i < data.length; i++) {
      data[i].value = 1;
      sum += data[i].value;
    }
  }
  div.className = 'donut';
  div.style.width = div.style.height = size0 + 'px';
  div.style.position = 'relative';

  text = div.appendChild(document.createElement('span'));

  text.className = 'donut-text';

  text.innerHTML = DOMPurify.sanitize(readable(sum));
  legend = document.createElement('div');

  let NS = 'http://www.w3.org/2000/svg',
    svg = doc.createElementNS(NS, 'svg'),
    startAngle = -PI / 2,
    arcRadius = r - weight / 2;

  svg.setAttribute('height', size0 + 'px');
  svg.setAttribute('width', size0 + 'px');

  let circle = doc.createElementNS(NS, 'circle');
  circle.setAttribute('cx', size0 / 2.0);
  circle.setAttribute('cy', size0 / 2.0);
  circle.setAttribute('r', arcRadius - weight / 2);
  circle.setAttribute('fill', fillColor);
  circle.setAttribute('fill-opacity', 1);
  svg.appendChild(circle);

  div.appendChild(svg);

  for (i = 0; i < data.length; i++) {
    value = data[i].value / sum;
    value = value === 1 ? 0.99999 : value;
    arc = doc.createElementNS(NS, 'path');
    let r1 = r + 5;
    let segmentAngle = value * PI * 2,
      endAngle = segmentAngle + startAngle,
      largeArc = (endAngle - startAngle) % (PI * 2) > PI ? 1 : 0,
      startX = r1 + cos(startAngle) * arcRadius,
      startY = r1 + sin(startAngle) * arcRadius,
      endX = r1 + cos(endAngle) * arcRadius,
      endY = r1 + sin(endAngle) * arcRadius;

    let name = data[i].name,
      c = Array.isArray(colors) ? colors[i % colors.length] : colors[name] || '#fff';

    startAngle = endAngle;

    setAttribute(arc, {
      d: ['M', startX, startY, 'A', arcRadius, arcRadius, 0, largeArc, 1, endX, endY].join(' '),
      stroke: c,
      'stroke-opacity': '1',
      'stroke-width': weight,
      fill: 'none',
      'data-name': name,
      class: 'donut-arc',
    });
    donut.data(arc, data[i]);
    (function (d, c, perc) {
      if (perc == '99.99') perc = '100';
      if (options.onclick && typeof options.onclick == 'function') {
        arc.addEventListener('click', function (e) {
          let t = e.target,
            val = readable(d.value);
          if (t.parentNode.stick != t) {
            t.parentNode.stick = t;
          } else t.parentNode.stick = false;
          options.onclick(d.name, !!t.parentNode.stick);
        });
      }
    })(data[i], c, (value * 100 + '').substr(0, 5));
    svg.appendChild(arc);
    if (data[i].active) {
      svg.stick = arc;
      let event = new MouseEvent('mouseenter', {
        view: window,
        bubbles: false,
        cancelable: true,
      });
      arc.dispatchEvent(event);
      arc.setAttribute('stroke-width', weight);
    }
  }

  div.appendChild(legend);
  if (el) {
    el.appendChild(div);
  }

  return div;
}

donut.data = function (arc, data) {
  if (typeof data === 'undefined') {
    return donutData[arc._DONUT];
  } else {
    donutData[(arc._DONUT = arc._DONUT || ++dataIndex)] = data;

    return arc;
  }
};

donut.setColor = function (arc, color) {
  arc.setAttribute('stroke', color);

  return arc;
};

function createDonut(points, opt, cfgFn) {
  let blocks = {},
    count = points.length,
    sumField = opt.sumField,
    fieldList = opt.order || (opt.order = []),
    fieldDict = opt.orderDict || (opt.orderDict = {}),
    titleDict = opt.title || {},
    cfg = {};
  if (typeof cfgFn == 'function') cfg = cfgFn(points);
  else if (typeof cfgFn == 'object') {
    cfg = cfgFn;
  }
  if (Array.isArray(opt.title) && opt.order) {
    titleDict = {};
    for (let i in opt.title) {
      titleDict[opt.order[i]] = opt.title[i];
    }
    opt.title = titleDict;
  }
  for (let i in fieldList) {
    fieldDict[fieldList[i]] = 1;
  }

  for (let i = 0; i < count; i++) {
    let s = points[i]._kind;

    if (!blocks[s]) blocks[s] = 0;
    if (!fieldDict[s]) {
      fieldDict[s] = 1;
      fieldList.push(s);
    }

    if (!sumField) blocks[s]++;
    else blocks[s] += points[i].options[sumField];
  }
  let list = [];

  for (let i in fieldList) {
    let s = fieldList[i];
    list.push({
      value: blocks[s] || 0,
      name: s,
      title: titleDict[s],
      active: cfg.active && cfg.active == s,
    });
  }

  let size = cfg.size || 50,
    weight = cfg.weight || 10,
    colors = cfg.colors;

  let myDonut = donut({
    size: size,
    weight: weight,
    data: list,
    onclick: cfg.onclick,
    colors: colors,
    fillColor: cfg.fillColor,
  });
  myDonut.config = cfg;

  return myDonut;
}
// to use donut as icon
L.DivIcon.prototype.createIcon = function (oldIcon) {
  let div = oldIcon && oldIcon.tagName === 'DIV' ? oldIcon : document.createElement('div'),
    options = this.options;

  if (options.el) {
    div.appendChild(options.el);
  } else {
    div.innerHTML = options.html ? DOMPurify.sanitize(options.html) : '';
  }

  if (options.bgPos) {
    let bgPos = L.Point(options.bgPos);
    div.style.backgroundPosition = -bgPos.x + 'px ' + -bgPos.y + 'px';
  }
  this._setIconStyles(div, 'icon');

  return div;
};

function defaultStyle(points) {
  let count = points.length,
    size = DONAT_SIZE,
    weight = DONAT_RING_WEIGHT,
    fill;

  if (count < 10) {
    fill = LOW_COLOR;
  } else if (count < 100) {
    fill = MIDDLE_COLOR;
  } else {
    fill = HIGH_COLOR;
  }

  return {
    size,
    weight,
    fill,
  };
}

export const DonutClusterMarker = (L.DonutClusterMarker = L.Marker.extend({
  initialize: function (position, kind, opt) {
    this._kind = kind;
    L.Marker.prototype.initialize.call(this, position, opt);
  },
  setKind: function (kind) {
    this._kind = kind;
  },
}));

L.createDonutClusterMarker = function (...args) {
  return new L.DonutClusterMarker(...args);
};

/**
 * @param {object} opt marker cluster's options
 * @param {object} donutOpt donut cluster's options
 */
export const DonutCluster = (L.DonutCluster = L.MarkerClusterGroup.extend({
  initialize: function (opt, donutOpt) {
    L.Util.setOptions(this, donutOpt);
    opt.iconCreateFunction = L.bind(this.iconCreateFunction, this);
    L.MarkerClusterGroup.prototype.initialize.call(this, opt);
  },
  setOptions: function (donutOpt) {
    L.Util.setOptions(this, donutOpt);

    this._featureGroup.eachLayer((c) => {
      if (c instanceof L.MarkerCluster) {
        this._overrideMarkerIcon(c);
        c._updateIcon();
      }
    });
  },
  iconCreateFunction: function (cluster) {
    let donutOpt = this.options;
    let markers = cluster.getAllChildMarkers();
    let myDonut = createDonut(markers, donutOpt, function (points) {
      let style;
      if (!donutOpt.style) {
        style = defaultStyle(points);
      } else {
        if (typeof donutOpt.style == 'function') {
          style = donutOpt.style(points);
        } else style = donutOpt.style;
      }

      return {
        size: style.size,
        weight: style.weight,
        colors: donutOpt.groupColors,
        fillColor: style.fill,
      };
    });

    return new L.DivIcon({
      el: myDonut,
      iconSize: new L.Point(myDonut.config.size + 10, myDonut.config.size + 10),
      className: 'donut-cluster',
    });
  },
}));

L.createDonutCluster = function (...args) {
  return new L.DonutCluster(...args);
};
