import { useQuery } from 'react-query';

import { GET_USERS_URL } from '../../constants';
import fetchData from '../../utils/fetchData';

const useFetchUsers = (enabled: boolean) => {
  return useQuery('users', () => fetchData(GET_USERS_URL), {
    placeholderData: [],
    enabled,
  });
};

export default useFetchUsers;
