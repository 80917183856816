import { useEffect, useMemo, useState } from 'react';

import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import { Alert, Box, Breadcrumbs, Link, Typography } from '@mui/material';

import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import { UPDATE_CUSTOM_REPORT_TEMPLATE } from '../../../constants';
import { IBodylAtrCheckboxes } from '../../../types';
import fetchData from '../../../utils/fetchData';
import useSnackbars from '../../../utils/hooks/useSnackbars';
import useFetchCustomReports from '../hooks/useFetchCustomReports';
import useFetchCustomReportShablons from '../hooks/useFetchCustomReportShablons';
import useFetchGetShablonId from '../hooks/useFetchGetShablonId';
import getAttributes from '../hooks/useGetAttributes';
import resetCheckboxesForm from '../hooks/useResetCheckboxesForm';

import ReportsCustomParams from './Params';

const EditCustomReport = () => {
  const { dataCustomDevices, isLoading, isError } = useFetchCustomReports();
  const { dataCustomReportShablons } = useFetchCustomReportShablons();

  let { shablonId = '' } = useParams();
  const { dataShablonId, isLoadingdataShablon, isErrordataShablon } =
    useFetchGetShablonId(shablonId); // получение сохраненных (отправленных на бэк) данных

  // чекбоксы в шапке отчета
  const initialHeaderAtrCheckboxes: any[] = useMemo(
    () => [
      { name: 'client', label: 'Клиент', checked: false },
      { name: 'cl_model', label: 'Модель', checked: false },
      { name: 'sensor_id', label: 'ID счетчика', checked: false },
      { name: 'id', label: 'ID датчика', checked: false },
      { name: 'date', label: 'Период', checked: false },
      { name: 'asset', label: 'Название группы', checked: false },
      { name: 'additional_info', label: 'Описание группы', checked: false },
    ],
    [],
  );

  const [isHeaderTextEnabled, setIsHeaderTextEnabled] = useState(false); // сост-ие чекбокса текстовое поле в шапке отчета
  const [settingFields, setSettingFields] = useState([{ name: '', value: '' }]); // поля название отчета и значение отчета в настройках
  const [numFieldsCreated, setNumFieldsCreated] = useState(1);

  const [headerAtrCheckboxes, setHeaderAtrCheckboxes] = useState<any>(initialHeaderAtrCheckboxes);

  // состояние выбора типа устройства в настройках отчета
  const [selectedDevice, setSelectedDevice] = useState('');

  // состояние выбора типа шаблона в настройках отчета
  const [selectedTemplate, setSelectedTemplate] = useState('');

  // состояние текста названия отчета в настройках отчета
  const [reportTitleText, setReportTitleText] = useState('');

  // состояние текста описания отчета в настройках отчета
  const [reportDescriptionText, setReportDescriptionText] = useState('');

  // объект для атрибутов (чекбоксов) в теле отчета
  const initialBodylAtrCheckboxes = {
    attr: {
      columnAtrNames: [],
      columnAtrFilters: [],
      columnAllChecked: false,
    },
  };

  // состояние для общих атрибутов в теле отчета
  const [bodyAtrCheckboxes, setBodyAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для пользовательских атрибутов в теле отчета
  const [bodyClientAtrCheckboxes, setBodyClientAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для поканальных атрибутов(УСПД) в теле отчета
  const [bodyChanneUspdlAtrCheckboxes, setBodyChannelUspdAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  // состояние для поканальных атрибутов(Датчик давления) в теле отчета
  const [bodyChanneSenslAtrCheckboxes, setBodyChannelSensAtrCheckboxes] =
    useState<IBodylAtrCheckboxes>(initialBodylAtrCheckboxes);

  const [selectDeviceError, setSelectDeviceError] = useState(false); // ошибка при не заполнении типа устройства
  const [selectTamplateError, setSelectTamplateError] = useState(false); // ошибка при не заполнении типа устройства
  const [reportTitleTextError, setReportTitleTextError] = useState(false); // ошибка при не заполнении названия отчета
  const [headerDateError, setHeaderDateError] = useState(false); // ошибка при не заполнении периода в шапке

  // переиспользуемая функция для проверки какие чекбоксы были выбраны при создании
  const updateCheckedProperties = (dataCol: any, dataCom: any) => {
    const { columnAtrNames, columnAtrFilters } = dataCol?.attr;
    const {
      common_columns,
      common_filters,
      client_columns,
      client_filters,
      channel_setting_uspd_columns,
      channel_setting_uspd_filters,
      channel_setting_pressure_sensor_columns,
      channel_setting_pressure_sensor_filters,
    } = dataCom;

    // Обновление свойства checked в columnAtrNames
    columnAtrNames.forEach((obj: { name: any; checked: boolean }) => {
      if (
        common_columns?.includes(obj.name) ||
        client_columns?.includes(obj.name) ||
        channel_setting_uspd_columns?.includes(obj.name) ||
        channel_setting_pressure_sensor_columns?.includes(obj.name)
      ) {
        obj.checked = true;
      }
    });

    // Обновление свойства checked в columnAtrFilters
    columnAtrFilters.forEach((obj: { name: any; checked: boolean; disabled: boolean }) => {
      if (
        common_filters?.includes(obj.name) ||
        client_filters?.includes(obj.name) ||
        channel_setting_uspd_filters?.includes(obj.name) ||
        channel_setting_pressure_sensor_filters?.includes(obj.name)
      ) {
        obj.checked = true;
        obj.disabled = false;
      }
    });

    // Обновление свойств columnAllChecked
    dataCol.attr.columnAllChecked = columnAtrNames.every((attr: { checked: any }) => attr.checked);

    return dataCol;
  };

  useEffect(() => {
    if (dataCustomDevices && dataShablonId) {
      const headerFieldsSet = new Set(dataShablonId?.header_fields);
      const updatedCheckboxes = initialHeaderAtrCheckboxes
        .map(({ name, label }) => ({
          name,
          label,
          checked: headerFieldsSet.has(name),
        }))
        .sort((a, b) => {
          const aIndex = dataShablonId?.header_fields?.indexOf(a.name);
          const bIndex = dataShablonId?.header_fields?.indexOf(b.name);
          if (aIndex === -1 && bIndex === -1) {
            return 0;
          } else if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          } else {
            return aIndex - bIndex;
          }
        });

      setHeaderAtrCheckboxes(updatedCheckboxes);
      setSelectedDevice(dataShablonId?.device_type);
      setSelectedTemplate(dataShablonId?.shablon_type);
      setReportTitleText(dataShablonId?.name);
      setReportDescriptionText(dataShablonId?.description);
      setSettingFields(
        dataShablonId?.header_text_fields?.map((obj: { [x: string]: any }) => {
          return {
            name: Object.keys(obj)[0],
            value: obj[Object.keys(obj)[0]],
          };
        }),
      );

      let commonData: any;
      commonData = getAttributes(dataCustomDevices?.attrib_name_mapping_for_rg_common, commonData);
      let newCommonData = { ...commonData };

      setBodyAtrCheckboxes(updateCheckedProperties(newCommonData, dataShablonId));

      let clientData: any;
      clientData = getAttributes(dataCustomDevices?.attrib_name_mapping_client, clientData);
      let newClientData = { ...clientData };
      setBodyClientAtrCheckboxes(updateCheckedProperties(newClientData, dataShablonId));

      let channelSettingsUspdData: any;
      channelSettingsUspdData = getAttributes(
        dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_uspd,
        channelSettingsUspdData,
      );
      let newChannelSettingsUspdData = { ...channelSettingsUspdData };

      setBodyChannelUspdAtrCheckboxes(
        updateCheckedProperties(newChannelSettingsUspdData, dataShablonId),
      );

      let channelSettingsSensData: any;
      channelSettingsSensData = getAttributes(
        dataCustomDevices?.attrib_name_mapping_for_rg_channel_settings_dd,
        channelSettingsSensData,
      );
      let newChannelSettingsSensData = { ...channelSettingsSensData };
      setBodyChannelSensAtrCheckboxes(
        updateCheckedProperties(newChannelSettingsSensData, dataShablonId),
      );
    }
  }, [dataCustomDevices, dataShablonId, initialHeaderAtrCheckboxes]);

  // функция для подготовки атрибутов тела отчета для отправки на бэк
  function getCheckedAttributes(dataCheckboxess: any) {
    const columns =
      dataCheckboxess &&
      dataCheckboxess?.attr?.columnAtrNames
        ?.filter((attr: any) => attr.checked)
        .map((attr: any) => attr.name);

    const filters =
      dataCheckboxess &&
      dataCheckboxess?.attr?.columnAtrFilters
        ?.filter((attr: any) => attr.checked)
        .map((attr: any) => attr.name);

    return { columns, filters };
  }

  const common = getCheckedAttributes(bodyAtrCheckboxes);
  const client = getCheckedAttributes(bodyClientAtrCheckboxes);
  const channel_setting_uspd = getCheckedAttributes(bodyChanneUspdlAtrCheckboxes);
  const channel_setting_pressure_sensor = getCheckedAttributes(bodyChanneSenslAtrCheckboxes);

  const [headerFields, setHeaderFields] = useState<[string]>(['']);

  useEffect(() => {
    if (headerAtrCheckboxes && Array.isArray(headerAtrCheckboxes)) {
      const selectedHeaderFields: any = headerAtrCheckboxes
        .filter((item) => item.checked)
        .map((item) => item.name);
      setHeaderFields(selectedHeaderFields);
    } else {
      setHeaderFields(['']);
    }
  }, [headerAtrCheckboxes]);

  const updatedForm: any = {
    device_type: selectedDevice,
    shablon_type: selectedTemplate,
    name: reportTitleText,
    description: reportDescriptionText,
    common,
    client,
    channel_setting_uspd,
    channel_setting_pressure_sensor,
    header_fields: headerFields,
    header_text_fields: settingFields,
  };

  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbars();

  const updatedFormMutation = useMutation(
    (newData) => {
      return fetchData(UPDATE_CUSTOM_REPORT_TEMPLATE, {
        method: 'PUT',
        body: newData,
      });
    },
    {
      onSuccess: () => {
        if (
          selectedDevice &&
          selectedTemplate &&
          reportTitleText &&
          headerFields?.includes('date')
        ) {
          showSnackbar(<Alert severity="success">Шаблон отчета обновлен</Alert>);
          queryClient.invalidateQueries('saveGeneratorForm', {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['saveGeneratorForm', 'customReportData', shablonId], {
            refetchInactive: true,
          });
          navigate(`/reports/custom`);
        }
      },
      onError: () => {
        showSnackbar(<Alert severity="error">Шаблон отчета не обновлен</Alert>);
      },
    },
  );

  const onSubmit: (event: React.FormEvent<HTMLFormElement>) => void = (event) => {
    let errorDeviceElement = document.getElementById('device-error');
    let errorTamplate = document.getElementById('template-error');
    let errorTitle = document.getElementById('title-error');
    let errorHeaderDate = document.getElementById('header-fields');

    event.preventDefault();

    if (!selectedDevice) {
      event.preventDefault();
      setSelectDeviceError(true);
      errorDeviceElement?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не обновлен</Alert>);
    } else if (!selectedTemplate) {
      event.preventDefault();
      setSelectTamplateError(true);
      errorTamplate?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не обновлен</Alert>);
    } else if (!reportTitleText) {
      event.preventDefault();
      setReportTitleTextError(true);
      errorTitle?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не обновлен</Alert>);
    } else if (!headerFields?.includes('date')) {
      event.preventDefault();
      setHeaderDateError(true);
      errorHeaderDate?.scrollIntoView({ behavior: 'smooth' });
      showSnackbar(<Alert severity="error">Шаблон отчета не обновлен</Alert>);
    } else if (
      dataCustomReportShablons?.some(
        (template: { name: string }) =>
          template.name.toLowerCase() === updatedForm.name.toLowerCase() &&
          template.name.toLowerCase() !== dataShablonId?.name.toLowerCase(),
      )
    ) {
      showSnackbar(<Alert severity="error">Шаблон с таким названием уже существует</Alert>);
    } else {
      setSelectDeviceError(false);
      setSelectTamplateError(false);
      setReportTitleTextError(false);
      setHeaderDateError(false);
      setSelectedDevice('');
      setSelectedTemplate('');
      setReportTitleText('');
      setReportDescriptionText('');
      setNumFieldsCreated(1);
      setSettingFields([{ name: '', value: '' }]);
      setIsHeaderTextEnabled(false);
      setHeaderAtrCheckboxes(initialHeaderAtrCheckboxes);
      resetCheckboxesForm(bodyAtrCheckboxes, setBodyAtrCheckboxes);
      resetCheckboxesForm(bodyClientAtrCheckboxes, setBodyClientAtrCheckboxes);
      resetCheckboxesForm(bodyChanneUspdlAtrCheckboxes, setBodyChannelUspdAtrCheckboxes);
      resetCheckboxesForm(bodyChanneSenslAtrCheckboxes, setBodyChannelSensAtrCheckboxes);
      updatedFormMutation.mutate({
        ...updatedForm,
        uuid: shablonId,
        is_favorite: dataShablonId?.is_favorite,
      });
    }
  };

  if (isLoadingdataShablon || isLoading) {
    return <Spinner data-testid="loader"></Spinner>;
  }

  if (isError || isErrordataShablon) {
    return <Message>Не удалось загрузить страницу</Message>;
  }

  return (
    <Box sx={{ padding: '32px 40px' }}>
      <Breadcrumbs style={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/reports/custom">
          Отчеты
        </Link>
        <span>Редактировать</span>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>
        Редактировать шаблон
      </Typography>
      <ReportsCustomParams
        onFormSubmit={(event) => onSubmit(event)}
        selectedDevice={selectedDevice}
        selectedTemplate={selectedTemplate}
        reportTitleText={reportTitleText}
        reportDescriptionText={reportDescriptionText}
        headerAtrCheckboxes={headerAtrCheckboxes}
        numFieldsCreated={numFieldsCreated}
        isHeaderTextEnabled={isHeaderTextEnabled}
        selectDeviceError={selectDeviceError}
        selectTamplateError={selectTamplateError}
        setSelectDeviceError={setSelectDeviceError}
        setSelectTamplateError={setSelectTamplateError}
        headerDateError={headerDateError}
        setHeaderDateError={setHeaderDateError}
        setSelectedDevice={setSelectedDevice}
        setSelectedTemplate={setSelectedTemplate}
        setReportTitleText={setReportTitleText}
        setReportDescriptionText={setReportDescriptionText}
        setNumFieldsCreated={setNumFieldsCreated}
        setSettingFields={setSettingFields}
        setIsHeaderTextEnabled={setIsHeaderTextEnabled}
        setHeaderAtrCheckboxes={setHeaderAtrCheckboxes}
        bodyAtrCheckboxes={bodyAtrCheckboxes}
        setBodyAtrCheckboxes={setBodyAtrCheckboxes}
        bodyClientAtrCheckboxes={bodyClientAtrCheckboxes}
        setBodyClientAtrCheckboxes={setBodyClientAtrCheckboxes}
        bodyChanneUspdlAtrCheckboxes={bodyChanneUspdlAtrCheckboxes}
        setBodyChannelUspdAtrCheckboxes={setBodyChannelUspdAtrCheckboxes}
        bodyChanneSenslAtrCheckboxes={bodyChanneSenslAtrCheckboxes}
        setBodyChannelSensAtrCheckboxes={setBodyChannelSensAtrCheckboxes}
        settingFields={settingFields}
        reportTitleTextError={reportTitleTextError}
        setReportTitleTextError={setReportTitleTextError}
      />
    </Box>
  );
};

export default EditCustomReport;
