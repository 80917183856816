import { useState } from 'react';

import { useQueryClient } from 'react-query';

import { useFormContext, Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddressField from '../../components/AddressField';
import ChipsField from '../../components/ChipsField';
import FormLabel from '../../components/FormLabel';
// import TelegramIcon from '../../components/icons/TelegramIcon';
import useFetchGroups from '../../utils/hooks/useFetchGroups';
import useFetchUsers from '../../utils/hooks/useFetchUsers';

const NotificationsTemplateInfo = () => {
  const queryClient = useQueryClient();
  const notificationsDeviceTypes = queryClient.getQueryData('notificationsDeviceTypesData');
  const notificationsTemplateTypes = queryClient.getQueryData('notificationsTemplateTypesData');

  const [isGroupsListOpen, setIsGroupsListOpen] = useState(false);
  const [isUsersListOpen, setIsUsersListOpen] = useState(false);

  const { control } = useFormContext();

  const { isFetched: isFetchedGroups, data: groupsData } = useFetchGroups(isGroupsListOpen);

  const { isFetching: isFetchingUsers, data: usersData } = useFetchUsers(isUsersListOpen);

  const handleGroupsListOpen = () => {
    setIsGroupsListOpen(true);
  };

  const handleUsersListOpen = () => {
    setIsUsersListOpen(true);
  };

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Название</FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  inputProps={{
                    'aria-label': 'Название',
                  }}
                  fullWidth
                  placeholder="Введите название"
                  {...field}
                />
              )}
              name="name"
              control={control}
            />
          </FormControl>
        </Grid>
        {Array.isArray(notificationsDeviceTypes) && (
          <Grid item mobile={12} xs={4}>
            <FormControl>
              <FormLabel>Тип устройства</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Выберите тип устройства</span>;
                      }
                      const selectedItem = notificationsDeviceTypes.find(
                        (item: any) => item.device_type_short === selected,
                      );

                      return selectedItem.device_type_full;
                    }}
                    {...field}
                  >
                    {notificationsDeviceTypes.map((item: Record<string, string>) => (
                      <MenuItem key={item.device_type_short} value={item.device_type_short}>
                        {item.device_type_full}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name="device_type"
                control={control}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Группа</FormLabel>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={groupsData}
                  loading={!isFetchedGroups}
                  onChange={(_, value) => field.onChange(value)}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <TextField placeholder="Выберите группу" {...params} />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Нет доступных групп"
                  onOpen={handleGroupsListOpen}
                />
              )}
              name="asset_id"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Серийный номер</FormLabel>
            <Controller
              render={({ field }) => (
                <ChipsField options={[]} freeSolo placeholder="Укажите серийный номер" {...field} />
              )}
              name="device_ids"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Адрес</FormLabel>
            <Controller
              name="address_full"
              control={control}
              render={({ field }) => <AddressField {...field} placeholder="Введите адрес" />}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={4}>
          <FormControl>
            <FormLabel>Получатель уведомления</FormLabel>
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <ChipsField
                  {...field}
                  options={usersData}
                  loading={isFetchingUsers}
                  isOptionEqualToValue={(option: any, value: any) => option.msisdn === value.msisdn}
                  getOptionLabel={(option: any) => option.fullname}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option: any, index) => (
                      <Chip size="small" label={option.fullname} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(props, option: any, { selected }) => (
                    <li {...props}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            flexShrink: 1,
                            fontWeight: 500,
                            fontSize: 14,
                          }}
                        >
                          {option.fullname}
                        </Box>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                          sx={{ flexShrink: 0 }}
                        >
                          {/* {option.telegram_chat_activated ? (
                            <TelegramIcon fontSize="small" />
                          ) : (
                            <Typography
                              sx={{ color: (theme) => theme.palette.text.link, fontSize: 10 }}
                            >
                              Нет Telegram
                            </Typography>
                          )} */}
                          <Checkbox checked={selected} />
                        </Stack>
                      </Stack>
                    </li>
                  )}
                  disableCloseOnSelect
                  placeholder="Выберите пользователя"
                  onOpen={handleUsersListOpen}
                />
              )}
            />
          </FormControl>
        </Grid>
        {Array.isArray(notificationsTemplateTypes) && (
          <Grid item mobile={12}>
            <FormControl>
              <FormLabel>Шаблон</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Выберите шаблон уведомления</span>;
                      }
                      const selectedItem = notificationsTemplateTypes.find(
                        (item: any) => item.name === selected,
                      );

                      return selectedItem.full_name;
                    }}
                    {...field}
                  >
                    {notificationsTemplateTypes.map((item: Record<string, string>) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.full_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name="basic_template_name"
                control={control}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item mobile={12} xs={3}>
          <FormControl>
            <Typography variant="h4" gutterBottom>
              Активность
            </Typography>
            <FormLabel>Включить шаблон</FormLabel>
            <Controller
              render={({ field }) => (
                <Switch
                  inputProps={{ 'aria-label': 'Включить шаблон' }}
                  size="large"
                  checked={field.value}
                  {...field}
                />
              )}
              name="is_active"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item mobile={12} xs={3}>
          <FormControl>
            <Typography variant="h4" gutterBottom>
              Каналы для рассылки
            </Typography>
            <FormGroup row={true}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox size="small" checked={field.value} {...field} />}
                    label="Telegram"
                  />
                )}
                name="is_telegram"
                control={control}
              />
            </FormGroup>
          </FormControl>
          <FormControl margin="none">
            <FormGroup row={true}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox size="small" checked={field.value} {...field} />}
                    label="Email-уведомления"
                  />
                )}
                name="is_web"
                control={control}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationsTemplateInfo;
