import { PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';

type IDashboardsPieChartProps = {
  data: any;
  name: string;
  dataId: string;
  payload: any;
  pieCell: any;
};

const DashboardsPieChart = (props: IDashboardsPieChartProps) => {
  const { data, name, dataId, payload, pieCell } = props;

  return (
    <>
      <ResponsiveContainer width="95%">
        <PieChart height={80} width={80}>
          <Pie
            data={data}
            nameKey={name}
            dataKey={dataId}
            cx="45%"
            cy="43%"
            legendType="circle"
            outerRadius={50}
          >
            {pieCell}
          </Pie>
          <Legend
            layout="vertical"
            align="left"
            iconSize={6}
            formatter={(value, entry, index) => <span style={{ color: '#969FA8' }}>{value}</span>}
            wrapperStyle={{ fontSize: '12px', left: 0, top: 12 }}
            payload={payload}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default DashboardsPieChart;
