import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d="M12.0001 7.5C11.8303 7.5 11.6623 7.50741 11.5014 7.51939L11.4322 7.52447C10.9124 7.5624 10.4458 7.59644 10.0212 8.02111C9.59651 8.44578 9.56247 8.91228 9.52454 9.43213L9.51946 9.5013C9.50748 9.66223 9.50007 9.83022 9.50007 10C9.50007 10.1698 9.50748 10.3378 9.51946 10.4987L9.52454 10.5679C9.56247 11.0877 9.59651 11.5542 10.0212 11.9789C10.4458 12.4036 10.9124 12.4376 11.4322 12.4755L11.5014 12.4806C11.6623 12.4926 11.8303 12.5 12.0001 12.5C12.1698 12.5 12.3378 12.4926 12.4988 12.4806L12.5679 12.4755C13.0878 12.4376 13.5543 12.4036 13.979 11.9789C14.4036 11.5542 14.4377 11.0877 14.4756 10.5679L14.4807 10.4987C14.4927 10.3378 14.5001 10.1698 14.5001 10C14.5001 9.83022 14.4927 9.66223 14.4807 9.5013L14.4756 9.43213C14.4377 8.91228 14.4036 8.44577 13.979 8.02111C13.5543 7.59644 13.0878 7.5624 12.5679 7.52447L12.4988 7.51939C12.3378 7.50741 12.1698 7.5 12.0001 7.5Z" />
      <path fillRule="evenodd" d="M18.2481 16.1025C18.0313 16.4183 17.7846 16.7243 17.2911 17.3364L16.2415 18.6384C14.6426 20.6217 13.8432 21.6134 12.8434 21.8861C12.2911 22.0367 11.7085 22.0367 11.1563 21.8861C10.1565 21.6133 9.35702 20.6217 7.75815 18.6383L6.70871 17.3366C6.21517 16.7244 5.9684 16.4183 5.75156 16.1025C4.75788 14.6556 4.16381 12.9728 4.02923 11.2237C4.00352 10.8895 4.0003 10.5468 3.99991 9.93923L3.99992 9.92365L3.99988 9.66334C3.99989 9.13855 4.03593 8.62705 4.07862 8.10669C4.36612 4.60222 5.9154 2.81616 9.40119 2.23101C10.2309 2.09173 11.1154 1.99902 11.9998 1.99902C12.8843 1.99902 13.7688 2.09173 14.5985 2.23101C18.0843 2.81616 19.6336 4.60222 19.9211 8.10669C19.9638 8.62705 19.9999 9.13851 19.9999 9.66332C19.9999 10.4491 19.9999 10.842 19.9705 11.2236C19.8359 12.9728 19.2419 14.6556 18.2481 16.1025ZM16.5995 14.9703C16.4372 15.2066 16.2489 15.4425 15.7443 16.0686L14.6845 17.3831C13.8582 18.408 13.3325 19.0563 12.8966 19.4965C12.4843 19.9129 12.3301 19.9531 12.3171 19.9565C12.1094 20.0132 11.8897 20.013 11.682 19.9564C11.669 19.953 11.5154 19.9129 11.1031 19.4965C10.6672 19.0563 10.1414 18.408 9.3152 17.3831L8.26576 16.0813C7.75335 15.4457 7.56357 15.2081 7.40023 14.9703C6.60569 13.8133 6.1309 12.4682 6.02333 11.0703C6.00441 10.8243 6.0003 10.5569 5.99991 9.93795L5.99992 9.9255L5.99988 9.66301C5.9999 9.22476 6.02998 8.78147 6.07192 8.27021C6.19555 6.7633 6.56774 5.92424 7.02942 5.40949C7.48412 4.90253 8.25856 4.45081 9.73229 4.20341C10.48 4.0779 11.251 3.99902 11.9998 3.99902C12.7487 3.99902 13.5197 4.0779 14.2674 4.20341C15.7411 4.45081 16.5156 4.90253 16.9703 5.40949C17.4319 5.92424 17.8041 6.7633 17.9278 8.27022C17.9697 8.78188 17.9999 9.22509 17.9999 9.66335C17.9999 10.4792 17.9985 10.7829 17.9764 11.0702C17.8689 12.4681 17.394 13.8133 16.5995 14.9703Z" />
    </SvgIcon>
  );
};

export default LocationIcon;
