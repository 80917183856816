import { styled } from '@mui/material/styles';

import landingImg from '../../images/landing_bg.png';

const LandingStyled = styled('div')({
  '.cover': {
    backgroundImage: `url(${landingImg})`,
    backgroundRepeat: 'no-repeat',
    width: '1376px',
    height: '572px',
    margin: '64px auto 90px',
  },
  '.cover-text-block': {
    padding: '60px 0 0 56px',
    width: '473px',
  },
  '.cover-header': {
    fontSize: '44px',
    lineHeight: '44px',
    color: '#000000',
    textTransform: 'uppercase',
    paddingBottom: '24px',
  },
  '.content-text': {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#000000',
    paddingBottom: '24px',
  },
  '.btn-action': {
    padding: '14px 32px 14px 32px',
    backgroundColor: '#E30611',
    transition: 'all 300ms linear',
    height: '52px',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 300ms linear',
      backgroundColor: '#E30611',
    },
  },
  '.landing-description': {
    width: '1048px',
    margin: '0 auto 90px auto',
  },
  '.description-header': {
    fontSize: '38px',
    fontWeight: 900,
    lineHeight: '36px',
    paddingBottom: '26px',
    textAlign: 'center',
  },
  '.description-content': {
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '28px',
  },
  '.landing-benefits': {
    background: '#F2F3F7',
    width: '1264px',
    margin: '0 auto 90px auto',
    padding: '40px 65px 68px 62px',
    borderRadius: '16px',
  },
  '.benefits-content': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    paddingLeft: '26px',
  },

  '.benefits-content-description': {
    paddingLeft: '26px',
  },
  '.soon': {
    padding: '1px 8px 0px',
    marginLeft: '8px',
    background: '#007CFF',
    width: '58px',
    height: '20px',
    borderRadius: '100px',
  },
  '.soon-text': {
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
  },
  '.landing-devices': {
    width: '1118px',
    margin: '0 auto 90px auto',
  },
  '.device-block': {
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '32px 126px 32px 32px',
    border: '5px solid #F2F3F7',
    borderRadius: '16px',
  },
  '.device-content': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 700,
    paddingLeft: '26px',
  },
  '.parameter-text': {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#1D2023',
  },
  '.parameter-btn': {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    height: '0',
    padding: '0',
  },
  '.device-img': {
    position: 'absolute',
    left: '665px',
    zIndex: 5,
  },
  '.landing-interface': {
    position: 'relative',
    background: '#F2F3F7',
    width: '1264px',
    margin: '0 auto 90px',
    padding: '40px 0 169px 32px',
    borderRadius: '16px',
  },
  '.landing-feedback': {
    background: '#2C3135',
    width: '1264px',
    margin: '0 auto 100px',
    padding: '40px 215px',
    borderRadius: '16px',
  },
  '.feedback-form': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});

export default LandingStyled;
