import { useQueryClient } from 'react-query';

import { useFormContext, Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import FormLabel from '../../components/FormLabel';
import { PERIMETER5_DEVICES_TYPES } from '../../constants';

import DeviceParamStatus from './DeviceParamStatus';

type Perimeter5DeviceEditProps = {
  deviceIndex: number;
  statuses: Record<string, boolean>;
};

const Perimeter5Device = (props: Perimeter5DeviceEditProps) => {
  const { deviceIndex, statuses } = props;

  const queryClient = useQueryClient();

  const deviceProfilesData: any = queryClient.getQueryData('deviceProfilesData');
  const Perimeter5ConnectionTypesData: any = queryClient.getQueryData(
    'Perimeter5ConnectionTypesData',
  );

  const { control } = useFormContext();

  return (
    <Grid container columnSpacing={4}>
      <Grid item mobile={12} xs={6}>
        <FormControl>
          <FormLabel>{deviceProfilesData?.attributes_mapping.sensor_type}</FormLabel>
          <Controller
            render={({ field }) => (
              <Select
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Выберите тип датчика</span>;
                  }

                  return PERIMETER5_DEVICES_TYPES[selected];
                }}
                {...field}
              >
                <MenuItem value="">Выберите тип датчика</MenuItem>
                {Object.entries(PERIMETER5_DEVICES_TYPES).map((item, index) => (
                  <MenuItem key={index} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
            )}
            name={`Perimeter5Devices.${deviceIndex}.sensor_type`}
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid item mobile={12} xs={6}>
        <FormControl>
          <FormLabel>{deviceProfilesData?.attributes_mapping.sensor_name}</FormLabel>
          <Controller
            render={({ field }) => <Input fullWidth {...field} />}
            name={`Perimeter5Devices.${deviceIndex}.sensor_name`}
            control={control}
          />
        </FormControl>
      </Grid>
      {Perimeter5ConnectionTypesData && (
        <Grid item mobile={12} xs={6}>
          <FormControl>
            <FormLabel>{deviceProfilesData?.attributes_mapping.connection_type}</FormLabel>
            <Controller
              render={({ field }) => (
                <Select
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span>Выберите тип передачи данных</span>;
                    }

                    return Perimeter5ConnectionTypesData[selected];
                  }}
                  SelectDisplayProps={{
                    className: statuses.hasOwnProperty('connection_type') ? 'MuiSelect-endAdornment' : '',
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <DeviceParamStatus statuses={statuses} field='connection_type' />
                    </InputAdornment>
                  }
                  {...field}
                >
                  <MenuItem value="">Выберите тип передачи данных</MenuItem>
                  {Object.entries(Perimeter5ConnectionTypesData).map((item: any, index: number) => (
                    <MenuItem key={index} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={`Perimeter5Devices.${deviceIndex}.connection_type`}
              control={control}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default Perimeter5Device;
