import { createContext, useState } from 'react';

const initialState = {
  userRights: {},
  setUserRights: () => {},
};

type IAppContext = {
  userRights?: {[k: string]: boolean};
  setUserRights: (rights: {[k: string]: boolean}) => void;
}

type AppContextProviderProps = {
  children: React.ReactNode
}

export const AppContext = createContext<IAppContext>(initialState);

const AppContextProvider = ({ children } : AppContextProviderProps) => {
  const [userRights, setUserRights] = useState<{[s: string]: boolean} | undefined>();

  return (
    <AppContext.Provider value={{
      userRights,
      setUserRights,
    }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider;
